import {
  ReportFiltersProvider,
  useFilters,
} from '../../../contexts/ReportFiltersProvider';
import { ConsumptionFilters } from '../../../domain/types';
import { add, startOfDay, sub } from 'date-fns';
import { ConsumptionReportContainer } from './ConsumptionReportContainer';
import React, { useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID, CONSUMPTION_REPORT_KEY } from '../../../domain/constants';
import { fromLocalStorage } from '../../../utils/localStorage';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

/**
 * Page container for the Consumption Report.
 * Initiates the report filters and renders the page components.
 */
export const ConsumptionReport = () => {
  const { selectedCustomerId } = useStateContext();

  const { defaultFilters, initialFilters } = useMemo(() => {
    const endDate = startOfDay(new Date());
    const startDate = add(sub(endDate, { years: 1 }), { days: 1 });

    const initialFilters: ConsumptionFilters = {
      timeInterval: 'month',
      breakdown: 'overall',
      usage: 'consumption_date',
      startDate,
      endDate,
      services: [],
      skus: [],
      skusGroups: [],
      resourcesLabels: [],
      projectsLabels: [],
      sdms: [],
      selectedCustomerId: selectedCustomerId,
    };

    const defaultFilters: ConsumptionFilters =
      fromLocalStorage(CONSUMPTION_REPORT_KEY, selectedCustomerId) ||
      initialFilters;

    defaultFilters.startDate = new Date(defaultFilters.startDate);
    defaultFilters.endDate = new Date(defaultFilters.endDate);

    return { initialFilters, defaultFilters };
  }, [selectedCustomerId]);

  return (
    <ReportFiltersProvider<ConsumptionFilters>
      defaultFilters={defaultFilters}
      initialFilters={initialFilters}
      reportKey={CONSUMPTION_REPORT_KEY}
    >
      <ReportContainer />
    </ReportFiltersProvider>
  );
};

/**
 * Set filters based on the page context.
 * Render the page components.
 */
const ReportContainer = () => {
  const {
    appliedFilters,
    setFilterValue,
    applyFilters,
  } = useFilters<ConsumptionFilters>();
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;
  const classes = useStyles();

  useEffect(() => {
    if (!isCustomerSelected) {
      applyFilters();
    }
  }, [isCustomerSelected, applyFilters, setFilterValue]);

  useEffect(() => {
    if (
      appliedFilters.timeInterval === 'doy' &&
      appliedFilters.selectedCustomerId === selectedCustomerId
    ) {
      setFilterValue('usage', 'consumption_date');
      applyFilters();
    }
  }, [
    appliedFilters.timeInterval,
    appliedFilters.selectedCustomerId,
    applyFilters,
    setFilterValue,
    selectedCustomerId,
  ]);

  useEffect(() => {
    setFilterValue('services', []);
  }, [selectedCustomerId, setFilterValue]);

  return (
    <div className={classes.container}>
      <ConsumptionReportContainer />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  disclaimer: {
    bottom: 0,
    left: 0,
    right: 0,
    background: '#f2f2ff',
    color: theme.palette.error.main,
    margin: 0,
    textAlign: 'center',
    position: 'fixed',
    display: 'flex',
    alignItems: 'flex-start',
  },
  disclaimerText: {
    padding: `0 ${theme.spacing(4)}`,
    textAlign: 'center',
    flex: 1,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
}));
