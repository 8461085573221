import { useStateContext } from '../../../contexts/StateProvider';
import { useParams } from 'react-router-dom';
import { QueryStatus } from '../../Messaging';
import { useProjectGroups } from './useProjectGroups';
import { useResetWhenCustomerChanges } from './useResetWhenCustomerChanges';
import { ProjectGroupProjectContainer } from './ProjectGroupProjectContainer';
import { PageHeader } from '../../PageHeader';

export const ProjectGroupProjectAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const { groupId } = useParams<{ groupId: string }>();

  const {
    isLoading,
    isError,
    projectGroupIds,
    projectGroupIdsToName,
    selectedGroupId,
  } = useProjectGroups(selectedCustomerId, Number(groupId));

  useResetWhenCustomerChanges(selectedCustomerId);

  if (isLoading || isError) {
    return <QueryStatus isError={isError} isLoading={isLoading} />;
  }

  return (
    <>
      <PageHeader title="Grouped Projects" />
      <ProjectGroupProjectContainer
        projectGroupIds={projectGroupIds}
        selectedGroupId={selectedGroupId}
        getGroupName={(id) => projectGroupIdsToName.get(id) ?? ''}
      />
    </>
  );
};
