import { Box, Button, Typography } from '@mui/material';
import { ReactNode, useRef } from 'react';
import { useStyles } from './FilterBar.stylesheet';
import FilterListIcon from '@mui/icons-material/FilterList';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export interface ConsumptionFiltersProps {
  children: ReactNode;
  onSubmit: () => void;
  onReset: () => void;
}

export const FilterBar = ({
  children,
  onSubmit,
  onReset,
}: ConsumptionFiltersProps) => {
  const filterBarContainerRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  return (
    <div className={classes.filterBarWrapper}>
      <div
        data-testid="filter-bar"
        className={classes.root}
        ref={filterBarContainerRef}
      >
        <div className={classes.drawerHeader}></div>
        <div className={classes.header}>
          <FilterListIcon />
          <Typography className={classes.headerTitle}>Filters</Typography>
        </div>
        <div className={classes.filters}>{children}</div>

        <div className={classes.buttons}>
          <div className={classes.buttonsContent}>
            <Button
              className={classes.filtersButton}
              data-testid="update-filters"
              color="primary"
              variant="contained"
              onClick={onSubmit}
              fullWidth
            >
              <DoneAllIcon />
              <Box ml={0.5}>Update</Box>
            </Button>
            <Button
              className={classes.filtersButton}
              data-testid="reset-filters"
              variant="outlined"
              onClick={onReset}
              fullWidth
            >
              <RotateLeftIcon />
              <Box ml={0.5}>Reset</Box>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
