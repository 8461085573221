import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { adminPaths } from '../../Routes/AdminRoutes';

export const useResetWhenCustomerChanges = (customerId: number) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();

  const isAtBasePath = useMemo(() => {
    return url === adminPaths.projectGroupProjects();
  }, [url]);

  const previousCustomerId = useRef(customerId);
  useEffect(() => {
    if (!isAtBasePath && previousCustomerId.current !== customerId) {
      push(adminPaths.projectGroupProjects());
    }
    previousCustomerId.current = customerId;
  }, [customerId, isAtBasePath, push]);
};
