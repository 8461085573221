import { ReportFiltersProvider } from '../../../contexts/ReportFiltersProvider';
import { BQ_USAGE_REPORT_KEY } from '../../../domain/constants';
import { BQUsageFilters } from '../../../domain/types';
import { BigQueryUsageReportContainer } from './components/BigQueryUsageReportContainer';
import { useDefaultFiltersValue } from './hooks/useDefaultFiltersValue';

export const BigQueryUsageReport = () => {
  const { initialFilters, defaultFilters } = useDefaultFiltersValue();

  return (
    <ReportFiltersProvider<BQUsageFilters>
      initialFilters={initialFilters}
      defaultFilters={defaultFilters}
      reportKey={BQ_USAGE_REPORT_KEY}
    >
      <BigQueryUsageReportContainer />
    </ReportFiltersProvider>
  );
};
