import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const appThemeBase = createTheme({
  palette: {
    primary: {
      main: '#43358a',
    },
  },
  components: {
    /*

    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(0, -1.5px) scale(0.75)',
        },
      },
    },
     */
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          zIndex: 99999,
        },
      },
    },
  },
});

export const appTheme = responsiveFontSizes(appThemeBase);
