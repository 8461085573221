import { InputAdornment, TextField } from '@mui/material';

interface ComponentProps {
  value: number;
  onChange: (value: number) => void;
}

export const PercentageInput = ({ value, onChange }: ComponentProps) => {
  return (
    <TextField
      value={value}
      onChange={(event) => onChange(+event.target.value)}
      sx={{ width: 40 }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};
