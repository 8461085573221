import { debounce, IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useMemo } from 'react';
import { Clear } from '@mui/icons-material';

export const SEARCH_DEBOUNCE_TIME_MS = 500;

export interface SearchFieldProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  filterBySearchTerm: (searchTerm?: string) => void;
  style?: any;
}

const debouncedSearch = (filter: () => void) => {
  return debounce(() => {
    filter();
  }, SEARCH_DEBOUNCE_TIME_MS);
};

export const SearchField = ({
  searchTerm,
  setSearchTerm,
  filterBySearchTerm,
  style,
}: SearchFieldProps) => {
  const classes = useStyles();
  const hasSearchTerm = searchTerm !== '';
  // applying the search is debounced so that a full search only happens when the user has finished typing
  const applySearch = useMemo(() => debouncedSearch(filterBySearchTerm), [
    filterBySearchTerm,
  ]);
  return (
    <div className={classes.root} style={style}>
      <TextField
        fullWidth
        value={searchTerm}
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          const value = e.target.value;
          setSearchTerm(value);
          if (value.length > 2 || value === '') {
            applySearch();
          }
        }}
      />
      <div className={classes.clear}>
        {hasSearchTerm && (
          <IconButton
            size="small"
            aria-label="Clear search"
            onClick={() => filterBySearchTerm('')}
          >
            <Clear fontSize="small" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  clear: {
    width: 30,
  },
});
