import { useAuthContext } from '../contexts/AuthProvider';
import {
  CUSTOMER_ROLE_PREFIX,
  Role,
  SYSTEM_ROLE_PREFIX,
} from '../domain/types';

export const hasRole = (role: Role | null, rolesToCheck: Role[]) => {
  return !!role && rolesToCheck.includes(role);
};

export const useHasRole = (...role: Role[]) => {
  const { userRole } = useAuthContext();
  return hasRole(userRole, role);
};

export const useHasSystemRole = () => {
  const { userRole } = useAuthContext();
  return isSystemRole(userRole);
};

export const isSystemRole = (role?: Role | null) => {
  return role?.startsWith(SYSTEM_ROLE_PREFIX) ?? false;
};

export const useHasCustomerRole = () => {
  const { userRole } = useAuthContext();
  return isCustomerRole(userRole);
};

export const isCustomerRole = (role?: Role | null) => {
  return role?.startsWith(CUSTOMER_ROLE_PREFIX) ?? false;
};

export const isCustomerViewerRole = (role?: Role | null) => {
  return role === Role.CUSTOMER_VIEWER;
};
