import { format } from 'date-fns';
import React from 'react';
import { API_DATE_FORMAT, UI_DATE_FORMAT } from '../../domain/constants';
import { DatePickerProps, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export type DatePickerFieldProps = {
  onChange?: (val: string | null) => void;
  uiFormat?: string;
  id?: string;
} & Omit<DatePickerProps<any>, 'onChange'>;

export const DatePickerField = ({
  onChange,
  uiFormat,
  ...datepickerProps
}: DatePickerFieldProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format={uiFormat ?? UI_DATE_FORMAT}
        {...datepickerProps}
        slotProps={{
          dialog: {
            'data-testid': 'datepicker',
          } as any,
        }}
        onChange={(e: Date | null) => {
          const formatedDate = e ? format(e, API_DATE_FORMAT) : null;
          onChange?.(formatedDate);
        }}
      />
    </LocalizationProvider>
  );
};
