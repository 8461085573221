import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import { ComposedChart, CartesianGrid, ResponsiveContainer } from 'recharts';

export interface ChartContainerProps<T> {
  data?: T[];
  children: ReactNode;
}

export const CHART_HEIGHT = 400;

export function ChartContainer<T>({ data, children }: ChartContainerProps<T>) {
  const classes = useStyles();

  return (
    <div className={classes.chartContainerRoot}>
      <div className={classes.chartContainerInner}>
        <ResponsiveContainer>
          <ComposedChart
            width={500}
            data={data}
            height={CHART_HEIGHT}
            className={classes.chart}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {children}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  chart: {
    zIndex: 100, // for hover tooltip
  },
  chartContainerRoot: {
    width: 'calc(100% - 8px)',
    height: CHART_HEIGHT,
    position: 'relative',
  },
  chartContainerInner: {
    width: '100%',
    height: CHART_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});
