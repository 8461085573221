import { DateType, formatDate } from '../utils/dateUtils';
import { UI_DATE_FORMAT } from '../domain/constants';

export interface DateDisplayProps {
  date: DateType;
  format?: string;
}

export const DateDisplay = ({
  date,
  format = UI_DATE_FORMAT,
}: DateDisplayProps) => {
  return <>{formatDate(date, format)}</>;
};
