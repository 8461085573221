import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';
import { Breakpoint, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface ModalTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

export const ModalTitle = withStyles(styles)((props: ModalTitleProps) => {
  const { children, classes, onClose, showCloseIcon } = props;

  return (
    <MuiDialogTitle className={classes.root} variant="h6">
      <div className={classes.title}>{children}</div>
      {onClose && showCloseIcon ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const ModalContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
}))(MuiDialogContent);

export const ModalActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export interface ModalProps {
  open?: boolean;
  dividers?: boolean;
  title?: ReactNode;
  content: ReactNode;
  actions?: ReactNode;
  showCloseIcon?: boolean;
  id: string;
  onClose: () => void;
  maxWidth?: Breakpoint;
}

export default function Modal({
  id,
  open = false,
  dividers = true,
  onClose,
  title,
  content,
  actions = (
    <Button autoFocus onClick={onClose} color="primary">
      Close
    </Button>
  ),
  showCloseIcon = true,
  maxWidth = 'sm',
}: ModalProps) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby={id}
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      {title && (
        <ModalTitle id={id} onClose={onClose} showCloseIcon={showCloseIcon}>
          {title}
        </ModalTitle>
      )}
      <ModalContent dividers={dividers}>{content}</ModalContent>
      <ModalActions>{actions}</ModalActions>
    </Dialog>
  );
}
