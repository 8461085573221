import { ServerHandler } from '../ServerHandler';
import { rest, RestHandler } from 'msw';
import {
  getMockForecastDataDaily,
  getMockForecastDataMonthly,
  getMockConsumptionData,
  getBurndownData,
  isProjectGroupIdUnallocated,
} from '../data/reportData';
import { apiPaths } from '../../utils/apiPaths';
import {
  BurndownFiltersDto,
  ConsumptionFiltersDto,
  CommitTrackerFiltersDto,
} from '../../domain/types';
class ChartHandlers implements ServerHandler {
  public resetData() {}

  private handleConsumptionData(path: string): RestHandler {
    return rest.post<ConsumptionFiltersDto>(path, async (req, res, ctx) => {
      const { timeInterval, breakdown, summary } = req.body;

      const data = getMockConsumptionData({
        timeInterval,
        breakdown: breakdown ?? 'overall',
        summary,
      });

      return res(ctx.status(200), ctx.json(data));
    });
  }

  private handleBurndownData(path: string): RestHandler {
    return rest.post<BurndownFiltersDto>(path, async (req, res, ctx) => {
      const { timeInterval } = req.body;
      const { projectGroupId } = req.body;

      const data = isProjectGroupIdUnallocated(projectGroupId)
        ? getBurndownData(timeInterval)
        : null;

      return res(ctx.status(200), ctx.json(data));
    });
  }

  private handleForecastData(path: string): RestHandler {
    return rest.post<CommitTrackerFiltersDto>(path, async (req, res, ctx) => {
      const { customerId, timeInterval } = req.body;
      const data =
        timeInterval === 'doy'
          ? getMockForecastDataDaily(customerId)
          : getMockForecastDataMonthly(customerId);
      return res(ctx.status(200), ctx.json(data));
    });
  }

  private handleConsumptionCurrentMonthForecastData(): RestHandler {
    const path = `/api${
      apiPaths.consumptionCurrentMonthForecastData(':customerId' as any).base
    }`;

    return rest.post(path, (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    });
  }

  public handlers() {
    return [
      // consumption data for all customers:
      this.handleConsumptionData(`/api${apiPaths.consumptionData().base}`),
      // consumption data for single customer:
      this.handleConsumptionData(
        `/api${apiPaths.consumptionData(':customerId' as any).base}`
      ),
      this.handleConsumptionCurrentMonthForecastData(),
      // commit tracking data, customer is handled in the body:
      this.handleForecastData(`/api${apiPaths.commitTracker().base}`),
      // burndown data for single customer:
      this.handleBurndownData(
        `/api${apiPaths.budgetRemainingData(':customerId' as any).base}`
      ),
    ];
  }
}

export const chartHandler = new ChartHandlers();
