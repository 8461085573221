import { useMutation, useQueryClient } from 'react-query';
import { useStateContext } from '../../../../contexts/StateProvider';
import { DisclaimerMessage } from '../../../../domain/types';
import { POST, PUT } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';

export const useDisclaimerMutation = ({
  disclaimer,
  onSettled,
  type,
}: {
  disclaimer: Partial<DisclaimerMessage>;
  onSettled: () => void;
  type: 'create' | 'update';
}) => {
  const queryClient = useQueryClient();
  const apiPath = apiPaths.disclaimerManager();
  const { showNotification } = useStateContext();
  const activeDisclaimerPath = apiPaths.disclaimerMessageActive();
  const HTTP_ACTION = type === 'create' ? POST : PUT;
  const url =
    type === 'create' ? apiPath.base : `${apiPath.base}/${disclaimer.id}`;

  const { mutate } = useMutation(
    apiPath.key.item(disclaimer?.id ?? -1),
    () =>
      HTTP_ACTION(url, {
        title: disclaimer.title,
        description: disclaimer.description,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.list());
        queryClient.invalidateQueries(activeDisclaimerPath.key.namespace());
        showNotification({
          message: 'Item created successfully',
          severity: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: 'There was an error. Please try again.',
          severity: 'error',
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );

  return mutate;
};
