import { useCallback, useMemo } from 'react';
import {
  CommitType,
  CommitTrackerData,
  CommitTrackerFilters,
  NameTotal,
} from '../../../domain/types';

interface Response {
  softIntersection: NameTotal | null;
  hardIntersection: NameTotal | null;
}

export const useCommitTrackerReportIntersections = (
  data: CommitTrackerData | undefined,
  appliedFilters: CommitTrackerFilters
): Response => {
  const findIntersection = useCallback(
    (amount: number | undefined): NameTotal | null => {
      if (!amount) {
        return null;
      }
      const combinedData = [
        ...(data?.consumption ?? []),
        ...(data?.forecast ?? []),
      ];
      return combinedData.find((item) => item.total >= amount) ?? null;
    },
    [data?.consumption, data?.forecast]
  );

  const findIntersectionForType = useCallback(
    (typeToExclude: CommitType, amount?: number) => {
      if (
        !appliedFilters.selectedYear ||
        appliedFilters.selectedType === typeToExclude
      ) {
        return null;
      }
      return findIntersection(amount);
    },
    [appliedFilters.selectedType, appliedFilters.selectedYear, findIntersection]
  );

  const softIntersection = useMemo(
    () =>
      findIntersectionForType(
        CommitType.HARD,
        appliedFilters.selectedYear?.softAmount
      ),
    [appliedFilters.selectedYear?.softAmount, findIntersectionForType]
  );
  const hardIntersection = useMemo(
    () =>
      findIntersectionForType(
        CommitType.SOFT,
        appliedFilters.selectedYear?.adjustedCommitAmount ??
          appliedFilters.selectedYear?.hardAmount
      ),
    [
      appliedFilters.selectedYear?.hardAmount,
      appliedFilters.selectedYear?.adjustedCommitAmount,
      findIntersectionForType,
    ]
  );

  return { softIntersection, hardIntersection };
};
