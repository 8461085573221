import React, { createContext, useCallback, useContext, useState } from 'react';
import firebase from '../config/firebase';
import { Customer, Role, User } from '../domain/types';
import {
  useAuthReauthenticate,
  useAuthTokenUpdate,
  useAuthHeaderUpdate,
  useGetCurrentUser,
} from './useAuthProviderHooks';
import { useIdleTimer } from 'react-idle-timer';

export type AuthContextProps = {
  user: firebase.User | null;
  setUser: (user: firebase.User | null) => void;
  userRole: Role | null;
  linkedCustomer: Customer | null;
  signOut: () => void;
  authenticated: boolean;
  loadingAuthState: boolean;
  setLoadingAuthState: (loading: boolean) => void;
  authorizationError: boolean;
};

export const DEFAULT_AUTH_CONTEXT: AuthContextProps = {
  user: null,
  setUser: () => {},
  userRole: null,
  linkedCustomer: null,
  signOut: () => {},
  authenticated: false,
  loadingAuthState: false,
  setLoadingAuthState: () => {},
  authorizationError: false,
};

export const AuthContext = createContext<AuthContextProps>(
  DEFAULT_AUTH_CONTEXT
);

const ONE_SECOND_IN_MILISECONDS = 1000;
const ONE_MINUTE_IN_SECONDS = 60;
const SESSION_IDLE_TIMEOUT =
  ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILISECONDS * 15;

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userRole, setUserRole] = useState<Role | null>(null);
  const [linkedCustomer, setLinkedCustomer] = useState<Customer | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [authorizationError, setAuthorizationError] = useState(false);
  const [interceptor, setInterceptor] = useState<number | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);

  const signOut = () => {
    setLinkedCustomer(null);
    setAuthorizationError(false);
    setUserRole(null);
    setUser(null);
    setAuthToken(null);
    setInterceptor(null);
  };

  const reauthenticate = useCallback(async () => {
    const firebaseUser = firebase.auth().currentUser;
    const newToken = await firebaseUser?.getIdToken(true);
    setAuthToken(newToken ?? null);
  }, [setAuthToken]);

  useIdleTimer({
    timeout: SESSION_IDLE_TIMEOUT,
    onIdle: () => reauthenticate(),
  });

  useAuthReauthenticate({ reauthenticate, setAuthToken });

  useAuthTokenUpdate({ setAuthToken, setUser, setLoadingAuthState });

  useAuthHeaderUpdate({ authToken, setInterceptor });

  useGetCurrentUser({
    user,
    interceptor,
    setLoadingAuthState,
    setAuthorizationError,
    setUserRole,
    setLinkedCustomer,
  });

  return (
    <AuthContext.Provider
      value={{
        authenticated: user !== null,
        authorizationError,
        linkedCustomer,
        loadingAuthState,
        setLoadingAuthState,
        user,
        userRole,
        setUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
