import { Autocomplete, TextField } from '@mui/material';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { PagedItems, Project } from '../../../domain/types';
import { ProjectGroupProjectFormValues } from './ProjectGroupProjectSchema';
import { apiPaths } from '../../../utils/apiPaths';
import { useStateContext } from '../../../contexts/StateProvider';
import { useQuery } from 'react-query';
import { GET_PAGED } from '../../../utils/api';
import { useParams } from 'react-router-dom';

export const ProjectGroupProjectForm = () => {
  const { control, errors } = useFormContext<ProjectGroupProjectFormValues>();
  const { selectedCustomerId } = useStateContext();
  const { groupId } = useParams<{ groupId: string }>();

  // get available projects for the group
  const projectApi = apiPaths.projectGroupProjects(
    selectedCustomerId,
    Number(groupId)
  );
  const { data, isLoading } = useQuery<PagedItems<Project>>(
    projectApi.key.list({ available: true }),
    () => GET_PAGED(projectApi.available)
  );

  const projects = data?.items ?? [];
  const projectIds = projects.map(({ id }) => id);
  const projectIdsToNameMap = projects.reduce((map, project) => {
    return map.set(
      project.id,
      `${project.gcpProjectName} (${project.gcpProjectId})`
    );
  }, new Map<number, string>());

  return (
    <>
      <Controller
        control={control}
        name="project_id"
        defaultValue={null}
        render={({ onChange, ...props }) => (
          <Autocomplete<number>
            options={projectIds}
            loading={isLoading}
            noOptionsText="All available projects have been added to this group"
            onChange={(e, value) => {
              onChange(value);
            }}
            getOptionLabel={(id) => projectIdsToNameMap.get(id) ?? ''}
            renderInput={(params) => (
              <CrudFormControl error={errors.project_id}>
                <TextField
                  {...params}
                  label="Project"
                  error={!!errors.project_id}
                />
              </CrudFormControl>
            )}
            {...props}
          />
        )}
      />
    </>
  );
};
