import { useEffect, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';
import { PagedItems, Project, ProjectGroup } from '../domain/types';
import { apiPaths } from '../utils/apiPaths';
import { useStateContext } from '../contexts/StateProvider';
import { GET } from '../utils/api';
import {
  ProjectGroupsState,
  createProjectGroupsSlice,
  createInitialState,
} from './projectGroupsSlice';
import { VOID_ID } from '../domain/constants';

export interface UseProjectGroupActions {
  toggleAll: () => void;
  clearAll: () => void;
  setSearchTerm: (term: string) => void;
  filterBySearchTerm: (term?: string) => void;
  getProjectsSuccess: (groupId: number, projects: PagedItems<Project>) => void;
  toggleGroup: (groupId: number) => void;
  toggleProject: (groupId: number, projectId: number) => void;
  selectGroup: (groupId: number) => void;
  selectProject: (groupId: number, projectId: number) => void;
  toggleGroupExpandedState: (groupId: number) => void;
  resetStates: () => void;
}

interface Response {
  state: ProjectGroupsState;
  actions: UseProjectGroupActions;
}

interface Props {
  includeProjects: boolean;
  overrideSelectedCustomerId?: number;
  projectsDefaultFilters?: any;
  includeUngroupedProjects?: boolean;
  isOnBudgetContext?: boolean;
  includeBillingAccountCharges?: boolean;
}

export const useProjectGroups = ({
  includeProjects,
  overrideSelectedCustomerId,
  projectsDefaultFilters,
  includeUngroupedProjects,
  isOnBudgetContext,
  includeBillingAccountCharges,
}: Props): Response => {
  const { actions, reducer, initialState } = useMemo(() => {
    const initialState = createInitialState(projectsDefaultFilters);
    const { actions, reducer } = createProjectGroupsSlice(initialState);
    return { actions, reducer, initialState };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { selectedCustomerId } = useStateContext();
  const selectedCustomer = overrideSelectedCustomerId
    ? overrideSelectedCustomerId
    : selectedCustomerId;

  const projectGroupApi = apiPaths.projectGroup(selectedCustomer);

  // fetch project groups whenever the customer id changes
  useEffect(() => {
    dispatch(actions.getProjectGroups());
  }, [actions, selectedCustomer]);

  const params = useMemo(() => {
    let params: Record<string, string | number | boolean> = {
      isOnBudgetContext: !!isOnBudgetContext,
      includeBillingAccountCharges: !!includeBillingAccountCharges,
    };
    if (includeProjects) {
      params.include_projects = true;
      params.include_ungrouped_projects = includeUngroupedProjects ?? false;
    }

    return params;
  }, [
    includeProjects,
    isOnBudgetContext,
    includeUngroupedProjects,
    includeBillingAccountCharges,
  ]);

  useQuery<PagedItems<ProjectGroup>>(
    projectGroupApi.key.list(params),
    () => GET(projectGroupApi.base, params),
    {
      onSuccess: (projectGroups) => {
        dispatch(
          actions.getProjectGroupsSuccess({
            queryFilters: initialState.queryFilters,
            projectGroups,
          })
        );
      },
      retry: false,
      enabled: selectedCustomer !== VOID_ID && state.loading,
    }
  );

  return {
    state,
    actions: useMemo(
      () => ({
        toggleAll: () => dispatch(actions.toggleAll()),
        clearAll: () => dispatch(actions.clearAll()),
        setSearchTerm: (term: string) => dispatch(actions.setSearchTerm(term)),
        filterBySearchTerm: (term?: string) =>
          dispatch(actions.filterBySearchTerm(term)),
        getProjectsSuccess: (groupId: number, projects: PagedItems<Project>) =>
          dispatch(actions.getProjectsSuccess({ groupId, projects })),
        toggleGroup: (groupId: number) =>
          dispatch(actions.toggleGroup(groupId)),
        toggleProject: (groupId: number, projectId: number) =>
          dispatch(actions.toggleProject({ groupId, projectId })),
        selectGroup: (groupId: number) =>
          dispatch(actions.selectGroup(groupId)),
        selectProject: (groupId: number, projectId: number) =>
          dispatch(actions.selectProject({ groupId, projectId })),
        toggleGroupExpandedState: (groupId: number) =>
          dispatch(actions.toggleGroupExpandedState(groupId)),
        resetStates: () => dispatch(actions.resetStates()),
      }),
      [actions]
    ),
  };
};
