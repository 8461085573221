import { Box, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import { SystemStatus } from '../Messaging/SystemStatus';

interface ComponentProps {
  renderTitle: () => ReactNode;
  renderSideControls?: () => ReactNode;
}

export function ChartHeader({
  renderTitle,
  renderSideControls,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.chartHeader}>
      <div className={classes.chartHeaderContent}>
        {renderTitle && renderTitle()}
        <Box display="flex" justifyContent="center" alignItems="center">
          {renderSideControls && renderSideControls()}
          <SystemStatus />
        </Box>
      </div>
      <Divider />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  chartHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chartHeaderContent: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
