import { Checkbox, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PagedItems } from '../../../domain/types';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import { DATA_GRID_PAGE_LIMIT } from '../../../domain/constants';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { CrudDataGridError } from './CrudDataGridError';
import { QueryLoader } from '../../Messaging/QueryStatus';

export interface CrudTableProps<T> {
  columns: GridColDef[];
  data?: PagedItems<T>;
  loading: boolean;
  error: any;
  selectedRowId: number | null;
  setSelectedRowId: (id: number | null) => void;
  defaultSortColumn: string;
  enableUpdate: boolean;
  enableDelete: boolean;
  editText?: string;
  enableDeleteOnRow?: (row: T) => boolean;
  enableUpdateOnRow?: (row: T) => boolean;
}

export function CrudDataGrid<T>({
  columns,
  data,
  loading,
  error,
  selectedRowId,
  setSelectedRowId,
  defaultSortColumn,
  enableUpdate,
  enableDelete,
  editText,
  enableDeleteOnRow,
  enableUpdateOnRow,
}: CrudTableProps<T>) {
  const classes = useStyles();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GRID_PAGE_LIMIT,
  });

  const { url: routePath } = useRouteMatch();
  const editIconTitle = editText ?? '';

  const updateSelectedRowId = useCallback(
    (id: string | number, selected: boolean) => {
      setSelectedRowId(selected ? (id as number) : null);
    },
    [setSelectedRowId]
  );
  const cols = useMemo<GridColDef[]>(() => {
    const cols: GridColDef[] = [];
    // rows are selectable if deleting is enabled
    if (enableDelete) {
      cols.push({
        field: 'select',
        width: 40,
        headerClassName: classes.checkbox,
        cellClassName: classes.checkbox,
        renderHeader: () => <span />,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          const disabled = enableDeleteOnRow ? !enableDeleteOnRow(row) : false;
          return (
            <Checkbox
              disabled={disabled}
              checked={row.id === selectedRowId}
              onChange={(e) => {
                updateSelectedRowId(row.id, e.target.checked);
              }}
              size="small"
              color="primary"
            />
          );
        },
      });
    }
    cols.push(...columns);
    if (enableUpdate) {
      cols.push({
        field: 'edit',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderHeader: () => <span />,
        renderCell: ({ row }) => {
          const { id } = row;
          const disabled = enableUpdateOnRow ? !enableUpdateOnRow(row) : false;

          return (
            <Tooltip title={editIconTitle} placement="left" arrow>
              <IconButton
                disabled={disabled}
                className="edit-button"
                component={Link}
                to={`${routePath}/${id}/edit`}
                size="small"
                aria-label="Edit"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        },
      });
    }
    return cols;
  }, [
    classes.checkbox,
    columns,
    enableDelete,
    enableUpdate,
    editIconTitle,
    routePath,
    selectedRowId,
    updateSelectedRowId,
    enableDeleteOnRow,
    enableUpdateOnRow,
  ]);

  useEffect(() => {
    setPaginationModel((value) => ({ ...value, page: 0 }));
  }, [data?.items?.length]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: defaultSortColumn,
      sort: 'asc',
    },
  ]);

  const rowCount = loading ? undefined : data?.total_items ?? 0;
  const rows = useMemo(() => data?.items ?? [], [data?.items]);

  return (
    <GridWidgetContainer>
      {error ? (
        <CrudDataGridError />
      ) : (
        <DataGrid
          // data
          className={classes.grid}
          columns={cols}
          columnBuffer={cols.length + 2}
          rows={rows}
          // state
          loading={loading}
          onPaginationModelChange={(model) => setPaginationModel(model)}
          paginationModel={paginationModel}
          pageSizeOptions={[DATA_GRID_PAGE_LIMIT]}
          onFilterModelChange={() =>
            setPaginationModel((value) => ({ ...value, page: 0 }))
          }
          density="compact"
          // selection
          rowSelectionModel={selectedRowId ? [selectedRowId] : []}
          disableRowSelectionOnClick
          // sorting
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          // pagination
          rowCount={rowCount}
          slots={{
            loadingOverlay: () => (
              <GridOverlay>
                <QueryLoader />
              </GridOverlay>
            ),
          }}
        />
      )}
    </GridWidgetContainer>
  );
}

const useStyles = makeStyles({
  checkbox: {
    '&.MuiDataGrid-cell': {
      padding: 0,
    },
  },
  grid: {
    '& .MuiDataGrid-row': {
      '& .edit-button': {
        opacity: 0.1,
      },
      '&:hover': {
        '& .edit-button': {
          opacity: 1,
        },
      },
    },
  },
});
