import { useState, useEffect } from 'react';

export const useSearchValue = <T, K>(
  selectedKeys: K[],
  filteredItems: T[],
  items: T[],
  itemLabel: string,
  singleItem?: boolean
) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (singleItem) {
      setSearchValue(selectedKeys[0] ? `${selectedKeys[0]}` : '');
    } else if (!selectedKeys || selectedKeys.length === 0) {
      setSearchValue(`All ${itemLabel} (${items.length})`);
    } else if (selectedKeys.length === items.length) {
      setSearchValue(`All ${itemLabel} (${items.length})`);
    } else if (selectedKeys.length > 0) {
      setSearchValue(
        `${selectedKeys.length} out of ${items.length} ${itemLabel}`
      );
    }
  }, [selectedKeys, filteredItems, items, itemLabel, singleItem]);

  return searchValue;
};
