import { useState } from 'react';

export interface CookieObject {
  key: string;
  value: string;
  path?: string;
  expires?: string;
}

const readCookie = () => {
  return document.cookie
    .split('; ')
    .filter((cookie: string) => !!cookie)
    .map((cookie) => {
      const [key, value, expires, path] = cookie
        .split('=')
        .map((value) => value && value.trim());

      return {
        key: key,
        value,
        expires,
        path,
      };
    })
    .reduce((cookieMap, cookie) => {
      cookieMap.set(cookie.key, cookie);
      return cookieMap;
    }, new Map<string, CookieObject>());
};

const writeCookie = (cookie: CookieObject) => {
  const cookieStr = `${cookie.key}=${cookie.value};expires=${cookie.expires};path=${cookie.path}`;
  document.cookie = cookieStr;
};

export const useCookie = (
  key: string
): [CookieObject | undefined, (value: CookieObject) => void] => {
  const cookies = readCookie();
  const [cookie, setCookie] = useState<CookieObject | undefined>(
    cookies.get(key)
  );

  const setCookieHandler = (value: CookieObject) => {
    setCookie(value);
    writeCookie(value);
  };

  return [cookie, setCookieHandler];
};
