import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useRef } from 'react';

// eslint-disable-next-line
const JSON_REGEX = /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g;

function syntaxHighlight(json: string, classMap: Map<string, string>) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(JSON_REGEX, function (match) {
    var cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return '<span class="' + classMap.get(cls) + '">' + match + '</span>';
  });
}

export const RecommenderDetails = ({ details }: { details: object }) => {
  const preRef = useRef<HTMLPreElement>(null);
  const classes = useStyles();

  useEffect(() => {
    const jsonBody = JSON.stringify(details, null, 2);
    const classMap = new Map([
      ['key', classes.key],
      ['number', classes.number],
      ['string', classes.string],
      ['boolean', classes.boolean],
      ['null', classes.null],
    ]);
    const parsedJson = syntaxHighlight(jsonBody, classMap);

    if (preRef.current) {
      preRef.current.innerHTML = parsedJson;
    }
  }, [details, classes]);

  return <pre className={classes.jsonContainer} ref={preRef}></pre>;
};

const useStyles = makeStyles((theme) => ({
  key: {
    color: '#e67e22',
  },
  number: {
    color: '#16a085',
  },
  string: {
    color: '#2ecc71',
  },
  boolean: {
    color: '#8e44ad',
  },
  null: {
    color: '#f1c40f',
  },
  jsonContainer: {
    background: '#2c3e50',
    color: 'white',
    padding: theme.spacing(1, 1),
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
}));
