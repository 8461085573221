import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { Budget, BudgetAllocationType } from '../../../domain/types';
import { useStateContext } from '../../../contexts/StateProvider';
import { GridColDef } from '@mui/x-data-grid';
import { BudgetSchema, BudgetValues } from './BudgetSchema';
import { BudgetForm } from './BudgetForm';
import { useGetCustomerCurrency } from '../BudgetAllocations/useGetCustomerCurrency';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { DateDisplay } from '../../DateDisplay';
import { NoItems } from '../../Messaging';

export const BudgetAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  const columns: GridColDef[] = [
    {
      headerName: 'Type',
      field: 'allocationType',
      renderCell: (params) => {
        return (
          <>
            {params.row.allocationType === BudgetAllocationType.PROJECT
              ? 'Project'
              : 'Group'}
          </>
        );
      },
      flex: 1,
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      flex: 1,
      renderCell: (params) => <DateDisplay date={params.row.startDate} />,
    },
    {
      headerName: 'Renewal Date', // labelled as Renewal date
      field: 'endDate',
      flex: 1,
      renderCell: (params) => <DateDisplay date={params.row.endDate} />,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      renderCell: (params) => {
        return (
          <MonetaryDisplay value={params.row.amount} currency={currency} />
        );
      },
    },
  ];

  if (currency) {
    return (
      <CrudContainer<Budget, BudgetValues>
        name="Budgets"
        apiPath={apiPaths.budget(selectedCustomerId)}
        columns={columns}
        defaultSortColumn="startDate"
        FormComponent={BudgetForm}
        validationSchema={BudgetSchema}
      />
    );
  }

  return <NoItems forMessage="billing accounts" severity="warning" />;
};
