import {
  Checkbox,
  ListItemIcon,
  ListItem,
  Typography,
  FormControlLabel,
  Theme,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

interface DropdownFilterListBoxProps<T, K> {
  filteredItems: T[];
  selectedKeys: K[];
  onChange: (serviceIds: K[]) => void;
  getItemKey: (item: T) => K;
  getItemLabel: (item: T) => string;
  singleItem?: boolean;
}

export const DropdownFilterListBox = <T, K>({
  filteredItems,
  selectedKeys,
  onChange,
  getItemKey,
  getItemLabel,
  singleItem,
}: DropdownFilterListBoxProps<T, K>) => {
  const selectionState = useMemo(() => {
    return filteredItems.reduce((state: Map<K, boolean>, item: T) => {
      state.set(
        getItemKey(item),
        selectedKeys.indexOf(getItemKey(item)) !== -1
      );
      return state;
    }, new Map<K, boolean>());
  }, [filteredItems, selectedKeys, getItemKey]);

  const classes = useStyles();

  const handleToggle = (itemKey: K) => () => {
    if (singleItem) {
      onChange([itemKey]);
      return;
    }

    const index = selectedKeys.indexOf(itemKey);
    const isSelected = index !== -1;

    if (isSelected) {
      const selectionState = [...selectedKeys];
      selectionState.splice(index, 1);
      onChange(selectionState);
    } else {
      onChange([...selectedKeys, itemKey]);
    }
  };

  return (
    <div data-testid="filter-list-box">
      <FixedSizeList
        width={340}
        height={300}
        itemSize={30}
        itemData={filteredItems}
        itemCount={filteredItems.length}
      >
        {({ index, style }) => {
          const item: T = filteredItems[index];
          const key: K = getItemKey(item);
          const labelId = `label-for-${index}`;

          const itemLabel = (
            <Typography noWrap variant="body2">
              {getItemLabel(item)}
            </Typography>
          );
          return (
            <ListItem
              selected={singleItem && selectedKeys.indexOf(key) > -1}
              style={style}
              key={`${key}`}
              role={undefined}
              dense
              button
              onClick={handleToggle(key)}
            >
              {singleItem ? (
                itemLabel
              ) : (
                <FormControlLabel
                  classes={{
                    root: classes.labelRoot,
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      className={classes.control}
                      color="primary"
                      size="small"
                      edge="start"
                      checked={selectionState.get(key)}
                      tabIndex={-1}
                      inputProps={{ 'aria-labelledby': labelId }}
                      disableRipple
                    />
                  }
                  label={itemLabel}
                />
              )}
              <ListItemIcon></ListItemIcon>
            </ListItem>
          );
        }}
      </FixedSizeList>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  labelRoot: {
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
  },
  label: {
    overflow: 'hidden',
  },
  control: {
    padding: theme.spacing(0, 1),
  },
}));
