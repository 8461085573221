import makeStyles from '@mui/styles/makeStyles';

const NAVBAR_HEIGHT = 64;
const APP_CONTENT_PADDING = 24;
const PAGE_BOTTOM_MARGIN = 8;
const FILTER_BAR_PADDING = 16;

export const FILTER_BUTTON_HEIGHT = 52.5;

export const FILTER_BAR_HEIGHT =
  NAVBAR_HEIGHT + APP_CONTENT_PADDING + PAGE_BOTTOM_MARGIN + FILTER_BAR_PADDING;

export const FILTER_BAR_WIDTH = 360;

export const useStyles = makeStyles((theme) => ({
  filterBarWrapper: {
    width: FILTER_BAR_WIDTH,
    marginRight: `-${theme.spacing(3)}`,
    flexShrink: 0,
  },
  root: {
    width: FILTER_BAR_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    background: 'white',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(1),
  },
  filters: {
    overflowX: 'hidden',
    position: 'relative',
    flex: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  buttonsContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  filtersButton: {
    width: 160,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  header: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: 48,
    color: theme.palette.grey[800],
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.grey[800],
    marginLeft: theme.spacing(0.5),
  },
}));
