import { ReactElement } from 'react';
import { Box } from '@mui/material';

export interface FilterBarItemProps {
  children: ReactElement;
  extraMargin?: boolean;
}

export const FilterBarItem = ({
  children,
  extraMargin,
}: FilterBarItemProps) => {
  return (
    <Box style={{ minHeight: 48 }} mb={2} mt={extraMargin ? 4 : 0}>
      {children}
    </Box>
  );
};
