import { apiPaths } from '../../utils/apiPaths';
import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { customerDiscounts } from '../data/adminData';
import { CustomerDiscount } from '../../domain/types';

class CustomerDiscountsHandler implements ServerHandler {
  constructor(private dateset: CustomerDiscount[]) {}

  private getNextId() {
    const id = this.dateset.map(({ id }) => id).slice(-1)[0];
    return id + 1;
  }

  resetData() {
    this.dateset = [...customerDiscounts];
  }

  handlers() {
    const basePath = `/api${
      apiPaths.customerDiscounts(':customerId' as any).base
    }`;
    return [
      rest.get(basePath, (req, res, ctx) => {
        return res(ctx.json(this.dateset));
      }),
      rest.post<CustomerDiscount>(basePath, (req, res, ctx) => {
        const newDiscount = req.body;
        newDiscount.id = this.getNextId();
        this.dateset = [...this.dateset, newDiscount];
        return res(ctx.json(this.dateset));
      }),
    ];
  }
}

export const customerDiscountsHandler = new CustomerDiscountsHandler([
  ...customerDiscounts,
]);
