import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRef, useState } from 'react';
import { CustomersCommitTrackerPopover } from './CustomersCommitTrackerPopover';
import { useCommitTrackerProgress } from './useCommitTrackerProgress';

interface CustomersCommitTrackerProgressBarProps {
  commitPeriodAmount: number;
  currentCommitConsumptionToDate: number;
  currentForecast: number;
}

const FULL_COMMIT_PERCENTAGE = 100;

export const CustomersCommitTrackerProgressBar: React.FC<CustomersCommitTrackerProgressBarProps> = ({
  commitPeriodAmount,
  currentCommitConsumptionToDate,
  currentForecast,
}) => {
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const {
    consumptionProgress,
    forecastProgress,
    commitTreshold,
  } = useCommitTrackerProgress({
    commitPeriodAmount,
    currentCommitConsumptionToDate,
    currentForecast,
  });

  const shouldDisplayCommitTreshold = commitTreshold < FULL_COMMIT_PERCENTAGE;

  return (
    <div className={classes.progressBarContainer}>
      <CustomersCommitTrackerPopover
        commitPeriodAmount={commitPeriodAmount}
        currentCommitConsumptionToDate={currentCommitConsumptionToDate}
        currentForecast={currentForecast}
        open={open}
        anchorEl={anchorEl.current}
      />
      <div
        data-testid="commit-progress-bar"
        className={classes.progressBar}
        aria-haspopup="true"
        ref={anchorEl}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div
          data-testid="consumption-progress"
          data-value={consumptionProgress}
          className={classes.consumptionProgressBar}
          style={{ width: `${consumptionProgress}%` }}
        ></div>
        <div
          data-testid="forecast-progress"
          data-value={forecastProgress}
          className={classes.forecastProgressBar}
          style={{ width: `${forecastProgress}%` }}
        ></div>
        {shouldDisplayCommitTreshold && (
          <div
            data-testid="commit-treshold"
            data-value={commitTreshold}
            style={{ left: `${commitTreshold}%` }}
            className={classes.commitTreshold}
          ></div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  progressBarContainer: {
    width: '100%',
  },
  progressBar: {
    height: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  consumptionProgressBar: {
    background: theme.palette.primary.light,
    height: '100%',
  },
  forecastProgressBar: {
    background: theme.palette.warning.main,
    opacity: 0.2,
    height: '100%',
  },
  commitTreshold: {
    width: 0,
    borderWidth: 1,
    borderStyle: 'dashed',
    height: '100%',
    borderColor: theme.palette.error.light,
    position: 'absolute',
    top: 0,
    boxSizing: 'border-box',
  },
}));
