import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Project, ProjectContactType } from '../../../domain/types';

const nameEmailValueGetter = (
  params: GridValueGetterParams,
  field: ProjectContactType
) => {
  const row = params.row as Project;
  const contact = row.contacts?.[field];
  if (!contact) {
    return '';
  }
  const { name, email } = contact;
  return `${name} (${email})`;
};

export const ProjectColumns: GridColDef[] = [
  {
    headerName: 'Project ID',
    field: 'gcpProjectId',
    flex: 0.7,
  },
  {
    headerName: 'Project Name',
    field: 'gcpProjectName',
    flex: 0.7,
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 1,
    valueGetter: (params) => {
      const row = params.row as Project;
      return row.description;
    },
  },
  {
    headerName: 'Business Owner',
    field: 'businessOwner',
    flex: 1,
    valueGetter: (params) => {
      return nameEmailValueGetter(params, ProjectContactType.BUSINESS_OWNER);
    },
  },
  {
    headerName: 'Product Manager',
    field: 'productManager',
    flex: 1,
    valueGetter: (params) => {
      return nameEmailValueGetter(params, ProjectContactType.PRODUCT_MANAGER);
    },
  },
];
