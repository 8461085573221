import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import {
  Apportionment,
  ApportionmentAllocationType,
  ApportionmentSplitType,
  Project,
  ProjectGroup,
} from '../../../domain/types';
import { useStateContext } from '../../../contexts/StateProvider';
import {
  BillingServiceManualApportionmentFormValues,
  BillingChargeManualApportionment,
} from './BillingServiceManualApportionmentSchema';
import {
  Autocomplete,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useListProjectGroups } from '../ServiceApportionments/useListProjectGroups';
import { useListProjects } from '../ServiceApportionments/useListProjects';
import { FieldError } from 'react-hook-form/dist/types';
import { percentageFormat } from '../../PercentageDisplay';
import { DatePickerField } from '../../Fields/DatePickerField';
import { UI_MONTHLY_DATE_FORMAT } from '../../../domain/constants';
import { isMonthlyApiFormat } from '../../../utils/dateUtils';

export const BillingServiceManualApportionmentForm: CrudFormComponent<BillingChargeManualApportionment> = ({
  item,
}) => {
  const {
    register,
    control,
    errors,
    watch,
  } = useFormContext<BillingServiceManualApportionmentFormValues>();

  const ids = item?.ids;
  const service = item?.serviceName;
  const gcpServiceId = item?.gcpServiceId ?? '';
  const usageDate = item?.usageDate;
  const { selectedCustomerId } = useStateContext();
  const isMonthlyFormat = isMonthlyApiFormat(item?.usageDate);

  const { projects, projectIdsToName } = useListProjects(selectedCustomerId);
  const { projectGroups, projectGroupIdsToName } = useListProjectGroups(
    selectedCustomerId,
    true
  );

  const allocationType = watch('allocationType', item?.allocationType);
  const splitType = watch('splitType', item?.splitType);

  const selectableApportionItems = useMemo<Apportionment[]>(() => {
    if (
      allocationType === ApportionmentAllocationType.ALL_PROJECTS ||
      allocationType === ApportionmentAllocationType.BILLING_ACCOUNT
    ) {
      return [];
    }
    return allocationType === ApportionmentAllocationType.PROJECT
      ? projects.map((project: Project) => ({
          projectId: project.id,
        }))
      : projectGroups.map((projectGroup: ProjectGroup) => ({
          projectGroupId: projectGroup.id,
        }));
  }, [allocationType, projects, projectGroups]);

  const getApportionmentItemName = useCallback<
    (appportionmentItem: Apportionment) => string
  >(
    (appportionmentItem: Apportionment): string =>
      allocationType === ApportionmentAllocationType.PROJECT
        ? `${projectIdsToName.get(Number(appportionmentItem.projectId))}`
        : `${projectGroupIdsToName.get(
            Number(appportionmentItem.projectGroupId)
          )}`,
    [allocationType, projectIdsToName, projectGroupIdsToName]
  );

  const [previousAllocationType, setPreviousAllocationType] = useState<
    ApportionmentAllocationType | undefined
  >(item?.allocationType);

  const [selectedApportionments, setSelectedApportionments] = useState<
    Apportionment[]
  >(item?.apportionments ?? []);

  useEffect(() => {
    // Clear out apportionments if the allocation type changes
    if (allocationType !== previousAllocationType) {
      setPreviousAllocationType(allocationType);
      setSelectedApportionments([]);
    }
  }, [allocationType, previousAllocationType]);

  return (
    <>
      {ids?.map((id, index) => (
        <input
          key={`ids[${index}]`}
          name={`ids[${index}]`}
          type="hidden"
          value={ids[index]}
          ref={register}
        />
      ))}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CrudFormControl error={errors.usageDate}>
            <Controller
              control={control}
              name="usageDate"
              defaultValue={usageDate}
              render={() => (
                <DatePickerField
                  id="usageDate"
                  value={usageDate ? new Date(usageDate) : null}
                  label="Usage Date"
                  uiFormat={
                    isMonthlyFormat ? UI_MONTHLY_DATE_FORMAT : undefined
                  }
                  disabled
                />
              )}
            />
          </CrudFormControl>
        </Grid>
      </Grid>

      <CrudFormControl error={errors.gcpServiceId}>
        <Controller
          control={control}
          name="gcpServiceId"
          defaultValue={gcpServiceId}
          render={() => (
            <TextField
              id="gcpServiceId"
              label="Service ID"
              disabled
              value={gcpServiceId}
              ref={register}
            ></TextField>
          )}
        />
      </CrudFormControl>

      <CrudFormControl error={errors.serviceName}>
        <Controller
          control={control}
          name="serviceName"
          defaultValue={service}
          render={() => (
            <TextField
              id="serviceName"
              label="Service Name"
              disabled
              value={service}
              ref={register}
            ></TextField>
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.allocationType}>
        <Controller
          control={control}
          name="allocationType"
          defaultValue={item?.allocationType ?? ''}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="allocation-type-label">
                Allocation Type
              </InputLabel>
              <Select
                onChange={(e) => {
                  setSelectedApportionments([]);
                  onChange(e);
                }}
                value={value}
                ref={register}
                id="allocationType"
                labelId="allocation-type-label"
              >
                <MenuItem
                  key={ApportionmentAllocationType.ALL_PROJECTS}
                  value={ApportionmentAllocationType.ALL_PROJECTS}
                >
                  All Projects
                </MenuItem>
                <MenuItem
                  key={ApportionmentAllocationType.BILLING_ACCOUNT}
                  value={ApportionmentAllocationType.BILLING_ACCOUNT}
                >
                  Billing Account
                </MenuItem>
                <MenuItem
                  key={ApportionmentAllocationType.PROJECT}
                  value={ApportionmentAllocationType.PROJECT}
                >
                  Projects
                </MenuItem>
                <MenuItem
                  key={ApportionmentAllocationType.PROJECT_GROUP}
                  value={ApportionmentAllocationType.PROJECT_GROUP}
                >
                  Project Groups
                </MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      {(allocationType === ApportionmentAllocationType.PROJECT ||
        allocationType === ApportionmentAllocationType.PROJECT_GROUP) && (
        <>
          <CrudFormControl error={errors.splitType}>
            <Controller
              control={control}
              name="splitType"
              defaultValue={item?.splitType ?? ''}
              disabled={!!item?.splitType}
              render={({ onChange, value }) => (
                <>
                  <InputLabel id="split-type-label">Split Type</InputLabel>
                  <Select
                    onChange={onChange}
                    value={value}
                    ref={register}
                    id="splitType"
                    labelId="split-type-label"
                  >
                    <MenuItem value={ApportionmentSplitType.EVEN_SPLIT}>
                      Even Split
                    </MenuItem>
                    <MenuItem
                      value={ApportionmentSplitType.PERCENTAGE_OF_SPEND}
                    >
                      Percentage of spend
                    </MenuItem>
                  </Select>
                </>
              )}
            />
          </CrudFormControl>
          <CrudFormControl
            error={(errors.apportionments as unknown) as FieldError}
          >
            <Autocomplete<Apportionment, true>
              options={selectableApportionItems}
              getOptionLabel={getApportionmentItemName}
              isOptionEqualToValue={(
                option: Apportionment,
                value: Apportionment
              ) => {
                return (
                  getApportionmentItemName(option) ===
                  getApportionmentItemName(value)
                );
              }}
              renderOption={(props, item) => (
                <MenuItem {...props}>{getApportionmentItemName(item)}</MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    allocationType === ApportionmentAllocationType.PROJECT
                      ? 'Projects'
                      : 'Project Groups'
                  }
                />
              )}
              //loading={unapportionedServicesLoading}
              onChange={(_, value: Apportionment[]) =>
                setSelectedApportionments(value)
              }
              value={selectedApportionments}
              multiple
            />
          </CrudFormControl>
          {splitType === ApportionmentSplitType.PERCENTAGE_OF_SPEND && (
            <Typography variant="h6">Assign Percentages</Typography>
          )}

          {(selectedApportionments ?? []).map((apportionment, index) => {
            const fieldName = `apportionments[${index}]`;
            const projectOrGroup =
              allocationType === ApportionmentAllocationType.PROJECT
                ? 'projectId'
                : 'projectGroupId';

            return (
              <Fragment
                key={
                  index +
                  `${apportionment + getApportionmentItemName(apportionment)}`
                }
              >
                <input
                  name={`${fieldName}.${projectOrGroup}`}
                  type="hidden"
                  value={apportionment[projectOrGroup]}
                  ref={register}
                />
                {splitType === ApportionmentSplitType.PERCENTAGE_OF_SPEND && (
                  <div>
                    <CrudFormControl
                      error={
                        (errors.apportionments?.[index]
                          ?.percentage as unknown) as FieldError
                      }
                    >
                      <Controller
                        name={`${fieldName}.percentage`}
                        control={control}
                        defaultValue={() =>
                          percentageFormat(
                            Number(apportionment.percentage),
                            false,
                            true
                          )
                        }
                        render={({ onChange, value }) => (
                          <TextField
                            id={`${getApportionmentItemName(
                              apportionment
                            )}percentage`}
                            value={value}
                            onChange={(e) => {
                              const val = parseInt(e.target.value) || 0;
                              onChange(val);
                            }}
                            label={`${getApportionmentItemName(
                              apportionment
                            )} percentage`}
                            InputLabelProps={{
                              htmlFor: `${getApportionmentItemName(
                                apportionment
                              )}percentage`,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </CrudFormControl>
                  </div>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
