import { Popover } from '@mui/material';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import PopoverRowIcon from '@mui/icons-material/PieChart';
import { COMMIT_CURRENCY } from '../../../domain/constants';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

interface CustomersCommitTrackerPopoverProps {
  open: boolean;
  anchorEl: any;
  commitPeriodAmount: number;
  currentCommitConsumptionToDate: number;
  currentForecast: number;
}

export const CustomersCommitTrackerPopover: React.FC<CustomersCommitTrackerPopoverProps> = ({
  open,
  anchorEl,
  commitPeriodAmount,
  currentCommitConsumptionToDate,
  currentForecast,
}) => {
  const classes = useStyles();

  return (
    <Popover
      data-testid="commit-progress-popover"
      open={open}
      anchorEl={anchorEl}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
      disableRestoreFocus
    >
      <div className={classes.popoverRow} data-testid="commit-amount">
        <label className={classes.popoverRowLabel}>
          <PopoverRowIcon className={classes.commitPeriodIcon} /> Commit Period
          Amount
        </label>
        <span data-testid="value">
          <MonetaryDisplay
            value={commitPeriodAmount}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </span>
      </div>
      <div className={classes.popoverRow} data-testid="consumption">
        <label className={classes.popoverRowLabel}>
          <PopoverRowIcon className={classes.consumptionIcon} /> Consumption to
          Date in Current Period
        </label>
        <span data-testid="value">
          <MonetaryDisplay
            value={currentCommitConsumptionToDate}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </span>
      </div>
      <div
        className={classes.popoverRow}
        data-testid="total-forecast-consumption"
      >
        <label className={classes.popoverRowLabel}>
          <PopoverRowIcon className={classes.forecastIcons} /> Additional
          Forecast Spend
        </label>
        <span data-testid="value">
          <MonetaryDisplay
            value={currentForecast}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </span>
      </div>
    </Popover>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  popoverRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popoverRowLabel: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  commitPeriodIcon: {
    color: theme.palette.error.light,
    opacity: 0.6,
  },
  consumptionIcon: {
    color: theme.palette.primary.light,
  },
  forecastIcons: {
    color: theme.palette.warning.main,
    opacity: 0.2,
  },
}));
