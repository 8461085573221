import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { useMemo } from 'react';

interface RecommendationCost {
  recommendationId: number;
  cost: number;
  currencyCode: string;
}

interface RecommendationDescription {
  recommendationId: number;
  description: string;
}

interface RecommendationDetails {
  recommendationId: number;
  details: object;
}

interface RecommendationDetailsResponse {
  details: RecommendationDetails[];
  costs: RecommendationCost[];
  descriptions: RecommendationDescription[];
}

interface AdditionalDetail {
  description: string;
  options: {
    cost: number;
    currencyCode: string;
    algorithm: string;
  }[];
}

interface HookResponse {
  isLoading: boolean;
  details: object;
  aggregatedDetails: RecommendationDetails[];
  costs: RecommendationCost[];
  descriptions: RecommendationDescription[];
  additionalDetails: AdditionalDetail[];
}

const mapAdditionalDetails = ({
  costs,
  descriptions,
  details,
}: {
  costs: RecommendationCost[];
  descriptions: RecommendationDescription[];
  details: RecommendationDetails[];
}): AdditionalDetail[] => {
  const result = descriptions
    .map((description) => {
      const cost = costs.find(
        ({ recommendationId }) =>
          recommendationId === description.recommendationId
      );
      const detail =
        details.find(
          ({ recommendationId }) =>
            recommendationId === description.recommendationId
        )?.details ?? ({} as any);
      const algorithm = detail?.overview?.algorithm ?? '';

      return {
        description: description.description,
        options: {
          cost: cost?.cost ?? 0,
          currencyCode: cost?.currencyCode ?? 'USD',
          algorithm,
        },
      };
    })
    .sort((first, second) => first.options.cost - second.options.cost)
    .reduce((current, item) => {
      if (!current[item.description]) {
        current[item.description] = {
          description: item.description,
          options: [],
        };
      }
      current[item.description].options.push(item.options);
      return current;
    }, {} as any);

  return Object.values(result);
};

export const useRecommendationDetails = (
  recommendationId: number,
  customerId: number
): HookResponse => {
  const apiPath = apiPaths.recommenderDetails(customerId);

  const { data, isLoading } = useQuery<RecommendationDetailsResponse>(
    apiPath.key.item(recommendationId),
    () => GET(apiPath.item(recommendationId)),
    {
      enabled: typeof recommendationId === 'number' && recommendationId !== -1,
    }
  );

  return useMemo<HookResponse>(() => {
    const response = {
      isLoading,
      details:
        data?.details?.find(
          (item) => item.recommendationId === recommendationId
        ) ?? {},
      aggregatedDetails: data?.details ?? [],
      costs: data?.costs ?? [],
      descriptions: data?.descriptions ?? [],
    };

    const additionalDetails = mapAdditionalDetails({
      costs: response.costs,
      details: response.aggregatedDetails,
      descriptions: response.descriptions,
    });

    return { ...response, additionalDetails };
  }, [data, isLoading, recommendationId]);
};
