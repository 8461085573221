import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { WidgetContainer } from '../../WidgetContainer';
import { QueryLoader } from '../../Messaging/QueryStatus';

interface TileProps {
  heading: string;
  subHeading: ReactNode;
  testId?: string;
  severity?: 'danger' | undefined;
  icon?: ReactNode;
  isLoading?: boolean;
  className?: string;
}

export const Tile = ({
  heading,
  subHeading,
  testId,
  severity,
  icon,
  isLoading,
  className,
}: TileProps) => {
  const classes = useStyles();

  return (
    <WidgetContainer className={clsx(classes.kpiContainer, className)}>
      <Box className={classes.tile} data-testid={testId}>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <QueryLoader />
          </div>
        ) : (
          <Typography
            variant="h4"
            component="p"
            data-testid="tile-subHeading"
            className={clsx(
              classes.subHeading,
              severity === 'danger' && classes['severityDanger']
            )}
          >
            {subHeading}
          </Typography>
        )}
        <Typography
          variant="body1"
          component="h6"
          className={classes.heading}
          data-testid="tile-heading"
        >
          {heading}
        </Typography>
      </Box>
      {icon && <div className={classes.iconContainer}>{icon}</div>}
    </WidgetContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  tile: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  heading: {
    color: theme.palette.grey[600],
    fontSize: '0.85em',
  },
  subHeading: {
    marginTop: theme.spacing(0.4),
    fontSize: '1.5em',
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
  },
  severityDanger: {
    color: 'red',
  },
  kpiContainer: {
    paddingRight: theme.spacing(2),
    minWidth: 240,
    minHeight: 90,
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: `4px solid ${theme.palette.primary.light}`,
  },
  iconContainer: {
    marginTop: theme.spacing(2),
    color: 'white',
    opacity: 0.8,
    background: theme.palette.primary.light,
    height: 36,
    padding: 4,
    borderRadius: 8,
  },
  loaderContainer: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -theme.spacing(2),
  },
}));
