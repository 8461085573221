import { Grid } from '@mui/material';
import { useFilters } from '../../../../contexts/ReportFiltersProvider';
import { useStateContext } from '../../../../contexts/StateProvider';
import { BQUsageFilters } from '../../../../domain/types';
import { useProjectGroups } from '../../../../hooks/useProjectGroups';
import { ProjectGroupsFilter } from '../../../Charts/Filtering/ProjectGroupsFilter';
import { DropdownFilter } from '../../Consumption/FilterFields/DropdownFilter/DropdownFilter';
import { FilterBar } from '../../FilterBar';
import { FilterBarItem } from '../../FilterBarItem';
import { TimeIntervalFilter } from '../../FilterFields/ConsumptionTimeIntervalFilter';
import { useBigQueryUsageUsers } from '../hooks/useBigQueryUsageUsers';
import { BigQueryUsageBreakdownFilter } from './BigQueryUsageBreakdownFilter';
import GroupIcon from '@mui/icons-material/Group';
import { ConsumptionDateFilter } from '../../Consumption/FilterFields/ConsumptionDateFilter';

export const BigQueryUsageFilterBar = () => {
  const {
    filters,
    setFilterValue,
    resetFilters,
    applyFilters,
  } = useFilters<BQUsageFilters>();

  const { state, actions } = useProjectGroups({
    includeProjects: true,
    includeUngroupedProjects: true,
    projectsDefaultFilters: filters.projectFilters,
    includeBillingAccountCharges: true,
  });
  const { selectedCustomerId } = useStateContext();
  const { items: users, isLoading: usersIsLoading } = useBigQueryUsageUsers(
    selectedCustomerId
  );

  return (
    <FilterBar
      onSubmit={() => applyFilters()}
      onReset={() => {
        actions.resetStates();
        resetFilters();
      }}
    >
      <FilterBarItem>
        <TimeIntervalFilter
          value={filters.timeInterval}
          onChange={(val) => {
            setFilterValue('timeInterval', val);
          }}
        />
      </FilterBarItem>
      <FilterBarItem>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ConsumptionDateFilter
              value={filters.startDate}
              onChange={(startDate: Date) =>
                setFilterValue('startDate', startDate)
              }
              label="Start Date"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsumptionDateFilter
              value={filters.endDate}
              onChange={(endDate: Date) => setFilterValue('endDate', endDate)}
              label="End Date"
            />
          </Grid>
        </Grid>
      </FilterBarItem>
      <FilterBarItem>
        <BigQueryUsageBreakdownFilter
          value={filters.breakdown}
          onChange={(value) => setFilterValue('breakdown', value)}
        />
      </FilterBarItem>
      <FilterBarItem>
        <DropdownFilter<string, string>
          title="Users"
          testId="users-filter"
          value={filters.userEmailFilters ?? []}
          onChange={(users: string[]) =>
            setFilterValue('userEmailFilters', users)
          }
          items={users}
          isLoading={usersIsLoading}
          getItemKey={(item: string) => item}
          getItemLabel={(item: string) => {
            return item;
          }}
          itemLabel="users"
          icon={<GroupIcon />}
        />
      </FilterBarItem>
      <FilterBarItem extraMargin>
        <ProjectGroupsFilter state={state} actions={actions} />
      </FilterBarItem>
    </FilterBar>
  );
};
