import { Link } from 'react-router-dom';
import { Tile } from '../Tiles/Tile';
import { Box, Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RecommendationSummary } from '../../../domain/types';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { RecommenderEntityFilter } from './RecommenderEntityFilter';
import { adminPaths } from '../../Routes/AdminRoutes';
import { MonetaryDisplay } from '../../MonetaryDisplay';

interface ComponentProps {
  summary: RecommendationSummary;
  isLoading: boolean;
  onboardingFinished: boolean;
}

export const RecommenderSummary = ({
  summary,
  isLoading,
  onboardingFinished,
}: ComponentProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Tile
          icon={<TimelapseIcon fontSize="large" />}
          isLoading={isLoading}
          testId="saving-kpi"
          heading="Savings"
          subHeading={<MonetaryDisplay value={summary?.cost} currency="USD" />}
        />
      </Grid>
      <Grid item>
        <Tile
          icon={<TimelapseIcon fontSize="large" />}
          isLoading={isLoading}
          testId="idle-machines-kpi"
          heading="Idle Machines"
          subHeading={summary?.idleMachines ?? <>&mdash;</>}
        />
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          alignSelf: 'flex-start',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Box display="flex" flexDirection="column">
          {onboardingFinished ? (
            <RecommenderEntityFilter />
          ) : (
            <Box display="flex" justifyContent="flex-end">
              <Button
                className={classes.enableAdditionalRecommendationsBtn}
                size="small"
                variant="outlined"
                component={Link}
                to={adminPaths.recommenderOnboarding}
              >
                Enable Additional Recommendations
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  enableAdditionalRecommendationsBtn: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
}));
