import { SetReportFilterValue } from '../../../contexts/ReportFiltersProvider';
import {
  ConsumptionFilters,
  Service,
  GCPLabel,
  Project,
  Customer,
  BillingAccount,
  CustomerSDM,
  ConsumptionSKU,
} from '../../../domain/types';
import { TimeIntervalFilter } from '../FilterFields/ConsumptionTimeIntervalFilter';
import { ConsumptionCurrencyFilter } from './FilterFields/ConsumptionCurrencyFilter';
import { ConsumptionDateFilter } from './FilterFields/ConsumptionDateFilter';
import { Grid } from '@mui/material';
import { ProjectGroupsFilter } from '../../Charts/Filtering/ProjectGroupsFilter';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';
import { UseProjectGroupActions } from '../../../hooks/useProjectGroups';
import { FilterBar } from '../FilterBar';
import { FilterBarItem } from '../FilterBarItem';
import { ConsumptionBreakdownFilter } from './FilterFields/ConsumptionBreakdownFilter';
import { ConsumptionUsageFilter } from './FilterFields/ConsumptionUsageFilter';
import { DropdownFilter } from './FilterFields/DropdownFilter/DropdownFilter';
import { useServicesFilterData } from './hooks/useServicesFilterData';
import { useLabelsFilterData } from './hooks/useLabelsFilterData';
import { useEffect } from 'react';
import { useCustomersBillingAccountsFilter } from './hooks/useCustomersBillingAccountsFilter';
import { ProjectGroupsState } from '../../../hooks/projectGroupsSlice';
import LabelIcon from '@mui/icons-material/Label';
import MemoryIcon from '@mui/icons-material/Memory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useBreakdownLabels } from './hooks/useBreakdownLabels';
import { useSDMSOptions } from './hooks/useSDMSOptions';
import { useConsumptionSKUS } from './hooks/useConsumptionSKUs';
import { SkuGroupsFilter } from './FilterFields/SkusGroupsFilter';

export interface ConsumptionFiltersProps {
  currency?: string;
  nativeCurrency?: string;
  reportIsLoading: boolean;
  filters: ConsumptionFilters;
  appliedFilters: ConsumptionFilters;
  setFilterValue: SetReportFilterValue<ConsumptionFilters>;
  onSubmit: () => void;
  resetFilters: () => void;
  onProjectsLoad: (projects: Partial<Project>[]) => void;
  state: ProjectGroupsState;
  actions: UseProjectGroupActions;
}

export const ConsumptionFilterBar = ({
  currency,
  nativeCurrency,
  reportIsLoading,
  filters,
  appliedFilters,
  setFilterValue,
  onSubmit,
  resetFilters,
  onProjectsLoad,
  state,
  actions,
}: ConsumptionFiltersProps) => {
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;
  const { skus } = useConsumptionSKUS(selectedCustomerId);

  useEffect(() => {
    const groups = Object.values(state.projectGroups);
    if (groups.length === 0) {
      return;
    }

    const projects = groups
      .map((group) => Object.values(group.projects))
      .flat()
      .map(({ gcpProjectId, gcpProjectName }) => ({
        gcpProjectId,
        gcpProjectName,
      }));

    onProjectsLoad(projects);
  }, [state.projectGroups, onProjectsLoad]);

  const { services, isLoading: servicesIsLoading } = useServicesFilterData(
    appliedFilters,
    selectedCustomerId
  );

  const {
    customerLabels,
    isLoading: customerLabelsIsLoading,
  } = useLabelsFilterData(selectedCustomerId);

  const {
    breakdownLabelsFilterKey,
    breakdownLabelsItemLabel,
    breakdownLabelsOptions,
  } = useBreakdownLabels({
    projectsLabels: customerLabels.projects ?? [],
    resourcesLabels: customerLabels.resources ?? [],
    breakdown: filters.breakdown,
  });

  const {
    customers,
    billingAccounts,
    isLoading: customerBillingIsLoading,
  } = useCustomersBillingAccountsFilter({
    selectedCustomerId: selectedCustomerId,
    selectedCustomers: filters?.customers,
    selectedBillingAccounts: filters?.billingAccounts,
  });

  const { items: sdms, isLoading: sdmIsLoading } = useSDMSOptions(
    selectedCustomerId === VOID_ID
  );

  return (
    <FilterBar
      onSubmit={onSubmit}
      onReset={() => {
        actions.resetStates();
        resetFilters();
      }}
    >
      {isCustomerSelected && (
        <FilterBarItem>
          <ConsumptionCurrencyFilter
            value={filters.currency ?? currency}
            isLoading={reportIsLoading}
            nativeCurrency={nativeCurrency}
            onChange={(val) => {
              setFilterValue('currency', val);
            }}
          />
        </FilterBarItem>
      )}
      <FilterBarItem>
        <TimeIntervalFilter
          value={filters.timeInterval}
          onChange={(val) => {
            setFilterValue('timeInterval', val);

            // doy only supports consumption date, not invoice date:
            if (val === 'doy') {
              setFilterValue('usage', 'consumption_date');
            }
          }}
        />
      </FilterBarItem>
      <FilterBarItem>
        <ConsumptionUsageFilter
          value={filters.usage}
          onChange={(val) => setFilterValue('usage', val)}
          disabled={filters.timeInterval === 'doy'}
        />
      </FilterBarItem>
      <FilterBarItem>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ConsumptionDateFilter
              value={filters.startDate}
              onChange={(startDate: Date) =>
                setFilterValue('startDate', startDate)
              }
              label="Start Date"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsumptionDateFilter
              value={filters.endDate}
              onChange={(endDate: Date) => setFilterValue('endDate', endDate)}
              label="End Date"
            />
          </Grid>
        </Grid>
      </FilterBarItem>
      <FilterBarItem extraMargin>
        <ConsumptionBreakdownFilter
          value={filters.breakdown}
          isCustomerSelected={isCustomerSelected}
          onChange={(val) => setFilterValue('breakdown', val)}
        />
      </FilterBarItem>
      {(filters.breakdown === 'project_label' ||
        filters.breakdown === 'resource_label') && (
        <FilterBarItem>
          <DropdownFilter<string, string>
            value={((f: ConsumptionFilters) => {
              switch (f.breakdown) {
                case 'project_label':
                  return f.projectLabel ? [f.projectLabel] : [];
                case 'resource_label':
                  return f.resourceLabel ? [f.resourceLabel] : [];
                default:
                  return [];
              }
            })(filters)}
            singleItem
            onChange={(val) => {
              setFilterValue(breakdownLabelsFilterKey, val[0]);
            }}
            title={'Label Key'}
            placeholder="Select label"
            items={breakdownLabelsOptions}
            isLoading={customerLabelsIsLoading}
            getItemKey={(item: string) => item}
            getItemLabel={(item: string) => item}
            itemLabel={breakdownLabelsItemLabel}
            testId={'label-key-filter'}
          />
        </FilterBarItem>
      )}

      {!isCustomerSelected && (
        <>
          <FilterBarItem>
            <DropdownFilter<Customer, number>
              title="Customers"
              testId="customers-filter"
              value={filters.customers ?? []}
              onChange={(customers: number[]) =>
                setFilterValue('customers', customers)
              }
              items={customers}
              isLoading={customerBillingIsLoading}
              getItemKey={(item: Customer) => item.id}
              getItemLabel={(item: Customer) => {
                return item.name;
              }}
              itemLabel="customers"
              icon={<AccountBalanceIcon />}
            />
          </FilterBarItem>

          <FilterBarItem>
            <DropdownFilter<BillingAccount, number>
              title="Billing Accounts"
              testId="billing-accounts-filter"
              value={filters.billingAccounts ?? []}
              onChange={(billingAccounts: number[]) =>
                setFilterValue('billingAccounts', billingAccounts)
              }
              items={billingAccounts}
              isLoading={customerBillingIsLoading}
              getItemKey={(item: BillingAccount) => item.id}
              getItemLabel={(item: BillingAccount) => {
                return item.billingAccountId;
              }}
              itemLabel="Billing Accounts"
              icon={<CreditCardIcon />}
            />
          </FilterBarItem>

          <FilterBarItem>
            <DropdownFilter<CustomerSDM, number>
              title="Customer SDMs"
              testId="customers-sdms"
              value={filters.sdms ?? []}
              onChange={(sdms: number[]) => setFilterValue('sdms', sdms)}
              items={sdms}
              isLoading={sdmIsLoading}
              getItemKey={(item: CustomerSDM) => item.id}
              getItemLabel={(item: CustomerSDM) => {
                return item.email;
              }}
              itemLabel="Customer SDMs"
              icon={<CreditCardIcon />}
            />
          </FilterBarItem>
        </>
      )}

      <FilterBarItem>
        <DropdownFilter<Service, number>
          title="Services"
          testId="services-filter"
          value={filters.services}
          onChange={(services: number[]) =>
            setFilterValue('services', services)
          }
          items={services}
          isLoading={servicesIsLoading}
          getItemKey={(item: Service) => item.id}
          getItemLabel={(item: Service) => {
            const gcpServiceId = item.gcp_service_id
              ? `, ${item.gcp_service_id}`
              : '';

            return `${item.name} (${item.type}${gcpServiceId})`;
          }}
          itemLabel="services"
          icon={<MemoryIcon />}
        />
      </FilterBarItem>
      {isCustomerSelected && (
        <>
          <FilterBarItem>
            <DropdownFilter<ConsumptionSKU, number>
              title="SKUs"
              testId="skus-filter"
              value={filters.skus ?? []}
              onChange={(skus: number[]) => setFilterValue('skus', skus)}
              items={skus}
              isLoading={customerBillingIsLoading}
              getItemKey={(item: ConsumptionSKU) => item.id}
              getItemLabel={(item: ConsumptionSKU) => {
                return item.skuName;
              }}
              itemLabel="SKUs"
              icon={<MemoryIcon />}
            />
          </FilterBarItem>
          <SkuGroupsFilter
            setFilterValue={setFilterValue}
            selectedSkuGroups={filters.skusGroups ?? []}
          />
          <FilterBarItem>
            <DropdownFilter<GCPLabel, number>
              title="Projects labels"
              value={filters.projectsLabels}
              onChange={(projectsLabels: number[]) =>
                setFilterValue('projectsLabels', projectsLabels)
              }
              items={customerLabels.projects}
              isLoading={customerLabelsIsLoading}
              getItemKey={(item: GCPLabel) => item.id}
              getItemLabel={(item: GCPLabel) => item.name}
              itemLabel="labels"
              icon={<LabelIcon />}
            />
          </FilterBarItem>
          <FilterBarItem>
            <DropdownFilter<GCPLabel, number>
              title="Resources labels"
              value={filters.resourcesLabels}
              onChange={(resourcesLabels: number[]) =>
                setFilterValue('resourcesLabels', resourcesLabels)
              }
              items={customerLabels.resources}
              isLoading={customerLabelsIsLoading}
              getItemKey={(item: GCPLabel) => item.id}
              getItemLabel={(item: GCPLabel) => item.name}
              itemLabel="labels"
              icon={<LabelIcon />}
            />
          </FilterBarItem>
        </>
      )}

      {isCustomerSelected && (
        <FilterBarItem extraMargin>
          <ProjectGroupsFilter state={state} actions={actions} />
        </FilterBarItem>
      )}
    </FilterBar>
  );
};
