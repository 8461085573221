import { RouteDetail } from '../domain/types';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';

interface MenuRoutesProps {
  routes: RouteDetail[];
}

export const MenuRoutes = ({ routes }: MenuRoutesProps) => {
  return (
    <>
      {routes.map(({ path, Component }) => {
        return (
          <Route key={path} path={path}>
            <ErrorBoundary>
              <Component />
            </ErrorBoundary>
          </Route>
        );
      })}
    </>
  );
};
