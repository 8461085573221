import { Customer, Editable } from '../../../domain/types';
import { array, object, SchemaOf, string, number } from 'yup';

export type CustomerFormValues = Editable<Customer>;

export const CustomerSchema: SchemaOf<CustomerFormValues> = object({
  name: string().required(),
  allowedDomains: array()
    .of(string().required())
    .min(1, 'At least one is required'),
  customer_project_groups: array(),
  sdmFullName: string(),
  sdmEmail: string(),
  sdmUserId: number(),
}).defined();
