import { useQuery } from 'react-query';
import { Budget } from '../../../domain/types';
import { GET } from '../../../utils/api';
import { apiPaths } from '../../../utils/apiPaths';

export function useActiveBudget(customerId: number) {
  const apiPath: string = apiPaths.budget(customerId).active;
  const { data: activeBudget, isLoading, isError } = useQuery<Budget>(
    apiPath,
    () => GET(apiPath)
  );

  return {
    activeBudget,
    isLoading,
    isError,
  };
}
