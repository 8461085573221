import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { CustomerDiscount, PagedItems } from '../../../../domain/types';
import { VOID_ID } from '../../../../domain/constants';

export const useCustomerDiscounts = (customerId: number) => {
  const apiPath = apiPaths.customerDiscounts(customerId);

  const { data, isLoading, isError } = useQuery<PagedItems<CustomerDiscount>>(
    apiPath.key.list(customerId),
    () => GET(apiPath.base),
    {
      enabled: customerId !== VOID_ID,
    }
  );

  return {
    items: data?.items ?? [],
    isLoading,
    isError,
  };
};
