import { useEffect } from 'react';
import { createHashHistory, HashHistory } from 'history';
import { dashboardRoutes } from '../components/Routes/useDashboardRoutes';
import {
  customerSpecificRoutes,
  nonCustomerRoutes,
} from '../components/Routes/AdminRoutes';

const ignoredRoutes = ['/app/dashboard'];

const buildLabelRoutes = (): Map<string, string> => {
  const routesTitles = new Map<string, string>();

  [...dashboardRoutes, ...customerSpecificRoutes, ...nonCustomerRoutes].forEach(
    (route) => {
      routesTitles.set(route?.link ?? route.path, route.label);
    }
  );

  routesTitles.set('/', 'Login');
  return routesTitles;
};

const getBaseRoute = (route: string) => {
  const isEndingWithNumber = route.match(/(\d+)$/gm);
  return isEndingWithNumber ? route.replace(/\/(\d+)$/gm, '') : route;
};

const sendPageViewEvent = (title: string, path: string) => {
  const location: string = window.location.href;

  gtag('set', 'page_path', path);
  gtag('set', 'page_location', location);
  gtag('set', 'page_title', title);

  gtag('event', 'page_view', {
    page_title: title,
    page_path: path,
    page_location: location,
  });
};

const labeledRoutes: Map<string, string> = buildLabelRoutes();

export const useAnalyticsNavigation = () => {
  useEffect(() => {
    const history: HashHistory = createHashHistory();
    const route = window.location.hash.split('#')[1]; // initial app load
    const title = labeledRoutes.get(route) as string;

    sendPageViewEvent(title, route);
    history.listen((update) => {
      const routePath: string = getBaseRoute(update.location?.pathname);
      let title = labeledRoutes.get(routePath) as string;
      if (ignoredRoutes.includes(routePath)) {
        return;
      }

      sendPageViewEvent(title, routePath);
    });
  }, []);
};
