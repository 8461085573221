import React from 'react';
import ApplicationRoutes from './components/Routes/ApplicationRoutes';
import { CookieConsent } from './components/CookieConsent/CookieConsent';
import { Theme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ThemeProvider as JSSThemeProvider } from '@mui/styles';
import { appTheme } from './theme/appTheme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={appTheme}>
      <JSSThemeProvider theme={appTheme}>
        <ApplicationRoutes />
        <CookieConsent />
      </JSSThemeProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
