import {
  ALL_ROLES,
  CUSTOMER_ROLES,
  Editable,
  FormValues,
  Role,
  User,
} from '../../../domain/types';
import { array, mixed, number, object, string } from 'yup';
import { FORM_ERRORS } from '../../../i18n/yup';
import {
  isCustomerRole,
  isCustomerViewerRole,
} from '../../../hooks/useHasRole';

export type UserFormValues = Editable<FormValues<User>>;

export const createUserSchema = (isSysAdmin: boolean) => {
  const allowedRoles = isSysAdmin ? ALL_ROLES : CUSTOMER_ROLES;

  // base schema
  const schema = object({
    email: string().required().email(),
    role: mixed<Role>()
      .required()
      .oneOf(allowedRoles, FORM_ERRORS.mixed.required),
  }).defined();

  // sys admins must also select a customer if they have selected a customer role
  if (isSysAdmin) {
    return schema.shape({
      customers: array(number().required()).when('role', {
        is: (val: Role) => isCustomerRole(val),
        then: array()
          .length(1, 'You must select a single customer for this user')
          .required(),
        otherwise: array().notRequired(),
      }),
      restrictedProjectGroups: array(number()).when('role', {
        is: (val: Role) => isCustomerViewerRole(val),
        then: array(),
        otherwise: array(),
      }),
      restrictedProjects: array(number()).when('role', {
        is: (val: Role) => isCustomerViewerRole(val),
        then: array(),
        otherwise: array(),
      }),
    });
  } else {
    return schema;
  }
};
