import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SelectionState } from './useFilterSelection';
import { AllSelector } from '../../../../Charts/Filtering/AllSelector';
import { SearchField } from '../../../../Charts/Filtering/SearchField';

interface DropdownFilterListBoxBarProps {
  selection: SelectionState;
  onCheckboxClick: () => void;
  searchTerm: string;
  onSearch: (searchTerm: string) => void;
  singleItem?: boolean;
}

export const DropdownFilterListBoxBar: React.FC<DropdownFilterListBoxBarProps> = ({
  selection,
  onCheckboxClick,
  searchTerm,
  onSearch,
  singleItem,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.filterBar}>
      {!singleItem && (
        <AllSelector
          multiSelect={true}
          selected={selection === SelectionState.Selected}
          indeterminate={selection === SelectionState.Some}
          toggleAll={onCheckboxClick}
          clearAll={() => {}}
        />
      )}
      <SearchField
        style={{ width: '100%' }}
        searchTerm={searchTerm}
        setSearchTerm={(s: string) => onSearch(s)}
        filterBySearchTerm={(searchTerm?: string) => {
          if (searchTerm === '') {
            onSearch('');
          }
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  filterBar: {
    display: 'flex',
    padding: theme.spacing(0.5),
    background: theme.palette.grey['200'],
  },
}));
