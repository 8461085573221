import { useQuery } from 'react-query';
import { PagedItems, PagedRequest } from '../../../domain/types';
import { GET_PAGED } from '../../../utils/api';
import { ApiPath } from '../../../utils/apiPaths';

interface Props {
  apiPath: ApiPath;
  options?: Record<string, string | number | boolean>;
}

interface Response<T> {
  data?: PagedItems<T>;
  isLoading: boolean;
  error: any;
  isError: boolean;
}

export const useCrudList = <T>({ apiPath, options }: Props): Response<T> => {
  const pagination: PagedRequest = {
    page: 0,
    size: 1_000_000,
  };
  const { data, isLoading, error, isError } = useQuery<PagedItems<T>>(
    apiPath.key.list(Object.assign({}, pagination, options ? options : {})),
    () => GET_PAGED(apiPath.base, pagination, options)
  );

  return {
    data,
    isLoading,
    error,
    isError,
  };
};
