import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthProvider';
import { appPaths } from './ApplicationRoutes';
import { ApplicationLoader } from '../ApplicationLoader';

export const AuthenticatedRoute = (props: RouteProps) => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);
  const location = useLocation();

  if (loadingAuthState) {
    return <ApplicationLoader />;
  }

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: appPaths.login,
          state: { prevPath: location.pathname },
        }}
      />
    );
  }

  return <Route {...props} />;
};
