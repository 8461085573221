import React, { ReactNode } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { FieldError } from 'react-hook-form/dist/types/errors';

export interface CrudFormControlProps {
  children: ReactNode;
  error?: FieldError;
  helperText?: string;
}

export const CrudFormControl = ({
  children,
  error,
  helperText,
}: CrudFormControlProps) => {
  const helperTextContent = error?.message ?? helperText;
  return (
    <Box mb={2}>
      <FormControl fullWidth error={!!error}>
        {children}
        {helperTextContent && (
          <FormHelperText>{helperTextContent}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
