import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'recharts';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CHART_TOOLTIP_ITEM_COUNT } from '../../domain/constants';
import clsx from 'clsx';
import { useChartContainerContext, ChartItemName } from './ChartProvider';

type TooltipPayload = ComponentProps<typeof Tooltip>;

export interface TooltipProps extends TooltipPayload {
  customFormatter?: (value: number | string) => string;
}

type ChartItem = {
  value?: any;
};

const sortDescending = (itemA: ChartItem, itemB: ChartItem) => {
  return itemB.value - itemA.value;
};

export const ChartItemsTooltip = ({
  payload,
  customFormatter,
}: TooltipProps) => {
  const { subscribeToActiveElement } = useChartContainerContext();
  const [selectedItem, setSelectedItem] = useState<ChartItemName>();

  useEffect(() => {
    const unsubscribe = subscribeToActiveElement((item?: ChartItemName) => {
      setSelectedItem((item as string)?.trim());
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToActiveElement]);

  const classes = useStyles();

  const itemsToList = useMemo(() => (payload ?? []).sort(sortDescending), [
    payload,
  ]);

  let shownItems = useMemo(() => {
    let shownItems = itemsToList.slice(0, CHART_TOOLTIP_ITEM_COUNT);
    if (selectedItem) {
      shownItems = shownItems.filter((item) => item.name === selectedItem);
    }
    return shownItems;
  }, [itemsToList, selectedItem]);

  const { hiddenItems, hiddenItemsValue } = useMemo(() => {
    const hiddenItems = itemsToList.slice(CHART_TOOLTIP_ITEM_COUNT);
    const hiddenItemsValue = hiddenItems.reduce(
      (total, item) => total + (item.value as number),
      0
    );
    return { hiddenItems, hiddenItemsValue };
  }, [itemsToList]);

  const listTitle = useMemo(
    () => itemsToList.find((item) => item.payload?.name)?.payload.name,
    [itemsToList]
  );

  const total = useMemo(
    () => itemsToList.reduce((sum, item) => sum + (item.value as number), 0),
    [itemsToList]
  );

  const percentageFromTotal = useMemo(() => {
    const itemValue = (shownItems[0]?.value ?? 0) as number;
    return shownItems.length === 1 ? (itemValue / total) * 100 : 100;
  }, [total, shownItems]);

  return (
    <Box className={classes.tile}>
      <div data-testid="chart_tooltip_title" className={classes.listTitle}>
        {listTitle}
      </div>

      {shownItems.map((item, index) => (
        <div key={`tooltip-id-${item.name}-${index}`} className={classes.row}>
          <div
            className={classes.colorBox}
            style={{ backgroundColor: item.color }}
          />
          {item.name}{' '}
          <span className={classes.itemValue}>
            {customFormatter?.(`${item.value}`) ?? item.value}
          </span>
        </div>
      ))}
      {!selectedItem && hiddenItems.length > 0 && (
        <div className={clsx(classes.row, classes.faded)}>
          <i>{hiddenItems.length} more</i>{' '}
          {customFormatter
            ? customFormatter(`${hiddenItemsValue}`)
            : hiddenItemsValue}
        </div>
      )}
      <div
        className={clsx(classes.row, classes.totalRow)}
        data-testid="chart_tooltip_total"
      >
        Total{' '}
        <span className={classes.itemValue}>
          {customFormatter ? customFormatter(total) : total}
        </span>
      </div>
      {shownItems?.length === 1 && (
        <div className={classes.row}>
          Percentage of Total
          <span className={classes.itemValue}>
            {Number(percentageFromTotal).toFixed(2)}%
          </span>
        </div>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  listTitle: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  tile: {
    backgroundColor: '#FFF',
    border: '1px solid #888',
    padding: theme.spacing(1),
    minWidth: 300,
  },
  row: {
    position: 'relative',
    paddingLeft: '28px',
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 15,
  },
  faded: {
    color: '#888',
  },
  colorBox: {
    position: 'absolute',
    top: '6px',
    left: '4px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  itemValue: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  totalRow: {
    paddingTop: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));
