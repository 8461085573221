import { Legend, Tooltip, XAxis, YAxis, Line, Bar } from 'recharts';
import { ChartLoader } from './ChartLoader';
import {
  ConsumptionValue,
  ConsumptionData,
  ConsumptionFilters,
} from '../../domain/types';
import { useMonetaryFormat } from '../MonetaryDisplay';
import { Y_AXIS_WIDTH } from '../../domain/constants';
import { ChartContainer } from './ChartContainer';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo } from 'react';

interface ConsumptionChartProps {
  data?: ConsumptionData;
  appliedFilters: ConsumptionFilters;
  isLoading: boolean;
  isError: boolean;
  selectedCostType: any;
  onDrillDown: (name: string) => void;
}

export const ConsumptionChart = ({
  data,
  isLoading,
  isError,
  appliedFilters,
  selectedCostType,
  onDrillDown,
}: ConsumptionChartProps) => {
  const classes = useStyles();
  const formatter = useMonetaryFormat(data?.currency);
  const yFormatter = useMonetaryFormat(data?.currency, true);
  const forecastKey = useMemo(() => {
    return selectedCostType.dataKey === 'net_cost'
      ? 'net_forecast'
      : 'gross_forecast';
  }, [selectedCostType]);
  const theme = useTheme();

  const renderForecast = useCallback(() => {
    const shouldRender =
      appliedFilters.timeInterval === 'month' &&
      appliedFilters.projectsLabels.length === 0 &&
      appliedFilters.resourcesLabels.length === 0 &&
      appliedFilters.skus?.length === 0 &&
      appliedFilters.skusGroups?.length === 0;

    return shouldRender ? (
      <Bar
        name="Forecasted Additional Cost"
        dataKey={forecastKey}
        fill={theme.palette.warning.main}
        fillOpacity="0.2"
        stackId="forecastMonth"
      />
    ) : null;
  }, [
    appliedFilters.timeInterval,
    appliedFilters.projectsLabels,
    appliedFilters.resourcesLabels,
    appliedFilters.skus,
    appliedFilters.skusGroups,
    theme.palette.warning.main,
    forecastKey,
  ]);

  return (
    <ChartLoader<ConsumptionValue[]>
      dataReady={(data) => data.length > 0}
      onData={(data: ConsumptionValue[]) => (
        <>
          <ChartContainer data={data}>
            <XAxis dataKey="name" tick={{ fontSize: 12, dy: 5 }} />
            <YAxis tickFormatter={yFormatter} width={Y_AXIS_WIDTH} />
            <Tooltip formatter={formatter} />
            <Legend wrapperStyle={{ position: 'relative' }} />

            {appliedFilters.timeInterval === 'doy' ? (
              <Line
                className={classes.chartItem}
                type="monotone"
                dataKey={selectedCostType.dataKey}
                name={selectedCostType.name}
                dot={false}
                stroke={theme.palette.primary.main}
              />
            ) : (
              <Bar
                className={classes.chartItem}
                onDoubleClick={(e) => onDrillDown((e as any).name)}
                name={selectedCostType.name}
                dataKey={selectedCostType.dataKey}
                fill={theme.palette.primary.main}
                stackId="forecastMonth"
              />
            )}

            {renderForecast()}
          </ChartContainer>
        </>
      )}
      data={data?.items}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

const useStyles = makeStyles(() => ({
  chartItem: {
    cursor: 'pointer',
  },
}));
