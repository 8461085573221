import { useRef } from 'react';

const FIRST_STACKED_BAR_INDEX = 0;
const SECOND_STACKED_BAR_INDEX = 1;

/**
 * Computes the distance between 2 bars rendered in the stacked bar.
 */
export const useBarGap = () => {
  const state = useRef({
    stackCounter: 0,
    previousOffset: 0,
    shouldCompute: true,
    barGap: {
      x: 0,
      width: 0,
      barOffset: 0,
    },
  });

  const barIterator = ({ width, x }: { width: number; x: number }) => {
    const { barGap, stackCounter } = state.current;

    if (stackCounter === FIRST_STACKED_BAR_INDEX) {
      state.current.previousOffset = x + width;
      state.current.stackCounter++;
    } else if (stackCounter === SECOND_STACKED_BAR_INDEX) {
      const { previousOffset } = state.current;
      const offset = x - previousOffset;
      barGap.x = offset / 2;
      barGap.width = offset;
      state.current.stackCounter++;
    }

    return barGap;
  };

  return barIterator;
};
