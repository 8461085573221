import { ProjectGroup } from './ProjectGroup';
import React, { memo, MutableRefObject } from 'react';
import { ProjectGroupTreeItem } from '../../../hooks/projectGroupsSlice';
import { UseProjectGroupActions } from '../../../hooks/useProjectGroups';
import { SelectionLevel } from './ProjectGroupsFilter';
import makeStyles from '@mui/styles/makeStyles';

export interface ProjectGroupsListProps {
  listHeight: number;
  listRef: MutableRefObject<any>;
  visibleGroups: ProjectGroupTreeItem[];
  actions: UseProjectGroupActions;
  multiSelect: boolean;
  selectionLevel?: SelectionLevel;
}

export const ProjectGroupsList = memo(
  ({
    listHeight,
    visibleGroups,
    actions,
    multiSelect,
    selectionLevel,
  }: ProjectGroupsListProps) => {
    const classes = useStyles({ listHeight });
    /**
     * React Window has been removed due to various performance glitches across
     * different OS.
     *
     * Return to commit 'ICAP-381' to view the changes made to revert back if
     * necessary.
     *
     */

    return (
      <div className={classes.groupList} style={{ height: listHeight }}>
        {visibleGroups.map((_, index) => {
          return (
            <ProjectGroup
              key={`group-${_.id}`}
              group={visibleGroups[index]}
              multiSelect={multiSelect}
              selectionLevel={selectionLevel}
              actions={actions}
            />
          );
        })}
      </div>
    );
  }
);

const useStyles = makeStyles(() => ({
  groupList: {
    overflow: 'auto',
  },
}));
