import { Controller, useFormContext } from 'react-hook-form';
import { Budget, BudgetAllocationType } from '../../../domain/types';
import React from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { BudgetValues } from './BudgetSchema';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import { useGetCustomerCurrency } from '../BudgetAllocations/useGetCustomerCurrency';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import { DatePickerField } from '../../Fields/DatePickerField';

export const BudgetForm: CrudFormComponent<Budget> = ({ item }) => {
  const { selectedCustomerId } = useStateContext();
  const { register, errors, control, watch } = useFormContext<BudgetValues>();
  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  const startDate = watch('startDate', item?.startDate);

  return (
    <>
      <input
        type="hidden"
        name="customerId"
        value={selectedCustomerId}
        ref={register}
      />
      <CrudFormControl error={errors.allocationType}>
        <Controller
          control={control}
          name="allocationType"
          defaultValue={item?.allocationType ?? ''}
          disabled={!!item?.allocationType}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="allocation-type-label">
                Allocation Type
              </InputLabel>
              <Select
                onChange={onChange}
                value={value}
                ref={register}
                id="allocationType"
                labelId="allocation-type-label"
              >
                <MenuItem value={BudgetAllocationType.PROJECT}>
                  Project
                </MenuItem>
                <MenuItem value={BudgetAllocationType.GROUP}>Group</MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.amount}>
        <Controller
          name="amount"
          control={control}
          defaultValue={item?.amount ?? ''}
          render={(props) => (
            <MonetaryValueField
              error={!!errors.amount}
              label="Amount"
              id="amount"
              InputProps={props}
              currency={currency}
            />
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.startDate}>
        <Controller
          control={control}
          name="startDate"
          defaultValue={item?.startDate ?? null}
          render={({ onChange, value }) => (
            <DatePickerField
              id="startDate"
              value={value ? new Date(value) : null}
              onChange={onChange}
              label="Start Date"
            />
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.endDate}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name="endDate"
            defaultValue={item?.endDate ?? null}
            render={({ onChange, value }) => (
              <DatePickerField
                id="endDate"
                value={value ? new Date(value) : null}
                minDate={addDays(new Date(startDate), 1)}
                onChange={onChange}
                label="Renewal Date" // show as renewal date on the form
              />
            )}
          />
        </LocalizationProvider>
      </CrudFormControl>
    </>
  );
};
