import { useStateContext } from '../../../contexts/StateProvider';
import { GridColDef } from '@mui/x-data-grid';
import { apiPaths } from '../../../utils/apiPaths';
import { CrudContainer } from '../../Crud/CrudContainer';
import { ServiceCharge } from '../../../domain/types';
import {
  ServiceChargeFormValues,
  ServiceChargeSchema,
} from './ServiceChargeSchema';
import { ServiceChargeForm } from './ServiceChargeForm';
import { useServices } from '../Services/useExternalServices';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { useGetCustomerCurrency } from '../BudgetAllocations/useGetCustomerCurrency';
import { DateDisplay } from '../../DateDisplay';
import { NoItems } from '../../Messaging';

export const ServiceChargeAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const { serviceIdsToName } = useServices(selectedCustomerId, Number());
  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  const columns: GridColDef[] = [
    {
      headerName: 'Service',
      field: 'serviceId',
      flex: 0.5,
      valueGetter: ({ row }) => {
        return serviceIdsToName.get(row.serviceId);
      },
    },
    {
      headerName: 'Invoice Number',
      field: 'invoiceNumber',
      flex: 0.5,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = row.amount as number;
        return <MonetaryDisplay value={value} currency={currency} />;
      },
    },
    {
      headerName: 'Currency',
      field: 'currency',
      flex: 0.5,
    },
    {
      headerName: 'Invoice Date',
      field: 'invoiceDate',
      flex: 0.5,
      renderCell: ({ row }) => <DateDisplay date={row.invoiceDate} />,
    },
    {
      headerName: 'Settle Date',
      field: 'settleDate',
      flex: 0.5,
      renderCell: ({ row }) => <DateDisplay date={row.settleDate} />,
    },
  ];

  if (currency) {
    return (
      <CrudContainer<ServiceCharge, ServiceChargeFormValues>
        name="Service Charges"
        apiPath={apiPaths.serviceCharge(selectedCustomerId)}
        columns={columns}
        defaultSortColumn="invoiceNumber"
        FormComponent={ServiceChargeForm}
        validationSchema={ServiceChargeSchema}
      />
    );
  }

  return <NoItems forMessage="billing accounts" severity="warning" />;
};
