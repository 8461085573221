import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET_PAGED } from '../../../../utils/api';
import { PagedItems, SkuGroup } from '../../../../domain/types';

export const useSkuGroups = () => {
  const apiPath = apiPaths.skuGroups();

  const { data, isLoading } = useQuery<PagedItems<SkuGroup>>(
    apiPath.key.list(),
    () => GET_PAGED(apiPath.base)
  );

  return {
    items: data?.items ?? [],
    isLoading,
  };
};
