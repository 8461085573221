import { useMutation, useQueryClient } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { POST } from '../../../../utils/api';
import { useStateContext } from '../../../../contexts/StateProvider';
import { ApiErrorResponse } from '../../../../domain/types';

const getApiPath = (skuGroupId: number, deallocate?: boolean) => {
  const allocatePath = apiPaths.skuGroupsSkus(skuGroupId).allocate;
  const deAllocatePath = apiPaths.skuGroupsSkus(skuGroupId).deallocate;
  return deallocate ? deAllocatePath : allocatePath;
};

interface MutationArgs {
  skuIds: number[];
  skuGroupId: number;
  deallocate?: boolean;
}

export const useSkuAllocation = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();

  const { mutate, isError, isLoading } = useMutation<
    unknown,
    ApiErrorResponse,
    MutationArgs
  >(
    ({ skuIds, skuGroupId, deallocate }) => {
      const apiPath = getApiPath(skuGroupId, deallocate);

      return POST(apiPath, {
        skuIds,
      });
    },
    {
      onSuccess: async (_, { deallocate, skuGroupId, skuIds }) => {
        await queryClient.invalidateQueries(apiPaths.skus().key.list());
        await queryClient.invalidateQueries(
          apiPaths.skuGroupsSkus(skuGroupId).key.list()
        );
        await queryClient.invalidateQueries(
          apiPaths.skus().key.item(skuIds[0])
        );
        const action = deallocate ? 'deallocated' : 'allocated';

        showNotification({
          message: `SKUs ${action} successfully`,
          severity: 'success',
        });
      },
    }
  );

  return {
    allocate: (args: Omit<MutationArgs, 'deallocate'>) => mutate(args),
    deallocate: (args: Omit<MutationArgs, 'deallocate'>) =>
      mutate({ ...args, deallocate: true }),
    isError,
    isLoading,
  };
};
