import { Box, Typography, styled } from '@mui/material';
import { CustomerDiscountException } from '../../../../domain/types';
import { DiscountCardExceptionItem } from './DiscountCardExceptionItem';
import { VOID_ID } from '../../../../domain/constants';
import { useState } from 'react';
import { useCustomerAdminState } from '../hooks/useCustomerAdminState';
import { uniqueId } from 'lodash';
import { useSkuGroupsOptions } from '../hooks/useSkuGroupsOptions';

const ExceptionsBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[400]}`,
  marginTop: theme.spacing(2),
}));

interface ComponentProps {
  exceptions: CustomerDiscountException[];
  discountUID: string;
}

const createNewException = () => ({
  percentage: 0,
  skuGroupId: VOID_ID,
  temporaryUID: uniqueId(),
});

export const DiscountCardExceptions = ({
  exceptions,
  discountUID,
}: ComponentProps) => {
  const { actions } = useCustomerAdminState();
  const skuGroupsOptions = useSkuGroupsOptions({ exceptions });
  const [newException, setNewException] = useState<CustomerDiscountException>(
    createNewException()
  );

  return (
    <ExceptionsBox>
      <Typography fontWeight="medium" mb={2} mt={2}>
        Exceptions
      </Typography>
      {exceptions.map((exception) => (
        <DiscountCardExceptionItem
          skuGroupsOptions={skuGroupsOptions}
          key={exception.temporaryUID}
          exception={exception}
          onActionHandler={(exception) => {
            actions.removeDiscountException(
              discountUID,
              exception.temporaryUID ?? ''
            );
          }}
          onChange={(exception) =>
            actions.updateDiscountException(discountUID, exception)
          }
        />
      ))}

      <DiscountCardExceptionItem
        skuGroupsOptions={skuGroupsOptions}
        placeholder
        exception={newException}
        onActionHandler={() => {
          actions.addDiscountException(discountUID, newException);
          setNewException(createNewException());
        }}
        onChange={(exception) => {
          setNewException((prev) => ({ ...prev, ...exception }));
        }}
      />
    </ExceptionsBox>
  );
};
