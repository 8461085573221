import { useCrudList } from '../../Crud/grid/useCrudList';
import { ProjectGroup } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useListProjectGroups = (
  customerId: number,
  excludeEmptyProjects?: boolean
) => {
  const { data, isLoading, isError } = useCrudList<ProjectGroup>({
    apiPath: apiPaths.projectGroup(customerId),
    options: { include_projects: !!excludeEmptyProjects },
  });

  const {
    projectGroups,
    projectGroupIds,
    projectGroupIdsToName,
  } = useMemo(() => {
    const projectGroups =
      data?.items.filter(
        (group) => !excludeEmptyProjects || group?.projects?.length
      ) ?? [];
    const projectGroupIds = projectGroups.map(({ id }) => id);
    const projectGroupIdsToName = projectGroups.reduce((map, projectGroup) => {
      return map.set(projectGroup.id, projectGroup.name);
    }, new Map<number, string>());

    return { projectGroups, projectGroupIds, projectGroupIdsToName };
  }, [data?.items, excludeEmptyProjects]);

  return {
    projectGroups,
    projectGroupIds,
    projectGroupIdsToName,
    isLoading,
    isError,
  };
};
