import { Box, Button } from '@mui/material';
import React from 'react';
import { useAuthContext } from '../contexts/AuthProvider';
import firebase from '../config/firebase';
import 'firebase/auth';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import UserAvatar from './UserAvatar';

const AuthStatus = () => {
  const { loadingAuthState, user, signOut } = useAuthContext();

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        signOut();
      });
  };

  if (loadingAuthState) {
    return null;
  }

  return user ? (
    <>
      <UserAvatar displayName={user.displayName} photoURL={user.photoURL} />
      <Button test-id="logout-btn" color="inherit" onClick={logOut}>
        <PowerSettingsNewIcon />
        <Box component="span" ml={0.5}>
          Logout
        </Box>
      </Button>
    </>
  ) : null;
};

export default AuthStatus;
