import { useMutation, useQueryClient } from 'react-query';
import { useStateContext } from '../../../../contexts/StateProvider';
import { PATCH } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';

export const useDisclaimerDisable = () => {
  const apiPath = apiPaths.disclaimerManager();
  const activeDisclaimerPath = apiPaths.disclaimerMessageActive();
  const { showNotification } = useStateContext();

  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (id) => {
      const url = `${apiPath.base}/${id}`;
      return PATCH(url, { enabled: false });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.list());
        queryClient.invalidateQueries(activeDisclaimerPath.key.namespace());
        showNotification({
          message: 'Item disabled successfully',
          severity: 'success',
        });
      },
    }
  );

  return mutate;
};
