import { rest } from 'msw';
import {
  RecommendationCategory,
  RecommendationPriority,
  RecommendationState,
  RecommendationSummary,
} from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { ServerHandler } from '../ServerHandler';

const priorities: RecommendationPriority[] = [
  'P1',
  'P2',
  'P3',
  'P4',
  'PRIORITY_UNSPECIFIED',
];

const states: RecommendationState[] = [
  'ACTIVE',
  'CLAIMED',
  'SUCCEEDED',
  'FAILED',
  'DISMISSED',
  'STATE_UNSPECIFIED',
];

const categories: RecommendationCategory[] = [
  'COST',
  'SECURITY',
  'PERFORMANCE',
  'MANAGEABILITY',
  'SUSTAINABILITY',
  'RELIABILITY',
  'CATEGORY_UNSPECIFIED',
];

const pickFromList = <T>(list: T[], index: number) => {
  const { length } = list;
  const listIndex = index % (length - 1);

  return list[listIndex];
};

export class RecommenderReportHandler implements ServerHandler {
  private dataset: any[] = [];
  private readonly summary: RecommendationSummary = {
    cost: 10,
    idleMachines: 10,
    impact: null,
  };

  constructor() {
    this.initDataSet();
  }

  public initDataSet() {
    this.dataset = Array.from({ length: 30 }).map((_, index: number) => {
      return {
        id: index,
        category: pickFromList(categories, index),
        date: '2021-10-01',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        priority: pickFromList(priorities, index),
        cloudEntityType: 'BILLING_ACCOUNT_ID',
        cloudEntityId: `ab-test-${index}`,
        resourceName: `test_resource_${index}`,
        state: pickFromList(states, index),
        targetResource: [{ name: 'test' }],
        recommenderSubtype: 'STOP_VM',
        cost: 10,
        currencyCode: 'USD',
        details: {
          overview: { testInstructions: 'test' },
        },
      };
    });
  }

  public resetData() {
    this.initDataSet();
  }

  public handlers() {
    return [
      rest.post(
        `/api${apiPaths.recommender(':customerId' as any).base}`,
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(this.dataset));
        }
      ),
      rest.post(
        `/api${apiPaths.recommenderSummary(':customerId' as any).base}`,
        (req, res, ctx) => {
          return res(ctx.status(200), ctx.json(this.summary));
        }
      ),
      rest.get(
        `/api${apiPaths.recommenderOnboarding(':customerId' as any).base}`,
        (req: any, res, ctx) => {
          return res(
            ctx.status(200),
            ctx.json({
              bqTableId: 'bq.test.id',
            })
          );
        }
      ),
      rest.get(
        `/api${apiPaths.recommenderDetails(':customerId' as any).base}/:item`,
        (req: any, res, ctx) => {
          return res(ctx.status(200), ctx.json([]));
        }
      ),
    ];
  }
}

export const recommenderReportHandler = new RecommenderReportHandler();
