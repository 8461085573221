import { Autocomplete, Chip, TextField } from '@mui/material';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomerFormValues } from './CustomerSchema';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { Customer } from '../../../domain/types';
import React from 'react';
import { FieldError } from 'react-hook-form/dist/types';

export const CustomerForm: CrudFormComponent<Customer> = ({ item }) => {
  const { register, control, errors } = useFormContext<CustomerFormValues>();

  return (
    <>
      <CrudFormControl error={errors.name}>
        <TextField
          id="name"
          name="name"
          inputRef={register}
          label="Name"
          defaultValue={item?.name}
          error={!!errors.name}
        />
      </CrudFormControl>
      <CrudFormControl>
        <TextField
          id="sdmEmail"
          name="sdmEmail"
          label="Customer SDM Email"
          defaultValue={item?.sdmEmail}
          disabled
        />
      </CrudFormControl>
      <CrudFormControl>
        <TextField
          id="sdmFullName"
          name="sdmFullName"
          label="Customer SDM"
          defaultValue={item?.sdmFullName}
          disabled
        />
      </CrudFormControl>
      <CrudFormControl error={(errors.allowedDomains as unknown) as FieldError}>
        <Controller
          control={control}
          name="allowedDomains"
          defaultValue={item?.allowedDomains ?? []}
          render={({ value, onChange, ...props }) => (
            <Autocomplete
              {...props}
              multiple
              id="allowedDomains"
              options={[]}
              freeSolo
              value={value}
              onChange={(e) => {
                const newValue = (e.target as HTMLInputElement).value;
                if (newValue === undefined) {
                  // user has clicked clear button
                  onChange([]);
                } else {
                  // user has entered text and hit enter
                  onChange([...value, (e.target as HTMLInputElement).value]);
                }
              }}
              renderTags={(value: string[]) =>
                value.map((option: string, index: number) => (
                  <Chip
                    key={`${option}-${index}`}
                    variant="outlined"
                    label={option}
                    onDelete={() =>
                      onChange(value.filter((_, i) => i !== index))
                    }
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Allowed Domains"
                  error={!!errors.allowedDomains}
                  helperText="Press enter to add domains."
                />
              )}
            />
          )}
        />
      </CrudFormControl>
    </>
  );
};
