import { useQuery } from 'react-query';
import {
  AggregatedConsumptionItemData,
  ConsumptionData,
  ConsumptionFilters,
  ConsumptionFiltersDto,
  ConsumptionItemData,
  SummaryTotalInfo,
} from '../../domain/types';
import { POST } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { useConsumptionFilters } from './useConsumptionFilters';
import { mean } from '../../utils/arrayUtils';
import {
  createItemMap,
  formatByBreakdownCostData,
  getSummaryTotals,
  formatSummaryData,
  includeCurrentMonthForecast,
} from '../../utils/consumptionUtils';
import { VOID_ID } from '../../domain/constants';
import { useMemo } from 'react';

interface Props {
  customerId?: number;
  filters: ConsumptionFilters;
}

interface Response {
  rawData?: ConsumptionData;
  averageNet?: number;
  averageGross?: number;
  formattedData?: AggregatedConsumptionItemData;
  itemMap: Record<string, number>;
  summaryData?: ConsumptionItemData;
  groupedSummaryData?: ConsumptionItemData;
  totals?: SummaryTotalInfo;
  currentMonthForecastData?: ConsumptionData;
  rawDataRequestFilters?: ConsumptionFiltersDto;
  isLoading: boolean;
  isError: boolean;
  nativeCurrency?: string;
}

const fetchConsumptionData = async (
  url: string,
  requestFilters: ConsumptionFiltersDto
): Promise<ConsumptionData> => {
  return await POST<ConsumptionData, ConsumptionFiltersDto>(
    url,
    requestFilters
  );
};

export const useConsumptionData = ({
  customerId,
  filters,
}: Props): Response => {
  const { breakdown, timeInterval } = filters;
  const rawDataRequestFilters = useConsumptionFilters(filters, customerId);
  const isReqEnabled = filters.selectedCustomerId === (customerId ?? VOID_ID);

  const summaryDataRequestFilters: ConsumptionFiltersDto = {
    ...rawDataRequestFilters,
    timeInterval, // summaries the data over the entire period
    summary: true,
  };

  const apiPath = apiPaths.consumptionData(customerId);
  const currentMonthForecastApiPath = apiPaths.consumptionCurrentMonthForecastData(
    customerId
  );

  // get the raw data:
  const {
    data: rawData,
    isLoading: isLoadingRawData,
    isError: isRawDataError,
  } = useQuery<ConsumptionData>(
    apiPath.key.list(rawDataRequestFilters),
    () => fetchConsumptionData(apiPath.base, rawDataRequestFilters),
    {
      enabled: isReqEnabled,
    }
  );

  // get the summary data:
  const {
    data: summaryData,
    isLoading: isLoadingSummaryData,
    isError: isSummaryDataError,
  } = useQuery<ConsumptionData>(
    apiPath.key.list(summaryDataRequestFilters),
    () =>
      POST<ConsumptionData, ConsumptionFiltersDto>(
        apiPath.base,
        summaryDataRequestFilters
      ),
    {
      enabled: isReqEnabled,
    }
  );

  //get the current month forecast
  const {
    data: currentMonthForecastData,
    isLoading: isLoadingCurrentMonthForecast,
    isError: isCurrentMonthForecastError,
  } = useQuery<ConsumptionData>(
    currentMonthForecastApiPath.key.list(rawDataRequestFilters),
    () =>
      POST<ConsumptionData, ConsumptionFiltersDto>(
        currentMonthForecastApiPath.base,
        rawDataRequestFilters
      ),
    {
      enabled: timeInterval === 'month' && isReqEnabled,
    }
  );

  return useMemo(() => {
    const isLoading =
      isLoadingRawData || isLoadingSummaryData || isLoadingCurrentMonthForecast;
    const isError =
      isRawDataError || isSummaryDataError || isCurrentMonthForecastError;

    if (!rawData || !summaryData) {
      return {
        itemMap: {},
        isLoading,
        isError,
      };
    }

    if (breakdown === 'overall') {
      const averageNet = mean(rawData?.items.map(({ net_cost }) => net_cost));
      const averageGross = mean(
        rawData?.items.map(({ gross_cost }) => gross_cost)
      );
      const totals = rawData?.items.reduce(
        (totals, item) => {
          totals.net += item.net_cost;
          totals.gross += item.gross_cost;
          totals.discount += item.discount;

          return totals;
        },
        { net: 0, gross: 0, discount: 0 }
      );
      return {
        rawData: includeCurrentMonthForecast(rawData, currentMonthForecastData),
        nativeCurrency: summaryData.nativeCurrency,
        averageNet,
        averageGross,
        itemMap: {},
        rawDataRequestFilters,
        totals,
        isLoading,
        isError,
      };
    } else {
      const { summaryDataItems, groupedSummaryDataItems } = formatSummaryData(
        summaryData.items,
        breakdown
      );
      const breakdownDataItems = formatByBreakdownCostData(
        rawData.items,
        groupedSummaryDataItems,
        breakdown
      );

      const itemMap = createItemMap(groupedSummaryDataItems);
      const totals = getSummaryTotals(summaryDataItems);
      return {
        nativeCurrency: summaryData.nativeCurrency,
        formattedData: {
          currency: rawData.currency,
          groupedData: breakdownDataItems,
        },
        summaryData: {
          currency: summaryData.currency,
          items: summaryDataItems,
        },
        groupedSummaryData: {
          currency: summaryData.currency,
          items: groupedSummaryDataItems,
        },
        itemMap,
        totals,
        rawDataRequestFilters,
        isLoading,
        isError,
      };
    }
  }, [
    breakdown,
    isLoadingRawData,
    isLoadingSummaryData,
    summaryData,
    rawData,
    isRawDataError,
    isSummaryDataError,
    currentMonthForecastData,
    isCurrentMonthForecastError,
    isLoadingCurrentMonthForecast,
    rawDataRequestFilters,
  ]);
};
