import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Commit, CommitType } from '../../../../domain/types';

export interface ForecastTypeFilterProps {
  value: CommitType | null;
  selectedCommit: Commit | null;
  onChange: (value: CommitType) => void;
}

export const ForecastTypeFilter = ({
  value,
  selectedCommit,
  onChange,
}: ForecastTypeFilterProps) => {
  const commitType = selectedCommit?.type;
  const isHard = commitType === CommitType.HARD;
  const isSoft = commitType === CommitType.SOFT;
  const isBoth = commitType === CommitType.HARD_AND_SOFT;
  return (
    <FormControl fullWidth>
      <InputLabel id="commit-type-filter-label" shrink={!!value}>
        Commit Type
      </InputLabel>
      <Select
        labelId="commit-type-filter-label"
        id="commit-type-filter"
        value={value ?? ''}
        disabled={selectedCommit?.type !== CommitType.HARD_AND_SOFT}
        onChange={(e) => onChange(e.target.value as CommitType)}
      >
        {(isHard || isBoth) && (
          <MenuItem value={CommitType.HARD}>Hard</MenuItem>
        )}
        {(isSoft || isBoth) && (
          <MenuItem value={CommitType.SOFT}>Soft</MenuItem>
        )}
        {isBoth && (
          <MenuItem value={CommitType.HARD_AND_SOFT}>Hard & Soft</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
