import {
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import {
  ALL_ROLES,
  Customer,
  CUSTOMER_ROLES,
  PagedItems,
  ProjectGroup,
  Role,
  User,
} from '../../../domain/types';
import { useQuery } from 'react-query';
import { apiPaths } from '../../../utils/apiPaths';
import { GET_PAGED } from '../../../utils/api';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { UserFormValues } from './UserSchema';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { isCustomerRole, useHasRole } from '../../../hooks/useHasRole';
import { useStateContext } from '../../../contexts/StateProvider';
import { useProjectGroups } from '../../../hooks/useProjectGroups';
import { ProjectTreeItem } from '../../../hooks/projectGroupsSlice';
import WarningIcon from '@mui/icons-material/Warning';

export const UserForm: CrudFormComponent<User> = ({ item }) => {
  const { register, errors, control, watch } = useFormContext<UserFormValues>();
  const isSysAdmin = useHasRole(Role.SYSTEM_ADMIN);
  const isCustomerAdmin = useHasRole(Role.CUSTOMER_ADMIN);
  const classes = useStyles();

  const { selectedCustomerId } = useStateContext();

  const selectedCustomers = watch(
    'customers',
    item?.customers?.map((c) => c.id) || []
  );

  const selectedCustomer = (selectedCustomers || [])[0] || selectedCustomerId;

  const selectedRole = watch('role', item?.role);
  const isCustomersVisible = isSysAdmin && isCustomerRole(selectedRole);
  const isCustomerViewerVisible = !!(
    (isSysAdmin || isCustomerAdmin) &&
    selectedRole === Role.CUSTOMER_VIEWER &&
    (selectedCustomers?.length > 0 || selectedCustomerId)
  );

  // roles for the role dropdown
  const roles = isSysAdmin ? ALL_ROLES : CUSTOMER_ROLES;

  // customers for the customer auto complete
  // the value is a number in the form model so we need to supply the auto complete
  // with a list of numbers and then map those numbers back to the customer to display
  // the names to the user
  const { data: customers } = useQuery<PagedItems<Customer>>(
    apiPaths.customer.key.list(),
    () => GET_PAGED(apiPaths.customer.base),
    {
      enabled: isSysAdmin || isCustomerAdmin,
    }
  );

  const {
    state: { projectGroups },
  } = useProjectGroups({
    includeProjects: true,
    includeUngroupedProjects: true,
    overrideSelectedCustomerId: selectedCustomer,
  });

  const { customerMap, customerIds } = useMemo(() => {
    const customerItems = customers?.items ?? [];
    const customerMap: Map<number, Customer> = customerItems.reduce(
      (map, customer) => {
        return map.set(customer.id, customer);
      },
      new Map()
    );
    const customerIds = Array.from(customerMap.keys());
    return { customerMap, customerIds };
  }, [customers?.items]);

  const {
    restrictedProjectGroupsMap,
    restrictedProjectGroupsIds,
    restrictedProjectsMap,
    restrictedProjectsIds,
  } = useMemo(() => {
    const restrictedProjectGroups = Object.keys(projectGroups).map(
      (key: any) => {
        const group = projectGroups[key];

        return {
          id: group.id,
          name: group.name,
          projects: Object.keys(group.projects).map(
            (projectKey: any) => group.projects[projectKey]
          ),
        };
      }
    );

    const restrictedProjectGroupsMap: Map<
      number,
      ProjectGroup
    > = restrictedProjectGroups
      .filter((projectGroup) => projectGroup?.id !== -1)
      .reduce((map, projectGroup) => {
        return map.set(projectGroup.id, projectGroup);
      }, new Map());

    const restrictedProjectGroupsIds = Array.from(
      restrictedProjectGroupsMap.keys()
    );

    const restrictedProjectsMap: Map<
      number,
      ProjectTreeItem
    > = restrictedProjectGroups.reduce((map, projectGroup) => {
      projectGroup?.projects?.forEach((project) => {
        map = map.set(project.id, project);
      });

      return map;
    }, new Map());

    const restrictedProjectsIds = Array.from(restrictedProjectsMap.keys());

    return {
      restrictedProjectGroupsMap,
      restrictedProjectGroupsIds,
      restrictedProjectsMap,
      restrictedProjectsIds,
    };
  }, [projectGroups]);

  const isReconfigurationNeeded = useMemo(() => {
    if (!item) {
      return false;
    }
    const { restrictedProjectGroups, restrictedProjects } = item;
    const allGroupsLinked = restrictedProjectGroups.every((item: any) =>
      restrictedProjectGroupsIds.includes(item)
    );
    const allProjectsLinked = restrictedProjects.every((item: any) =>
      restrictedProjectsIds.includes(item)
    );
    const hasRestrictions =
      restrictedProjectGroups.length > 0 || restrictedProjects.length > 0;

    return hasRestrictions && !(allGroupsLinked && allProjectsLinked);
  }, [restrictedProjectGroupsIds, restrictedProjectsIds, item]);

  return (
    <>
      <CrudFormControl error={errors.email}>
        <TextField
          id="email"
          name="email"
          inputRef={register}
          label="Email Address"
          defaultValue={item?.email}
          error={!!errors.email}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.role}>
        <InputLabel id="role-label">Role</InputLabel>
        <Controller
          name="role"
          control={control}
          defaultValue={item?.role ?? ''}
          render={({ ...props }) => (
            <Select
              data-testid="user-role-select"
              labelId="role-label"
              id="role"
              {...props}
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </CrudFormControl>
      {isCustomersVisible && (
        <Controller
          control={control}
          name="customers"
          defaultValue={item?.customers.map(({ id }) => id) ?? []}
          render={({ onChange, ...props }) => (
            <Autocomplete<number, true>
              multiple={true}
              options={customerIds}
              onChange={(e, value) => {
                onChange(value);
              }}
              getOptionLabel={(id) => customerMap.get(id)?.name ?? ''}
              renderInput={(params) => (
                <CrudFormControl
                  error={(errors.customers as unknown) as FieldError}
                >
                  <TextField
                    {...params}
                    label="Customer"
                    error={!!errors.customers}
                  />
                </CrudFormControl>
              )}
              {...props}
            />
          )}
        />
      )}
      {isCustomerViewerVisible && (
        <>
          <Typography variant="body2">
            Restricted Access at Project/Group Level
          </Typography>
          {isReconfigurationNeeded && (
            <Typography
              data-testid="warning-message"
              variant="body2"
              className={classes.reconfigurationBanner}
            >
              <WarningIcon fontSize="small" />
              Some projects or groups used internally for customer views access
              are now removed or moved.Please replace "item removed" with the
              desired resources.
            </Typography>
          )}
          <Controller
            control={control}
            name="restrictedProjectGroups"
            defaultValue={item?.restrictedProjectGroups ?? []}
            render={({ onChange, ...props }) => (
              <Autocomplete<number, true>
                multiple={true}
                options={restrictedProjectGroupsIds}
                onChange={(e, value) => {
                  onChange(value);
                }}
                getOptionLabel={(id) =>
                  restrictedProjectGroupsMap.get(id)?.name ?? 'item removed'
                }
                renderInput={(params) => (
                  <CrudFormControl
                    error={
                      (errors.restrictedProjectGroups as unknown) as FieldError
                    }
                  >
                    <TextField
                      {...params}
                      label="Accessible Project Groups (empty means no restrictions)"
                      error={!!errors.restrictedProjectGroups}
                    />
                  </CrudFormControl>
                )}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="restrictedProjects"
            defaultValue={item?.restrictedProjects ?? []}
            render={({ onChange, ...props }) => (
              <Autocomplete<number, true>
                multiple={true}
                options={restrictedProjectsIds}
                onChange={(e, value) => {
                  onChange(value);
                }}
                getOptionLabel={(id) => {
                  const project = restrictedProjectsMap.get(id);
                  if (!project) {
                    return 'item removed';
                  }

                  return `${project.gcpProjectName} (${project.gcpProjectId})`;
                }}
                renderInput={(params) => (
                  <CrudFormControl
                    error={(errors.restrictedProjects as unknown) as FieldError}
                  >
                    <TextField
                      {...params}
                      label="Accessible Projects (empty means no restrictions)"
                      error={!!errors.restrictedProjects}
                    />
                  </CrudFormControl>
                )}
                {...props}
              />
            )}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  reconfigurationBanner: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.error.light,
  },
}));
