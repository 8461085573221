import { useCrudList } from '../../Crud/grid/useCrudList';
import { Project } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useListProjects = (customerId: number) => {
  const { data, isLoading, isError } = useCrudList<Project>({
    apiPath: apiPaths.project(customerId),
  });
  const { projects, projectIds, projectIdsToName } = useMemo(() => {
    const projects = data?.items ?? [];
    const projectIds = projects.map(({ id }) => id);
    const projectIdsToName = projects.reduce((map, project) => {
      return map.set(
        project.id,
        `${project.gcpProjectName} (${project.gcpProjectId})`
      );
    }, new Map<number, string>());

    return { projects, projectIds, projectIdsToName };
  }, [data?.items]);

  return {
    projects,
    projectIds,
    projectIdsToName,
    isLoading,
    isError,
  };
};
