import { useMemo } from 'react';
import { GET_PAGED } from '../../../../utils/api';
import { PagedItems, SkuItem } from '../../../../domain/types';
import { GridPaginationModel } from '@mui/x-data-grid';
import { apiPaths } from '../../../../utils/apiPaths';
import { useQuery } from 'react-query';

export const useSkus = (
  paginationModel: GridPaginationModel,
  searchModel: { field: string; search: string },
  filterUngrouped: boolean
) => {
  const apiPath = apiPaths.skus();

  const { data, isLoading, isError } = useQuery<PagedItems<SkuItem>>(
    apiPath.key.list([searchModel, filterUngrouped]),
    () =>
      GET_PAGED(
        apiPath.base,
        {
          page: 0,
          size: 1000000,
        },
        { ...searchModel, filterUngrouped }
      )
  );

  return useMemo(() => {
    return {
      items: data?.items ?? [],
      totalPages: data?.total_pages ?? 0,
      totalItems: data?.total_items ?? 0,
      page: data?.page ?? 0,
      isError,
      isLoading,
    };
  }, [data, isLoading, isError]);
};
