import { useQuery } from 'react-query';
import { BillingAccount, PagedItems, Customer } from '../../../../domain/types';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET_PAGED } from '../../../../utils/api';
import { useMemo } from 'react';
import { VOID_ID } from '../../../../domain/constants';

interface HookResult {
  customers: Customer[];
  billingAccounts: BillingAccount[];
  isLoading: boolean;
}

interface HookParams {
  selectedCustomerId: number;
  selectedCustomers?: number[];
  selectedBillingAccounts?: number[];
}

const filterBillingAccountsByCustomerSelection = (
  billingAccounts: BillingAccount[],
  selectedCustomers: number[] = [],
  selectedBillingAccounts: number[] = []
): BillingAccount[] => {
  if (selectedCustomers.length === 0) {
    return billingAccounts;
  }

  return billingAccounts.filter((billingAccount: BillingAccount) => {
    const isSelectedCustomerBA = selectedCustomers.includes(
      billingAccount.customerId
    );
    const isSelected = selectedBillingAccounts.includes(billingAccount.id);

    return isSelectedCustomerBA || isSelected;
  });
};

export const useCustomersBillingAccountsFilter = ({
  selectedCustomerId,
  selectedBillingAccounts,
  selectedCustomers,
}: HookParams): HookResult => {
  const isFetchedEnabled = selectedCustomerId === VOID_ID;

  const {
    data: billingAccountsData,
    isLoading: billingAccountsIdLoading,
  } = useQuery<PagedItems<BillingAccount>>(
    apiPaths.billingAccounts.key.list(),
    () => GET_PAGED(apiPaths.billingAccounts.base),
    {
      enabled: isFetchedEnabled,
    }
  );

  const { data: customersData, isLoading: customersIsLoading } = useQuery<
    PagedItems<Customer>
  >(apiPaths.customer.key.list(), () => GET_PAGED(apiPaths.customer.base), {
    enabled: isFetchedEnabled,
  });

  return useMemo(() => {
    const customers = customersData?.items ?? [];
    const billingAccounts = billingAccountsData?.items ?? [];

    return {
      customers: customers,
      billingAccounts: filterBillingAccountsByCustomerSelection(
        billingAccounts,
        selectedCustomers,
        selectedBillingAccounts
      ),
      isLoading: customersIsLoading || billingAccountsIdLoading,
    };
  }, [
    customersIsLoading,
    billingAccountsIdLoading,
    billingAccountsData,
    customersData,
    selectedBillingAccounts,
    selectedCustomers,
  ]);
};
