import { Avatar } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import PersonIcon from '@mui/icons-material/Menu';

type UserAvatarProps = Pick<
  firebase.default.UserInfo,
  'displayName' | 'photoURL'
>;

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.light}`,
}));

const UserAvatar = ({ displayName, photoURL }: UserAvatarProps) => {
  const alt = displayName ?? 'Billing App User';
  const src = photoURL ?? null;

  return src ? (
    <CustomAvatar alt={alt} src={src} />
  ) : (
    <CustomAvatar alt={alt}>
      <PersonIcon />
    </CustomAvatar>
  );
};

export default UserAvatar;
