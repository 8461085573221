import {
  TextField,
  TextFieldProps,
  AutocompleteProps,
  Autocomplete,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { useQuery } from 'react-query';
import { apiPaths } from '../../utils/apiPaths';
import { GET } from '../../utils/api';

export type CurrencyFieldProps = {
  label: string;
  error?: boolean;
  onChange?: (val: string | null) => void;
  TextFieldProps?: TextFieldProps;
} & Omit<
  AutocompleteProps<string, false, false, false>,
  'renderInput' | 'options'
>;

export const orderCurrency = (values: string[] | undefined): string[] => {
  if (values === undefined) {
    return [];
  }
  const baseCurrList = ['EUR', 'GBP', 'USD'];
  values = values.filter((curr: string) => !baseCurrList.includes(curr));
  values.sort();
  return [...baseCurrList, ...values];
};

export const CurrencyCodeField = forwardRef(
  (
    {
      label,
      error,
      onChange,
      TextFieldProps,
      ...autoCompleteProps
    }: CurrencyFieldProps,
    ref
  ) => {
    const { data: currencies, isLoading } = useQuery<string[]>(
      apiPaths.currency.key.list(),
      () => GET(apiPaths.currency.base)
    );

    const orderedCurrencies = orderCurrency(currencies);

    return (
      <Autocomplete<string, false, false, false>
        {...autoCompleteProps}
        ref={ref}
        loading={isLoading}
        disableClearable={false}
        options={orderedCurrencies}
        onChange={(e, value) => {
          onChange?.(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...TextFieldProps}
            label={label}
            error={error}
          />
        )}
      />
    );
  }
);
