import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReportUsage } from '../../../../domain/types';

export interface ConsumptionUsageFilterProps {
  disabled: boolean;
  value: ReportUsage;
  onChange: (value: ReportUsage) => void;
}

export const ConsumptionUsageFilter = ({
  disabled = false,
  value,
  onChange,
}: ConsumptionUsageFilterProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="usage-by-filter">Usage by</InputLabel>
      <Select
        labelId="usage-by-filter-label"
        id="usage-by-filter"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value as ReportUsage)}
      >
        <MenuItem value="consumption_date">Consumption Date</MenuItem>
        <MenuItem value="invoice_date">Invoice Date</MenuItem>
      </Select>
    </FormControl>
  );
};
