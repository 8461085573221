import React from 'react';
import { TextField, Autocomplete, MenuItem } from '@mui/material';
import { Commit } from '../../../../domain/types';
import { formatDate } from '../../../../utils/dateUtils';
import makeStyles from '@mui/styles/makeStyles';

export interface ForecastCommitFilterProps {
  value: Commit | null;
  onChange: (val: Commit | null) => void;
  commits: Commit[] | null;
  commitsLoading: boolean;
}

export const ForecastCommitFilter = ({
  value,
  onChange,
  commits,
  commitsLoading,
}: ForecastCommitFilterProps) => {
  const classes = useStyles();

  const getCommitLabel = (commit: Commit): string => {
    return `${formatDate(new Date(commit.startDate))} - ${formatDate(
      new Date(commit.endDate)
    )}`;
  };

  return (
    <Autocomplete<Commit>
      className={classes.input}
      value={value}
      onChange={(e, value) => {
        onChange(value);
      }}
      clearIcon=""
      loading={commitsLoading}
      options={commits ?? []}
      getOptionLabel={(item: Commit) => getCommitLabel(item)}
      isOptionEqualToValue={(option: Commit, value: Commit) => {
        return option.id === value.id;
      }}
      getOptionDisabled={(option: Commit) =>
        new Date() < new Date(option.startDate)
      }
      renderOption={(props, commit) => (
        <MenuItem {...props}>{getCommitLabel(commit)}</MenuItem>
      )}
      renderInput={(params) => <TextField {...params} label="Commit" />}
    />
  );
};

const useStyles = makeStyles({
  input: {
    minWidth: 284,
  },
});
