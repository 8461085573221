import { FormEvent, forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

type MonetaryValueFieldProps = {
  currency?: string;
} & TextFieldProps;

export const MonetaryValueField = ({
  id,
  error,
  currency,
  InputProps,
  InputLabelProps,
  ...props
}: MonetaryValueFieldProps) => {
  return (
    <TextField
      error={!!error}
      {...props}
      InputLabelProps={{
        ...InputLabelProps,
        htmlFor: id,
      }}
      InputProps={{
        ...InputProps,
        id,
        startAdornment: currency ? (
          <InputAdornment position="start">{currency}</InputAdornment>
        ) : undefined,
        inputComponent: MonetaryFormatField,
      }}
    />
  );
};

const MonetaryFormatField = forwardRef(
  (
    {
      onChange,
      defaultValue,
      value,
      ...textFieldProps
    }: InputBaseComponentProps,
    ref
  ) => {
    return (
      <NumberFormat
        {...textFieldProps}
        getInputRef={ref}
        onValueChange={({ value }) => {
          onChange?.((Number(value) as unknown) as FormEvent<HTMLInputElement>);
        }}
        thousandSeparator
        isNumericString
        defaultValue={defaultValue ? String(defaultValue) : undefined}
        decimalScale={2}
        value={value ? String(value) : undefined}
      />
    );
  }
);
