import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/styles';

interface ComponentProps {
  children: ReactNode;
  className?: ClassNameMap | string;
  style?: CSSProperties;
}

export function WidgetContainer({
  children,
  className,
  style,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box style={style} className={clsx(classes.widgetContainer, className)}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    position: 'relative',
    background: 'white',
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));
