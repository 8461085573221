import { boolean, object, SchemaOf, string } from 'yup';

export type SkuGroupFormValue = {
  groupName: string;
  commitIncluded?: boolean;
};

export const SkuGroupSchema: SchemaOf<SkuGroupFormValue> = object({
  groupName: string().required(),
  commitIncluded: boolean(),
}).defined();
