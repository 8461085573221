import { FormControlLabel, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';

export interface ProjectGroupSelectorProps {
  checked: boolean;
  indeterminate?: boolean;
  onToggle: () => void;
  onSelect: () => void;
  label: string;
  gcpProjectName?: string;
  multiSelect: boolean;
}

export const ProjectGroupSelector = ({
  checked,
  indeterminate,
  onToggle,
  onSelect,
  label,
  gcpProjectName,
  multiSelect,
}: ProjectGroupSelectorProps) => {
  const classes = useStyles();
  // we render icons here instead of actual form fields due to performance issues with
  // re-rendering a lot of them in the list concurrently

  const selectorState = () => {
    if (checked) {
      return 'checked';
    } else if (indeterminate) {
      return 'indeterminate';
    } else {
      return 'default';
    }
  };

  const getCheckbox = () => {
    const color = checked ? 'primary' : 'action';
    const CheckboxIcon = checked
      ? CheckBox
      : indeterminate
      ? IndeterminateCheckBox
      : CheckBoxOutlineBlank;

    return (
      <CheckboxIcon
        data-testid={`${selectorState()}-${label}`}
        color={color}
        fontSize="small"
        className={classes.control}
      />
    );
  };
  const getRadio = () => {
    const color = checked ? 'primary' : 'action';
    const RadioIcon = checked ? RadioButtonChecked : RadioButtonUnchecked;
    return (
      <RadioIcon
        data-testid={`${selectorState()}-${label}`}
        color={color}
        fontSize="small"
        className={classes.control}
      />
    );
  };
  return (
    <FormControlLabel
      control={multiSelect ? getCheckbox() : getRadio()}
      onClick={multiSelect ? onToggle : onSelect}
      classes={{
        root: classes.labelRoot,
        label: classes.label,
      }}
      data-testid={`label-${label}`}
      label={
        <>
          {gcpProjectName && (
            <Typography
              title={gcpProjectName.length > 20 ? gcpProjectName : ''}
              variant="subtitle2"
              noWrap
            >
              {gcpProjectName}
            </Typography>
          )}
          <Typography
            title={label.length > 20 ? label : ''}
            variant="body2"
            noWrap
          >
            {label}
          </Typography>
        </>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  selector: {
    padding: theme.spacing(0, 1),
  },
  groupOnlySelector: {
    padding: theme.spacing(0.8),
  },
  control: {
    padding: theme.spacing(0, 1),
  },
  labelRoot: {
    width: 220,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: 0,
  },
  label: {
    overflow: 'hidden',
  },
}));
