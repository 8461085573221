import {
  Apportionment,
  ApportionmentAllocationType,
  ApportionmentSplitType,
  Editable,
  FormValues,
  ServiceApportionment,
  BillingServiceCharge,
} from '../../../domain/types';
import { array, mixed, string, number, object, SchemaOf } from 'yup';

export type BillingChargeManualApportionment = Omit<
  ServiceApportionment,
  'serviceId'
> &
  Pick<BillingServiceCharge, 'ids' | 'serviceName' | 'usageDate'>;

export type BillingServiceManualApportionmentFormValues = Editable<
  FormValues<BillingChargeManualApportionment>
>;

export type SubmittedBillingServiceManualApportionmentFormValues = Omit<
  BillingServiceManualApportionmentFormValues,
  'apportionments'
>;

export const BillingServiceManualApportionmentSchema: SchemaOf<BillingServiceManualApportionmentFormValues> = object(
  {
    serviceName: string().required(),
    gcpServiceId: string(),
    ids: array<number[]>().required(),
    usageDate: string().required(),

    allocationType: mixed<ApportionmentAllocationType>()
      .oneOf(
        Object.values(ApportionmentAllocationType),
        'This field is required'
      )
      .required(),
    splitType: mixed<ApportionmentSplitType>()
      .oneOf(Object.values(ApportionmentSplitType), 'This field is required')
      .when(
        ['allocationType'],
        (allocationType: ApportionmentAllocationType) => {
          if (
            allocationType === ApportionmentAllocationType.ALL_PROJECTS ||
            allocationType === ApportionmentAllocationType.BILLING_ACCOUNT
          ) {
            return mixed<ApportionmentSplitType>()
              .oneOf(
                Object.values(ApportionmentSplitType),
                'This field is required'
              )
              .optional();
          } else {
            return mixed<ApportionmentSplitType>()
              .oneOf(
                Object.values(ApportionmentSplitType),
                'This field is required'
              )
              .required();
          }
        }
      ),
    apportionments: array<Apportionment[]>()
      .of(
        object().shape({
          projectId: number().optional(),
          projectGroupId: number().optional(),
          percentage: number().optional(),
        })
      )
      .when(
        ['allocationType', 'splitType'],
        (allocationType: ApportionmentAllocationType, splitType: any) => {
          if (
            allocationType === ApportionmentAllocationType.PROJECT ||
            allocationType === ApportionmentAllocationType.PROJECT_GROUP
          ) {
            // Project or Project Group split
            if (
              (splitType as ApportionmentSplitType) ===
              ApportionmentSplitType.PERCENTAGE_OF_SPEND
            ) {
              // Percentage of spend - percentage field required
              return array<Apportionment[]>()
                .of(
                  object().shape({
                    projectId: number(),
                    projectGroupId: number(),
                    percentage: number()
                      .min(1, 'Percentage must be at least 1%')
                      .required(),
                  })
                )
                .when('allocationType', {
                  is: ApportionmentAllocationType.PROJECT,
                  then: array()
                    .of(
                      object().shape({
                        projectId: number().required(),
                        percentage: number().required(),
                      })
                    )
                    .min(1),
                })
                .when('allocationType', {
                  is: ApportionmentAllocationType.PROJECT_GROUP,
                  then: array()
                    .of(
                      object().shape({
                        projectGroupId: number().required(),
                        percentage: number().required(),
                      })
                    )
                    .min(1),
                })
                .test(
                  'apportionments',
                  'Sum of percentages must equal 100',
                  (value: Apportionment[] | undefined) => {
                    if (!value || !value.length) {
                      return false;
                    }
                    return (
                      value.reduce((total, apportionment) => {
                        return total + (apportionment.percentage ?? 0);
                      }, 0) === 100
                    );
                  }
                );
            } else {
              // Even split - percentage field not required
              return array<Apportionment[]>()
                .min(1)
                .when('allocationType', {
                  is: ApportionmentAllocationType.PROJECT,
                  then: array()
                    .of(
                      object().shape({
                        projectId: number().required(),
                      })
                    )
                    .min(1)
                    .required(),
                })
                .when('allocationType', {
                  is: ApportionmentAllocationType.PROJECT_GROUP,
                  then: array()
                    .of(
                      object().shape({
                        projectGroupId: number().required(),
                      })
                    )
                    .min(1)
                    .required(),
                });
            }
          } else {
            // ALL PROJECTS
            return array<Apportionment[]>().optional();
          }
        }
      ),
  }
).defined();
