import { useState } from 'react';
import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { useStateContext } from '../../../contexts/StateProvider';
import { GridColDef } from '@mui/x-data-grid';
import { FormGroup, Switch, FormControlLabel } from '@mui/material';
import { useGetCustomerCurrency } from '../BudgetAllocations/useGetCustomerCurrency';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { DateDisplay } from '../../DateDisplay';
import { BillingServiceManualApportionmentForm } from './BillingServiceManualApportionmentForm';
import {
  BillingChargeManualApportionment,
  BillingServiceManualApportionmentSchema,
  BillingServiceManualApportionmentFormValues,
  SubmittedBillingServiceManualApportionmentFormValues,
} from './BillingServiceManualApportionmentSchema';
import {
  API_DATE_FORMAT,
  UI_DATE_FORMAT,
  UI_MONTHLY_DATE_FORMAT,
} from '../../../domain/constants';
import format from 'date-fns/format';

export const BillingServiceChargesAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const [isMonthlyFilter, setIsMonthlyFilter] = useState<boolean>(true);
  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  const columns: GridColDef[] = [
    {
      headerName: 'Service Name',
      field: 'serviceName',
      flex: 0.5,
    },
    {
      headerName: 'Service ID',
      field: 'gcpServiceId',
      flex: 0.5,
    },
    {
      headerName: 'Service Type',
      field: 'serviceType',
      flex: 0.5,
    },
    {
      headerName: isMonthlyFilter ? 'Usage Month/Year' : 'Usage Date',
      field: 'usageDate',
      flex: 0.5,
      renderCell: ({ row }) => (
        <DateDisplay
          date={row.usageDate}
          format={isMonthlyFilter ? UI_MONTHLY_DATE_FORMAT : UI_DATE_FORMAT}
        />
      ),
    },
    {
      headerName: 'Gross Cost',
      field: 'cost',
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = row.cost;
        return <MonetaryDisplay value={value} currency={currency} />;
      },
    },
    {
      headerName: 'Credit',
      field: 'credit',
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = row.credit;
        return <MonetaryDisplay value={value} currency={currency} />;
      },
    },
    {
      headerName: 'Net Cost',
      field: 'netCost',
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = row.netCost;
        return <MonetaryDisplay value={value} currency={currency} />;
      },
    },
  ];

  const handleGroupByMonthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsMonthlyFilter(event.target.checked);
  };

  return (
    <CrudContainer<
      BillingChargeManualApportionment,
      BillingServiceManualApportionmentFormValues,
      SubmittedBillingServiceManualApportionmentFormValues
    >
      CrudHeaderControls={
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isMonthlyFilter}
                onChange={handleGroupByMonthChange}
              />
            }
            label="Group by month"
          />
        </FormGroup>
      }
      crudApiParams={{ monthlyFilter: isMonthlyFilter }}
      name="Manual Billing Account Service Apportionment"
      apiPath={apiPaths.billingServiceCharges(selectedCustomerId)}
      columns={columns}
      defaultSortColumn="serviceName"
      FormComponent={BillingServiceManualApportionmentForm}
      validationSchema={BillingServiceManualApportionmentSchema}
      enableCreate={false}
      enableDelete={false}
      enableUpdate={true}
      overrideRequest={true}
      editText={'Apportion'}
      valueMapper={(values) => {
        /**
         * Value mapper is used to ensure all whole percentage numbers are converted between 0 and 1
         * The api expects 0 - 1 and will return 0 - 1.
         * You will find on the UI these values are formatted as whole numbers  0 - 100
         */
        const { apportionments, ...submittedValues } = values;
        delete submittedValues.gcpServiceId;

        const formattedApportionments =
          apportionments?.map((apportionment) => {
            const percentage = apportionment.percentage
              ? apportionment.percentage / 100
              : undefined;
            return {
              ...apportionment,
              percentage,
            };
          }) ?? [];

        const usageDate: string = isMonthlyFilter
          ? format(new Date(submittedValues.usageDate), API_DATE_FORMAT)
          : submittedValues.usageDate;

        return {
          ...{ ...submittedValues, usageDate },
          apportionments: formattedApportionments,
        };
      }}
    />
  );
};
