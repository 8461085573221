import { useMemo } from 'react';
import { BQUsageFilters } from '../../../../domain/types';
import { useBigQueryUsageData } from './useBigQueryUsageData';
import { formatSummaryData } from '../utils/formatSummaryData';
import { formatByBreakdownCostData } from '../utils/formatByBreakdownCostData';

interface HookProps {
  customerId: number;
  filters: BQUsageFilters;
}

export const createItemMap = (summaryData: any[]): Record<string, number> => {
  return Object.fromEntries(
    summaryData.map(({ item }, index) => [item, index])
  );
};

export const useBigQueryUsageReport = ({ customerId, filters }: HookProps) => {
  const { items, isLoading: itemsIsLoading, currency } = useBigQueryUsageData({
    customerId,
    filters,
    summary: false,
  });
  const {
    items: summaryItems,
    isLoading: summaryIsLoading,
  } = useBigQueryUsageData({ customerId, filters, summary: true });

  return useMemo(() => {
    const { summaryDataItems, groupedSummaryDataItems } = formatSummaryData(
      summaryItems,
      filters.breakdown
    );
    const indicativeCost = formatByBreakdownCostData(
      items,
      groupedSummaryDataItems,
      filters.breakdown
    );
    const itemsMap = createItemMap(groupedSummaryDataItems);

    return {
      items,
      isLoading: itemsIsLoading || summaryIsLoading,
      summaryItems: summaryDataItems,
      currency,
      itemsMap,
      groupedSummaryDataItems,
      breakdownDataItems: {
        indicativeCost,
      },
    };
  }, [
    items,
    summaryItems,
    itemsIsLoading,
    summaryIsLoading,
    currency,
    filters.breakdown,
  ]);
};
