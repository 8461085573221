import { Tile } from './Tile';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { ReactNode } from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface SummaryTileProps {
  title: string;
  value?: number;
  currency?: string;
  testId?: string;
  icon?: ReactNode;
  isLoading?: boolean;
}

export const SummaryTile = ({
  title,
  value,
  currency,
  testId,
  icon,
  isLoading,
}: SummaryTileProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Tile
        isLoading={isLoading}
        heading={title}
        className={classes.kpiContainer}
        subHeading={
          typeof value === 'undefined' ? (
            <>&mdash;</>
          ) : (
            <Tooltip
              title={
                <Typography variant="body1">
                  <MonetaryDisplay value={value} currency={currency} />
                </Typography>
              }
            >
              <span>
                <MonetaryDisplay value={value} currency={currency} shortForm />
              </span>
            </Tooltip>
          )
        }
        icon={icon}
        testId={testId}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  kpiContainer: {
    [theme.breakpoints.down(1570)]: {
      minWidth: 190,
    },
  },
}));
