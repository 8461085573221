import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ConsumptionFilters } from '../../../domain/types';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFilters } from '../../../contexts/ReportFiltersProvider';
import { ProjectGroupsState } from '../../../hooks/projectGroupsSlice';
import { UseProjectGroupActions } from '../../../hooks/useProjectGroups';
import { AllSelector } from './AllSelector';
import { SearchField } from './SearchField';
import { ProjectGroupsList } from './ProjectGroupsList';
import {
  FILTER_BAR_HEIGHT,
  FILTER_BUTTON_HEIGHT,
} from '../../../components/Dashboard/FilterBar.stylesheet';

/**
 * group - restrict selections to group level only - child projects will
 * not be loaded
 *
 * project - groups can not be selected, but child projects can be loaded
 * and selected
 */
export type SelectionLevel = 'all' | 'group' | 'project';

interface ProjectGroupsFilterProps {
  multiSelect?: boolean;
  selectionLevel?: SelectionLevel;
  state: ProjectGroupsState;
  actions: UseProjectGroupActions;
}

// If the list is smaller then this value it can not be used
const MINIMUM_LIST_HEIGHT = 180;

export const ProjectGroupsFilter = ({
  state: {
    loading,
    loaded,
    projectGroups,
    selectionState,
    searchTerm,
    queryFilters,
  },
  actions,
  multiSelect = true,
  selectionLevel = 'all',
}: ProjectGroupsFilterProps) => {
  const { setFilterValue } = useFilters<ConsumptionFilters>();

  const [listHeight, setListHeight] = useState<number>(430);

  // update project filters whenever the top level selection state changes
  // or the selected projects/groups changes
  useEffect(() => {
    setFilterValue(
      'projectFilters',
      selectionState === 'selected' ? undefined : queryFilters
    );
  }, [queryFilters, selectionState, setFilterValue]);

  const classes = useStyles();

  const visibleGroups = useMemo(
    () => Object.values(projectGroups).filter(({ visible }) => visible),
    [projectGroups]
  );

  const otherFiltersRef = useRef<any>();
  const listRef = useRef<any>();
  const toggleSize = (index: number) => {
    listRef?.current?.resetAfterIndex(index);
  };

  /**
   * Used to dynamically determine the height of the list. React window
   * requires a set height so using a dynamic height gives better UX to
   * users with different screen sizes.
   */
  useLayoutEffect(() => {
    if (otherFiltersRef?.current?.offsetTop !== undefined) {
      const viewportHeight = window.innerHeight;
      const offsetTop = otherFiltersRef.current.offsetTop;

      const TOP_CONTENT_PADDING = 24;
      const FILTER_BAR_PADDING_TOP = 8;
      const PROJECT_GROUPS_BOTTOM_MARGIN = 34;

      const filterBarOffset =
        FILTER_BAR_HEIGHT +
        FILTER_BUTTON_HEIGHT +
        TOP_CONTENT_PADDING +
        FILTER_BAR_PADDING_TOP +
        PROJECT_GROUPS_BOTTOM_MARGIN;

      const computedListHeight = viewportHeight - (filterBarOffset + offsetTop);
      setListHeight(
        computedListHeight < MINIMUM_LIST_HEIGHT
          ? MINIMUM_LIST_HEIGHT
          : computedListHeight
      );
    }
  }, [otherFiltersRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = !loaded || loading;

  return (
    <div
      className={classes.projectGroups}
      data-testid="project-groups-filter"
      ref={otherFiltersRef}
    >
      <InputLabel shrink>Project Groups</InputLabel>
      {isLoading ? (
        <QueryLoader testId="project-groups-filter-loading" />
      ) : (
        <>
          <div className={classes.globalFiltering}>
            <AllSelector
              multiSelect={multiSelect}
              selected={selectionState === 'selected'}
              indeterminate={selectionState === 'some'}
              toggleAll={actions.toggleAll}
              clearAll={actions.clearAll}
            />
            <SearchField
              searchTerm={searchTerm}
              setSearchTerm={(searchTerm) => actions.setSearchTerm(searchTerm)}
              filterBySearchTerm={(searchTerm) => {
                actions.filterBySearchTerm(searchTerm);
                toggleSize(0);
              }}
            />
          </div>
          <ProjectGroupsList
            listHeight={listHeight}
            listRef={listRef}
            visibleGroups={visibleGroups}
            actions={actions}
            multiSelect={multiSelect}
            selectionLevel={selectionLevel}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  globalFiltering: {
    display: 'flex',
  },
  projectGroups: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
