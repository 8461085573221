import { useFormContext } from 'react-hook-form';
import { CommitSkuGroup } from '../../../domain/types';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { SkuGroupFormValue } from './SkuGroupSchema';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';
import { useState } from 'react';

export const SkuGroupForm: CrudFormComponent<CommitSkuGroup> = ({ item }) => {
  const { register, errors } = useFormContext<SkuGroupFormValue>();
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  const [checked, setChecked] = useState(item?.commitIncluded);

  return (
    <>
      <CrudFormControl error={errors.groupName}>
        <TextField
          id="groupName"
          name="groupName"
          inputRef={register}
          label="Group Name"
          defaultValue={item?.groupName}
          error={!!errors.groupName}
        />
      </CrudFormControl>
      {isCustomerSelected && (
        <CrudFormControl error={errors.commitIncluded}>
          <FormControlLabel
            label="Counts toward commit?"
            control={
              <Checkbox
                id="commitIncluded"
                name="commitIncluded"
                inputRef={register}
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
          />
        </CrudFormControl>
      )}
    </>
  );
};
