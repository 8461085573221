import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import UpdateIcon from '@mui/icons-material/Update';
import { useLastSyncData } from '../Charts/useStatusData';
import { Box, Tooltip } from '@mui/material';

export const SystemStatus = () => {
  const { data, isLoading, isError } = useLastSyncData();

  const classes = useStyles();
  let lastSyncTime;

  if (isLoading || !data) {
    return null;
  }

  lastSyncTime = isError ? undefined : data.lastSyncTime;
  const lastSyncLabel = lastSyncTime
    ? `Data as of ${lastSyncTime}`
    : 'Indeterminate data update time';

  return (
    <Box component="span" pl={2}>
      <Tooltip
        title={
          <div>
            {lastSyncLabel}
            <p>Data for last 48 hours may not be complete</p>
            <p>
              Forecast data can't be filtered by labels, only by services and
              projects!
            </p>
          </div>
        }
      >
        <UpdateIcon fontSize="small" className={classes.icon} />
      </Tooltip>
      <div className={classes.metaLabel}>
        <span>Data for last 48 hours may not be complete</span>
        <span data-testid="last-sync-time">{lastSyncLabel}</span>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    metaLabel: {
      visibility: 'hidden',
      width: 0,
      height: 0,
    },
    root: {
      margin: theme.spacing(3, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.grey[600],
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      marginRight: theme.spacing(0.6),
      color: theme.palette.grey[600],
    },
    error: {
      color: theme.palette.error.light,
    },
  })
);
