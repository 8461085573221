import {
  BudgetAllocation,
  BudgetAllocationAmountType,
  Editable,
  FormValues,
} from '../../../domain/types';
import { mixed, number, object, SchemaOf, string } from 'yup';

export type BudgetAllocationValues = Editable<FormValues<BudgetAllocation>>;

export const BudgetAllocationSchema: SchemaOf<BudgetAllocationValues> = object({
  projectOrGroup: object()
    .shape({
      id: number().required(),
      name: string().required(),
    })
    .required(),
  amountType: mixed<BudgetAllocationAmountType>()
    .oneOf(Object.values(BudgetAllocationAmountType), 'This field is required')
    .required(),
  amount: number()
    .required()
    .when(['amountType'], {
      is: BudgetAllocationAmountType.PERCENTAGE,
      then: number().when(
        'maximumAmount',
        (maximumAmount: number, schema: any) =>
          schema.max(
            maximumAmount,
            `This value cannot exceed the remaining budget allocation percentage (${maximumAmount}%)`
          )
      ),
      otherwise: number().when(
        'maximumAmount',
        (maximumAmount: number, schema: any) =>
          schema.max(
            maximumAmount,
            `This value cannot exceed the remaining budget allocation (${maximumAmount})`
          )
      ),
    }),
});
