import { useQuery } from 'react-query';
import { DisclaimerMessage } from '../../../../domain/types';
import { GET } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';

export const useMessageDisclaimer = () => {
  const apiPath = apiPaths.disclaimerMessageActive();

  const { data: message, isLoading } = useQuery<DisclaimerMessage>(
    apiPath.key.namespace(),
    () => GET(apiPath.base),
    {
      retry: false,
    }
  );

  return { message, isLoading };
};
