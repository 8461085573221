import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

export type CheckboxSelectorState = 'checked' | 'indeterminate' | 'default';

const getCheckboxIcon = (state: CheckboxSelectorState) => {
  switch (state) {
    case 'checked':
      return <CheckBoxIcon fontSize="small" color="primary" />;
    case 'indeterminate':
      return <IndeterminateCheckBoxIcon fontSize="small" color="primary" />;
    case 'default':
      return <CheckBoxOutlineBlankIcon fontSize="small" />;
    default:
      return <CheckBoxOutlineBlankIcon fontSize="small" />;
  }
};

interface ComponentProps {
  state: CheckboxSelectorState;
  onChange: (state: CheckboxSelectorState) => void;
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: 9,
}));

export const CheckboxSelector = ({ state, onChange }: ComponentProps) => {
  const CheckboxIcon = getCheckboxIcon(state);

  return (
    <StyledIconButton onClick={() => onChange(state)}>
      {CheckboxIcon}
    </StyledIconButton>
  );
};
