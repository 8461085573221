import { ChartTitle } from '../ChartTitle';
import { ConsumptionFilters } from '../../../domain/types';
import { useMemo } from 'react';

interface ConsumptionReportTitleProps {
  filters: ConsumptionFilters;
}

const toUpperCase = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const ConsumptionReportTitle = ({
  filters,
}: ConsumptionReportTitleProps) => {
  const title = useMemo(() => {
    const { timeInterval, breakdown: breakdownBy } = filters;
    const period = timeInterval === 'doy' ? 'Day' : 'Month';
    const breakdown = breakdownBy
      .split('_')
      .map((word: string) => toUpperCase(word))
      .join(' ');

    return `${breakdown} GCP Spend by ${period}`;
  }, [filters]);
  return <ChartTitle heading={title} />;
};
