import { useQuery } from 'react-query';
import { GET } from '../../../utils/api';
import { PagedItems, Service } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useAllServices = (customerId: number, serviceId: number) => {
  /**
   *  Usually, the service endpoint only returns third party services.
   *  Use the param 'include_all' to return the full list for mapping names and types
   */
  const serviceApi = apiPaths.services();
  const params = { include_all: true };
  const { data, isLoading, isError } = useQuery<PagedItems<Service>>(
    serviceApi.key.list(params),
    () => GET(serviceApi.base, params)
  );

  // split the returned data into:
  // - a list of service ids for the autocomplete options
  // - a map of id->name to display each option to the user
  const {
    services,
    serviceIds,
    serviceIdsToName,
    selectedServiceId,
  } = useMemo(() => {
    const services = data?.items ?? [];
    const serviceIds = services.map(({ id }) => id);
    const serviceIdsToName = services.reduce((map, service) => {
      return map.set(service.id, service.name);
    }, new Map<number, string>());

    const selectedServiceId =
      isNaN(serviceId) || !serviceIds.includes(serviceId) ? null : serviceId;

    return { services, serviceIds, serviceIdsToName, selectedServiceId };
  }, [data?.items, serviceId]);

  return {
    isLoading,
    isError,
    services,
    serviceIds,
    serviceIdsToName,
    selectedServiceId,
  };
};
