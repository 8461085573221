import {
  orange,
  yellow,
  green,
  blueGrey,
  deepPurple,
  purple,
  pink,
  amber,
  lightGreen,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  lime,
  grey,
} from '@mui/material/colors';
import { useMemo } from 'react';

const primaryColors = [
  orange[700],
  amber[500],
  yellow[500],
  lightGreen[500],
  green[200],
  pink[400],
  deepPurple[700],
  blueGrey[500],
];

const hues = [
  purple,
  blue,
  teal,
  cyan,
  green,
  blueGrey,
  indigo,
  lightBlue,
  orange,
  pink,
  amber,
  deepPurple,
  grey,
  lime,
  blue,
];
const shades = [700, 400, 300, 'A700'];

const colorSpectrum = shades
  .map((shade: any) => hues.map((hue: any) => hue[shade]))
  .flat();

export const useChartSeriesColors = () =>
  useMemo(() => Array.from(new Set([...primaryColors, ...colorSpectrum])), []);
