import { useStateContext } from '../../../contexts/StateProvider';
import { useParams } from 'react-router-dom';
import { useResetWhenCustomerChanges } from '../ProjectGroupProject/useResetWhenCustomerChanges';
import { BudgetAllocationsContainer } from './BudgetAllocationsContainer';
import { useListBudgets } from './useListBudgets';
import { QueryStatus } from '../../Messaging';
import { PageHeader } from '../../PageHeader';

export const BudgetAllocationAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const { budgetId } = useParams<{ budgetId: string }>();

  const {
    isLoading,
    isError,
    budgetIds,
    budgetIdsToName,
    selectedBudgetId,
  } = useListBudgets(selectedCustomerId, Number(budgetId) || null);

  useResetWhenCustomerChanges(selectedCustomerId);

  if (isLoading || isError) {
    return <QueryStatus isError={isError} isLoading={isLoading} />;
  }

  return (
    <>
      <PageHeader title="Allocations" />
      <BudgetAllocationsContainer
        budgetIds={budgetIds}
        selectedBudgetId={selectedBudgetId}
        getBudgetName={(id) => budgetIdsToName.get(id) ?? ''}
      />
    </>
  );
};
