import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { PageHeader } from '../../PageHeader';
import { RecommenderOnboardingForm } from './BQOnboardingForm';

export const BigQueryAnalyticsOnboarding = () => {
  return (
    <div>
      <PageHeader title="BigQuery Analytics Onboarding" />
      <Typography mb={2}>
        This page describes the steps required to enable the detailed BigQuery
        Analysis consumption reporting. This feature will use the BigQuery API
        to extract information about the queries that run in your organisation
        and present cost broken down by user, project and job id. Here’s what
        you’ll have to do to enable this:
      </Typography>
      <Stepper orientation="vertical">
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">
            Grant View Access to BigQuery Resources (jobs, reservations and
            commitments)
          </StepLabel>
          <StepContent>
            <Typography paragraph={true}>
              Grant the BigQuery Resource Viewer
              <b>(roles/bigquery/resourceViewer)</b> role to the Appsbroker
              Billing Platform service account{' '}
              <b>
                (sync-bqqm-sa@ab-billing-app-v2-prod.iam.gserviceaccount.com)
              </b>{' '}
              at the organisation level.
            </Typography>
          </StepContent>
        </Step>
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">
            Provide a GCP project id within the organisation
          </StepLabel>
          <StepContent>
            <Typography paragraph={true}>
              In order to actually carry out the Data Gathering, we will also
              need an ID of a project within the Organisation to use as a
              reference. This project does not need to contain the data - but it
              must be ‘owned’ by the Organisation being queried. Please make
              sure that this project is not removed for the duration of your use
              of this feature.
            </Typography>
            <RecommenderOnboardingForm />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};
