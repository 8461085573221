import { GridColDef } from '@mui/x-data-grid';
import { CrudContainer } from '../../Crud/CrudContainer';
import { CommitSkuGroup } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { SkuGroupFormValue, SkuGroupSchema } from './SkuGroupSchema';
import { SkuGroupForm } from './SkuGroupForm';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';
import { Button, Checkbox } from '@mui/material';
import { useHandleSkuGroupCommitUpdates } from '../Customer/hooks/useHandleSkuGroupCommitUpdates';
import { TailSpin } from 'react-loader-spinner';
import SaveIcon from '@mui/icons-material/Save';
import { appTheme } from '../../../theme/appTheme';

export const SkuGroups = () => {
  const {
    commitsToUpdate,
    setUpdatedCommits,
    saveCommitsToUpdate,
    isLoading,
  } = useHandleSkuGroupCommitUpdates();
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  const columns: GridColDef<CommitSkuGroup>[] = [
    {
      field: 'groupName',
      headerName: 'Group Name',
      flex: 1,
    },
    {
      field: 'gcpGroupId',
      headerName: 'Group ID',
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      flex: 1,
    },
  ];

  if (isCustomerSelected) {
    columns.push({
      headerName: 'Counts toward commit',
      field: 'commitIncluded',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Checkbox
            checked={
              commitsToUpdate.hasOwnProperty(row.id)
                ? commitsToUpdate[row.id]
                : row.commitIncluded
            }
            onChange={(event) => setUpdatedCommits(row, event.target.checked)}
          />
        );
      },
    });
  }

  return (
    <CrudContainer<CommitSkuGroup, SkuGroupFormValue>
      name="SKU Group"
      apiPath={
        isCustomerSelected
          ? apiPaths.customerSkuGroups(selectedCustomerId)
          : apiPaths.skuGroups()
      }
      columns={columns}
      defaultSortColumn="name"
      FormComponent={SkuGroupForm}
      validationSchema={SkuGroupSchema}
      enableUpdateOnRow={(row) => row.source === 'USER_ENTRY'}
      enableDeleteOnRow={(row) => row.source === 'USER_ENTRY'}
      CrudHeaderControls={
        <>
          {Object.keys(commitsToUpdate).length > 0 && (
            <Button
              data-testid="grid_create_btn"
              size="small"
              variant="text"
              color="primary"
              onClick={saveCommitsToUpdate}
              startIcon={
                isLoading ? (
                  <TailSpin
                    height={20}
                    width={20}
                    color={appTheme.palette.primary.main}
                  />
                ) : (
                  <SaveIcon />
                )
              }
            >
              Save Commit Updates
            </Button>
          )}
        </>
      }
    />
  );
};
