import EqualizerIcon from '@mui/icons-material/Equalizer';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import EventIcon from '@mui/icons-material/Event';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import DescriptionIcon from '@mui/icons-material/Description';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

// Admin routes icons
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BuildIcon from '@mui/icons-material/Build';
import MemoryIcon from '@mui/icons-material/Memory';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import QrCodeIcon from '@mui/icons-material/QrCode';

// Customer Admin icons
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import WarningIcon from '@mui/icons-material/Warning';

import { dashboardPaths } from './Routes/useDashboardRoutes';
import { adminPaths } from './Routes/AdminRoutes';
import { ReactElement } from 'react';

const iconsMap = new Map<string, ReactElement>([
  [dashboardPaths.consumption, <EqualizerIcon />],
  [dashboardPaths.budgetBurndown, <TrendingDownIcon />],
  [dashboardPaths.customersCommitTracker, <TrackChangesIcon />],
  [dashboardPaths.commitTracker, <EventIcon />],
  [dashboardPaths.purchaseOrder, <DescriptionIcon />],
  [dashboardPaths.recommender, <WbIncandescentIcon />],
  [dashboardPaths.bigQueryUsage, <QueryStatsIcon />],

  [adminPaths.users, <PeopleIcon />],
  [adminPaths.customers, <AccountBalanceIcon />],
  [adminPaths.unmappedBillingAccounts, <CreditCardIcon />],
  [adminPaths.services, <MemoryIcon />],
  [adminPaths.systemConfiguration, <BuildIcon />],
  [adminPaths.systemManagement, <DesktopWindowsIcon />],
  [adminPaths.skuGroups, <QrCodeIcon />],

  [adminPaths.recommenderOnboarding, <LiveHelpIcon />],
  [adminPaths.bigQueryAnalyticsOnboarding, <LiveHelpIcon />],
  [adminPaths.serviceApportionments, <CallSplitIcon />],
  [adminPaths.projects, <WorkIcon />],
  [adminPaths.billingAccounts, <CreditCardIcon />],
  [adminPaths.projectGroups, <GroupWorkIcon />],
  [adminPaths.budgets, <AccountBalanceWalletIcon />],
  [adminPaths.commits, <HourglassFullIcon />],
  [adminPaths.purchaseOrders, <DescriptionIcon />],
  [adminPaths.disclaimerManager, <WarningIcon />],
]);

interface ComponentProps {
  path: string;
}

export function NavIcon({ path }: ComponentProps) {
  return iconsMap.get(path) ?? <></>;
}
