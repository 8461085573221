import { useQuery } from 'react-query';
import {
  PurchaseOrderRemainingData,
  PurchaseOrderSummary,
} from '../../domain/types';
import { POST } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { formatBurndownData } from '../../utils/burndownUtils';
import { formatDate } from '../../utils/dateUtils';
import { CHART_MONTHLY_DATE_FORMAT } from '../../domain/constants';

interface Props {
  customerId: number;
}

const getRenewalDateFormated = (purchaseOrderSummary: PurchaseOrderSummary) => {
  if (purchaseOrderSummary.renewalDate) {
    return formatDate(
      purchaseOrderSummary.renewalDate,
      CHART_MONTHLY_DATE_FORMAT
    );
  }

  return '';
};

export const usePurchaseOrdersBurndownData = ({ customerId }: Props) => {
  const apiPath = apiPaths.purchaseOrdersRemainingData(customerId);
  // Temporary empty filters
  // Next iterations will implement them.
  const requestFilters = {};

  const { data, isLoading, isError } = useQuery<PurchaseOrderRemainingData>(
    apiPath.key.list(),
    () => POST<PurchaseOrderRemainingData, object>(apiPath.base, requestFilters)
  );

  if (!data) {
    return {
      data: undefined,
      isLoading,
      isError,
    };
  }

  return {
    data: {
      purchaseOrderSummary: {
        ...data.purchaseOrderSummary,
        renewalDateFormatted: getRenewalDateFormated(data.purchaseOrderSummary),
      },
      items: formatBurndownData(data, true),
      currency: data.currency,
      purchaseOrderRemaining: data.purchaseOrderRemaining,
    },
    isLoading,
    isError,
  };
};
