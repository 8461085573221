import { BillingAccount } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CrudHandler } from '../CrudHandler';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { mockBillingAccountData } from '../data/adminData';

export const billingAccountsHandler = new CustomerCrudHandler<BillingAccount>(
  apiPaths.billingAccount(':customerId' as any).base,
  {
    1: mockBillingAccountData,
  }
);

export const allBillingAccountsHandler = new CrudHandler<BillingAccount>(
  apiPaths.billingAccounts.base,
  mockBillingAccountData
);
