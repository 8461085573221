import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useHasRole } from '../hooks/useHasRole';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Role } from '../domain/types';
import { useDashboardRoutes } from './Routes/useDashboardRoutes';
import { nonCustomerRoutes, useAdminRoutes } from './Routes/AdminRoutes';
import { MenuLinks } from './MenuLinks';
import { useStateContext } from '../contexts/StateProvider';
import { VOID_ID } from '../domain/constants';
import clsx from 'clsx';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuthContext } from '../contexts/AuthProvider';
import { RouteCategory, useGetCurrentRoute } from '../hooks/useGetCurrentRoute';

interface LinksDrawerProps {
  open: boolean;
  onClose: () => void;
}

const DASHBOARD_INDEX = 0;
const ADMIN_INDEX = 1;
const CUSTOMER_ADMIN_INDEX = 2;

interface MenuLinksProps {
  onMenusSelect: (index: number) => void;
}

const NavigationSectionHeader = ({
  title,
  icon,
}: {
  title: string;
  icon: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div
        className={clsx('dashboard-header-icon', classes.dashboardHeaderIcon)}
      >
        {icon}
      </div>
      <Typography
        variant="h6"
        className={clsx('links-section-title', classes.navHeader)}
      >
        {title}
      </Typography>
    </div>
  );
};

const DashboardBackButton = ({
  onMenusSelect,
}: {
  onMenusSelect: (index: number) => void;
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      onClick={() => onMenusSelect(DASHBOARD_INDEX)}
      className={classes.submenuButton}
    >
      <ListItemIcon className={clsx(classes.listItemIcon, classes.backButton)}>
        <ArrowBackIcon />
      </ListItemIcon>
      <span className={classes.dashboardBackLabel}>Reports</span>
    </ListItem>
  );
};

const DashboardMenu = ({ onMenusSelect }: MenuLinksProps) => {
  const classes = useStyles();
  const dashboardRoutes = useDashboardRoutes();
  const isAdmin = useHasRole(Role.CUSTOMER_ADMIN, Role.SYSTEM_ADMIN);
  const { userRole } = useAuthContext();
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  return (
    <>
      <div>
        <NavigationSectionHeader title="Reports" icon={<AssessmentIcon />} />
        <MenuLinks routes={dashboardRoutes} />
      </div>
      <div>
        <Divider className={classes.menuDivider} />
        {userRole === Role.SYSTEM_ADMIN && (
          <ListItem
            button
            onClick={() => onMenusSelect(ADMIN_INDEX)}
            data-testid="admin-section-btn"
            className={classes.submenuButton}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsSystemDaydreamIcon />
            </ListItemIcon>
            System Admin
          </ListItem>
        )}
        {isAdmin && isCustomerSelected && (
          <ListItem
            onClick={() => onMenusSelect(CUSTOMER_ADMIN_INDEX)}
            button
            data-testid="customer-admin-section-btn"
            className={classes.submenuButton}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            Admin
          </ListItem>
        )}
      </div>
    </>
  );
};

const AdminMenu = ({ onMenusSelect }: MenuLinksProps) => {
  return (
    <div>
      <NavigationSectionHeader
        title="System Admin"
        icon={<SettingsSystemDaydreamIcon />}
      />
      <DashboardBackButton onMenusSelect={onMenusSelect} />
      <MenuLinks routes={nonCustomerRoutes} />
    </div>
  );
};

const CustomerAdminMenu = ({ onMenusSelect }: MenuLinksProps) => {
  const adminRoutes = useAdminRoutes();

  return (
    <div>
      <NavigationSectionHeader title="Admin" icon={<SettingsIcon />} />
      <DashboardBackButton onMenusSelect={onMenusSelect} />
      <MenuLinks routes={adminRoutes} />
    </div>
  );
};

const linksIndexMapping = new Map<RouteCategory, number>([
  ['dashboard', DASHBOARD_INDEX],
  ['customer', CUSTOMER_ADMIN_INDEX],
  ['non-customer', ADMIN_INDEX],
]);

const LinksDrawer = ({ open, onClose }: LinksDrawerProps) => {
  const classes = useStyles();
  const [selectedMenus, setSelectedMenus] = useState(DASHBOARD_INDEX);
  const isAdmin = useHasRole(Role.CUSTOMER_ADMIN, Role.SYSTEM_ADMIN);
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;
  const routeType = useGetCurrentRoute();

  useEffect(() => {
    if (!isAdmin || !isCustomerSelected) {
      setSelectedMenus(DASHBOARD_INDEX);
    } else {
      setSelectedMenus(linksIndexMapping.get(routeType) ?? DASHBOARD_INDEX);
    }
  }, [isAdmin, isCustomerSelected, routeType]);

  const SelectedMenuLinks = useMemo(() => {
    if (selectedMenus === DASHBOARD_INDEX) {
      return (
        <DashboardMenu
          onMenusSelect={(selectedMenu) => setSelectedMenus(selectedMenu)}
        />
      );
    } else if (selectedMenus === ADMIN_INDEX) {
      return (
        <AdminMenu
          onMenusSelect={(selectedMenu) => setSelectedMenus(selectedMenu)}
        />
      );
    } else if (selectedMenus === CUSTOMER_ADMIN_INDEX) {
      return (
        <CustomerAdminMenu
          onMenusSelect={(selectedMenu) => setSelectedMenus(selectedMenu)}
        />
      );
    }
  }, [selectedMenus]);

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClosed]: !open,
      })}
      variant="permanent"
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: clsx(classes.drawerPaper, classes.paperHover, {
          [classes.drawerOpen]: open,
          [classes.drawerClosed]: !open,
        }),
      }}
    >
      <div className={classes.drawerHeader}></div>
      <List className={classes.linksList}>{SelectedMenuLinks}</List>
    </Drawer>
  );
};

export const LINKS_DRAWER_WIDTH = 280;
export const LINKS_DRAWER_COLLAPSED_WIDTH = 70;

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: LINKS_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  dashboardHeaderIcon: {
    paddingLeft: theme.spacing(3),
    color: theme.palette.grey[600],
  },
  drawerOpen: {
    width: LINKS_DRAWER_WIDTH,
    '& .dashboard-header-icon': {
      display: 'none',
    },
  },
  headerContainer: {
    minHeight: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  drawerClosed: {
    overflowX: 'hidden',
    width: LINKS_DRAWER_COLLAPSED_WIDTH,
    '& .links-section-title': {
      display: 'none',
    },
    '& .dashboard-header-icon': {
      display: 'block',
    },
    '& .indented-menu-link': {
      marginLeft: 0,
    },
  },
  paperHover: {
    '&:hover': {
      width: LINKS_DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .links-section-title': {
        display: 'block',
      },
      '& .dashboard-header-icon': {
        display: 'none',
      },
      '& .indented-menu-link': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  drawerPaper: {
    overflow: 'hidden',
  },
  arrowIcon: {},
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  navHeader: {
    marginLeft: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize + 2,
    color: theme.palette.grey[800],
  },
  listItemIcon: {
    minWidth: 16,
    marginRight: theme.spacing(3),
  },
  linksList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  menuDivider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submenuButton: {
    minHeight: 48,
    paddingLeft: theme.spacing(3),
  },
  backButton: {
    background: theme.palette.grey[100],
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    marginRight: 18,
    marginLeft: -4,
  },
  dashboardBackLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export default LinksDrawer;
