import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { Customer, Role, User } from '../../../domain/types';
import { UserForm } from './UserForm';
import { createUserSchema, UserFormValues } from './UserSchema';

import { GridColDef } from '@mui/x-data-grid';
import { useHasRole } from '../../../hooks/useHasRole';
import { useStateContext } from '../../../contexts/StateProvider';
import { QueryLoader } from '../../Messaging/QueryStatus';

const createColumns = (isSysAdmin: boolean): GridColDef[] => {
  const emailColumn: GridColDef = {
    headerName: 'Email',
    field: 'email',
    flex: 3,
  };
  const customerColumn: GridColDef = {
    headerName: 'Customer',
    field: 'customers',
    flex: 3,
    valueGetter: (params) => {
      return (params.value as Customer[])
        .map((customer) => customer.name)
        .join(', ');
    },
  };
  const roleColumn: GridColDef = {
    headerName: 'Role',
    field: 'role',
    flex: 2,
  };
  if (isSysAdmin) {
    return [emailColumn, customerColumn, roleColumn];
  } else {
    return [emailColumn, roleColumn];
  }
};

export const UserAdmin = () => {
  const isSysAdmin = useHasRole(Role.SYSTEM_ADMIN);

  const { selectedCustomerId } = useStateContext();

  // if you reload this page it renders very briefly with a selected customer id of 0
  // for customer admins
  // this check prevents it from carrying on until the actual customer id is set in the context
  if (!isSysAdmin && !selectedCustomerId) {
    return <QueryLoader />;
  }

  const apiPath = isSysAdmin
    ? apiPaths.users
    : apiPaths.customerUsers(selectedCustomerId);

  return (
    <CrudContainer<User, UserFormValues>
      name="User"
      apiPath={apiPath}
      columns={createColumns(isSysAdmin)}
      defaultSortColumn="email"
      FormComponent={UserForm}
      validationSchema={createUserSchema(isSysAdmin)}
    />
  );
};
