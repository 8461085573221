import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { apiPaths } from '../../utils/apiPaths';
import { generateConsumptionLabelsData } from '../data/reportData';

class CustomerLabelsHandler implements ServerHandler {
  public resetData() {}

  public handlers() {
    return [
      rest.get(
        `/api${apiPaths.customerLabels(':customerId' as any).base}`,
        (_req, res, ctx) => {
          const payload = generateConsumptionLabelsData(20);
          return res(ctx.status(200), ctx.json(payload));
        }
      ),
    ];
  }
}

export const customerLabelsHandler = new CustomerLabelsHandler();
