/* eslint-disable no-template-curly-in-string*/
import { setLocale } from 'yup';

export const FORM_ERRORS = {
  mixed: {
    required: 'This field is required',
    // generally typeError is due to a field needing a default value of `null` so should be
    // treated like a required error in most cases
    notType: 'This field is required',
  },
  string: {
    email: 'This field must be a valid email address',
    length: 'This field must be exactly ${length} characters long',
  },
  array: {
    min: 'This field must contain at least ${min} item(s)',
  },
  number: {
    moreThan: 'This field must have a value greater than ${more}',
  },
};

setLocale(FORM_ERRORS);
