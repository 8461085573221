import { AppBar, Container, IconButton, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AuthStatus from './AuthStatus';
import CustomersModal from './Modals/CustomersModal';
import SnackbarNotification from './Messaging/Snackbar';
import { useStateContext } from '../contexts/StateProvider';
import { useStyles } from './AuthenticatedLayout.styles';
import { GET } from '../utils/api';
import MenuIcon from '@mui/icons-material/Menu';
import { Customer } from '../domain/types';
import { VOID_ID } from '../domain/constants';
import { useQuery, useQueryClient } from 'react-query';
import { ApiNamespace, apiPaths } from '../utils/apiPaths';
import { useAuthContext } from '../contexts/AuthProvider';
import { SelectedCustomer } from './SelectedCustomer';
import LinksDrawer from './LinksDrawer';
import { useSyncCustomerIdSearchParam } from '../hooks/useSyncCustomerIdSearchParam';

interface AuthenticatedLayoutProps {
  children: NonNullable<ReactNode>;
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const classes = useStyles();

  const {
    selectedCustomerId,
    setSelectedCustomerId,
    setSelectedCustomerName,
    notification,
    closeNotification,
  } = useStateContext();
  const queryClient = useQueryClient();
  useSyncCustomerIdSearchParam();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const { linkedCustomer, userRole } = useAuthContext();

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const {
    data: selectedCustomer,
    isLoading: isLoadingSelectedCustomer,
  } = useQuery<Customer>(
    apiPaths.customer.key.item(selectedCustomerId),
    () => GET(apiPaths.customer.item(selectedCustomerId)),
    {
      retry: false,
      enabled: selectedCustomerId !== VOID_ID,
      onError: () => {
        setSelectedCustomerId(VOID_ID);
      },
    }
  );

  // when the selected customer changes invalidate all associated data for them
  useEffect(() => {
    queryClient.invalidateQueries([ApiNamespace.CUSTOMER, selectedCustomerId]);
  }, [queryClient, selectedCustomerId]);

  useEffect(() => {
    if (linkedCustomer) {
      setSelectedCustomerId(linkedCustomer.id);
    }
  }, [setSelectedCustomerId, linkedCustomer]);

  useEffect(() => {
    if (selectedCustomerId === VOID_ID || selectedCustomer?.name) {
      gtag('event', 'set_user_info', {
        customer_name_value: selectedCustomer?.name ?? 'unselected',
        user_role_value: userRole,
      });
    }
  }, [selectedCustomer?.name, userRole, selectedCustomerId]);

  useEffect(() => {
    setSelectedCustomerName(selectedCustomer?.name);
  }, [selectedCustomer?.name, setSelectedCustomerName]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            data-testid="open-nav-menu_btn"
            color="inherit"
            aria-label="Open Menu"
            onClick={() => setDrawerOpen((previousValue) => !previousValue)}
            edge="start"
            className={clsx(classes.menuButton)}
            size="large"
          >
            <MenuIcon />
          </IconButton>

          <SelectedCustomer
            isLoading={isLoadingSelectedCustomer}
            selectedCustomer={selectedCustomer}
            showSelector={!linkedCustomer}
            onChooseCustomer={() => setCustomersDialogOpen(true)}
          />
          <AuthStatus />
        </Toolbar>
      </AppBar>

      <LinksDrawer open={drawerOpen} onClose={handleDrawerClose} />

      <CustomersModal
        open={customersDialogOpen}
        onClose={() => {
          setCustomersDialogOpen(false);
        }}
      />

      <SnackbarNotification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={() => closeNotification()}
      />

      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <Container maxWidth={false} className={classes.contentContainer}>
          {children}
        </Container>
      </main>
    </div>
  );
};

export default AuthenticatedLayout;
