import {
  Alert,
  Button,
  FormControl,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useStateContext } from '../../../contexts/StateProvider';
import { RecommenderOnboardingModel } from '../../../domain/types';
import { GET, POST, PUT } from '../../../utils/api';
import { apiPaths } from '../../../utils/apiPaths';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { PageHeader } from '../../PageHeader';

function useRecommenderOnboardingData() {
  const queryClient = useQueryClient();
  const { selectedCustomerId, showNotification } = useStateContext();
  const apiPath = apiPaths.recommenderOnboarding(selectedCustomerId);

  const { data, isLoading } = useQuery<RecommenderOnboardingModel>(
    apiPath.key.item(selectedCustomerId),
    () => GET(apiPath.base)
  );

  const { mutate: create, isLoading: createIsLoading } = useMutation(
    (data: any) => POST(apiPath.base, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.item(selectedCustomerId));
        showNotification({
          message: 'Item created successfully',
          severity: 'success',
        });
      },
    }
  );

  const { mutate: edit, isLoading: editIsLoading } = useMutation(
    (data: any) => PUT(apiPath.base, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.item(selectedCustomerId));
        showNotification({
          message: 'Item updated successfully',
          severity: 'success',
        });
      },
    }
  );

  return useMemo(
    () => ({
      data: data ?? { bqTableId: '' },
      isLoading: isLoading || createIsLoading || editIsLoading,
      mutate: data?.id ? edit : create,
    }),
    [data, isLoading, createIsLoading, editIsLoading, create, edit]
  );
}

interface FormProps {
  value: Partial<RecommenderOnboardingModel>;
  onSubmit: (values: Partial<RecommenderOnboardingModel>) => void;
}

const RecommenderOnboardingForm = ({ value, onSubmit }: FormProps) => {
  const classes = useStyles();
  const [bqTableId, setBqTableId] = useState(value.bqTableId);

  const handleOnFinishClick = () => {
    if (bqTableId?.trim()) {
      onSubmit({
        bqTableId,
      });
    }
  };

  const handleOnInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setBqTableId(value);
  };

  useEffect(() => {
    setBqTableId(value.bqTableId);
  }, [value?.bqTableId]);

  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        className={classes.onboardingFromInputs}
      >
        <div className={classes.onboardingInputContainer}>
          <span>BigQuery Table ID:</span>
          <FormControl className={classes.onboardingInput}>
            <TextField
              name="bqTableId"
              placeholder="Type BigQuery table id..."
              data-test-id="bqTableId-input"
              value={bqTableId}
              onChange={handleOnInputChange}
            />
          </FormControl>
        </div>
        <Grid container className={classes.onboardingFromSubmit}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnFinishClick}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RecommenderOnboarding = () => {
  const { data, mutate, isLoading } = useRecommenderOnboardingData();
  const classes = useStyles();

  return (
    <div>
      <PageHeader title="Recommender Onboarding" />
      <Stepper orientation="vertical">
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">Before you start</StepLabel>
          <StepContent>
            <Typography paragraph={true}>
              This application requires access to an export of Google
              Recommender data in order to surface those recommendations here.
              This page will explain how you can configure your Google
              environment and provide us with access to recommendation data. We
              will use this and any other data Appsbroker has access to for the
              sole purpose of helping you run an efficient, secure and
              best-practice based Google environment. Once ingested, these
              recommendations will be made available to users with the “Customer
              Admin” role in your organisation on this app.
            </Typography>
            <Typography>
              <b>IMPORTANT:</b> Currently, Google Recommender data can only be
              exported to a US dataset, so please make sure that this complies
              with the data residency policies in your organisation. By
              completing the steps below you also agree to provide Appsbroker
              with access to this recommendation data.
            </Typography>
          </StepContent>
        </Step>
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">Enable BigQuery Export</StepLabel>
          <StepContent>
            <Grid container justifyContent="flex-start">
              <Typography paragraph={true}>
                Please follow the instructions on the provided link to create a
                data transfer for Google recommendations to BigQuery:{' '}
              </Typography>
              <Link
                target="_blank"
                href="https://cloud.google.com/recommender/docs/bq-export/export-recommendations-to-bq"
              >
                <span className={classes.linkContent}>
                  <LinkIcon />
                  link
                </span>
              </Link>
            </Grid>
            <Typography paragraph={true}>
              When configuring, please select the following under{' '}
              <b>“Schedule Options”:</b>
            </Typography>
            <ul>
              <li>
                Repeats: <b>Daily</b>{' '}
              </li>
              <li>
                Start date and run time: <b>Tomorrow 06:00 UTC</b>
              </li>
            </ul>
            <Typography>
              <b>Please note:</b> Data will take a couple of days to appear in
              the table. Customers with a Google support package can organise
              recommendations data across projects by scheduling one-time or
              recurring Recommender API Data Transfers to BigQuery.
            </Typography>
          </StepContent>
        </Step>
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">
            Grant Appsbroker Access to the BigQuery Table
          </StepLabel>
          <StepContent>
            <Typography>
              Grant read access to the BigQuery table containing recommendations
              to the following Appsbroker service account:{' '}
              <b>
                sync-billing-data-sa@ab-billing-app-v2-prod.iam.gserviceaccount.com
              </b>{' '}
              with the role: <b>roles/bigquery.dataViewer.</b>
            </Typography>
          </StepContent>
        </Step>
        <Step expanded={true} active={true}>
          <StepLabel data-testid="step_label">
            Provide the BigQuery Table ID
          </StepLabel>
          <StepContent>
            <Typography>
              Provide the BigQuery Table ID in this field.
            </Typography>
            {isLoading ? (
              <QueryLoader />
            ) : (
              <RecommenderOnboardingForm
                value={data}
                onSubmit={(values) => mutate(values)}
              />
            )}

            <Alert severity="info" className={classes.alert}>
              It will take a couple of days for the recommendations to appear in
              the app, once this configuration is done.
            </Alert>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  onboardingFromInputs: {
    marginTop: theme.spacing(2),
    width: 700,
  },
  onboardingFromSubmit: {
    marginTop: theme.spacing(2),
  },
  onboardingDisclaimer: {
    padding: theme.spacing(2),
  },
  onboardingDisclaimerContainer: {
    padding: theme.spacing(1),
  },
  onboardingInputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  onboardingInput: {
    flex: 1,
    paddingLeft: theme.spacing(1),
  },
  linkContent: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));
