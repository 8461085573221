import { rest } from 'msw';
import { apiPaths } from '../../utils/apiPaths';
import { ServerHandler } from '../ServerHandler';

class CustomerSkuHandler implements ServerHandler {
  resetData() {}

  handlers() {
    return [
      rest.get(
        `/api${apiPaths.consumptionSKUs(':customerId' as any).base}`,
        (req, res, ctx) => {
          return res(ctx.json([]));
        }
      ),
    ];
  }
}

export const customerSkuHandler = new CustomerSkuHandler();
