import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { CustomerSDM } from '../../../../domain/types';

export const useSDMSOptions = (enabled: boolean) => {
  const { data, isLoading } = useQuery<CustomerSDM[]>(
    apiPaths.sdms.key.list(),
    () => GET(apiPaths.sdms.base),
    {
      enabled,
    }
  );

  return {
    items: data ?? [],
    isLoading,
  };
};
