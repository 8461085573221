import Modal from '../../Modals/Modal';
import { Button, Typography } from '@mui/material';
import { useCrudDelete } from './useCrudDelete';
import { ApiPath } from '../../../utils/apiPaths';
import { useParams } from 'react-router-dom';
import { CustomCrudAction } from '../CrudContainer';

export interface CrudDeleteModalProps {
  apiPath: ApiPath;
  onClose: () => void;
  customDelete: CustomCrudAction;
}

export const CrudDeleteModal = ({
  apiPath,
  onClose,
  customDelete,
}: CrudDeleteModalProps) => {
  const { id } = useParams<{ id: string | undefined }>();
  const entityId = Number(id);
  const { isMutating, confirmDelete } = useCrudDelete({
    apiPath,
    onSuccess: onClose,
    customDelete,
  });

  const action = customDelete?.action || 'delete';
  const gerund = customDelete?.gerund || 'Deleting';
  const confirm = `Confirm ${action.charAt(0).toUpperCase() + action.slice(1)}`;

  return (
    <Modal
      open
      title={confirm}
      id="crud-delete-modal"
      onClose={onClose}
      content={
        <Typography>
          Are you sure you want to {action} the selected item?
        </Typography>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={isMutating}
            onClick={() => confirmDelete(entityId)}
          >
            {isMutating ? `${gerund}...` : 'Yes'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    />
  );
};
