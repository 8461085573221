import { HashRouter as Router, Route } from 'react-router-dom';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { RoleRestrictedRoute } from './RoleRestrictedRoute';
import { adminPaths, AdminRoutes } from './AdminRoutes';
import { DashboardRoutes } from './DashboardRoutes';
import { dashboardPaths } from './useDashboardRoutes';
import { LoginRoute } from './LoginRoute';
import { createRoutePaths } from '../../utils/createRoutePaths';
import { Role } from '../../domain/types';
import { SwitchRoute } from './SwitchRoute';
import AuthenticatedLayout from '../AuthenticatedLayout';
import { useAnalyticsNavigation } from '../../hooks/useAnalyticsNavigation';

export const appPaths = createRoutePaths(
  {
    login: '/',
    authenticated: '/app',
  },
  ''
);

const ApplicationRoutes = () => {
  useAnalyticsNavigation();

  return (
    <Router>
      <SwitchRoute>
        <Route path={appPaths.login} exact component={LoginRoute} />
        <AuthenticatedRoute path={appPaths.authenticated}>
          <AuthenticatedLayout>
            <SwitchRoute>
              <Route
                path={dashboardPaths.basePath}
                component={DashboardRoutes}
              />
              <RoleRestrictedRoute
                path={adminPaths.basePath}
                component={AdminRoutes}
                requiredRole={[Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]}
              />
            </SwitchRoute>
          </AuthenticatedLayout>
        </AuthenticatedRoute>
      </SwitchRoute>
    </Router>
  );
};

export default ApplicationRoutes;
