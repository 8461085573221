import { BillingAccount } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { mockBillingAccountData } from '../data/adminData';

export const allocateBillingAccountsHandler = new CustomerCrudHandler<BillingAccount>(
  apiPaths.allocateBillingAccount(':customerId' as any).base,
  {
    1: mockBillingAccountData,
  }
);
