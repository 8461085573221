import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PagedItems,
  Project,
  ProjectGroup,
  ProjectGroupProject,
} from '../domain/types';

export type SelectionState = 'selected' | 'none' | 'some';

export type ProjectTreeItem = {
  id: number;
  selected: boolean;
  gcpProjectId: string;
  gcpProjectName: string;
  visible: boolean;
};

export type ProjectGroupTreeItem = {
  id: number;
  loading: boolean;
  loaded: boolean;
  selectionState: SelectionState;
  expanded: boolean;
  visible: boolean;
  name: string;
  projects: ProjectsMap;
};

export type ProjectsMap = Record<number, ProjectTreeItem>;
export type ProjectGroupsMap = Record<number, ProjectGroupTreeItem>;

export type ProjectGroupsState = {
  searchTerm: string;
  selectionState: SelectionState;
  loading: boolean;
  loaded: boolean;
  projectGroups: ProjectGroupsMap;
  queryFilters: {
    groups: number[];
    projects: number[];
  };
};

export type ProjectGroupsInitialState = {
  groups: number[];
  projects: number[];
};

/*
 * Utility functions
 */

/**
 * Returns a group by groupId from state
 */
const getGroup = (
  state: ProjectGroupsState,
  groupId: number
): ProjectGroupTreeItem => state.projectGroups[groupId];

/**
 * Returns a project by groupId and projectId from state
 */
const getProject = (
  state: ProjectGroupsState,
  groupId: number,
  projectId: number
) => getGroup(state, groupId).projects[projectId];

/**
 * Returns all groups
 */
const getGroups = (state: ProjectGroupsState): ProjectGroupTreeItem[] =>
  Object.values(state.projectGroups);

/**
 * Returns all projects for a specific group id
 */
const getProjectsByGroupId = (
  state: ProjectGroupsState,
  groupId: number
): ProjectTreeItem[] => Object.values(getGroup(state, groupId).projects);

const getProjectsByGroup = (group: ProjectGroupTreeItem): ProjectTreeItem[] =>
  Object.values(group.projects);

const getProjectGroupSelectionState = (
  state: ProjectGroupsState,
  groupId: number
): SelectionState => {
  const projects = getProjectsByGroupId(state, groupId);

  return projects.every(({ selected }) => selected === projects[0].selected)
    ? projects[0].selected
      ? 'selected'
      : 'none'
    : 'some';
};

/**
 * state functions:
 */

/**
 * Function to determine the state of the root of the selection.
 * Called when the user actions any selection checkboxes or when typing search criteria.
 */
const getRootSelectionState = (state: ProjectGroupsState): SelectionState => {
  const groups = getGroups(state);
  const selectedGroups = groups.filter(
    (group) => group.selectionState !== 'none'
  );

  const visibleGroups = groups.filter((group) => group.visible);
  const selectedVisibleGroups = visibleGroups.filter(
    (group) => group.selectionState !== 'none'
  );
  const partiallySelected = selectedGroups.filter(
    (group) => group.selectionState === 'some'
  );

  if (partiallySelected.length > 0) {
    /**
     * When a search term exists, the other groups are hidden when the criteria is not met.
     * If these are hidden and already selected, check if the selected match visible.
     * This will ensure the toggle all can still be used without getting stuck in endless loop.
     */
    if (state.searchTerm) {
      if (selectedVisibleGroups.length === visibleGroups.length) {
        return 'some';
      }
      return 'none';
    }
    return 'some';
  } else if (selectedGroups.length === groups.length) {
    /**
     * 'selected' state should only ever be applied when every possible group is
     *  selected and no partial selections exist. In the filter context, if the state
     *  is selected, then 'undefined' is passed to the api so 'all' groups are filtered.
     *  This is so the UI doesnt need to pass thousands of groups if everything is selected.
     */
    return 'selected';
  } else if (selectedVisibleGroups.length > 0) {
    return 'some';
  } else {
    return 'none';
  }
};

const updateFilters = (state: ProjectGroupsState) => {
  let { projectGroupIds, projectIds } = getGroups(state).reduce(
    (acc, { id, selectionState }) => {
      if (selectionState === 'selected' && id === -1) {
        const selectedProjectIds = getProjectsByGroupId(state, id).map(
          (p) => p.id
        );

        acc = {
          projectIds: [...acc.projectIds, ...selectedProjectIds],
          projectGroupIds: [...acc.projectGroupIds],
        };
      } else if (selectionState === 'selected') {
        acc = {
          projectIds: acc.projectIds,
          projectGroupIds: [...acc.projectGroupIds, id],
        };
      } else {
        const selectedProjectIds = getProjectsByGroupId(state, id)
          .filter((p) => p.selected)
          .map((p) => p.id);

        acc = {
          projectIds: [...acc.projectIds, ...selectedProjectIds],
          projectGroupIds: acc.projectGroupIds,
        };
      }
      return acc;
    },
    {
      projectGroupIds: [] as number[],
      projectIds: [] as number[],
    }
  );

  state.queryFilters.groups = projectGroupIds;
  state.queryFilters.projects = projectIds;
};

/**
 *  The initial state of the project groups. Default are set to
 *  empty with unticked option. Will behave the same way as if all
 *  are checked.
 */
export const createInitialState = (
  defaultState?: ProjectGroupsInitialState
): ProjectGroupsState => ({
  searchTerm: '',
  selectionState: 'none',
  loading: false,
  loaded: false,
  projectGroups: {},
  queryFilters: {
    groups: defaultState?.groups || [],
    projects: defaultState?.projects || [],
  },
});

const doesNameMatch = (name: string, toCompare: string) =>
  name.toLocaleLowerCase().includes(toCompare);

const updateGroupSelectionState = (
  group: ProjectGroupTreeItem,
  selected: boolean
) => {
  if (group.visible) {
    const projects = getProjectsByGroup(group);

    if (projects.length === 0) {
      // if the group has no projects then just toggle it's selection state directly
      // as there aren't any projects to care about whether they are all/partially selected
      group.selectionState = selected ? 'selected' : 'none';
    } else {
      const areVisibleProjectsSelected = projects.every((project) => {
        if (project.visible) {
          return project.selected === true;
        } else {
          return project.selected === false;
        }
      });

      const allProjectsAreSelected = projects.every(
        (project) => project.selected
      );

      if (allProjectsAreSelected) {
        projects.forEach((project) => {
          project.selected = false;
        });
      } else if (areVisibleProjectsSelected) {
        projects.forEach((project) => {
          project.selected = true;
        });
      } else {
        projects
          .filter((project) => project.visible)
          .forEach((project) => {
            project.selected = true;
          });
      }

      const allProjectsSelected =
        projects.length > 0 && projects.every(({ selected }) => selected);
      const someProjectsSelected = projects.some(({ selected }) => selected);
      group.selectionState = allProjectsSelected
        ? 'selected'
        : someProjectsSelected
        ? 'some'
        : 'none';
    }
  }
};

const createProjectsMap = (
  projects: ProjectGroupProject[],
  selected: boolean,
  selectedProjects?: number[]
): ProjectsMap => {
  const projectMap: ProjectsMap = {};
  projects.forEach((project: ProjectGroupProject) => {
    projectMap[project.id] = {
      id: project.id,
      gcpProjectId: project.gcpProjectId,
      gcpProjectName: project.gcpProjectName ?? '',
      visible: true,
      selected:
        (selectedProjects && selectedProjects.indexOf(project.id) !== -1) ||
        selected,
    };
  });
  return projectMap;
};

const clearSelections = (state: ProjectGroupsState, resetUi = false) => {
  state.selectionState = 'none';
  getGroups(state).forEach((group) => {
    group.selectionState = 'none';
    if (resetUi) {
      group.expanded = false;
      group.visible = true;
    }
    getProjectsByGroupId(state, group.id).forEach((project) => {
      project.selected = false;
      if (resetUi) {
        project.visible = true;
      }
    });
  });
};

export const createProjectGroupsSlice = (initialState: ProjectGroupsState) => {
  return createSlice({
    name: 'projectGroups',
    initialState,
    reducers: {
      /*
       * Fetching groups
       */

      getProjectGroups: () => {
        // getting project groups resets all values back to their defaults
        // but sets loading to true
        return {
          ...initialState,
          loading: true,
        };
      },
      getProjectGroupsSuccess: (
        state,
        {
          payload,
        }: PayloadAction<{
          projectGroups: PagedItems<ProjectGroup>;
          queryFilters: any;
        }>
      ) => {
        payload.projectGroups.items.forEach(({ id, name, projects }) => {
          const groupProjectsNotIncluded = !projects;
          const groupLoadedWithProjects =
            Array.isArray(projects) && projects.length > 0;
          // ignore groups with no projects
          if (groupProjectsNotIncluded || groupLoadedWithProjects) {
            let selectionState: SelectionState = 'none';
            const containAllProjects = projects?.every(
              (proj) => payload.queryFilters.projects.indexOf(proj.id) !== -1
            );

            if (containAllProjects && id === -1) {
              selectionState = 'selected';
            } else if (payload.queryFilters.groups.indexOf(id) !== -1) {
              selectionState = 'selected';
            } else {
              const someProjectsSelected = projects?.some(
                (proj) => payload.queryFilters.projects.indexOf(proj.id) !== -1
              );

              if (someProjectsSelected) {
                selectionState = 'some';
              }
            }

            state.projectGroups[id] = {
              id: id,
              name: name,
              loading: false,
              loaded: !!projects,
              selectionState,
              visible: true,
              expanded: false,
              projects: createProjectsMap(
                projects ?? [],
                selectionState === 'selected',
                payload.queryFilters.projects
              ),
            };
          }
        });
        state.loading = false;
        state.loaded = true;
        updateFilters(state);
      },

      /*
       * Fetching projects
       */

      getProjects: (state, { payload: groupId }: PayloadAction<number>) => {
        getGroup(state, groupId).loading = true;
      },
      getProjectsSuccess: (
        state,
        {
          payload: { groupId, projects },
        }: PayloadAction<{
          groupId: number;
          projects: PagedItems<Project>;
        }>
      ) => {
        const group = getGroup(state, groupId);
        group.projects = createProjectsMap(
          projects.items,
          group.selectionState === 'selected'
        );
        group.loading = false;
        group.loaded = true;
      },

      /*
       * Filtering
       */

      setSearchTerm: (
        state,
        { payload: searchTerm }: PayloadAction<string>
      ) => {
        state.searchTerm = searchTerm.toLocaleLowerCase();
      },
      filterBySearchTerm: (
        state,
        { payload: searchTerm }: PayloadAction<string | undefined>
      ) => {
        if (typeof searchTerm !== 'undefined') {
          state.searchTerm = searchTerm.toLocaleLowerCase();
        }

        getGroups(state).forEach((group) => {
          group.visible = doesNameMatch(group.name, state.searchTerm);
          let areAnyProjectsVisible = false;
          getProjectsByGroupId(state, group.id).forEach((project) => {
            if (doesNameMatch(project.gcpProjectName, state.searchTerm)) {
              group.visible = true; // show the group even if it's own name didn't match
              project.visible = true;
              areAnyProjectsVisible = true;
            } else {
              project.visible = false;
            }
          });
          // expand the group if any of it's projects are visible otherwise collapse it
          // this will automatically collapse all groups when the search term is cleared
          // rather than leaving them all expanded based on the last search term
          group.expanded = state.searchTerm !== '' && areAnyProjectsVisible;
        });
        state.selectionState = getRootSelectionState(state);
      },

      /*
       * Toggling selection states (multi-select mode)
       */

      toggleAll: (state) => {
        // if nothing is currently selected then select it all
        // otherwise de-select it all
        const selectAll = state.selectionState === 'none';

        getGroups(state).forEach((group) => {
          updateGroupSelectionState(group, selectAll);
        });

        state.selectionState = getRootSelectionState(state);
        updateFilters(state);
      },
      toggleGroup: (state, { payload: groupId }: PayloadAction<number>) => {
        const group = getGroup(state, groupId);

        // if selection state is none then select all, otherwise de-select all
        updateGroupSelectionState(group, group.selectionState === 'none');

        state.selectionState = getRootSelectionState(state);
        updateFilters(state);
      },
      toggleProject: (
        state,
        {
          payload: { groupId, projectId },
        }: PayloadAction<{ groupId: number; projectId: number }>
      ) => {
        // update project selection
        const project = getProject(state, groupId, projectId);
        project.selected = !project.selected;

        // update parent group selection
        getGroup(state, groupId).selectionState = getProjectGroupSelectionState(
          state,
          groupId
        );

        // update root selection
        state.selectionState = getRootSelectionState(state);

        // update filters
        updateFilters(state);
      },

      /*
       * Selecting selection states (single select mode)
       */

      selectGroup: (state, { payload: groupId }: PayloadAction<number>) => {
        // clear all prior selections
        clearSelections(state);

        /**
         * update selection - set to some as if 'selected', the context
         * will think all options are selected, therefore will send 'undefined'
         * instead of the singular group
         **/
        state.selectionState = 'some';
        getGroup(state, groupId).selectionState = 'selected';

        // update filters
        state.queryFilters.groups = [groupId];
        state.queryFilters.projects = [];
      },
      selectProject: (
        state,
        {
          payload: { groupId, projectId },
        }: PayloadAction<{ groupId: number; projectId: number }>
      ) => {
        // clear all prior selections
        clearSelections(state);

        /**
         * update selection - set to some as if 'selected', the context
         * will think all options are selected, therefore will send 'undefined'
         * instead of the singular project
         **/
        state.selectionState = 'some';
        getProject(state, groupId, projectId).selected = true;

        // update filters
        state.queryFilters.groups = [];
        state.queryFilters.projects = [projectId];
      },

      clearAll: (state) => {
        clearSelections(state);
        state.queryFilters.groups = [];
        state.queryFilters.projects = [];
      },

      /*
       * Expanding/collapsing groups
       */

      toggleGroupExpandedState: (
        state,
        { payload: groupId }: PayloadAction<number>
      ) => {
        const group = getGroup(state, groupId);
        const { expanded, loaded } = group;

        if (!expanded && !loaded) {
          group.loading = true;
        }

        group.expanded = !expanded;
      },

      /*
       * Resetting state
       */

      resetStates: (state: ProjectGroupsState) => {
        state.searchTerm = '';
        state.queryFilters = {
          projects: [],
          groups: [],
        };
        clearSelections(state, true);
      },
    },
  });
};
