import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { PurchaseOrder } from '../../../domain/types';
import { GridColDef } from '@mui/x-data-grid';
import {
  PurchaseOrderFormValues,
  PurchaseOrderSchema,
} from './PurchaseOrderSchema';
import { useStateContext } from '../../../contexts/StateProvider';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { formatDate } from '../../../utils/dateUtils';
import { DateDisplay } from '../../DateDisplay';

const columns: GridColDef[] = [
  {
    headerName: 'PO Number',
    field: 'poNumber',
    flex: 1,
  },
  {
    headerName: 'Date',
    field: 'date',
    flex: 1,
    renderCell: ({ value }) => <DateDisplay date={value as string} />,
  },
  {
    headerName: 'Renewal Date',
    field: 'renewalDate',
    flex: 1,
    renderCell: ({ value }) =>
      value ? <DateDisplay date={value as string} /> : <></>,
  },
  {
    headerName: 'Amount',
    field: 'amount',
    flex: 1,
    renderCell: ({ value, row }) => {
      return <MonetaryDisplay value={Number(value)} currency={row.currency} />;
    },
  },
  {
    headerName: 'Budget',
    field: 'budget',
    flex: 1,
    valueGetter: ({ value }) => {
      const budgetDetails = value as PurchaseOrder['budget'];
      if (budgetDetails) {
        return `${formatDate(budgetDetails.startDate)} - ${formatDate(
          budgetDetails.endDate
        )}`;
      }
    },
  },
];

export const PurchaseOrderAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  return (
    <CrudContainer<PurchaseOrder, PurchaseOrderFormValues>
      name="Purchase Order"
      apiPath={apiPaths.purchaseOrder(selectedCustomerId)}
      columns={columns}
      defaultSortColumn="date"
      FormComponent={PurchaseOrderForm}
      validationSchema={PurchaseOrderSchema}
    />
  );
};
