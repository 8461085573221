import { apiPaths } from '../../utils/apiPaths';
import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { skuGroups } from '../data/adminData';

class SkuGroupsHandler implements ServerHandler {
  handlers() {
    const skuGroupsApi = apiPaths.skuGroups();
    return [
      rest.get(`/api${skuGroupsApi.base}`, (req, res, ctx) => {
        return res(
          ctx.json({
            items: skuGroups,
          })
        );
      }),
    ];
  }

  public resetData() {}
}

export const skuGroupsHandler = new SkuGroupsHandler();
