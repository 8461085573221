import axios from 'axios';

interface MockState {
  cyUser: string | null;
  idTokenChangeCB: any[];
  currentUser: {
    getIdToken: () => Promise<string | null>;
  } | null;
}

const mockState: MockState = {
  cyUser: null,
  currentUser: null,
  idTokenChangeCB: [],
};

function initMockState() {
  mockState.cyUser = window.localStorage.getItem('cy_user');
  mockState.currentUser = mockState.cyUser
    ? {
        getIdToken: () => Promise.resolve(mockState.cyUser),
      }
    : null;
}

export const E2E_ENV = 'e2e';

export function isE2E_Enabled() {
  return process.env.REACT_APP_ENV === E2E_ENV;
}

function stubFirebase(firebase: any) {
  initMockState();

  const AuthFunction = function () {
    return {
      currentUser: mockState.currentUser,
      signOut: () => {
        mockState.currentUser = null;
        return Promise.resolve();
      },
      signInWithPopup: async (user: string) => {
        window.localStorage.setItem('cy_user', user);
        mockState.currentUser = {
          getIdToken: () => Promise.resolve(user),
        };

        mockState.cyUser = user;
        await Promise.resolve();

        mockState.idTokenChangeCB.forEach((cb: any) =>
          cb(mockState.currentUser)
        );

        return Promise.resolve({ user: { email: user } });
      },
      onIdTokenChanged: (callback: any) => {
        mockState.idTokenChangeCB.push(callback);
        callback(mockState.currentUser);
        return () => {};
      },
    };
  };

  AuthFunction.GoogleAuthProvider = class GoogleAuthProvider {};
  (firebase as any).auth = AuthFunction;
}

function bindMockInterceptor() {
  axios.interceptors.request.use(async (config) => {
    config.headers['X-Mock-Auth-Email'] = mockState.cyUser;
    return config;
  });
}

export function setupE2E(firebase: any) {
  if (!isE2E_Enabled()) {
    return;
  }

  stubFirebase(firebase);
  bindMockInterceptor();
}
