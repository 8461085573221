import { useMemo } from 'react';
import { apiPaths } from '../../../../utils/apiPaths';
import { useQuery } from 'react-query';
import { GET } from '../../../../utils/api';
import { Project } from '../../../../domain/types';

interface Props {
  recommenderSubtype: string;
  targetResources: string[];
  selectedCustomerId: number;
}

export const useRecommendationProjectId = ({
  recommenderSubtype,
  targetResources,
  selectedCustomerId,
}: Props) => {
  const projectNumber: string | null = useMemo(() => {
    const isProjectSubType = ['CLEANUP_PROJECT', 'RECLAIM_PROJECT'].includes(
      recommenderSubtype
    );

    if (!isProjectSubType) {
      return null;
    }

    const PROJECTS_REGEX = /(projects)\/([0-9]*)/gm;
    const resourceString = targetResources.find((resource) =>
      PROJECTS_REGEX.test(resource)
    );
    if (!resourceString) {
      return null;
    }

    return resourceString.split('/').pop() ?? null;
  }, [recommenderSubtype, targetResources]);

  const apiPath = apiPaths.project(selectedCustomerId);

  const { data: project, isLoading } = useQuery<Project>(
    apiPath.key.item(projectNumber ? +projectNumber : -1),
    () => GET(apiPath.itemByNumber(projectNumber ?? '')),
    {
      enabled: !!projectNumber,
      retry: false,
    }
  );

  return useMemo(() => {
    return {
      project: project ? { ...project, gcpProjectNumber: projectNumber } : null,
      isLoading,
    };
  }, [project, isLoading, projectNumber]);
};
