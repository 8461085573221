import { ReactNode, createContext, useMemo, useReducer } from 'react';
import {
  CustomerDiscount,
  CustomerDiscountException,
  SkuGroup,
} from '../../../../domain/types';
import { useSkuGroups } from '../hooks/useSkuGroups';
import { initialState, customerDiscountSlice } from './customerDiscountSlice';

interface Actions {
  setCustomerDiscounts: (discounts: CustomerDiscount[]) => void;
  addCustomerDiscount: (discount: CustomerDiscount) => void;
  removeCustomerDiscount: (discountUID: string) => void;
  updateCustomerDiscount: (discount: CustomerDiscount) => void;
  addDiscountException: (
    discountUID: string,
    exception: CustomerDiscountException
  ) => void;
  updateDiscountException: (
    discountUID: string,
    exception: CustomerDiscountException
  ) => void;
  removeDiscountException: (discountUID: string, temporaryUID: string) => void;
}

interface ContextProps {
  discounts: CustomerDiscount[];
  skuGroups: SkuGroup[];
  actions: Actions;
}

export const CustomerAdminContext = createContext<ContextProps>({
  discounts: initialState.discounts,
  skuGroups: [],
  actions: {
    setCustomerDiscounts: () => {},
    addCustomerDiscount: () => {},
    removeCustomerDiscount: () => {},
    updateCustomerDiscount: () => {},
    addDiscountException: () => {},
    updateDiscountException: () => {},
    removeDiscountException: () => {},
  },
});

export const CustomerAdminStateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { items: skuGroups } = useSkuGroups();
  const [state, dispatch] = useReducer(
    customerDiscountSlice.reducer,
    initialState
  );

  const actions = useMemo(() => {
    const { actions } = customerDiscountSlice;

    return {
      setCustomerDiscounts: (discounts: CustomerDiscount[]) => {
        dispatch(actions.setDiscounts(discounts));
      },
      addCustomerDiscount: (discount: CustomerDiscount) => {
        dispatch(actions.addDiscount(discount));
      },
      removeCustomerDiscount: (discountUID: string) => {
        dispatch(actions.removeDiscount(discountUID));
      },
      updateCustomerDiscount: (discount: CustomerDiscount) => {
        dispatch(actions.updateDiscount(discount));
      },
      addDiscountException: (
        discountUID: string,
        exception: CustomerDiscountException
      ) => {
        dispatch(
          actions.addDiscountException({
            discountUID,
            exception,
          })
        );
      },
      updateDiscountException: (
        discountUID: string,
        exception: CustomerDiscountException
      ) => {
        dispatch(
          actions.updateDiscountException({
            discountUID,
            exception,
          })
        );
      },
      removeDiscountException: (discountUID: string, temporaryUID: string) => {
        dispatch(
          actions.removeDiscountException({
            discountUID,
            temporaryUID,
          })
        );
      },
    };
  }, [dispatch]);

  return (
    <CustomerAdminContext.Provider
      value={{ discounts: state.discounts, actions, skuGroups }}
    >
      {children}
    </CustomerAdminContext.Provider>
  );
};
