import { Button, ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRecommenderFilters } from './hooks/useRecommenderFilters';
import clsx from 'clsx';
import { RecommendationEntity } from '../../../domain/types';

const options: { value: RecommendationEntity; label: string }[] = [
  { value: 'BILLING_ACCOUNT_ID', label: 'Billing Account' },
  { value: 'PROJECT_NUMBER', label: 'Project' },
  { value: 'FOLDER_ID', label: 'Folder' },
  { value: 'ORGANIZATION_ID', label: 'Organization' },
];

export const RecommenderEntityFilter = () => {
  const classes = useStyles();
  const filtersContext = useRecommenderFilters();
  const { entities } = filtersContext.filters;

  const handleSelection = (value: RecommendationEntity) => {
    filtersContext.applyFilters({
      entities: [value],
    });
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      {options.map((option) => (
        <Button
          key={option.value}
          className={clsx(entities.includes(option.value) && classes.selected)}
          onClick={() => handleSelection(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light,
      color: 'white',
    },
  },
}));
