import { useMutation, useQueryClient } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { ApiErrorResponse, CustomerDiscount } from '../../../../domain/types';
import { DELETE, POST, PUT } from '../../../../utils/api';
import { VOID_ID } from '../../../../domain/constants';
import { useStateContext } from '../../../../contexts/StateProvider';

interface MutationArgs {
  customerId: number;
  discounts: CustomerDiscount[];
}

type ExceptionsDTO = {
  percentage: number;
  skuGroupId: number;
  rank: number;
};

type DiscountDTO = {
  id?: number;
  percentage: number;
  startDate: string;
  endDate?: string;
  exceptions: ExceptionsDTO[];
};

const discountDTOMapper = (discount: CustomerDiscount): DiscountDTO => {
  const newExceptions = discount.exceptions.map((exception, index) => ({
    skuGroupId: exception.skuGroupId,
    percentage: exception.percentage,
    rank: index,
  }));

  const discountDTO: DiscountDTO = {
    percentage: discount.percentage,
    startDate: discount.startDate,
    endDate: discount.endDate,
    exceptions: newExceptions,
  };

  if (discount.id !== VOID_ID) {
    discountDTO.id = discount.id;
  }

  return discountDTO;
};

export const useDiscountMutation = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();

  const createMutation = async ({ customerId, discounts }: MutationArgs) => {
    const apiPath = apiPaths.customerDiscounts(customerId);
    const newDiscounts = discounts.filter(
      (discount) =>
        discount.dirty && discount.id === VOID_ID && !discount.deleted
    );
    const updatedDiscounts = discounts.filter(
      (discount) =>
        discount.dirty && discount.id !== VOID_ID && !discount.deleted
    );

    const removedDiscounts = discounts.filter(
      (discount) => discount.id !== VOID_ID && discount.deleted
    );

    for (const item of removedDiscounts) {
      await DELETE(`${apiPath.base}/${item.id}`);
    }

    for (const item of updatedDiscounts) {
      await PUT(`${apiPath.base}/${item.id}`, discountDTOMapper(item));
    }

    for (const item of newDiscounts) {
      await POST(`${apiPath.base}`, discountDTOMapper(item));
    }
  };

  const { mutate, isLoading } = useMutation<
    unknown,
    ApiErrorResponse,
    MutationArgs
  >(createMutation, {
    onSuccess: (_, { customerId }) => {
      queryClient.invalidateQueries(
        apiPaths.customerDiscounts(customerId).key.list(customerId)
      );
      queryClient.invalidateQueries(
        apiPaths.customersDiscountsSummary().key.list()
      );

      showNotification({
        message: 'Changes applied successfully',
        severity: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: 'There was an error. Please try again.',
        severity: 'error',
      });
    },
  });

  return {
    mutate,
    isLoading,
  };
};
