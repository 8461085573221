import { Budget, PurchaseOrder } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { mockBudgetsData, mockPurchaseOrderData } from '../data/adminData';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { rest, RestHandler } from 'msw';
import { PurchaseOrderFormValues } from '../../components/Admin/PurchaseOrder/PurchaseOrderSchema';

class Handler extends CustomerCrudHandler<PurchaseOrder> {
  private findBudget(budgetId?: number | null) {
    return mockBudgetsData.find((budget) => budget.id === budgetId);
  }

  private createPurchaseOrder(
    id: number,
    customerId: number,
    budget: Budget,
    values: Omit<PurchaseOrderFormValues, 'budgetId'>
  ): PurchaseOrder {
    return {
      ...values,
      id,
      customerId,
      budget: {
        id: budget.id,
        startDate: budget.startDate,
        endDate: budget.endDate,
      },
    };
  }

  createHandler(): RestHandler {
    return rest.post<PurchaseOrderFormValues>(
      this.endpoint,
      async (req, res, ctx) => {
        const { budgetId, ...body } = req.body;
        const { customerId } = req.params;
        const budget = this.findBudget(budgetId);
        if (!budget) {
          return res(ctx.status(404));
        }
        const id = this.nextId++;
        const newPurchaseOrder = this.createPurchaseOrder(
          id,
          customerId,
          budget,
          body
        );
        this.getDataset(this.getKey(req.params)).set(id, newPurchaseOrder);
        return res(ctx.json(newPurchaseOrder));
      }
    );
  }

  updateHandler(): RestHandler {
    return rest.put<PurchaseOrderFormValues>(
      this.itemEndpoint,
      async (req, res, ctx) => {
        const { id, customerId } = req.params;
        const { budgetId, ...body } = req.body;
        const budget = this.findBudget(budgetId);
        if (!budget) {
          return res(ctx.status(404));
        }
        const itemId = parseInt(id, 10);
        const updatedPurchaseOrder = this.createPurchaseOrder(
          itemId,
          customerId,
          budget,
          body
        );
        this.getDataset(this.getKey(req.params)).set(
          itemId,
          updatedPurchaseOrder
        );
        return res(ctx.json(updatedPurchaseOrder));
      }
    );
  }
}

export const purchaseOrdersHandler = new Handler(
  apiPaths.purchaseOrder(':customerId' as any).base,
  {
    1: mockPurchaseOrderData,
  }
);
