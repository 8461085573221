import { ApiPath } from '../../../utils/apiPaths';
import React from 'react';
import { CrudFormModal } from '../form/CrudFormModal';
import { useCrudUpdate, useCrudUpdateManual } from './useCrudUpdate';
import { useParams } from 'react-router-dom';
import { CrudFormComponent } from '../form/CrudFormComponent';
import { CrudValueMapper } from '../CrudContainer';
import { PagedItems } from '../../../domain/types';

export interface CrudUpdateModalProps<T, FormValues, SubmittedValues> {
  name: string;
  apiPath: ApiPath;
  onClose: () => void;
  FormComponent: CrudFormComponent<T>;
  valueMapper: CrudValueMapper<FormValues, SubmittedValues>;
}

export function CrudUpdateModal<T, FormValues, SubmittedValues>({
  name,
  FormComponent,
  apiPath,
  onClose,
  valueMapper,
}: CrudUpdateModalProps<T, FormValues, SubmittedValues>) {
  const { id } = useParams<{ id: string }>();

  const {
    onSubmit,
    item,
    isDataLoading,
    hasDataError,
    isUpdating,
    hasUpdateError,
    updateErrorMessage,
  } = useCrudUpdate<T, SubmittedValues>({
    id: parseInt(id, 10),
    apiPath,
    onSuccess: () => {
      onClose();
    },
  });
  return (
    <CrudFormModal<FormValues, SubmittedValues>
      title={`Update ${name}`}
      mutatingButtonText="Updating..."
      buttonText="Update"
      onSubmit={onSubmit}
      onClose={onClose}
      isDataLoading={isDataLoading}
      hasDataError={hasDataError}
      isMutating={isUpdating}
      hasMutationError={hasUpdateError}
      mutationErrorMessage={updateErrorMessage}
      valueMapper={valueMapper}
    >
      <FormComponent item={item} />
    </CrudFormModal>
  );
}

export interface CrudUpdateManualModalProps<T, FormValues, SubmittedValues> {
  name: string;
  apiPath: ApiPath;
  onClose: () => void;
  FormComponent: CrudFormComponent<T>;
  valueMapper: CrudValueMapper<FormValues, SubmittedValues>;
  data?: PagedItems<T>;
}

export function CrudUpdateModalManual<T, FormValues, SubmittedValues>({
  name,
  FormComponent,
  apiPath,
  onClose,
  valueMapper,
  data,
}: CrudUpdateManualModalProps<T, FormValues, SubmittedValues>) {
  const { id } = useParams<{ id: string }>();

  const dataItem = data?.items.filter((item: any) => {
    if (typeof item.id !== 'undefined') {
      return item.id === parseInt(id);
    }
    return false;
  });

  const {
    onSubmit,
    item,
    isDataLoading,
    hasDataError,
    isUpdating,
    hasUpdateError,
    updateErrorMessage,
  } = useCrudUpdateManual<T, SubmittedValues>({
    id: parseInt(id, 10),
    apiPath,
    onSuccess: () => {
      onClose();
    },
    dataItem,
  });

  return (
    <CrudFormModal<FormValues, SubmittedValues>
      title={`Update ${name}`}
      mutatingButtonText="Updating..."
      buttonText="Update"
      onSubmit={onSubmit}
      onClose={onClose}
      isDataLoading={isDataLoading}
      hasDataError={hasDataError}
      isMutating={isUpdating}
      hasMutationError={hasUpdateError}
      mutationErrorMessage={updateErrorMessage}
      valueMapper={valueMapper}
    >
      <FormComponent item={item} />
    </CrudFormModal>
  );
}
