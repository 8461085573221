import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { format, subMonths } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { CartesianGrid, LineChart, ResponsiveContainer, XAxis } from 'recharts';
import { CHART_MONTHLY_DATE_FORMAT } from '../../domain/constants';
import { QueryStatus } from '../Messaging';
import { CHART_HEIGHT } from './ChartContainer';

type ChartLoaderProps<T extends unknown> = {
  isLoading: boolean;
  isError: boolean;
  data?: T;
  noDataMessage?: string;
  onData: (data: T) => JSX.Element;
  dataReady: (data: T) => boolean;
};

const ChartPlaceholder = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  const data: { name: string; value: number }[] = useMemo(() => {
    const today = new Date();
    const months = [today];
    for (let i = 1; i < 12; i++) {
      const previousMonth = months[i - 1];
      const month = subMonths(previousMonth, 1);
      months.push(month);
    }

    return months
      .sort((first, second) => first.getTime() - second.getTime())
      .map((month) => ({
        name: format(month, CHART_MONTHLY_DATE_FORMAT),
        value: 100,
      }));
  }, []);

  return (
    <div className={classes.chartContainerRoot}>
      <div className={classes.chartContainerInner}>
        <div className={classes.placeholderContent}>{children}</div>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} width={300}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 12, dy: 5 }}
              tickLine={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export function ChartLoader<T extends unknown>({
  isLoading,
  isError,
  data,
  noDataMessage = 'Your query returned no data.',
  onData,
  dataReady,
}: ChartLoaderProps<T>) {
  const classes = useStyles();

  if (data && dataReady(data)) {
    return onData(data);
  } else if (isLoading) {
    return (
      <ChartPlaceholder>
        <QueryStatus isLoading={true} isError={isError} />
        Loading data
      </ChartPlaceholder>
    );
  } else if (isError) {
    return (
      <ChartPlaceholder>
        <Alert severity="error" className={classes.alert}>
          An error occurred whilst loading the data
        </Alert>
      </ChartPlaceholder>
    );
  }

  return (
    <ChartPlaceholder>
      <Alert severity="info" className={classes.alert}>
        {noDataMessage}
      </Alert>
    </ChartPlaceholder>
  );
}

const useStyles = makeStyles((theme) => ({
  alert: {
    marginRight: theme.spacing(3),
  },
  placeholderContent: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1,
    userSelect: 'none',
  },
  chartContainerRoot: {
    width: 'calc(100% - 8px)',
    height: CHART_HEIGHT,
    position: 'relative',
  },
  chartContainerInner: {
    width: '100%',
    height: CHART_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
