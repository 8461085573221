/**
 *  Helper function to format database Enum values to UI friendly.
 *  Removes '_' and converts capitilised text to 'Title Case'
 *
 *  ## TO DO - future updates could include more complex functions for different scenarios
 *  ## Example: preposition and postpostion words could remain lower case - 'of', 'and' etc.
 */
export const enumStringFormat = (str: string) => {
  if (typeof str === 'string') {
    return str
      .replace(/_/g, ' ')
      .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
      .replace(/^[^ ]/g, (match) => match.toUpperCase());
  }
  return '';
};

export const stringToPercentage = (str: string): number | string => {
  return str !== ''
    ? (Number(str.replace(/[^0-9.]/g, '')) / 100).toFixed(2)
    : '';
};

export const formatToPercentage = (percentage: number): number | string => {
  return percentage ? Math.round(percentage * 100) : '';
};

export const pixelsToNumber = (pixels?: string) => {
  if (!pixels || !pixels.trim()) {
    return 0;
  }
  const pixelsNumber = pixels.split('px')[0];
  return !isNaN(+pixelsNumber) ? +pixelsNumber : 0;
};
