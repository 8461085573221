import { useQuery } from 'react-query';
import {
  CommitTrackerData,
  CommitTrackerFilters,
  CommitTrackerFiltersDto,
} from '../../domain/types';
import { POST } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { applyCumulative } from '../../utils/commitTrackerUtils';
import { useCommitTrackerFilters } from './useCommitTrackerFilters';

interface Props {
  customerId: number;
  filters: CommitTrackerFilters;
  enabled: boolean;
}

interface Response {
  data?: CommitTrackerData;
  average?: number;
  rawDataRequestFilters?: CommitTrackerFiltersDto;
  isLoading: boolean;
  isError: boolean;
}

export const useCommitTrackerData = ({
  customerId,
  filters,
  enabled,
}: Props): Response => {
  const requestData = useCommitTrackerFilters(customerId, filters);
  const apiPath = apiPaths.commitTracker(customerId);

  const { data, isLoading, isError } = useQuery<CommitTrackerData>(
    apiPath.key.list(requestData),
    () =>
      POST<CommitTrackerData, CommitTrackerFiltersDto | undefined>(
        apiPath.base,
        requestData
      ),
    {
      enabled: enabled && !!requestData,
    }
  );

  return {
    data: data ? applyCumulative(data) : undefined,
    rawDataRequestFilters: requestData,
    isLoading,
    isError,
  };
};
