import { Project } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { rest, RestHandler } from 'msw';
import { mockProjectData } from '../data/adminData';

class Handler extends CustomerCrudHandler<Project> {
  createHandler(): RestHandler {
    return rest.post(this.endpoint, async (req, res, ctx) => {
      return res(ctx.status(404));
    });
  }
  deleteHandler(): RestHandler {
    return rest.delete(this.itemEndpoint, async (req, res, ctx) => {
      return res(ctx.status(404));
    });
  }
  // can only update the metadata for projects
  updateHandler(): RestHandler {
    return rest.put(this.itemEndpoint, async (req, res, ctx) => {
      const { id } = req.params;
      const itemId = Number(id);
      const dataSet = this.getDataset(this.getKey(req.params));
      const item = dataSet.get(itemId);
      if (!item) {
        return res(ctx.status(404));
      }
      if (typeof req.body !== 'object') {
        return res(ctx.status(400));
      }
      const updatedItem = {
        ...item,
        description: req.body.description ?? '',
        contacts: req.body.contacts,
      };
      dataSet.set(itemId, updatedItem);
      return res(ctx.json(updatedItem));
    });
  }
}

export const projectsHandler = new Handler(
  apiPaths.project(':customerId' as any).base,
  {
    1: mockProjectData,
  }
);
