import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useStateContext } from '../../../contexts/StateProvider';
import { apiPaths } from '../../../utils/apiPaths';
import { GET, POST, PUT } from '../../../utils/api';
import { BQUsageOnboardingModel } from '../../../domain/types';
import { useMemo } from 'react';

export const useBQOnboarding = () => {
  const queryClient = useQueryClient();
  const { selectedCustomerId, showNotification } = useStateContext();
  const apiPath = apiPaths.bigQueryUsageOnboarding(selectedCustomerId);

  const { data, isLoading } = useQuery<BQUsageOnboardingModel>(
    apiPath.key.item(selectedCustomerId),
    () => GET(apiPath.base)
  );

  const { mutate: create, isLoading: createIsLoading } = useMutation(
    (projectId: string) => POST(apiPath.base, { projectId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.item(selectedCustomerId));
        showNotification({
          message: 'Item created successfully',
          severity: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: 'There was an error. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  const { mutate: edit, isLoading: editIsLoading } = useMutation(
    (projectId: string) => PUT(apiPath.base, { projectId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiPath.key.item(selectedCustomerId));
        showNotification({
          message: 'Item updated successfully',
          severity: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: 'There was an error. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  return useMemo(
    () => ({
      data: data ?? { projectId: '' },
      isLoading: isLoading || createIsLoading || editIsLoading,
      mutate: data ? edit : create,
    }),
    [data, isLoading, createIsLoading, editIsLoading, create, edit]
  );
};
