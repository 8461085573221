import { CommitTrackerData, NameTotal } from '../domain/types';

export const applyCumulative = (data: CommitTrackerData) => {
  /**
   * take the first element of the array as the initial value
   * and adjust (forecast should carry on from consumption) if necessary
   */
  const update = ([head, ...tail]: NameTotal[], startingAdjustment = 0) => {
    return tail.reduce<NameTotal[]>(
      (acc, val, index) => {
        acc.push({
          name: val.name,
          total: acc[index].total + val.total,
        });

        return acc;
      },
      [
        {
          name: head.name,
          total: head.total + startingAdjustment,
        },
      ]
    );
  };

  const consumption =
    data.consumption.length > 0
      ? update(data.consumption, data.excessRollover ?? 0)
      : [];
  const forecast =
    data.forecast.length > 0 && consumption.length > 0
      ? update(data.forecast, consumption[consumption.length - 1].total)
      : [];

  return {
    ...data,
    consumption,
    forecast,
  };
};
