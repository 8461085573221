import { UI_DATE_FORMAT, API_MONTHLY_DATE_REGEX } from '../domain/constants';
import {
  addDays,
  addYears,
  compareAsc,
  format,
  isWithinInterval,
} from 'date-fns';
import { Commit, CommitTrackingType } from '../domain/types';

export type DateType = Date | string;

const toDate = (date: DateType): Date => {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};

export const formatDate = (
  date: DateType,
  dateFormat = UI_DATE_FORMAT
): string => {
  return format(toDate(date), dateFormat);
};

export const todayIsWithinRange = (startDate: DateType, endDate: DateType) => {
  return isWithinInterval(Date.now(), {
    start: new Date(startDate),
    end: new Date(endDate),
  });
};

/**
 *  Reusable function to determine whether to use the final end date of a commit
 *  or to add a year on to the passed in start date.
 *
 *  When the start date is used, a day is also removed to ensure the correct end date format
 *  eg: 2021-01-01  >>  2021-12-31
 */
export const calculateCommitEndDate = (
  commit: Commit | null,
  startDate: DateType
): Date => {
  const trackingType = commit?.trackingType;

  // use end date if full term and commit has an end date
  if (trackingType === CommitTrackingType.FULL && commit?.endDate) {
    return toDate(commit.endDate);
  }

  // otherwise calculate it from the start date
  return addDays(addYears(toDate(startDate), 1), -1);
};

export const formatToUpdatedTime = (dateTime: DateType) => {
  try {
    return format(toDate(dateTime), `HH:mm 'on' ${UI_DATE_FORMAT}`);
  } catch {
    return undefined;
  }
};

export const sortCommitsByDateAsc = (commits: Commit[]) =>
  commits.sort((commitA: Commit, commitB: Commit) => {
    return compareAsc(new Date(commitA.startDate), new Date(commitB.startDate));
  });

export const isMonthlyApiFormat = (
  date: string | undefined | null
): boolean => {
  return API_MONTHLY_DATE_REGEX.test(date ?? '');
};
