import { BQUsageBreakdown, BQUsageValue } from '../../../../domain/types';

export const formatByBreakdownCostData = (
  rawData: BQUsageValue[],
  summaryData: BQUsageValue[],
  breakdown: BQUsageBreakdown
) => {
  const items = rawData.map((item) => ({
    ...item,
    item: item[breakdown as keyof BQUsageValue] ?? 'unknown',
  }));

  const OTHER_PROPERTY = `Other ${breakdown}s`;
  const listedBreakdownItems = summaryData.filter(
    (item) => item[breakdown as keyof BQUsageValue] !== OTHER_PROPERTY
  );

  const formatItems = (groupedItems: Record<string, number>) => {
    let otherValue = 0;
    const formattedItems: Record<string, number> = {};

    // check every grouped item to see if it matches the 'allowed' list
    // if not, add the value to the newly grouped item
    // eg: 'Other projects'
    for (const [itemKey, value] of Object.entries(groupedItems)) {
      if (
        listedBreakdownItems.some(
          (listedItem) =>
            listedItem[breakdown as keyof BQUsageValue] === itemKey
        )
      ) {
        formattedItems[itemKey] = value >= 0 ? value : 0;
      } else {
        otherValue += groupedItems[itemKey];
        formattedItems[OTHER_PROPERTY] = otherValue;
      }
    }
    return formattedItems;
  };

  const indicativeCost: Record<string, number>[] = Array.from(
    items.reduce((m, currentItem) => {
      const { name, item, indicative_cost } = currentItem;
      return m.set(name, { ...m.get(name), ...{ [item]: indicative_cost } });
    }, new Map()),
    ([key, items]) => {
      const formattedItems = formatItems(items);
      return { name: key, ...formattedItems };
    }
  );

  const keys = summaryData
    .filter((item) => item[breakdown as keyof BQUsageValue] !== OTHER_PROPERTY)
    .map((item) => item[breakdown as keyof BQUsageValue] as string);

  return indicativeCost.map((item) => {
    const datum: Record<string, number> = {
      name: item.name,
    };

    keys.forEach((key: string) => {
      datum[key] = item[key] ?? 0;
    });

    return datum;
  });
};
