import {
  debounce,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

interface ComponentProps {
  value: string;
  onSearch: (search: string) => void;
}

export const RecommenderSearch = ({ value, onSearch }: ComponentProps) => {
  const [search, setSearch] = useState(value);

  const onDebouncedSearched = useMemo(() => {
    return debounce((searchTerm: string) => onSearch(searchTerm), 500);
  }, [onSearch]);

  useEffect(() => {
    setSearch(value);
  }, [value, setSearch]);

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    onDebouncedSearched(searchTerm);
  };

  return (
    <FormControl style={{ width: 280 }}>
      <TextField
        placeholder="Search"
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
