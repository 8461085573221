import { styled } from '@mui/styles';
import { useFilters } from '../../../../contexts/ReportFiltersProvider';
import { BQUsageFilters } from '../../../../domain/types';
import { useStateContext } from '../../../../contexts/StateProvider';
import { GenericReport } from '../../GenericReport';
import { PageHeader } from '../../../PageHeader';
import { BigQueryUsageFilterBar } from './BigQueryUsageFilterBar';
import { BigQueryUsageChart } from '../../../Charts/BigQueryUsageChart';
import { useBigQueryUsageReport } from '../hooks/useBigQueryUsageReport';
import { BigUsageDataGrid } from './BigQueryUsageDataGrid';
import { ChartProvider } from '../../../Charts/ChartProvider';
import { ChartWidgetContainer } from '../../../ChartWidgetContainer';
import { useMemo } from 'react';
import { ChartHeader } from '../../../Charts/ChartHeader';
import { BigQueryUsageReportTitle } from './BigQueryUsageReportTitle';

const Container = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
}));

export const BigQueryUsageReportContainer = () => {
  const { appliedFilters } = useFilters<BQUsageFilters>();
  const { selectedCustomerId } = useStateContext();
  const {
    isLoading,
    currency,
    summaryItems,
    itemsMap,
    breakdownDataItems,
    groupedSummaryDataItems,
  } = useBigQueryUsageReport({
    customerId: selectedCustomerId,
    filters: appliedFilters,
  });

  const chartItems = useMemo(() => {
    return groupedSummaryDataItems?.slice().reverse() ?? [];
  }, [groupedSummaryDataItems]);

  return (
    <Container>
      <GenericReport
        renderPageHeader={() => (
          <PageHeader title="BigQuery Analytics Report"></PageHeader>
        )}
        renderFilters={() => <BigQueryUsageFilterBar />}
        renderReport={() => (
          <>
            <ChartProvider>
              <ChartWidgetContainer>
                <ChartHeader
                  renderTitle={() => (
                    <BigQueryUsageReportTitle filters={appliedFilters} />
                  )}
                />
                <BigQueryUsageChart
                  isLoading={isLoading}
                  currency={currency}
                  isError={false}
                  itemsMap={itemsMap}
                  formattedData={breakdownDataItems.indicativeCost}
                  chartItems={chartItems}
                />
              </ChartWidgetContainer>
            </ChartProvider>
            <BigUsageDataGrid
              items={summaryItems}
              isLoading={isLoading}
              currency={currency}
            />
          </>
        )}
      />
    </Container>
  );
};
