import { useMemo } from 'react';
import { CustomerDiscount } from '../../../../domain/types';
import { useCustomerAdminState } from './useCustomerAdminState';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

interface DiscountInterval {
  min: Date | null;
  max: Date | null;
}

interface DiscountIntervals {
  start: DiscountInterval;
  end: DiscountInterval;
}

const initDiscountIntervals = () => {
  return {
    start: {
      min: null,
      max: null,
    },
    end: {
      min: null,
      max: null,
    },
  };
};

const getStartMinimum = ({
  discounts,
  index,
}: {
  discounts: CustomerDiscount[];
  index: number;
}) => {
  const previousDiscountIndex = index > 0 ? index - 1 : -1;
  if (previousDiscountIndex === -1) {
    return null;
  }

  const prevDiscount = discounts[previousDiscountIndex];
  const prevDate = prevDiscount.endDate
    ? prevDiscount.endDate
    : prevDiscount.startDate;

  return addDays(new Date(prevDate ?? ''), 1);
};

const getEndMaximum = ({
  discounts,
  index,
}: {
  discounts: CustomerDiscount[];
  index: number;
}) => {
  const nextDiscountIndex = index < discounts.length - 1 ? index + 1 : -1;
  if (nextDiscountIndex === -1) {
    return null;
  }

  return subDays(new Date(discounts[nextDiscountIndex].startDate), 1);
};

const getDiscountIntervals = (
  discount: CustomerDiscount,
  discounts: CustomerDiscount[]
) => {
  const discountIntervals: DiscountIntervals = initDiscountIntervals();
  const sortedDiscounts = [...discounts]
    .filter((item) => !item.deleted)
    .sort((first, second) => {
      return +new Date(first.startDate) - +new Date(second.startDate);
    });

  const index = sortedDiscounts.findIndex(
    (item) => item.temporaryUID === discount.temporaryUID
  );

  discountIntervals.start.min = getStartMinimum({
    discounts: sortedDiscounts,
    index,
  });
  discountIntervals.end.max = getEndMaximum({
    discounts: sortedDiscounts,
    index,
  });

  discountIntervals.start.max = discount.endDate
    ? subDays(new Date(discount.endDate), 1)
    : null;

  discountIntervals.end.min = discount.startDate
    ? addDays(new Date(discount.startDate), 1)
    : null;

  return discountIntervals;
};

export const useDiscountRanges = (discount: CustomerDiscount) => {
  const { discounts } = useCustomerAdminState();

  return useMemo(() => getDiscountIntervals(discount, discounts), [
    discount,
    discounts,
  ]);
};
