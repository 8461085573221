import { useQuery } from 'react-query';
import { BillingAccount, PagedItems } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { GET_PAGED } from '../../../utils/api';

interface Response {
  currency?: string;
  average?: number;
  isLoading: boolean;
  isError: boolean;
}

export const useGetCustomerCurrency = (customerId: number): Response => {
  const { data, isLoading, isError } = useQuery<PagedItems<BillingAccount>>(
    apiPaths.billingAccount(customerId).key.list(customerId),
    () => GET_PAGED(apiPaths.billingAccount(customerId).base)
  );

  return {
    currency: data?.items[0]?.currency,
    isLoading: isLoading,
    isError: isError,
  };
};
