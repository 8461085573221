import { debounce } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useFilters } from '../../../../contexts/ReportFiltersProvider';
import { useStateContext } from '../../../../contexts/StateProvider';
import { VOID_ID } from '../../../../domain/constants';
import {
  ConsumptionFilters,
  Customer,
  PagedItems,
  ReportBreakdown,
} from '../../../../domain/types';
import {
  ProjectGroupsState,
  ProjectGroupTreeItem,
} from '../../../../hooks/projectGroupsSlice';
import { UseProjectGroupActions } from '../../../../hooks/useProjectGroups';
import { GET_PAGED } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';

const useCustomers = (customerId: number) => {
  const { data } = useQuery<PagedItems<Customer>>(
    apiPaths.customer.key.list(),
    () => GET_PAGED(apiPaths.customer.base),
    {
      retry: false,
      keepPreviousData: true,
      enabled: customerId === VOID_ID,
    }
  );

  return useMemo(() => data?.items ?? [], [data]);
};

const useProjectsMaps = (projectsGroupsState: ProjectGroupsState) => {
  return useMemo(() => {
    const { projectGroups } = projectsGroupsState;

    const groupsMap = Object.values(projectGroups).reduce(
      (groupsMap, group: ProjectGroupTreeItem) => {
        groupsMap.set(group.name, group.id);
        return groupsMap;
      },
      new Map<string, number>([])
    );

    const projectsMap = Object.values(projectGroups).reduce(
      (projectsMap, group) => {
        Object.values(group.projects).forEach((project) => {
          projectsMap.set(project.gcpProjectId, {
            projectId: project.id,
            groupId: group.id,
          });
        });

        return projectsMap;
      },
      new Map<string, { projectId: number; groupId: number }>([])
    );

    return { groupsMap, projectsMap };
  }, [projectsGroupsState]);
};

const getNextBreakdownValue = (
  breakdown: ReportBreakdown,
  customerId: number
): ReportBreakdown => {
  const breakdowns = new Map<ReportBreakdown, ReportBreakdown>([
    ['customer', 'billing_account'],
    ['project_group', 'project'],
    ['project', 'service'],
  ]);
  customerId !== VOID_ID
    ? breakdowns.set('overall', 'project_group')
    : breakdowns.set('overall', 'customer');

  return breakdowns.get(breakdown) ?? 'overall';
};

export const useConsumptionDrillDown = (
  projectsGroupsState: ProjectGroupsState,
  actions: UseProjectGroupActions
): ((item: string) => void) => {
  const {
    setFilterValue,
    applyFilters,
    appliedFilters,
  } = useFilters<ConsumptionFilters>();
  const { selectedCustomerId } = useStateContext();
  const { breakdown } = appliedFilters;

  const customers = useCustomers(selectedCustomerId);
  const { projectsMap, groupsMap } = useProjectsMaps(projectsGroupsState);

  const debouncedApplyFilters = useMemo(() => {
    return debounce(() => applyFilters(), 100);
  }, [applyFilters]);

  const onDrillDown = useCallback(
    (item?: string) => {
      if (!item?.trim()) {
        return;
      }
      const nextBreakdown = getNextBreakdownValue(
        breakdown,
        selectedCustomerId
      );

      switch (breakdown) {
        case 'project_group':
          if (!groupsMap.has(item)) {
            return;
          }
          const groupId = groupsMap.get(item) as number;
          actions.resetStates();
          actions.toggleGroup(groupId);
          break;
        case 'project':
          if (!projectsMap.has(item)) {
            return;
          }
          const value = projectsMap.get(item) as {
            projectId: number;
            groupId: number;
          };
          actions.resetStates();
          actions.toggleProject(value.groupId, value.projectId);
          break;
        case 'customer':
          const customer = customers.find((customer) => customer.name === item);
          if (!customer) {
            return;
          }
          setFilterValue('customers', [customer.id]);
          break;
        case 'service':
        case 'billing_account':
          return;
      }

      setFilterValue('breakdown', nextBreakdown);
      debouncedApplyFilters();
    },
    [
      breakdown,
      setFilterValue,
      debouncedApplyFilters,
      selectedCustomerId,
      actions,
      groupsMap,
      projectsMap,
      customers,
    ]
  );

  return onDrillDown;
};
