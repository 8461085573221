import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AttachMoney,
  Lock,
  TrendingUp,
  Build,
  EnergySavingsLeaf,
  AccessTime,
} from '@mui/icons-material';
import { useMemo } from 'react';
import { RecommendationCategory } from '../../../domain/types';

export const RecommanderIcon: React.FC<{
  category: RecommendationCategory;
}> = ({ category }) => {
  const classes = useStyles();

  const icon = useMemo(() => {
    switch (category) {
      case 'COST':
        return <AttachMoney fontSize="small" />;
      case 'SECURITY':
        return <Lock fontSize="small" />;
      case 'PERFORMANCE':
        return <TrendingUp fontSize="small" />;
      case 'MANAGEABILITY':
        return <Build fontSize="small" />;
      case 'SUSTAINABILITY':
        return <EnergySavingsLeaf fontSize="small" />;
      case 'RELIABILITY':
        return <AccessTime fontSize="small" />;
      default:
        return <></>;
    }
  }, [category]);

  return <span className={classes.container}>{icon}</span>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
  },
}));
