const percentageOf = (sum: number, total: number) => {
  return (sum / total) * 100;
};

export const useCommitTrackerProgress = ({
  commitPeriodAmount,
  currentCommitConsumptionToDate,
  currentForecast,
}: {
  commitPeriodAmount: number;
  currentCommitConsumptionToDate: number;
  currentForecast: number;
}) => {
  const totalForecastConsumption =
    currentForecast + currentCommitConsumptionToDate;

  const totalAmount =
    totalForecastConsumption > commitPeriodAmount
      ? totalForecastConsumption
      : commitPeriodAmount;

  return {
    consumptionProgress: percentageOf(
      currentCommitConsumptionToDate,
      totalAmount
    ),
    forecastProgress: percentageOf(currentForecast, totalAmount),
    commitTreshold: percentageOf(commitPeriodAmount, totalAmount),
  };
};
