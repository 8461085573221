import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DisclaimerMessage } from '../../../domain/types';
import { useDisclaimerMutation } from './hooks/useDisclaimerMutation';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  message: DisclaimerMessage | null;
  mode: 'create' | 'update';
}

const emptyDisclaimer = {
  title: '',
  description: '',
};

export function DisclaimerManagerDialog({
  open,
  onClose,
  message,
  mode,
}: ComponentProps) {
  const [disclaimer, setDisclaimer] = useState<Partial<DisclaimerMessage>>({
    ...emptyDisclaimer,
  });

  useEffect(() => {
    setDisclaimer(message ?? { ...emptyDisclaimer });
  }, [message]);

  const handleClose = () => {
    setDisclaimer({ ...emptyDisclaimer });
    onClose();
  };

  const mutate = useDisclaimerMutation({
    disclaimer,
    type: mode,
    onSettled: () => handleClose(),
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {mode === 'create'
          ? 'Create Disclaimer Message'
          : 'Update Disclaimer Message'}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="message-title"
            label="Title"
            value={disclaimer.title}
            onChange={(e) =>
              setDisclaimer((prev) => ({ ...prev, title: e.target.value }))
            }
            type="text"
            name="title"
          />
        </FormControl>
        <Box marginTop={1} marginBottom={1} width="100%">
          <FormControl fullWidth>
            <TextField
              id="message-description"
              label="Description"
              rows={4}
              multiline
              value={disclaimer.description}
              onChange={(e) =>
                setDisclaimer((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              type="area"
              name="description"
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="modal_save-btn"
          variant="contained"
          color="primary"
          onClick={() => mutate()}
        >
          {mode === 'create' ? 'Create' : 'Update'}
        </Button>
        <Button data-testid="modal_close-btn" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
