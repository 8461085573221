import { Editable, FormValues, ProjectGroup } from '../../../domain/types';
import { number, object, SchemaOf, string } from 'yup';

export type ProjectGroupFormValues = Editable<
  FormValues<Omit<ProjectGroup, 'projects'>>
>;

export const ProjectGroupSchema: SchemaOf<ProjectGroupFormValues> = object({
  customerId: number().required(),
  name: string().required(),
  type: string().required(),
}).defined();
