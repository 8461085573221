import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';

export const ReportGridContainer = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapperContainer}>
        <div className={classes.gridContainer}>{children}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    wrapperContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      minHeight: '100%',
      paddingBottom: 16,
    },
    gridContainer: {
      background: 'white',
      width: '100%',
      minHeight: '100%',
      flexGrow: 1,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
  })
);
