import { useMemo } from 'react';
import { BurndownFilters, BurndownFiltersDto } from '../../domain/types';

export const useBurndownFilters = (
  filters: BurndownFilters
): BurndownFiltersDto => {
  return useMemo<BurndownFiltersDto>(() => {
    const filtersDto: BurndownFiltersDto = {
      timeInterval: filters.timeInterval,
    };

    // for groups and projects are represented by an array (for compatibility with multi-select)
    // option, but for mutually exclusive, they will have a max length of 1:
    if (filters.projectFilters?.groups.length) {
      filtersDto.projectGroupId = filters.projectFilters.groups[0];
    } else if (filters.projectFilters?.projects.length) {
      filtersDto.projectId = filters.projectFilters.projects[0];
    }

    return filtersDto;
  }, [
    filters.projectFilters?.groups,
    filters.projectFilters?.projects,
    filters.timeInterval,
  ]);
};
