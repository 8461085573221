import { useQuery } from 'react-query';
import { PageHeader } from '../../PageHeader';
import { apiPaths } from '../../../utils/apiPaths';
import { GET_PAGED } from '../../../utils/api';
import { useEffect, useMemo, useState } from 'react';
import { PagedItems, SkuGroupedItem, SkuGroup } from '../../../domain/types';
import { Autocomplete, Box, Button, Checkbox, TextField } from '@mui/material';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useHistory, useParams } from 'react-router-dom';
import { adminPaths } from '../../Routes/AdminRoutes';
import { useSkuAllocation } from '../Skus/hooks/useSkuAllocation';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CheckboxSelector,
  CheckboxSelectorState,
} from '../../CheckboxSelector';
import { isEqual } from '../../../utils/arrayUtils';

const useSkuGroupSku = (skuGroupId: number) => {
  const apiPath = apiPaths.skuGroupsSkus(skuGroupId);

  const { data, isLoading, isError } = useQuery<PagedItems<SkuGroupedItem>>(
    apiPath.key.list(skuGroupId),
    () => GET_PAGED(apiPath.base),
    {
      enabled: skuGroupId !== -1,
    }
  );

  return useMemo(() => {
    return {
      items: data?.items ?? [],
      isLoading,
      isError,
    };
  }, [data, isLoading, isError]);
};

export const useSkuGroups = () => {
  const apiPath = apiPaths.skuGroups();

  const { data, isLoading, isError } = useQuery<PagedItems<SkuGroup>>(
    apiPath.key.list(),
    () => GET_PAGED(apiPath.base)
  );

  return useMemo(() => {
    return {
      items: data?.items ?? [],
      isLoading,
      isError,
    };
  }, [data, isLoading, isError]);
};

export const SkuGroupSku = () => {
  const { items: skuGroups } = useSkuGroups();
  const { groupId } = useParams<{ groupId: string }>();
  const currentGroupId = groupId ? +groupId : -1;

  const { push } = useHistory();
  const { items, isLoading } = useSkuGroupSku(currentGroupId);
  const [pageModel, setPageModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  });
  const [selectedSkus, setSelectedSkus] = useState<number[]>([]);
  const { deallocate } = useSkuAllocation();

  useEffect(() => {
    setPageModel({
      page: 0,
      pageSize: 100,
    });
  }, [items]);

  const onSkuSelection = (skuItem: SkuGroupedItem, checked: boolean) => {
    const selectedSkuId = selectedSkus.find((id) => id === skuItem.id);

    if (!selectedSkuId && checked) {
      setSelectedSkus((previousState) => [...previousState, skuItem.id]);
    } else if (selectedSkuId && !checked) {
      const filteredSelection = selectedSkus.filter(
        (id) => id !== selectedSkuId
      );

      setSelectedSkus(filteredSelection);
    }
  };

  const deAllocate = () => {
    deallocate({ skuIds: selectedSkus, skuGroupId: currentGroupId });
  };

  const selectionState: CheckboxSelectorState = useMemo(() => {
    if (selectedSkus.length === 0) {
      return 'default';
    }

    return isEqual(
      items.map(({ id }) => id),
      selectedSkus
    )
      ? 'checked'
      : 'indeterminate';
  }, [items, selectedSkus]);

  const onSelectionChange = (state: CheckboxSelectorState) => {
    if (state === 'checked' || state === 'indeterminate') {
      setSelectedSkus([]);
    } else if (state === 'default') {
      setSelectedSkus(items.map(({ id }) => id));
    }
  };

  const columns: GridColDef<SkuGroupedItem>[] = [
    {
      field: 'selected',
      headerName: '',
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      width: 40,
      renderHeader: () => {
        if (items.length === 0) {
          return null;
        }

        return (
          <CheckboxSelector
            state={selectionState}
            onChange={onSelectionChange}
          />
        );
      },
      renderCell: ({ row }) => {
        const checked = selectedSkus.some((id) => row.id === id);

        return (
          <Checkbox
            checked={checked}
            size="small"
            color="primary"
            onChange={(event) => onSkuSelection(row, event.target.checked)}
          />
        );
      },
    },
    {
      field: 'skuName',
      headerName: 'SKU Name',
      flex: 1,
    },
    {
      field: 'gcpSkuId',
      headerName: 'GCP SKU Id',
      flex: 1,
    },
    {
      field: 'serviceName',
      headerName: 'Service Name',
      flex: 1,
    },
    {
      field: 'gcpServiceId',
      headerName: 'GCP Service Id',
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Date',
      flex: 1,
    },
  ];

  return (
    <>
      <PageHeader title="Grouped SKUs" />
      <Box display="flex">
        <Autocomplete<number>
          sx={{ width: 300, mr: 2 }}
          options={skuGroups.map(({ id }) => id)}
          value={groupId ? +groupId : null}
          getOptionLabel={(option) =>
            skuGroups.find(({ id }) => id === option)?.groupName ?? ''
          }
          onChange={(_, value: number | null) => {
            push(adminPaths.skuGroupSku(value ?? undefined));
          }}
          renderInput={(params) => (
            <CrudFormControl>
              <TextField {...params} label="SKU Group" />
            </CrudFormControl>
          )}
        />
        <Button
          disabled={selectedSkus.length === 0}
          startIcon={<DeleteIcon fontSize="small" />}
          size="small"
          color="primary"
          onClick={deAllocate}
        >
          Delete
        </Button>
      </Box>

      <GridWidgetContainer>
        <DataGrid
          density="compact"
          loading={isLoading}
          columns={columns}
          rows={items}
          pageSizeOptions={[15, 50, 100]}
          paginationModel={pageModel}
          onPaginationModelChange={(model) => setPageModel(model)}
        />
      </GridWidgetContainer>
    </>
  );
};
