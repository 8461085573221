import { Box, Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { QueryLoader } from './Messaging/QueryStatus';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Customer } from '../domain/types';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export interface SelectedCustomerProps {
  isLoading: boolean;
  selectedCustomer?: Customer;
  showSelector?: boolean;
  onChooseCustomer: () => void;
}

export const SelectedCustomer = ({
  isLoading,
  showSelector,
  selectedCustomer,
  onChooseCustomer,
}: SelectedCustomerProps) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h6"
      className={classes.title}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <img
        className={classes.logo}
        src="/logo_simple.png"
        width={28}
        height={28}
        alt="App Logo"
      />
      <span>Billing Management</span>

      {isLoading ? (
        <QueryLoader loaderColor="#fff" />
      ) : (
        <span className={classes.customerSelectorContainer}>
          {showSelector ? (
            <Button
              color="primary"
              className={classes.customerSelector}
              onClick={onChooseCustomer}
            >
              <AccountBalanceIcon
                fontSize="small"
                className={classes.customerIcon}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
              >
                <span>{selectedCustomer?.name ?? 'Select Customer'}</span>
                <ArrowDropDownIcon />
              </Box>
            </Button>
          ) : (
            <Button
              color="primary"
              className={classes.customerSelector}
              disabled
              style={{ color: 'white' }}
            >
              <AccountBalanceIcon
                fontSize="small"
                className={classes.customerIcon}
              />
              <Typography variant="button">{selectedCustomer?.name}</Typography>
            </Button>
          )}
        </span>
      )}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    customerSelector: {
      color: 'white',
      marginLeft: '1em',
      border: `2px solid ${theme.palette.primary.light}`,
      minWidth: 180,
      display: 'flex',
      justifyContent: 'flex-start',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
    },
    customerSelectorContainer: {
      marginLeft: theme.spacing(1),
    },
    logo: {
      marginRight: theme.spacing(0.5),
    },
    customerIcon: {
      marginRight: theme.spacing(0.5),
      paddingRight: 4,
    },
  })
);
