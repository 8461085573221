import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  RecommanderSortOption,
  RecommenderSort,
} from './RecommenderFiltersProvider';

interface ComponentProps {
  value: RecommenderSort;
  onChange: (value: RecommenderSort) => void;
}

export const RecommenderSortFilter = ({ value, onChange }: ComponentProps) => {
  const classes = useStyles();
  const handleOnSortChange = (params: Partial<RecommenderSort>) => {
    onChange({ ...value, ...params });
  };

  const toggleSortOder = () => {
    const order = value.order === 'asc' ? 'desc' : 'asc';
    onChange({ ...value, order });
  };

  return (
    <Grid
      className={classes.container}
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
    >
      <FormControl className={classes.inputContainer}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={value.field as string}
          onChange={(e) =>
            handleOnSortChange({
              field: e.target.value as RecommanderSortOption,
            })
          }
        >
          <MenuItem value="priority">Priority</MenuItem>
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="cost">Cost</MenuItem>
        </Select>
      </FormControl>
      <IconButton onClick={toggleSortOder} size="large">
        {value.order === 'asc' ? (
          <ArrowUpward fontSize="small" />
        ) : (
          <ArrowDownward fontSize="small" />
        )}
      </IconButton>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    flex: 1,
  },
  container: {
    marginRight: theme.spacing(2),
    width: '100%',
  },
}));
