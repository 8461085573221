import { PagedItems, Service, ServiceType } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CrudHandler } from '../CrudHandler';
import { mockServiceData } from '../data/adminData';
import { rest, RestHandler } from 'msw';
import { generateConsumptionFilterServices } from '../data/reportData';

class Handler extends CrudHandler<Service> {
  private includeAllServices(val: string | null) {
    return val === 'true';
  }
  listHandler(): RestHandler {
    return rest.get(this.endpoint, (req, res, ctx) => {
      const includeAllServices = this.includeAllServices(
        req.url.searchParams.get('include_all')
      );
      const dataSet = this.getDataset(this.getKey(req.params));
      const services = Array.from(dataSet.values());
      const externalServices = services.filter(
        (service) => service.type === ServiceType.THIRD_PARTY
      );
      const data: PagedItems<Service> = {
        items: includeAllServices ? services : externalServices,
        total_items: dataSet.size,
        page: 1,
        total_pages: 1,
      };
      return res(ctx.status(200), ctx.json(data));
    });
  }

  additionalHandlers(): RestHandler[] {
    const pathWithId = apiPaths.servicesFilter(1).base;
    const path = apiPaths.servicesFilter().base;
    const servicesData = generateConsumptionFilterServices(20);

    return [
      rest.post(`/api${pathWithId}`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(servicesData));
      }),
      rest.post(`/api${path}`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(servicesData));
      }),
    ];
  }
}
export const servicesHandler = new Handler(
  apiPaths.services().base,
  mockServiceData
);
