import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { PagedItems, Project } from '../../../domain/types';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useQueryClient } from 'react-query';
import { apiPaths } from '../../../utils/apiPaths';
import { GET_PAGED, POST } from '../../../utils/api';
import { AddBox } from '@mui/icons-material';
import { useStateContext } from '../../../contexts/StateProvider';
import { useListProjectGroups } from '../ServiceApportionments/useListProjectGroups';
import { UngroupedProjectsTable } from './UngroupedProjectsTable';

interface ProjectGroup {
  id?: number;
  key?: number;
  value: string;
}

export const UngroupedProjectsAdmin = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const { showNotification } = useStateContext();
  const { selectedCustomerId } = useStateContext();

  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [
    selectedProjectGroup,
    setSelectedProjectGroup,
  ] = useState<ProjectGroup | null>(null);

  const {
    isLoading: isProjectGroupsLoading,
    projectGroupIdsToName,
  } = useListProjectGroups(selectedCustomerId);

  const {
    data: unassociatedProjects,
    isLoading: isLoadingUnassociatedProjects,
    error: unassociatedProjectsError,
  } = useQuery<PagedItems<Project>>(
    apiPaths.unassociated(selectedCustomerId).key.list(),
    () => GET_PAGED(apiPaths.unassociated(selectedCustomerId).base)
  );

  const {
    isLoading: isAssignProjectsLoading,
    refetch: assignProjects,
  } = useQuery(
    apiPaths.associateProjects(selectedCustomerId, selectedProjectGroup?.id)
      .base,
    () =>
      POST(
        apiPaths.associateProjects(selectedCustomerId, selectedProjectGroup?.id)
          .base,
        {
          ids: selectedProjects.map((proj: Project) => proj.id),
        }
      ),
    {
      enabled: false,
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiPaths.unassociated(selectedCustomerId).key.list()
        );

        showNotification({
          message: 'Projects assigned successfully',
          severity: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: 'Unable to assign projects. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  const isAssignButtonDisabled =
    !selectedCustomerId ||
    !selectedProjectGroup?.id ||
    selectedProjects.length < 1 ||
    isAssignProjectsLoading;

  let projectGroups: ProjectGroup[] = [];

  projectGroupIdsToName.forEach((p, key) => {
    projectGroups.push({
      id: key,
      value: p,
    });
  });

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography variant="h6">Ungrouped Projects</Typography>
        <Box ml={4} className={classes.buttons}>
          <Autocomplete<ProjectGroup>
            className={classes.dropdown}
            options={projectGroups}
            value={selectedProjectGroup}
            onChange={(e, value) => {
              setSelectedProjectGroup(value);
            }}
            getOptionLabel={(item: ProjectGroup) => item?.value ?? ''}
            isOptionEqualToValue={(
              option: ProjectGroup,
              value: ProjectGroup
            ) => {
              return option.id === value.id;
            }}
            renderOption={(props, item) => (
              <MenuItem key={item.id} {...props}>
                {item.value}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Project group" />
            )}
            loading={isProjectGroupsLoading}
          />

          <Button
            size="small"
            color="primary"
            disabled={isAssignButtonDisabled}
            startIcon={<AddBox fontSize="small" />}
            onClick={() => assignProjects()}
          >
            Add to group
          </Button>
        </Box>
      </Toolbar>

      <UngroupedProjectsTable
        projects={unassociatedProjects?.items ?? []}
        loading={isLoadingUnassociatedProjects}
        error={unassociatedProjectsError}
        onSelectionChanged={setSelectedProjects}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ccc',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0.2),
  },
  buttons: {
    display: 'flex',
    'flex-direction': 'horizontal',
    '& .MuiButton-root': {
      marginRight: theme.spacing(2),
    },
  },
  dropdown: {
    width: '250px',
    marginRight: theme.spacing(2),
  },
}));
