import { useQuery } from 'react-query';
import { GET } from '../../../utils/api';
import { PagedItems, Service } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useListUnapportionedServices = (customerId: number) => {
  const serviceApi = apiPaths.billingApportionmentServices(customerId);

  const { data, isLoading, isError } = useQuery<PagedItems<Service>>(
    serviceApi.key.list(),
    () => GET(serviceApi.base)
  );

  const unapportionedServices = useMemo(() => {
    return data?.items ?? [];
  }, [data?.items]);

  return {
    isLoading,
    isError,
    unapportionedServices: unapportionedServices,
  };
};
