import { adminPaths } from '../../Routes/AdminRoutes';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Autocomplete, TextField } from '@mui/material';
import { Route } from 'react-router';
import { CrudContainer } from '../../Crud/CrudContainer';
import { Project } from '../../../domain/types';
import {
  ProjectGroupProjectFormValues,
  ProjectGroupProjectSchema,
} from './ProjectGroupProjectSchema';
import { apiPaths } from '../../../utils/apiPaths';
import { ProjectColumns } from '../Project/ProjectColumns';
import { ProjectGroupProjectForm } from './ProjectGroupProjectForm';
import { useHistory } from 'react-router-dom';
import { useStateContext } from '../../../contexts/StateProvider';

export interface ProjectGroupProjectContainerProps {
  projectGroupIds: number[];
  selectedGroupId: number | null;
  getGroupName: (id: number) => string;
}

export const ProjectGroupProjectContainer = ({
  projectGroupIds,
  selectedGroupId,
  getGroupName,
}: ProjectGroupProjectContainerProps) => {
  const { selectedCustomerId } = useStateContext();
  const { push } = useHistory();

  const customHeader = {
    title: 'Projects in Project Groups',
  };

  const customCreate = {
    title: 'Add Projects to a Project Group',
    buttonText: 'Add',
    action: 'add',
    gerund: 'Adding..',
    past: 'added',
  };

  return (
    <>
      <Autocomplete<number>
        options={projectGroupIds}
        onChange={(e, value) => {
          push(adminPaths.projectGroupProjects(value ?? undefined));
        }}
        value={selectedGroupId}
        getOptionLabel={(id) => getGroupName(id)}
        renderInput={(params) => (
          <CrudFormControl>
            <TextField {...params} label="Project Group" />
          </CrudFormControl>
        )}
      />
      <Route path={adminPaths.projectGroupProjects(':groupId')}>
        {selectedGroupId && (
          <CrudContainer<Project, ProjectGroupProjectFormValues>
            name="Project Group Projects"
            apiPath={apiPaths.projectGroupProjects(
              selectedCustomerId,
              selectedGroupId
            )}
            columns={ProjectColumns}
            defaultSortColumn="gcpProjectId"
            FormComponent={ProjectGroupProjectForm}
            validationSchema={ProjectGroupProjectSchema}
            enableUpdate={false}
            customHeader={customHeader}
            customCreate={customCreate}
          />
        )}
      </Route>
    </>
  );
};
