import { SetReportFilterValue } from '../../../contexts/ReportFiltersProvider';
import { ForecastCommitFilter } from './FilterFields/ForecastCommitFilter';
import { ForecastYearFilter } from './FilterFields/ForecastYearFilter';
import { Commit, CommitTrackerFilters } from '../../../domain/types';
import { ForecastTypeFilter } from './FilterFields/ForecastTypeFilter';
import { ForecastTrackingTypeFilter } from './FilterFields/ForecastTrackingTypeFilter';
import { FilterBar } from '../FilterBar';
import { FilterBarItem } from '../FilterBarItem';

export interface CommitTrackerFiltersProps {
  filters: CommitTrackerFilters;
  setFilterValue: SetReportFilterValue<CommitTrackerFilters>;
  onSubmit: () => void;
  resetFilters: () => void;
  commits: Commit[] | null;
  commitsLoading: boolean;
}

export const CommitTrackerFilterBar = ({
  filters,
  setFilterValue,
  onSubmit,
  resetFilters,
  commits,
  commitsLoading,
}: CommitTrackerFiltersProps) => {
  return (
    <FilterBar onSubmit={onSubmit} onReset={resetFilters}>
      <FilterBarItem>
        <ForecastCommitFilter
          value={filters.selectedCommit}
          commits={commits}
          commitsLoading={commitsLoading}
          onChange={(val) => {
            setFilterValue('selectedCommit', val);
            setFilterValue('selectedYear', val?.commitAmounts[0] ?? null);
            setFilterValue('selectedType', val?.type ?? null);
            setFilterValue('selectedTrackingType', val?.trackingType ?? null);
          }}
        />
      </FilterBarItem>
      <FilterBarItem>
        <ForecastYearFilter
          value={filters.selectedYear ?? filters.selectedCommit}
          selectedCommit={filters.selectedCommit}
          onChange={(val) => setFilterValue('selectedYear', val)}
        />
      </FilterBarItem>
      <FilterBarItem>
        <ForecastTypeFilter
          value={filters.selectedType}
          selectedCommit={filters.selectedCommit}
          onChange={(val) => setFilterValue('selectedType', val)}
        />
      </FilterBarItem>
      <FilterBarItem>
        <ForecastTrackingTypeFilter
          value={filters.selectedTrackingType}
          selectedCommit={filters.selectedCommit}
          onChange={(val) => setFilterValue('selectedTrackingType', val)}
        ></ForecastTrackingTypeFilter>
      </FilterBarItem>
    </FilterBar>
  );
};
