import { format } from 'date-fns';
import {
  AggregatedConsumptionByCost,
  ConsumptionData,
  ConsumptionItemValue,
  ConsumptionValue,
  SummaryTotalInfo,
} from '../domain/types';

/**
 * constant for the max number of items that can display in the consumption chart
 * max currently set to 19 so that the remaining are grouped as one item and they fit into the
 * tooltip as 20 items
 */
const MAX_ITEMS = 19;

type ChartBreakdown =
  | 'project'
  | 'service'
  | 'sku'
  | 'project_group'
  | 'customer'
  | 'billing_account'
  | 'project_label'
  | 'resource_label';

export const formatByBreakdownCostData = (
  rawData: ConsumptionValue[],
  summaryData: ConsumptionValue[],
  breakdown: ChartBreakdown
): AggregatedConsumptionByCost => {
  const items = rawData.map((rawDataItem) => ({
    ...rawDataItem,
    item: rawDataItem[breakdown] ?? 'unknown', // project/service/project_service -> item
  }));

  // currently only projects are reduced to ensure all services can be seen
  // The logic is in place incase the filtered down number is changed to include services
  const OTHER_PROPERTY = `Other ${breakdown}s`;
  const listedBreakdownItems = summaryData.filter(
    (item) => item[breakdown] !== OTHER_PROPERTY
  );
  const formatItems = (groupedItems: Record<string, number>) => {
    let otherValue = 0;
    const formattedItems: Record<string, number> = {};

    // check every grouped item to see if it matches the 'allowed' list
    // if not, add the value to the newly grouped item
    // eg: 'Other projects'
    for (const [itemKey, value] of Object.entries(groupedItems)) {
      if (
        listedBreakdownItems.some(
          (listedItem) => listedItem[breakdown] === itemKey
        )
      ) {
        formattedItems[itemKey] = value >= 0 ? value : 0;
      } else {
        otherValue += groupedItems[itemKey];
        formattedItems[OTHER_PROPERTY] = otherValue;
      }
    }
    return formattedItems;
  };

  /**
   * Creates a map using the date as the key and maps all items to that date.
   * Returns an array totalling the number of dates, with each item as a property
   * on the same index.
   *
   * eg: {name: 'Jun-2021', looker: 12, compute-engine: 10...}
   *
   * Note: when creating the array, the items are formatted to ensure the number is
   * below the MAX_ITEMS limt. Currently only applicable to projects.
   */
  const net = Array.from(
    items.reduce((m, currentItem) => {
      const { name, item, net_cost } = currentItem;
      return m.set(name, { ...m.get(name), ...{ [item]: net_cost } });
    }, new Map()),
    ([key, items]) => {
      const formattedItems = formatItems(items);
      return { name: key, ...formattedItems };
    }
  );
  const gross = Array.from(
    items.reduce((m, currentItem) => {
      const { name, item, gross_cost } = currentItem;
      return m.set(name, { ...m.get(name), ...{ [item]: gross_cost } });
    }, new Map()),
    ([key, items]) => {
      const formattedItems = formatItems(items);
      return { name: key, ...formattedItems };
    }
  );

  return {
    net,
    gross,
  };
};

interface SummarisedDataResponse {
  summaryDataItems: ConsumptionItemValue[];
  groupedSummaryDataItems: ConsumptionItemValue[];
}

interface SummaryItemProperties {
  project: string;
  service: string;
  project_group: string;
}

export const computeOtherItems = (grouped: any, propName: string) => {
  return grouped.slice(MAX_ITEMS).reduce((prev: any, curr: any) => {
    const groupedObjects = { ...prev };

    for (const key in curr) {
      if (Object.prototype.hasOwnProperty.call(curr, key)) {
        if (typeof curr[key] === 'string') {
          groupedObjects[key] = `Other ${propName}s`;
        } else {
          if (groupedObjects[key]) {
            groupedObjects[key] += curr[key];
          } else {
            groupedObjects[key] = curr[key];
          }
        }
      }
    }
    return groupedObjects;
  }, {});
};

export const formatSummaryData = (
  summaryData: ConsumptionValue[],
  breakdown: string
): SummarisedDataResponse => {
  const properties = [
    'project',
    'service',
    'sku',
    'project_group',
    'customer',
    'billing_account',
    'project_label',
    'resource_label',
  ];

  const propName =
    properties.find((prop) =>
      summaryData.some((datum) => datum.hasOwnProperty(prop))
    ) ?? 'unknown';

  let summaryDataItems = summaryData.map((summaryItem) => ({
    ...summaryItem,
    item: summaryItem[propName as keyof SummaryItemProperties] ?? 'unknown', // project/service -> item
  }));

  summaryDataItems.sort(
    ({ net_cost: totalA }, { net_cost: totalB }) => totalB - totalA
  );

  let groupedSummaryDataItems = [...summaryDataItems];

  if (
    groupedSummaryDataItems?.length > MAX_ITEMS &&
    ['project', 'project_group', 'service', 'label', 'sku'].includes(breakdown)
  ) {
    const otherItems = computeOtherItems(groupedSummaryDataItems, propName);

    // since grouped items are summed together, ensure discount percentage is recalculated
    if (otherItems.discount_percentage) {
      otherItems.discount_percentage =
        otherItems.discount / otherItems.gross_cost;
    }

    groupedSummaryDataItems = groupedSummaryDataItems.slice(0, MAX_ITEMS);
    groupedSummaryDataItems.push(otherItems);
  }

  return { summaryDataItems, groupedSummaryDataItems };
};

export const createItemMap = (
  summaryData: ConsumptionItemValue[]
): Record<string, number> => {
  return Object.fromEntries(
    summaryData.map(({ item }, index) => [item, index])
  );
};

export const getSummaryTotals = (
  items: ConsumptionItemValue[]
): SummaryTotalInfo => {
  return items.reduce(
    (acc, { gross_cost, net_cost, discount }) => {
      return {
        net: acc.net + net_cost,
        gross: acc.gross + gross_cost,
        discount: acc.discount + discount,
      };
    },
    {
      net: 0,
      gross: 0,
      discount: 0,
    }
  );
};

export const includeCurrentMonthForecast = (
  rawData: ConsumptionData,
  forecast?: ConsumptionData
): ConsumptionData => {
  const currentDate = format(new Date(), 'MMM-yyyy');
  const forecastItem = forecast?.items?.find((item) => {
    return item.name === currentDate;
  }) ?? {
    name: '',
    net_cost: 0,
    gross_cost: 0,
  };

  return {
    ...rawData,
    items: rawData.items.map((item: ConsumptionValue) =>
      item.name === forecastItem.name
        ? {
            ...item,
            net_forecast: forecastItem.net_cost,
            gross_forecast: forecastItem.gross_cost,
          }
        : item
    ),
  };
};
