import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { VOID_ID } from '../../../../domain/constants';

export const useRecommenderEnabled = (customerId: number) => {
  const apiPath = apiPaths.recommenderOnboarding(customerId);

  return useQuery<boolean>(
    apiPath.key.item(customerId),
    () => GET(apiPath.base),
    {
      enabled: customerId !== VOID_ID,
    }
  );
};
