import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { PagedItems, Project } from '../../../domain/types';
import makeStyles from '@mui/styles/makeStyles';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { SelectionLevel } from './ProjectGroupsFilter';
import { IconButton } from '@mui/material';
import { useStateContext } from '../../../contexts/StateProvider';
import { apiPaths } from '../../../utils/apiPaths';
import { GET } from '../../../utils/api';
import { ProjectGroupTreeItem } from '../../../hooks/projectGroupsSlice';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ProjectGroupProject } from './ProjectGroupProject';
import { ProjectGroupSelector } from './ProjectGroupSelector';
import { UseProjectGroupActions } from '../../../hooks/useProjectGroups';

export interface ProjectGroupProps {
  group: ProjectGroupTreeItem;
  multiSelect: boolean;
  selectionLevel?: SelectionLevel;
  actions: UseProjectGroupActions;
}

export const ProjectGroup = ({
  group: { id, name, expanded, selectionState, projects, loading },
  multiSelect,
  selectionLevel,
  actions,
}: ProjectGroupProps) => {
  const classes = useStyles();
  const { selectedCustomerId } = useStateContext();

  const projectGroupProjectsApi = apiPaths.projectGroupProjects(
    selectedCustomerId,
    id
  );

  const projectsList = useMemo(() => Object.values(projects), [projects]);

  useQuery<PagedItems<Project>>(
    projectGroupProjectsApi.key.list(),
    () => GET(projectGroupProjectsApi.base),
    {
      onSuccess: (projects: PagedItems<Project>) =>
        actions.getProjectsSuccess(id, projects),
      retry: false,
      enabled: loading,
    }
  );

  return (
    <>
      <div className={classes.projectGroup}>
        {multiSelect || selectionLevel === 'group' ? (
          <ProjectGroupSelector
            checked={selectionState === 'selected'}
            indeterminate={selectionState === 'some'}
            onToggle={() => actions.toggleGroup(id)}
            onSelect={() => actions.selectGroup(id)}
            label={name}
            multiSelect={multiSelect}
          />
        ) : (
          <span>{name}</span>
        )}

        {(multiSelect || selectionLevel === 'project') && (
          <IconButton
            size="small"
            component="small"
            color="primary"
            onClick={() => {
              actions.toggleGroupExpandedState(id);
            }}
            className={classes.toggler}
            aria-label={`${expanded ? 'Collapse' : 'Expand'} ${name}`}
          >
            {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
      </div>

      {expanded && (
        <div className={classes.projectContainer}>
          {projectsList.map((project) => (
            <ProjectGroupProject
              key={project.id}
              project={project}
              multiSelect={multiSelect}
              onSelectProject={(projectId) =>
                actions.selectProject(id, projectId)
              }
              onToggleProjectSelection={(projectId) =>
                actions.toggleProject(id, projectId)
              }
              selectionLevel={selectionLevel}
            />
          ))}

          {loading && (
            <div className={classes.loading}>
              <QueryLoader testId={`Loading ${name} projects`} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  projectGroup: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1.4),
    fontSize: 14,
  },
  projectContainer: {
    marginTop: theme.spacing(0.5),
  },
  toggler: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1.4),
  },
  loading: {
    marginLeft: theme.spacing(6),
  },
}));
