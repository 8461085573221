import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ProjectTreeItem } from '../../../hooks/projectGroupsSlice';
import { ProjectGroupSelector } from './ProjectGroupSelector';
import { SelectionLevel } from './ProjectGroupsFilter';

export interface ProjectProps {
  project: ProjectTreeItem;
  multiSelect: boolean;
  onSelectProject: (id: number) => void;
  onToggleProjectSelection: (id: number) => void;
  selectionLevel?: SelectionLevel;
}

export const ProjectGroupProject = ({
  project,
  multiSelect,
  onSelectProject,
  onToggleProjectSelection,
  selectionLevel,
}: ProjectProps) => {
  const classes = useStyles();
  if (!project.visible) {
    return null;
  }
  return (
    <div className={classes.project}>
      {(selectionLevel === 'project' || selectionLevel === 'all') && (
        <ProjectGroupSelector
          checked={project.selected}
          onToggle={() => onToggleProjectSelection(project.id)}
          onSelect={() => onSelectProject(project.id)}
          label={project.gcpProjectId}
          gcpProjectName={project.gcpProjectName}
          multiSelect={multiSelect}
        />
      )}
      {selectionLevel === 'group' && project.gcpProjectName}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  project: {
    fontSize: 14,
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(0.5),
  },
}));
