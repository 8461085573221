import { useMemo } from 'react';
import { ConsumptionFilters, ConsumptionFiltersDto } from '../../domain/types';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../domain/constants';

export const useConsumptionFilters = (
  filters: ConsumptionFilters,
  customerId?: number
): ConsumptionFiltersDto => {
  return useMemo<ConsumptionFiltersDto>(() => {
    const filtersDto: ConsumptionFiltersDto = {
      timeInterval: filters.timeInterval,
      startDate: format(new Date(filters.startDate), API_DATE_FORMAT),
      endDate: format(new Date(filters.endDate), API_DATE_FORMAT),
    };

    // project/group filters are only used for specific customer queries
    if (customerId) {
      if (filters.projectFilters?.groups.length) {
        filtersDto.projectGroupsFilter = {
          ids: filters.projectFilters.groups,
          inclusive: true,
        };
      }
      if (filters.projectFilters?.projects.length) {
        filtersDto.projectsFilter = {
          ids: filters.projectFilters.projects,
          inclusive: true,
        };
      }
    }

    if (filters.timeInterval === 'doy') {
      filtersDto.usage = 'consumption_date';
    } else {
      filtersDto.usage = filters.usage;
    }

    if (
      [
        'service',
        'project',
        'sku',
        'project_group',
        'customer',
        'billing_account',
        'project_label',
        'resource_label',
      ].includes(filters.breakdown)
    ) {
      filtersDto.breakdown = filters.breakdown;
    }

    if (filters?.services.length > 0) {
      filtersDto.serviceFilter = {
        ids: filters.services,
        inclusive: true,
      };
    }

    if (filters?.projectsLabels.length > 0) {
      filtersDto.projectsLabelsFilter = {
        ids: filters.projectsLabels,
        inclusive: true,
      };
    }

    if (filters?.resourcesLabels.length > 0) {
      filtersDto.resourcesLabelsFilter = {
        ids: filters.resourcesLabels,
        inclusive: true,
      };
    }

    if (filters?.customers && filters.customers.length > 0) {
      filtersDto.customerFilter = {
        ids: filters.customers,
        inclusive: true,
      };
    }

    if (filters?.billingAccounts && filters.billingAccounts.length > 0) {
      filtersDto.billingAccountFilter = {
        ids: filters.billingAccounts,
        inclusive: true,
      };
    }

    if (filters?.skus && filters.skus.length > 0) {
      filtersDto.skusFilter = {
        ids: filters.skus,
        inclusive: true,
      };
    }

    if (filters.skusGroups && filters.skusGroups.length > 0) {
      filtersDto.skusGroupsFilter = {
        ids: filters.skusGroups,
        inclusive: true,
      };
    }

    if (filters.breakdown === 'project_label') {
      filtersDto.breakdownKey = filters.projectLabel;
    }

    if (filters?.sdms && filters.sdms.length > 0) {
      filtersDto.sdmsFilter = {
        ids: filters.sdms,
        inclusive: true,
      };
    }

    if (filters.breakdown === 'resource_label') {
      filtersDto.breakdownKey = filters.resourceLabel;
    }

    filtersDto.currency = filters.currency;
    return filtersDto;
  }, [
    customerId,
    filters.endDate,
    filters.breakdown,
    filters.timeInterval,
    filters.usage,
    filters.projectFilters?.groups,
    filters.projectFilters?.projects,
    filters.startDate,
    filters.services,
    filters.projectsLabels,
    filters.resourcesLabels,
    filters.customers,
    filters.billingAccounts,
    filters.currency,
    filters.resourceLabel,
    filters.projectLabel,
    filters.sdms,
    filters.skus,
    filters.skusGroups,
  ]);
};
