import { useStateContext } from '../../../contexts/StateProvider';
import { CrudContainer } from '../../Crud/CrudContainer';
import {
  CommitAmount,
  Commit,
  CommitType,
  CommitTrackingType,
} from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { CommitForm } from './CommitForm';
import Tooltip from '@mui/material/Tooltip';
import {
  CommitSchema,
  CommitFormValues,
  SubmittedCommitFormValues,
} from './CommitSchema';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { MonetaryDisplay, monetaryFormat } from '../../MonetaryDisplay';

const getTotalCommitAmount = ({ row }: GridValueGetterParams) => {
  const commitAmounts = (row.commitAmounts ?? []) as CommitAmount[];
  return commitAmounts.reduce((total, { softAmount = 0, hardAmount = 0 }) => {
    return total + softAmount + hardAmount;
  }, 0);
};

const getCommitAmounts = ({ value, row }: GridValueGetterParams) => {
  const currency = row.currency as string;

  return (value as CommitAmount[])
    .map((amount) => {
      const { hardAmount, softAmount } = amount;

      if (row.type === CommitType.HARD) {
        return monetaryFormat(hardAmount ?? 0, currency);
      }
      if (row.type === CommitType.SOFT) {
        return monetaryFormat(softAmount ?? 0, currency);
      }
      return `HARD: ${monetaryFormat(
        hardAmount ?? 0,
        currency
      )}, SOFT: ${monetaryFormat(softAmount ?? 0, currency)}`;
    })
    .join(', ');
};

export const columns: GridColDef[] = [
  {
    headerName: 'Start Date',
    field: 'startDate',
    flex: 0.5,
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: 0.5,
  },
  {
    headerName: 'Commit Type',
    field: 'type',
    flex: 0.5,
  },
  {
    headerName: 'Commit Tracking Type',
    field: 'trackingType',
    flex: 0.5,
    valueGetter: ({ row }) => {
      return row.trackingType === CommitTrackingType.ANNUAL
        ? 'Annualised'
        : 'Full Term';
    },
  },
  {
    headerName: 'Total Commit Amount',
    field: 'totalCommitAmount',
    flex: 0.5,
    valueGetter: getTotalCommitAmount,
    renderCell: ({ value, row }) => {
      const currency = row.currency as string;
      return <MonetaryDisplay value={value} currency={currency} />;
    },
  },
  {
    headerName: 'Superseded',
    field: 'superseded',
    flex: 0.5,
  },
  {
    headerName: 'Commit Amounts',
    field: 'commitAmounts',
    flex: 1,
    valueGetter: getCommitAmounts,
    renderCell: ({ value }) => {
      return (
        <Tooltip title={value} aria-label={value}>
          <span>{value}</span>
        </Tooltip>
      );
    },
  },
];

export const CommitAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  return (
    <CrudContainer<Commit, CommitFormValues, SubmittedCommitFormValues>
      name="Commits"
      apiPath={apiPaths.commits(selectedCustomerId)}
      columns={columns}
      defaultSortColumn="startDate"
      FormComponent={CommitForm}
      validationSchema={CommitSchema}
      valueMapper={(values) => {
        const { numberOfYears, ...submittedValues } = values;
        return submittedValues;
      }}
    />
  );
};
