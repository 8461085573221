import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { PurchaseOrder } from '../../../domain/types';
import { Controller, useFormContext } from 'react-hook-form';
import { PurchaseOrderFormValues } from './PurchaseOrderSchema';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import { CurrencyCodeField } from '../../Fields/CurrencyCodeField';
import React from 'react';
import { DatePickerField } from '../../Fields/DatePickerField';
import { useStateContext } from '../../../contexts/StateProvider';
import { useListBudgets } from '../BudgetAllocations/useListBudgets';

export const PurchaseOrderForm: CrudFormComponent<PurchaseOrder> = ({
  item,
}) => {
  const {
    register,
    control,
    watch,
    errors,
  } = useFormContext<PurchaseOrderFormValues>();
  const { selectedCustomerId } = useStateContext();

  const budgetId = watch('budgetId', item?.budget?.id);
  const startDate = watch('date');

  const { budgetIds, budgetIdsToName, selectedBudgetId } = useListBudgets(
    selectedCustomerId,
    budgetId
  );

  return (
    <>
      <CrudFormControl error={errors.date}>
        <TextField
          id="poNumber"
          name="poNumber"
          inputRef={register}
          label="Purchase Order Number"
          defaultValue={item?.poNumber}
          error={!!errors.poNumber}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.date}>
        <Controller
          control={control}
          name="date"
          defaultValue={item?.date ?? null}
          render={({ onChange, value }) => (
            <DatePickerField
              id="date"
              value={value ? new Date(value) : null}
              onChange={onChange}
              label="Date"
            />
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.renewalDate}>
        <Controller
          control={control}
          name="renewalDate"
          defaultValue={item?.renewalDate ?? null}
          render={({ onChange, value }) => (
            <DatePickerField
              id="renewal-date"
              value={value ? new Date(value) : null}
              minDate={startDate}
              onChange={onChange}
              label="Renewal date"
            />
          )}
        />
      </CrudFormControl>
      <Box display="flex">
        <Box width={150} mr={2}>
          <CrudFormControl error={errors.currency}>
            <Controller
              control={control}
              name="currency"
              defaultValue={item?.currency ?? null}
              render={(props) => (
                <CurrencyCodeField
                  {...props}
                  label="Currency"
                  error={!!errors.currency}
                />
              )}
            />
          </CrudFormControl>
        </Box>
        <Box flexGrow={1}>
          <CrudFormControl error={errors.amount}>
            <Controller
              name="amount"
              control={control}
              defaultValue={item?.amount ?? ''}
              render={(props) => (
                <MonetaryValueField
                  error={!!errors.amount}
                  label="Amount"
                  id="amount"
                  InputProps={props}
                />
              )}
            />
          </CrudFormControl>
        </Box>
      </Box>
      <CrudFormControl error={errors.budgetId}>
        <Controller
          control={control}
          name="budgetId"
          defaultValue={item?.budget?.id ?? null}
          render={({ onChange }) => (
            <>
              <InputLabel id="budget-label">Budget</InputLabel>
              <Select
                onChange={(event) => {
                  if (event.target.value === '') {
                    onChange(null);
                  } else {
                    onChange(event);
                  }
                }}
                value={selectedBudgetId ?? ''}
                id="budget"
                labelId="budget-label"
              >
                <MenuItem key="None" value="">
                  None
                </MenuItem>
                {budgetIds?.map((id) => (
                  <MenuItem key={id} value={id}>
                    {budgetIdsToName.get(id)}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </CrudFormControl>
    </>
  );
};
