import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMessageDisclaimer } from './hooks/useMessageDisclaimer';
import { Close } from '@mui/icons-material';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { FILTER_BAR_WIDTH } from '../FilterBar.stylesheet';
import { pixelsToNumber } from '../../../utils/stringUtils';

const LINKS_DRAWER_COLLAPSED_WIDTH = 70;
const DISCLAIMER_MESSAGE_KEY = 'disclaimer-message';

interface StoredDisclaimer {
  id: number;
  active: boolean;
  lastUpdate?: string;
}

const emptyStoredDisclaimer: StoredDisclaimer = {
  id: -1,
  active: false,
  lastUpdate: '',
};

export function ConsumptionDisclaimer() {
  const { message } = useMessageDisclaimer();
  const classes = useStyles();
  const [
    storedDisclaimer,
    setStoredDisclaimer,
  ] = useLocalStorage<StoredDisclaimer>(
    DISCLAIMER_MESSAGE_KEY,
    emptyStoredDisclaimer
  );
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    if (!message) {
      return;
    }
    const isPreviousMessage = message.id === storedDisclaimer.id;
    const hasChanges = message.lastUpdate !== storedDisclaimer.lastUpdate;

    if (isPreviousMessage && hasChanges) {
      setStoredDisclaimer({
        id: message.id,
        active: true,
        lastUpdate: message.lastUpdate,
      });
    } else if (message && !isPreviousMessage) {
      setStoredDisclaimer({
        id: message.id,
        active: true,
        lastUpdate: message.lastUpdate,
      });
    }

    setShowDisclaimer(storedDisclaimer.active);
  }, [message, storedDisclaimer, setStoredDisclaimer]);

  if (!message || !showDisclaimer) {
    return null;
  }

  const onCloseHandler = () => {
    setStoredDisclaimer({
      id: message.id,
      active: false,
      lastUpdate: message.lastUpdate,
    });
    setShowDisclaimer(false);
  };

  return (
    <div className={classes.disclaimer}>
      <p className={classes.disclaimerText}>{message?.description}</p>
      <IconButton
        className={classes.closeButton}
        onClick={onCloseHandler}
        size="large"
      >
        <Close />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    bottom: 0,
    left: LINKS_DRAWER_COLLAPSED_WIDTH + pixelsToNumber(theme.spacing(3)),
    right: FILTER_BAR_WIDTH + pixelsToNumber(theme.spacing(4)),
    background: '#f2f2ff',
    color: theme.palette.error.main,
    margin: 0,
    textAlign: 'center',
    position: 'fixed',
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: theme.zIndex.drawer - 1,
  },
  disclaimerText: {
    padding: `0 ${theme.spacing(4)}`,
    textAlign: 'center',
    flex: 1,
  },
  closeButton: {
    alignSelf: 'flex-start',
  },
}));
