import { useLocation, useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export interface SearchParams {
  [key: string]: string[];
}

export const useSearchParams = (): [
  SearchParams,
  (p: SearchParams) => void
] => {
  const location: Location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    const urlSeachParams = new URLSearchParams(location.search);
    const keys = Array.from(urlSeachParams.keys());

    return keys.reduce((memo, key) => {
      memo[key] = urlSeachParams.getAll(key);
      return memo;
    }, {} as SearchParams);
  }, [location.search]);

  const setSearchParam = useCallback(
    (params: SearchParams) => {
      const urlParams = new URLSearchParams();
      Object.keys(params).forEach((key: string) => {
        const values = params[key];

        values.forEach((value: string) => {
          urlParams.set(key, value);
        });
      });

      history.push({
        search: `?${urlParams.toString()}`,
      });
    },
    [history]
  );

  return [searchParams, setSearchParam];
};
