import { Component, ErrorInfo, ReactNode } from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },
    icon: {
      fontSize: 128,
    },
    title: {
      margin: theme.spacing(5, 0),
    },
  });

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
  classes: Record<keyof ReturnType<typeof styles>, string>;
}

class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className={classes.root}>
          <SentimentVeryDissatisfied className={classes.icon} />
          <Typography variant="h4" className={classes.title}>
            Something Went Wrong
          </Typography>
          <Typography gutterBottom>
            An error occurred which prevented this page from being displayed.
          </Typography>
          <Typography>Please try again.</Typography>
        </div>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundary = withStyles(styles)(ErrorBoundaryComponent);
