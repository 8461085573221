import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { SkuItem } from '../../../domain/types';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { useEffect, useMemo, useState } from 'react';
import { Badge, Checkbox, IconButton } from '@mui/material';
import { useSkus } from './hooks/useSkus';
import { SearchModel } from './types';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { styled } from '@mui/system';
import { SkuDetailsModal } from './SkuDetailsModal';
import {
  CheckboxSelector,
  CheckboxSelectorState,
} from '../../CheckboxSelector';
import { isEqual } from '../../../utils/arrayUtils';

interface ComponentProps {
  searchModel: SearchModel;
  onSearchModelChange: (model: SearchModel) => void;
  onSkusSelectionChange: (skusIds: number[]) => void;
  filterUngrouped: boolean;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    right: -5,
    top: 11,
    width: 30,
  },
}));

export const SkuAdminGrid = ({
  searchModel,
  onSearchModelChange,
  onSkusSelectionChange,
  filterUngrouped,
}: ComponentProps) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 100,
  });
  const { items, totalItems, isLoading } = useSkus(
    paginationModel,
    searchModel,
    filterUngrouped
  );

  const [selectedSkuItem, setSelectedSkuItem] = useState<SkuItem | null>(null);
  const [skuDetailsOpen, setSkuDetailsOpen] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState<number[]>([]);

  const onSkuDetailsOpen = (skuItem: SkuItem) => {
    setSelectedSkuItem(skuItem);
    setSkuDetailsOpen(true);
  };

  const onSkuDetailsClose = () => {
    setSkuDetailsOpen(false);
    setSelectedSkuItem(null);
  };

  const setSkuSelection = (skuItem: SkuItem, checked: boolean) => {
    const selectedSkuId = selectedSkus.find((id) => id === skuItem.id);

    if (!selectedSkuId && checked) {
      setSelectedSkus((previousState) => [...previousState, skuItem.id]);
    } else if (selectedSkuId && !checked) {
      const filteredSelection = selectedSkus.filter(
        (id) => id !== selectedSkuId
      );

      setSelectedSkus(filteredSelection);
    }
  };

  useEffect(() => {
    onSkusSelectionChange(selectedSkus);
  }, [selectedSkus, onSkusSelectionChange]);

  const selectionState: CheckboxSelectorState = useMemo(() => {
    if (selectedSkus.length === 0) {
      return 'default';
    }

    return isEqual(
      items.map(({ id }) => id),
      selectedSkus
    )
      ? 'checked'
      : 'indeterminate';
  }, [items, selectedSkus]);

  const onSelectionChange = (state: CheckboxSelectorState) => {
    if (state === 'checked' || state === 'indeterminate') {
      setSelectedSkus([]);
    } else if (state === 'default') {
      setSelectedSkus(items.map(({ id }) => id));
    }
  };

  const columns: GridColDef<SkuItem>[] = [
    {
      field: 'selected',
      width: 40,
      disableColumnMenu: true,
      headerName: '',
      filterable: false,
      sortable: false,
      renderHeader: () => {
        if (items.length === 0) {
          return null;
        }

        return (
          <CheckboxSelector
            state={selectionState}
            onChange={onSelectionChange}
          />
        );
      },
      renderCell: ({ row }) => {
        const checked = selectedSkus.some((id) => id === row.id);

        return (
          <Checkbox
            checked={checked}
            size="small"
            color="primary"
            onChange={(e) => {
              setSkuSelection(row, e.target.checked);
            }}
          />
        );
      },
    },
    {
      field: 'skuName',
      headerName: 'SKU Name',
      flex: 1,
    },
    {
      field: 'gcpSkuId',
      headerName: 'GCP SKU Id',
      flex: 1,
    },
    {
      field: 'serviceName',
      headerName: 'Service Name',
      flex: 1,
    },
    {
      field: 'gcpServiceId',
      headerName: 'GCP Service Id',
      flex: 1,
    },
    {
      field: 'groups',
      headerName: 'Groups',
      width: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton
            aria-label="sku-groups-button"
            size="small"
            onClick={() => onSkuDetailsOpen(row)}
          >
            <StyledBadge color="primary" badgeContent={row.groupsCount}>
              <BrandingWatermarkIcon fontSize="small" />
            </StyledBadge>
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <GridWidgetContainer>
        <DataGrid<SkuItem>
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          loading={isLoading}
          columns={columns}
          rows={items}
          rowCount={totalItems}
          density="compact"
          onFilterModelChange={(model) => {
            const item = model.items[0];
            const { field, value } = item;
            if (value) {
              onSearchModelChange({
                field: field,
                search: value,
              });
            } else {
              onSearchModelChange({
                field: '',
                search: '',
              });
            }
          }}
          onPaginationModelChange={(model) => setPaginationModel(model)}
          paginationModel={paginationModel}
          pageSizeOptions={[15, 50, 100]}
          slots={{
            loadingOverlay: () => (
              <GridOverlay>
                <QueryLoader />
              </GridOverlay>
            ),
          }}
        />
      </GridWidgetContainer>
      <SkuDetailsModal
        open={skuDetailsOpen}
        skuItem={selectedSkuItem}
        onClose={onSkuDetailsClose}
      />
    </>
  );
};
