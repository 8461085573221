import React, { ReactNode, useEffect, useState } from 'react';
import {
  InputAdornment,
  InputLabel,
  Popover,
  TextField,
  Theme,
} from '@mui/material';
import ArrowDropdown from '@mui/icons-material/ArrowDropDown';
import { DropdownFilterListBox } from './DropdownFilterListBox';
import { DropdownFilterListBoxBar } from './DropdownFilterListBoxBar';
import { makeStyles } from '@mui/styles';
import { useFilterSelection, SelectionState } from './useFilterSelection';
import { union } from '../../../../../utils/arrayUtils';
import { QueryLoader } from '../../../../Messaging/QueryStatus';
import { useSearchValue } from './useSearchValue';

interface DropdownFilterProps<T, K> {
  value: K[];
  onChange: (val: K[]) => void;
  title: string;
  items: T[];
  isLoading: boolean;
  getItemKey: (item: T) => K;
  getItemLabel: (item: T) => string;
  itemLabel: string;
  testId?: string;
  icon?: ReactNode;
  singleItem?: boolean;
  placeholder?: string;
}

export const DropdownFilter = <T, K>({
  value: selectedKeys,
  title,
  onChange,
  items,
  isLoading,
  getItemKey,
  getItemLabel,
  itemLabel,
  testId,
  icon,
  singleItem,
  placeholder,
}: DropdownFilterProps<T, K>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState<T[]>([]);

  const selection: SelectionState = useFilterSelection<T, K>(
    selectedKeys,
    filteredItems,
    items,
    getItemKey
  );

  const inputValue: string = useSearchValue<T, K>(
    selectedKeys,
    filteredItems,
    items,
    itemLabel,
    singleItem
  );

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchTerm('');
    setAnchorEl(null);
  };

  const handleToggle = () => {
    if (filteredItems.length === 0) {
      return;
    } else if (
      selection === SelectionState.Some ||
      selection === SelectionState.Selected
    ) {
      const excludedItems = [...selectedKeys].filter(
        (key: K) =>
          filteredItems.findIndex((item: T) => {
            return getItemKey(item) === key;
          }) === -1
      );
      onChange(excludedItems);
    } else if (filteredItems.length !== items.length) {
      const partialSelectedKeys: K[] = union<K>(
        filteredItems.map((item: T) => getItemKey(item)),
        [...selectedKeys]
      );
      onChange(partialSelectedKeys);
    } else {
      onChange(items.map((item: T) => getItemKey(item)));
    }
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const newItems = items.filter((item: T) =>
      getItemLabel(item)
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase())
    );
    setFilteredItems(newItems);
  }, [items, searchTerm, getItemLabel]);

  const onChangeHandler = (value: K[]) => {
    onChange(value);
    if (singleItem) {
      handleClose();
    }
  };

  return (
    <div data-testid={testId}>
      <InputLabel shrink>{title}</InputLabel>
      {isLoading && (
        <div>
          <QueryLoader />
        </div>
      )}
      {!isLoading && (
        <>
          <TextField
            value={inputValue}
            className={classes.popInput}
            size="medium"
            onClick={handleClick}
            placeholder={placeholder ?? 'Select item'}
            InputProps={{
              startAdornment: icon && (
                <InputAdornment position="start" className={classes.inputIcon}>
                  {icon}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropdown />
                </InputAdornment>
              ),
            }}
          />
          <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
            <DropdownFilterListBoxBar
              searchTerm={searchTerm}
              onSearch={setSearchTerm}
              onCheckboxClick={handleToggle}
              selection={selection}
              singleItem={singleItem}
            />
            <DropdownFilterListBox<T, K>
              singleItem={singleItem}
              filteredItems={filteredItems}
              selectedKeys={selectedKeys}
              getItemKey={getItemKey}
              getItemLabel={getItemLabel}
              onChange={onChangeHandler}
            />
          </Popover>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  popInput: {
    width: '100%',
    caretColor: 'transparent',
  },
  searchField: {
    width: '100%',
  },
  inputIcon: {
    color: theme.palette.grey[500],
  },
}));
