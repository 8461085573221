import {
  Autocomplete,
  Box,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { RecommenderSearch } from '../../Dashboard/Recommender/RecommenderSearch';
import { SearchModel } from './types';
import { WidgetContainer } from '../../WidgetContainer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styled } from '@mui/system';

interface ComponentProps {
  onSearchModelChange: (model: SearchModel) => void;
  searchModel: SearchModel;
  filterUngrouped: boolean;
  onFilterUngrouped: (checked: boolean) => void;
}

const fieldOptions = [
  { value: 'skuName', label: 'SKU Name' },
  { value: 'gcpSkuId', label: 'GCP SKU Id' },
  { value: 'serviceName', label: 'Service Name' },
  { value: 'gcpServiceId', label: 'GCP Service Id' },
];

const StyledFilterAltIcon = styled(FilterAltIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const SkusAdminFiltersPanel = ({
  onSearchModelChange,
  searchModel,
  filterUngrouped,
  onFilterUngrouped,
}: ComponentProps) => {
  return (
    <WidgetContainer style={{ marginRight: 32 }}>
      <Box pt={3} pb={2} pr={2} pl={2} display="flex" alignItems="flex-end">
        <StyledFilterAltIcon sx={{ mr: 1, mb: 1 }} />
        <Box display="flex" alignItems="flex-end">
          <Box display="flex" alignItems="flex-end">
            <Autocomplete
              sx={{ width: 170, mr: 2 }}
              value={searchModel.field}
              options={fieldOptions.map((item) => item.value)}
              onChange={(_, value) =>
                onSearchModelChange({ ...searchModel, field: value ?? '' })
              }
              getOptionLabel={(value) =>
                fieldOptions.find((item) => item.value === value)?.label ?? ''
              }
              renderInput={(params) => <TextField {...params} label="Field" />}
            />
            <RecommenderSearch
              value={searchModel.search}
              onSearch={(search) =>
                onSearchModelChange({ ...searchModel, search })
              }
            />
          </Box>

          <FormControlLabel
            sx={{ ml: 2 }}
            control={<Switch />}
            label="Ungrouped SKUs"
            aria-label="Ungrouped SKUs"
            value={filterUngrouped}
            color="primary"
            onChange={(_, checked) => onFilterUngrouped(checked)}
          />
        </Box>
      </Box>
    </WidgetContainer>
  );
};
