import { BudgetAllocation, PagedItems } from '../../domain/types';
import { CrudHandler } from '../CrudHandler';
import { apiPaths } from '../../utils/apiPaths';
import { RequestParams, rest } from 'msw';
import { mockBudgetAllocationsData } from '../data/adminData';

class Handler extends CrudHandler<BudgetAllocation> {
  getKey(params: RequestParams) {
    const { customerId, budgetId } = params;
    return `${customerId}_${budgetId}`;
  }

  additionalHandlers() {
    return [
      rest.get(
        `/api${
          apiPaths.budgetAllocations(':customerId' as any, ':budgetId' as any)
            .available
        }`,
        async (req, res, ctx) => {
          const items = Array.from(
            this.getDataset(this.getKey(req.params)).values()
          );
          const availableItems = mockBudgetAllocationsData.filter(
            (budgetAllocation) =>
              !items.map(({ id }) => id).includes(budgetAllocation.id)
          );
          const data: PagedItems<BudgetAllocation> = {
            items: availableItems,
            total_items: availableItems.length,
            page: 1,
            total_pages: 1,
          };
          return res(ctx.status(200), ctx.json(data));
        }
      ),
      rest.get(
        `/api${
          apiPaths.budgetAllocations(':customerId' as any, ':budgetId' as any)
            .available
        }`,
        async (req, res, ctx) => {
          const items = Array.from(
            this.getDataset(this.getKey(req.params)).values()
          );
          const availableItems = mockBudgetAllocationsData.filter(
            (budgetAllocation) =>
              !items.map(({ id }) => id).includes(budgetAllocation.id)
          );
          const data: PagedItems<BudgetAllocation> = {
            items: availableItems,
            total_items: availableItems.length,
            page: 1,
            total_pages: 1,
          };
          return res(ctx.status(200), ctx.json(data));
        }
      ),
    ];
  }
}

export const budgetAllocationsHandler = new Handler(
  apiPaths.budgetAllocations(':customerId' as any, ':budgetId' as any).base,
  {
    '1_1': [mockBudgetAllocationsData[0], mockBudgetAllocationsData[1]],
  }
);
