import React from 'react';
import ReactDOM from 'react-dom';
// i18n import needs to come before anything else
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthProvider';
import { StateProvider } from './contexts/StateProvider';
import { QueryClientProvider } from 'react-query';
import './index.css';
import { createQueryClient } from './createQueryClient';

if (process.env.REACT_APP_MOCK_API === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const queryClient = createQueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <StateProvider>
          <App />
        </StateProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
