import { useMutation, useQueryClient } from 'react-query';
import { DELETE, PATCH } from '../../../utils/api';
import { ApiPath } from '../../../utils/apiPaths';
import { useStateContext } from '../../../contexts/StateProvider';
import { ApiErrorResponse } from '../../../domain/types';
import { CustomCrudAction } from '../CrudContainer';

interface Response {
  isMutating: boolean;
  confirmDelete: (id: number) => void;
}

interface Props {
  apiPath: ApiPath;
  onSuccess: () => void;
  customDelete?: CustomCrudAction;
}
export const useCrudDelete = ({
  apiPath,
  onSuccess,
  customDelete,
}: Props): Response => {
  const { showNotification } = useStateContext();
  const queryClient = useQueryClient();

  const past = customDelete?.past || 'deleted';
  const gerund = customDelete?.gerund || 'deleting';

  let VERB = DELETE;
  let PATH = apiPath.item;

  if (customDelete?.verb === 'PATCH') {
    VERB = PATCH;
    PATH = apiPath.unmap || apiPath.item;
  }

  const { mutate, isLoading } = useMutation<unknown, ApiErrorResponse, number>(
    (id: number) => VERB(PATH(id)),
    {
      onError: (error) =>
        showNotification({
          message:
            error.response?.data.detail ??
            `There was an error ${gerund} that item. Please try again.`,
          severity: 'error',
        }),
      onSuccess: () => {
        showNotification({
          message: `Item ${past} successfully`,
          severity: 'success',
        });
        queryClient.invalidateQueries(apiPath.key.list());
        onSuccess();
      },
    }
  );

  return {
    isMutating: isLoading,
    confirmDelete: mutate,
  };
};
