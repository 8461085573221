import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { Box, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { POST } from '../../utils/api';
import {
  BurndownFiltersDto,
  CommitTrackerFiltersDto,
  ConsumptionFiltersDto,
} from '../../domain/types';
import { ExportApiPath } from '../../utils/apiPaths';
import { useStateContext } from '../../contexts/StateProvider';
import { API_DATE_FORMAT } from '../../domain/constants';

interface ReportExportManagerProps {
  path: ExportApiPath;
  filters?:
    | ConsumptionFiltersDto
    | CommitTrackerFiltersDto
    | BurndownFiltersDto;
  type: string;
}

export const ReportExportManager = ({
  path,
  filters,
  type,
}: ReportExportManagerProps) => {
  const { selectedCustomerName, showNotification } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: onDownload } = useQuery<string>(
    path.key.list(),
    () => POST(path.base, filters || {}),
    {
      onSuccess: (csvData: string) => {
        setIsLoading(false);
        const blob = new Blob([csvData], {
          type: 'plain/text',
          endings: 'native',
        });

        const date = format(new Date(), API_DATE_FORMAT);

        const filename = `${selectedCustomerName}-billing-${type}-export-${date}.csv`;

        saveAs(blob, filename);

        showNotification({
          message: 'CSV exported successfully',
          severity: 'success',
        });
      },
      onError: () => {
        setIsLoading(false);
        showNotification({
          message: 'Unable to create CSV. Please try again.',
          severity: 'error',
        });
      },
      enabled: false,
      retry: false,
    }
  );

  return (
    <Button
      disabled={isLoading}
      size="small"
      onClick={() => {
        setIsLoading(true);
        onDownload();
      }}
    >
      <CloudDownloadIcon color="primary" />
      <Box ml={1} component="span">
        Export
      </Box>
    </Button>
  );
};
