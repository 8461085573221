import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { Project } from '../../../domain/types';
import { useStateContext } from '../../../contexts/StateProvider';
import { ProjectForm } from './ProjectForm';
import { ProjectFormValues, ProjectSchema } from './ProjectSchema';
import { ProjectColumns } from './ProjectColumns';

export const ProjectAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  return (
    <CrudContainer<Project, ProjectFormValues>
      name="Project"
      apiPath={apiPaths.project(selectedCustomerId)}
      columns={ProjectColumns}
      defaultSortColumn="gcpProjectId"
      FormComponent={ProjectForm}
      validationSchema={ProjectSchema}
      enableCreate={false}
      enableDelete={false}
    />
  );
};
