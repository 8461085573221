import { BQUsageFilters } from '../../domain/types';
import { ChartContainer } from './ChartContainer';
import { ChartLoader } from './ChartLoader';
import { useMonetaryFormat } from '../MonetaryDisplay';
import { Y_AXIS_WIDTH } from '../../domain/constants';
import { Tooltip, XAxis, YAxis, Bar, Area } from 'recharts';
import { useTheme } from '@mui/material';
import { getResolvedArrayValue } from '../../utils/arrayUtils';
import { useChartSeriesColors } from '../../theme/useChartSeriesColors';
import { useFilters } from '../../contexts/ReportFiltersProvider';
import { useCallback, useState } from 'react';
import { ChartItemsTooltip } from './ChartItemsTooltip';
import { useChartContainerContext } from './ChartProvider';
import makeStyles from '@mui/styles/makeStyles';
import { useBarGap } from './useBarGap';
import clsx from 'clsx';

interface ComponentProps {
  currency: string;
  isLoading: boolean;
  isError: boolean;
  itemsMap: Record<string, number>;
  formattedData: Record<string, number>[];
  chartItems: any[];
}

export const BigQueryUsageChart = ({
  currency,
  isLoading,
  isError,
  itemsMap,
  formattedData,
  chartItems,
}: ComponentProps) => {
  const theme = useTheme();
  const yFormatter = useMonetaryFormat(currency, true);
  const colors = useChartSeriesColors();
  const { appliedFilters } = useFilters<BQUsageFilters>();
  const { timeInterval } = appliedFilters;
  const { setActiveHoverElement } = useChartContainerContext();
  const [isHoverActive, setIsHoverActive] = useState(false);
  const classes = useStyles();
  const formatter = useMonetaryFormat(currency);

  const renderCustomTooltip = useCallback(
    (props: any) => {
      return (
        <ChartItemsTooltip
          payload={props.payload}
          customFormatter={formatter}
        />
      );
    },
    [formatter]
  );
  const barIterator = useBarGap();
  return (
    <ChartLoader<any[]>
      dataReady={(data) => data.length > 0}
      onData={(data: any[]) => (
        <>
          <ChartContainer data={data}>
            <XAxis dataKey="name" tick={{ fontSize: 12, dy: 5 }} />
            <YAxis tickFormatter={yFormatter} width={Y_AXIS_WIDTH} />
            <Tooltip content={renderCustomTooltip} />
            {chartItems.map(({ item }, index) => {
              const color =
                getResolvedArrayValue(colors, itemsMap[item]) ??
                theme.palette.primary.main;
              if (timeInterval === 'doy') {
                return (
                  <Area
                    style={{ cursor: 'pointer' }}
                    key={`${item}-${index}`}
                    dataKey={item}
                    stroke={color}
                    fill={color}
                    stackId={1}
                    strokeWidth={0}
                    activeDot={false}
                    className={isHoverActive ? classes.chartItem : ''}
                    onMouseEnter={() => {
                      setIsHoverActive(true);
                      setActiveHoverElement(item);
                    }}
                    onMouseLeave={() => {
                      setIsHoverActive(false);
                      setActiveHoverElement(undefined);
                    }}
                  />
                );
              }
              return (
                <Bar
                  isAnimationActive={false}
                  key={`${item}-${index}`}
                  dataKey={item}
                  stroke={color}
                  fill={color}
                  stackId={1}
                  className={clsx(
                    'item_bar',
                    `item__${item}`,
                    isHoverActive && classes['chartItem']
                  )}
                  onMouseEnter={() => {
                    setIsHoverActive(true);
                    setActiveHoverElement(item);
                  }}
                  onMouseLeave={() => {
                    setIsHoverActive(false);
                    setActiveHoverElement(undefined);
                  }}
                  shape={(props: any) => {
                    const { fill, x, y, width, height } = props;
                    const gap = barIterator({
                      width,
                      x,
                    });

                    return (
                      <>
                        <rect
                          x={x - gap.x}
                          y={y}
                          height={height}
                          width={width + gap.width}
                          fillOpacity={0}
                        />
                        <rect
                          className="inner-bar"
                          style={{ cursor: 'pointer' }}
                          x={x}
                          y={y}
                          width={width}
                          height={height}
                          fill={fill}
                        />
                      </>
                    );
                  }}
                />
              );
            })}
          </ChartContainer>
        </>
      )}
      data={formattedData}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

const useStyles = makeStyles({
  chartItem: {
    opacity: 0.7,
    userSelect: 'none',
    '&:hover': {
      opacity: 1,
    },
  },
});
