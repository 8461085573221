import { useQuery } from 'react-query';
import { RemainingBudget } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { GET } from '../../../utils/api';

export const useGetRemainingBudget = (customerId: number, budgetId: number) => {
  const { data, isLoading, isError } = useQuery<RemainingBudget>(
    apiPaths.budget(customerId).remainingBudget(budgetId),
    () => GET(apiPaths.budget(customerId).remainingBudget(budgetId))
  );
  return {
    data,
    isLoading: isLoading,
    isError: isError,
  };
};
