import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { Service } from '../../../domain/types';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { ServiceFormValues } from './ServiceSchema';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';

export const ServiceForm: CrudFormComponent<Service> = ({ item }) => {
  const { register, control, errors } = useFormContext<ServiceFormValues>();

  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  return (
    <>
      <CrudFormControl error={errors.gcp_service_id}>
        <TextField
          id="gcp_service_id"
          name="gcp_service_id"
          inputRef={register}
          label="Service ID"
          defaultValue={item?.gcp_service_id}
          error={!!errors.gcp_service_id}
          disabled={true}
        ></TextField>
      </CrudFormControl>

      <CrudFormControl error={errors.name}>
        <TextField
          id="name"
          name="name"
          inputRef={register}
          label="Service Name"
          defaultValue={item?.name}
          error={!!errors.name}
          InputProps={{
            readOnly: isCustomerSelected,
            disabled: isCustomerSelected,
          }}
        ></TextField>
      </CrudFormControl>

      {isCustomerSelected && (
        <CrudFormControl error={errors.commitIncluded}>
          <FormControlLabel
            label="Counts towards commit"
            control={
              <Controller
                control={control}
                inputRef={register}
                name="commitIncluded"
                defaultValue={item?.commitIncluded}
                render={({ onChange, value }) => (
                  <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            }
          />
        </CrudFormControl>
      )}
    </>
  );
};
