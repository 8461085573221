import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { Control } from 'react-hook-form/dist/types/form';
import { CommitType } from '../../../domain/types';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import { CommitFormValues } from './CommitSchema';

interface CommitAmountProps {
  width: 6 | 12;
  currency: string;
  error?: FieldError;
  fieldName: string;
  defaultValue: number | '';
  label: string;
  control: Control<CommitFormValues>;
  commitType: CommitType.HARD | CommitType.SOFT;
}

export const CommitAmount = ({
  width,
  currency,
  error,
  fieldName,
  defaultValue,
  label,
  control,
  commitType,
}: CommitAmountProps) => {
  const withPrefix = (name: string) =>
    `${fieldName}.${commitType === CommitType.HARD ? 'hard' : 'soft'}${name}`;
  return (
    <Grid item xs={width}>
      <CrudFormControl error={error}>
        <Controller
          id={withPrefix('Amount')}
          name={withPrefix('Amount')}
          control={control}
          defaultValue={defaultValue}
          render={({ ...props }) => (
            <MonetaryValueField
              error={!!error}
              label={label}
              id={withPrefix('-amount-field')}
              currency={currency}
              InputProps={props}
            />
          )}
        />
      </CrudFormControl>
    </Grid>
  );
};
