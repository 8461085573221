import { Button, FormControl, Grid, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useBQOnboarding } from './useBQOnboarding';

export const RecommenderOnboardingForm = () => {
  const classes = useStyles();
  const [gcpProjectId, setGcpProjectId] = useState('');
  const { data, isLoading, mutate } = useBQOnboarding();

  useEffect(() => {
    setGcpProjectId(data.projectId);
  }, [data]);

  const handleOnFinishClick = () => {
    mutate(gcpProjectId);
  };

  const handleOnInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setGcpProjectId(value);
  };

  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        className={classes.onboardingFromInputs}
      >
        <div className={classes.onboardingInputContainer}>
          <span>GCP Project ID:</span>
          <FormControl className={classes.onboardingInput}>
            <TextField
              disabled={isLoading}
              name="gcp-project-id"
              placeholder="Type GCP Project ID..."
              data-test-id="gcp-project-id"
              value={gcpProjectId}
              onChange={handleOnInputChange}
            />
          </FormControl>
        </div>
        <Grid container className={classes.onboardingFromSubmit}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleOnFinishClick}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  onboardingFromInputs: {
    marginTop: theme.spacing(2),
    width: 700,
  },
  onboardingFromSubmit: {
    marginTop: theme.spacing(2),
  },
  onboardingDisclaimer: {
    padding: theme.spacing(2),
  },
  onboardingDisclaimerContainer: {
    padding: theme.spacing(1),
  },
  onboardingInputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  onboardingInput: {
    flex: 1,
    paddingLeft: theme.spacing(1),
  },
  linkContent: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));
