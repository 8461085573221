import { useMemo } from 'react';
import { BQUsageFilters, BQUsageFiltersDTO } from '../../../../domain/types';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../domain/constants';

interface HookProps {
  filters: BQUsageFilters;
  summary: boolean;
}

export const useBigQueryUsageFilters = ({
  filters,
  summary,
}: HookProps): BQUsageFiltersDTO => {
  return useMemo(() => {
    const filtersDto: BQUsageFiltersDTO = {
      startDate: format(new Date(filters.startDate), API_DATE_FORMAT),
      endDate: format(new Date(filters.endDate), API_DATE_FORMAT),
      timeInterval: filters.timeInterval,
      breakdown: filters.breakdown,
    };

    if (summary) {
      filtersDto.summary = summary;
    }

    if (filters.projectFilters?.groups.length) {
      filtersDto.projectGroupsFilter = {
        ids: filters.projectFilters.groups,
        inclusive: true,
      };
    }
    if (filters.projectFilters?.projects.length) {
      filtersDto.projectsFilter = {
        ids: filters.projectFilters.projects,
        inclusive: true,
      };
    }

    if (filters.userEmailFilters.length) {
      filtersDto.userEmailFilter = filters.userEmailFilters;
    }

    return filtersDto;
  }, [filters, summary]);
};
