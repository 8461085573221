import { add, startOfDay, sub } from 'date-fns';
import { useMemo } from 'react';
import { BQUsageFilters } from '../../../../domain/types';
import { BQ_USAGE_REPORT_KEY } from '../../../../domain/constants';
import { useStateContext } from '../../../../contexts/StateProvider';
import { fromLocalStorage } from '../../../../utils/localStorage';

const getDefaultTimeInterval = () => {
  const endDate = startOfDay(new Date());
  const startDate = add(sub(endDate, { years: 1 }), { days: 1 });
  return { startDate, endDate };
};

export const useDefaultFiltersValue = (): {
  initialFilters: BQUsageFilters;
  defaultFilters: BQUsageFilters;
} => {
  const { selectedCustomerId } = useStateContext();

  const initialFilters = useMemo(() => {
    const { startDate, endDate } = getDefaultTimeInterval();

    return {
      startDate,
      endDate,
      breakdown: 'project',
      timeInterval: 'doy',
      projectFilters: {
        groups: [],
        projects: [],
      },
      selectedCustomerId,
      userEmailFilters: [],
    } as BQUsageFilters;
  }, [selectedCustomerId]);

  const defaultFilters = useMemo(() => {
    const filters =
      fromLocalStorage(BQ_USAGE_REPORT_KEY, selectedCustomerId) ||
      initialFilters;

    filters.startDate = new Date(filters.startDate);
    filters.endDate = new Date(filters.endDate);

    return filters;
  }, [initialFilters, selectedCustomerId]);

  return {
    initialFilters,
    defaultFilters,
  };
};
