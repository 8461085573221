import { BQUsageValue } from '../../../../domain/types';
import { computeOtherItems } from '../../../../utils/consumptionUtils';

const MAX_ITEMS = 19;

export function formatSummaryData(items: BQUsageValue[], breakdown: string) {
  let summaryDataItems = items.map((item) => ({
    ...item,
    item: item[breakdown as keyof BQUsageValue],
  }));

  summaryDataItems.sort(
    ({ indicative_cost: first }, { indicative_cost: second }) => second - first
  );

  let groupedSummaryDataItems = [...summaryDataItems];
  if (groupedSummaryDataItems.length > MAX_ITEMS) {
    const otherItems = computeOtherItems(groupedSummaryDataItems, breakdown);
    groupedSummaryDataItems = groupedSummaryDataItems.slice(0, MAX_ITEMS);
    groupedSummaryDataItems.push(otherItems);
  }

  return { summaryDataItems, groupedSummaryDataItems };
}
