import { boolean, object, SchemaOf, string } from 'yup';
import { Editable, FormValues, Service } from '../../../domain/types';

export type ServiceFormValues = Editable<FormValues<Omit<Service, 'type'>>>;

export const getServiceSchema = (isCustomerSelected: boolean) => {
  return isCustomerSelected
    ? object({
        commitIncluded: boolean().required(),
      })
    : object({
        name: string().required(),
      });
};

export const ServiceSchema: SchemaOf<ServiceFormValues> = object({
  name: string().required(),
  commitIncluded: boolean().notRequired(),
  gcp_service_id: string(),
});
