import { dashboardRoutes } from '../components/Routes/useDashboardRoutes';
import {
  customerSpecificRoutes,
  nonCustomerRoutes,
} from '../components/Routes/AdminRoutes';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { RouteDetail } from '../domain/types';

export type RouteCategory = 'dashboard' | 'customer' | 'non-customer';

const assignedRoutes = (
  routesMap: Map<string, RouteCategory>,
  routes: RouteDetail[],
  category: RouteCategory
) => {
  routes.forEach((route) => {
    const path = route.link ?? route.path;
    routesMap.set(path, category);
  });
};

const removePathParams = (pathName: string) => {
  const isEndingWithNumber = pathName.match(/(\d+)$/gm);
  return isEndingWithNumber ? pathName.replace(/\/(\d+)$/gm, '') : pathName;
};

export const useGetCurrentRoute = () => {
  const routes = useMemo(() => {
    const mappedRoutes = new Map<string, RouteCategory>();
    assignedRoutes(mappedRoutes, dashboardRoutes, 'dashboard');
    assignedRoutes(mappedRoutes, customerSpecificRoutes, 'customer');
    assignedRoutes(mappedRoutes, nonCustomerRoutes, 'non-customer');
    return mappedRoutes;
  }, []);

  const location = useLocation();

  return useMemo(() => {
    const { pathname } = location;
    const path = removePathParams(pathname);
    return routes.get(path) ?? 'dashboard';
  }, [location, routes]);
};
