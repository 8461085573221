export const mean = (values?: number[]): number | undefined => {
  if (!values?.length) {
    return undefined;
  }

  return values.reduce((total, value) => total + value, 0) / values.length;
};

export const getResolvedArrayValue = (values: any[], index: number) => {
  if (!values?.length || index < 0) {
    return undefined;
  }

  return values[index % values.length];
};

export const union = <T>(first: T[], second: T[]) => {
  return Array.from(new Set([...first, ...second]));
};

export const isEqual = (first: number[], second: number[]) => {
  if (first.length !== second.length) {
    return false;
  }

  const sortedFirst = [...first].sort();
  const sortedSecond = [...second].sort();

  for (let i = 0; i < sortedFirst.length; i++) {
    if (sortedFirst[i] !== sortedSecond[i]) {
      return false;
    }
  }

  return true;
};
