import { useCrudList } from '../../Crud/grid/useCrudList';
import { Budget } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';
import { formatDate } from '../../../utils/dateUtils';

export const useListBudgets = (
  customerId: number,
  budgetId?: number | null
) => {
  const { data, isLoading, isError } = useCrudList<Budget>({
    apiPath: apiPaths.budget(customerId),
  });

  // split the returned data into:
  // - a list of budget ids for the autocomplete options
  // - a map of id->name to display each option to the user
  const { budgetIds, budgetIdsToName, selectedBudgetId } = useMemo(() => {
    const budgets = data?.items ?? [];
    const budgetIds = budgets.map(({ id }) => id);
    const budgetIdsToName = budgets.reduce((map, budget) => {
      return map.set(
        budget.id,
        `${formatDate(budget.startDate)} - ${formatDate(budget.endDate)}`
      );
    }, new Map<number, string>());

    const selectedBudgetId =
      budgetId &&
      (isNaN(budgetId) || !budgetIds.includes(budgetId) ? null : budgetId);

    return { budgetIds, budgetIdsToName, selectedBudgetId };
  }, [data?.items, budgetId]);

  return {
    isLoading,
    isError,
    budgetIds,
    budgetIdsToName,
    selectedBudgetId,
  };
};
