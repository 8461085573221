import { User } from '../../domain/types';
import { CrudHandler } from '../CrudHandler';
import { apiPaths } from '../../utils/apiPaths';
import {
  mockCustomerAdminUserData,
  mockSysAdminUserData,
} from '../data/adminData';

export const sysAdminUsersHandler = new CrudHandler<User>(
  apiPaths.users.base,
  mockSysAdminUserData
);

export const customerAdminUsersHandler = new CrudHandler<User>(
  apiPaths.customerUsers(':customerId' as any).base,
  mockCustomerAdminUserData
);

export const customerViewerSettingsHandler = new CrudHandler(
  apiPaths.customerViewerSettings.base,
  []
);
