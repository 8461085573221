import {
  useState,
  useMemo,
  createContext,
  ReactNode,
  useCallback,
} from 'react';
import {
  RecommendationCategory,
  RecommendationEntity,
  RecommendationState,
} from '../../../domain/types';

interface RecommenderFiltersProviderProps {
  children: ReactNode;
}

type Priority = 'P1' | 'P2' | 'P3' | 'P4';

interface RecommenderFilters {
  category: RecommendationCategory[];
  state: RecommendationState[];
  priority: Priority[];
  entities: RecommendationEntity[];
}

export type RecommanderSortOption = 'priority' | 'cost' | 'date';

export type RecommenderSortOder = 'asc' | 'desc';

export interface RecommenderSort {
  field: RecommanderSortOption;
  order: RecommenderSortOder;
}

export interface RecommenderFiltersContextProps {
  search: string;
  applySearch: (searchParam: string) => void;
  filters: RecommenderFilters;
  sortBy: RecommenderSort;
  applyFilters: (filters: Partial<RecommenderFilters>) => void;
  applySort: (sortBy: RecommenderSort) => void;
}

export const RecommenderFiltersContext = createContext<RecommenderFiltersContextProps>(
  {
    search: '',
    filters: {
      category: [],
      state: [],
      priority: [],
      entities: [],
    },
    sortBy: {
      field: 'priority',
      order: 'asc',
    },
    applySearch: () => {},
    applyFilters: () => {},
    applySort: () => {},
  }
);

export const RecommenderFiltersProvider: React.FC<RecommenderFiltersProviderProps> = ({
  children,
}) => {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<RecommenderSort>({
    field: 'priority',
    order: 'asc',
  });
  const [filters, setFilters] = useState<RecommenderFilters>({
    category: [],
    state: ['ACTIVE'],
    priority: [],
    entities: ['BILLING_ACCOUNT_ID'],
  });

  const applySearch = useCallback(
    (searchParam: string) => {
      setSearch(searchParam);
    },
    [setSearch]
  );

  const applyFilters = useCallback(
    (newFilters: Partial<RecommenderFilters>) => {
      setFilters((prevValue: RecommenderFilters) => ({
        ...prevValue,
        ...newFilters,
      }));
    },
    [setFilters]
  );

  const applySort = useCallback((sortPayload: RecommenderSort) => {
    setSortBy(sortPayload);
  }, []);

  const value = useMemo(
    () => ({ search, applySearch, filters, applyFilters, applySort, sortBy }),
    [search, applySearch, filters, applyFilters, applySort, sortBy]
  );

  return (
    <RecommenderFiltersContext.Provider value={value}>
      {children}
    </RecommenderFiltersContext.Provider>
  );
};
