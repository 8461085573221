import { Alert, Box } from '@mui/material';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';
import { RecommenderReportTable } from './RecommenderReportTable';
import { useRecommenderData } from './hooks/useRecommenderData';
import { RecommenderFiltersProvider } from './RecommenderFiltersProvider';
import { RecommenderFilterBar } from './RecommenderFilterBar';
import { RecommenderSummary } from './RecommenderSummary';
import { PageHeader } from '../../PageHeader';
import { useRecommenderEnabled } from './hooks/useRecommenderEnabled';

import { QueryLoader } from '../../Messaging/QueryStatus';

export const RecommenderReport = () => {
  const { selectedCustomerId } = useStateContext();
  const { data: isEnabled, isLoading } = useRecommenderEnabled(
    selectedCustomerId
  );
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="flex-start" mt={3}>
        <QueryLoader testId="recommender-loader" />
      </Box>
    );
  }

  if (!isCustomerSelected) {
    return (
      <Box mt={3}>
        <Alert severity="info">Select a customer to view the report.</Alert>
      </Box>
    );
  }

  return (
    <RecommenderFiltersProvider>
      <RecommenderReportContainer onboardingFinished={isEnabled ?? false} />
    </RecommenderFiltersProvider>
  );
};

const RecommenderReportContainer = ({
  onboardingFinished,
}: {
  onboardingFinished: boolean;
}) => {
  const { data, isLoading, summary } = useRecommenderData();

  return (
    <>
      <PageHeader title="Recommender" />
      <RecommenderSummary
        summary={summary}
        isLoading={isLoading}
        onboardingFinished={onboardingFinished}
      />
      <RecommenderFilterBar />
      <RecommenderReportTable isLoading={isLoading} rows={data} />
    </>
  );
};
