import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { Customer } from '../../../domain/types';
import { GridColDef } from '@mui/x-data-grid';
import { CustomerFormValues, CustomerSchema } from './CustomerSchema';
import { CustomerForm } from './CustomerForm';
import { IconButton } from '@mui/material';
import DiscountIcon from '@mui/icons-material/Discount';
import { useState } from 'react';
import { VOID_ID } from '../../../domain/constants';
import { CustomerDiscountModalContainer } from './components/CustomerDiscountModal';
import { useCustomersDiscountsSummary } from './hooks/useCustomersDiscountsSummary';

export const CustomerAdmin = () => {
  const [discountsOpen, setDiscountsOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(VOID_ID);
  const customerMapping = useCustomersDiscountsSummary();

  const columns: GridColDef<Customer>[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'SDM',
      field: 'sdmEmail',
      flex: 1,
    },
    {
      headerName: 'Discount',
      field: 'percentage',
      flex: 1,
      renderCell: ({ row }) => {
        const percentage = customerMapping.get(row.id)?.percentage;
        return percentage ? `${percentage}%` : null;
      },
    },
    {
      headerName: 'Exceptions',
      field: 'exceptions',
      flex: 1,
      renderCell: ({ row }) => {
        const exceptions = customerMapping.get(row.id)?.exceptionsCounter;
        return exceptions ? exceptions : null;
      },
    },
    {
      headerName: '',
      field: 'action',
      width: 30,
      filterable: false,
      sortable: false,
      renderHeader: () => <span></span>,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          onClick={() => {
            setDiscountsOpen(true);
            setSelectedCustomerId(row.id);
          }}
        >
          <DiscountIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <CrudContainer<Customer, CustomerFormValues>
        name="Customer"
        apiPath={apiPaths.customer}
        columns={columns}
        defaultSortColumn="name"
        FormComponent={CustomerForm}
        validationSchema={CustomerSchema}
      />
      <CustomerDiscountModalContainer
        selectedCustomerId={selectedCustomerId}
        open={discountsOpen}
        onClose={() => setDiscountsOpen(false)}
      />
    </>
  );
};
