import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { DisclaimerMessage } from '../../../domain/types';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { PageHeader } from '../../PageHeader';
import { DisclaimerManagerDialog } from './DisclaimerManagerDialog';
import { DisclaimerMessageCard } from './DisclaimerMessageCard';
import { useDisclaimerData } from './hooks/useDisclaimerData';
import { useDisclaimerDisable } from './hooks/useDisclaimerDisable';

export function DisclaimerManager() {
  const [
    selectedMessage,
    setSelectedMessage,
  ] = useState<DisclaimerMessage | null>(null);
  const [dialogMode, setDialogMode] = useState<'create' | 'update'>('create');

  const { items, isLoading } = useDisclaimerData();
  const disable = useDisclaimerDisable();
  const [modalOpen, setModalOpen] = useState(false);

  const onDisclaimerCreate = () => {
    setDialogMode('create');
    setModalOpen(true);
  };

  const onDisclaimerEdit = (id: number) => {
    const message = items.find((item) => item.id === id);
    if (!message) {
      return;
    }

    setDialogMode('update');
    setSelectedMessage(message);
    setModalOpen(true);
  };

  const onDisable = (id: number) => {
    const message = items.find((item) => item.id === id);
    if (!message) {
      return;
    }

    disable(message.id as any);
  };

  const Cards = items.map((item) => (
    <Grid item xs={3} key={item.id}>
      <DisclaimerMessageCard
        model={item}
        onEdit={onDisclaimerEdit}
        onDisable={onDisable}
      />
    </Grid>
  ));

  return (
    <>
      <PageHeader title="Disclaimer Manager" />
      <Box marginBottom={2}>
        <Button
          data-testid="create-btn"
          onClick={onDisclaimerCreate}
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box>
          <QueryLoader />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {Cards}
        </Grid>
      )}

      <DisclaimerManagerDialog
        message={selectedMessage}
        mode={dialogMode}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}
