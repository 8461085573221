import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { ProjectGroup } from '../../../domain/types';
import { GridColDef } from '@mui/x-data-grid';
import {
  ProjectGroupFormValues,
  ProjectGroupSchema,
} from './ProjectGroupSchema';
import { ProjectGroupForm } from './ProjectGroupForm';
import { useStateContext } from '../../../contexts/StateProvider';

const columns: GridColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 2,
  },
  {
    headerName: 'Type',
    field: 'type',
    flex: 1,
  },
];

export const ProjectGroupAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  return (
    <CrudContainer<ProjectGroup, ProjectGroupFormValues>
      name="Project Group"
      apiPath={apiPaths.projectGroup(selectedCustomerId)}
      columns={columns}
      defaultSortColumn="name"
      FormComponent={ProjectGroupForm}
      validationSchema={ProjectGroupSchema}
    />
  );
};
