import {
  Budget,
  BudgetAllocationProject,
  PagedItems,
  Project,
  ProjectGroup,
  RemainingBudget,
} from '../../domain/types';
import {
  mockBudgetsData,
  mockProjectData,
  mockProjectGroupData,
} from '../data/adminData';
import { rest } from 'msw';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { getBurndownData } from '../data/reportData';

class Handler extends CustomerCrudHandler<Budget> {
  additionalHandlers() {
    return [
      rest.get(
        `/api${apiPaths.budget(':customerId' as any).available}`,
        async (req, res, ctx) => {
          const items = Array.from(
            this.getDataset(this.getKey(req.params)).values()
          );
          const availableItems = mockBudgetsData.filter(
            (budget) => !items.map(({ id }) => id).includes(budget.id)
          );
          const data: PagedItems<Budget> = {
            items: availableItems,
            total_items: availableItems.length,
            page: 1,
            total_pages: 1,
          };
          return res(ctx.status(200), ctx.json(data));
        }
      ),
      rest.get(
        `/api${apiPaths.budget(':customerId' as any).active}`,
        (req, res, ctx) => {
          const { budget } = getBurndownData('doy');

          return res(ctx.status(200), ctx.json(budget));
        }
      ),
      rest.get(
        `/api${apiPaths
          .budget(':customerId' as any)
          .remainingBudget(':budgetId' as any)}`,
        async (req, res, ctx) => {
          const data: RemainingBudget = {
            absolute: 700000,
            percentage: 0.7,
          };
          return res(ctx.status(200), ctx.json(data));
        }
      ),
      rest.get(
        `/api${apiPaths
          .budget(':customerId' as any)
          .availableProjects(':budgetId' as any)}`,
        async (req, res, ctx) => {
          const data: BudgetAllocationProject[] = mockProjectData.map(
            (project: Project): BudgetAllocationProject => ({
              id: project.id,
              name: project.gcpProjectId,
              gcpProjectId: project.gcpProjectId,
              gcpProjectName: project.gcpProjectName,
            })
          );
          return res(ctx.status(200), ctx.json(data));
        }
      ),
      rest.get(
        `/api${apiPaths
          .budget(':customerId' as any)
          .availableProjectGroups(':budgetId' as any)}`,
        async (req, res, ctx) => {
          const data: BudgetAllocationProject[] = mockProjectGroupData.map(
            (group: ProjectGroup): BudgetAllocationProject => ({
              id: group.id,
              name: group.name,
            })
          );
          return res(ctx.status(200), ctx.json(data));
        }
      ),
    ];
  }
}

export const budgetsHandler = new Handler(
  apiPaths.budget(':customerId' as any).base,
  {
    1: mockBudgetsData,
  }
);
