import { Service, ServiceApportionment } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { mockServiceApportionmentsData } from '../data/adminData';
import { CustomerCrudHandler } from '../CustomerCrudHandler';

export const serviceApportionmentsHandler = new CustomerCrudHandler<ServiceApportionment>(
  apiPaths.serviceApportionments(':customerId' as any).base,
  {
    1: mockServiceApportionmentsData,
  }
);

export const billingServiceApportionmentsHandler = new CustomerCrudHandler<Service>(
  apiPaths.billingApportionmentServices(':customerId' as any).base,
  {
    1: [],
  }
);
