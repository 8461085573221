import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { Chip, Theme, Button, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Recommendation,
  RecommendationCategory,
  RecommendationEntity,
} from '../../../domain/types';
import { DateDisplay } from '../../DateDisplay';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { RecommanderIcon } from './RecommenderIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import { RecommenderDialog } from './RecommenderDialog';
import { DATA_GRID_PAGE_LIMIT } from '../../../domain/constants';
import LinkIcon from '@mui/icons-material/Link';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { useRecommenderFilters } from './hooks/useRecommenderFilters';
import { QueryLoader } from '../../Messaging/QueryStatus';

interface RecommanderReportTableProps {
  isLoading: boolean;
  rows: Recommendation[];
}

const RecommandationCategoryCell: React.FC<{
  category: RecommendationCategory;
}> = ({ category }) => {
  const classes = useStyles();

  return (
    <div className={classes.categoryCell}>
      <RecommanderIcon category={category} />
      <span className={classes.categoryLabel}>{category}</span>
    </div>
  );
};

const PriorityCell: React.FC<{ priority: string }> = ({ priority }) => {
  return <Chip variant="outlined" size="small" label={priority} />;
};

const StatusCell: React.FC<{ status: string }> = ({ status }) => {
  return <Chip size="small" variant="outlined" label={status} />;
};

const parseDetails = (details: string) => {
  const tokens = details.split('_').map((token: string) => token.toLowerCase());

  return tokens.join(' ');
};

const cloudEntitiesLabels = new Map<RecommendationEntity, string>([
  ['BILLING_ACCOUNT_ID', 'Billing Account Id'],
  ['PROJECT_NUMBER', 'Project Id'],
  ['ORGANIZATION_ID', 'Organization Id'],
  ['FOLDER_ID', 'Folder Id'],
]);

const buildRecommendationExternalLink = ({
  cloudEntityId,
  cloudEntityType,
  subType,
}: {
  cloudEntityId: string;
  cloudEntityType: string;
  subType: string;
}) => {
  const baseUrl = 'https://console.cloud.google.com/home';

  switch (cloudEntityType) {
    case 'PROJECT_NUMBER':
      return `${baseUrl}/recommendations?project=${cloudEntityId}`;
    case 'ORGANIZATION_ID':
      return `${baseUrl}/recommendations?organizationId=${cloudEntityId}`;
    case 'BILLING_ACCOUNT_ID':
      return subType === 'BILLING_ACCOUNT_SCOPED_COMMITMENTS'
        ? `${baseUrl}/billingRecommendations;accountId=${cloudEntityId}/list/COMBINED_BILLING_COMMITMENTS`
        : null;
    default:
      return null;
  }
};

export const RecommenderReportTable: React.FC<RecommanderReportTableProps> = ({
  rows,
  isLoading,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState();
  const { filters } = useRecommenderFilters();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GRID_PAGE_LIMIT,
  });

  const handleDetailsClick = (recommendation: any) => {
    setDialogOpen(true);
    setSelectedRecommendation(recommendation);
  };

  const columns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        headerName: 'Impact',
        field: 'category',
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <RecommandationCategoryCell category={row.category} />
        ),
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <div className={classes.descriptionButton}>
              <Button size="small" onClick={() => handleDetailsClick(row)}>
                <DescriptionIcon fontSize="small" />
                {parseDetails(row.recommenderSubtype)}
              </Button>
            </div>
          );
        },
      },
      {
        headerName: 'Refreshed',
        field: 'date',
        flex: 0.5,
        sortable: false,
        renderCell: (params) => <DateDisplay date={params.row.date} />,
      },
      {
        headerName: 'Cost',
        field: 'cost',
        width: 160,
        sortable: false,
        renderCell: ({ row }) => {
          if (row?.cost) {
            const value = row.cost;
            return (
              <MonetaryDisplay value={value} currency={row?.currencyCode} />
            );
          }

          return <></>;
        },
      },
      {
        headerName: 'Priority',
        field: 'priority',
        width: 100,
        sortable: false,
        renderCell: ({ row }) => <PriorityCell priority={row.priority} />,
      },
      {
        headerName: 'Status',
        field: 'state',
        width: 140,
        sortable: false,
        renderCell: ({ row }) => <StatusCell status={row.state} />,
      },
    ];

    if (filters.entities.length !== 1) {
      columns.splice(1, 0, {
        headerName: 'Cloud Entity',
        field: 'cloudEntityType',
        width: 200,
        sortable: false,
      });
      columns.splice(2, 0, {
        headerName: 'Cloud Entity Id',
        field: 'cloudEntityId',
        width: 200,
        sortable: false,
      });
    } else {
      const column = filters.entities[0];
      columns.splice(1, 0, {
        headerName: cloudEntitiesLabels.get(column),
        field: 'cloudEntityId',
        flex: 1,
        sortable: false,
      });
    }

    if (filters.entities[0] !== 'FOLDER_ID') {
      columns.push({
        headerName: 'Link',
        field: 'link',
        width: 100,
        sortable: false,
        renderCell: ({ row }) => {
          const link = buildRecommendationExternalLink({
            cloudEntityId: row.cloudEntityId,
            cloudEntityType: row.cloudEntityType,
            subType: row.recommenderSubtype,
          });

          return link ? (
            <Link target="_blank" href={link}>
              <LinkIcon />
            </Link>
          ) : (
            <></>
          );
        },
      });
    }

    return columns;
  }, [filters.entities, classes.descriptionButton]);

  return (
    <GridWidgetContainer>
      <DataGrid
        getRowId={(row) => row?.id}
        density="compact"
        columns={columns}
        rows={rows}
        columnBuffer={columns.length - 1}
        loading={isLoading}
        disableColumnFilter
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[DATA_GRID_PAGE_LIMIT, 50, 100]}
        slots={{
          loadingOverlay: () => (
            <GridOverlay>
              <QueryLoader />
            </GridOverlay>
          ),
        }}
      />
      <RecommenderDialog
        open={dialogOpen}
        selectedRecommendation={selectedRecommendation}
        onClose={() => setDialogOpen(false)}
      />
    </GridWidgetContainer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  categoryCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryLabel: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  descriptionButton: {
    marginLeft: -theme.spacing(1),
  },
}));
