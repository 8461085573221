import { useMemo } from 'react';
import { CustomerDiscountException, SkuGroup } from '../../../../domain/types';
import { useCustomerAdminState } from './useCustomerAdminState';

export const useSkuGroupsOptions = ({
  exceptions,
}: {
  exceptions: CustomerDiscountException[];
}): SkuGroup[] => {
  const { skuGroups } = useCustomerAdminState();

  return useMemo(
    () =>
      skuGroups.filter(
        (group) =>
          !exceptions.find((exception) => exception.skuGroupId === group.id)
      ),
    [skuGroups, exceptions]
  );
};
