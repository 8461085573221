import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup';
import { MIN_MONEY_VALUE } from '../../../domain/constants';
import {
  CommitAmount,
  Commit,
  CommitType,
  Editable,
  FormValues,
  CommitTrackingType,
  CommitKind,
} from '../../../domain/types';

export type CommitFormValues = Editable<FormValues<Omit<Commit, 'customerId'>>>;
export type SubmittedCommitFormValues = Omit<CommitFormValues, 'numberOfYears'>;

const commitAmount = () =>
  number()
    .min(MIN_MONEY_VALUE, `Value should be at least ${MIN_MONEY_VALUE}`)
    .required();

export const CommitSchema: SchemaOf<CommitFormValues> = object({
  startDate: string().required(),
  endDate: string().required(),
  numberOfYears: number().min(1).required(),
  kind: mixed<CommitKind>().oneOf(Object.values(CommitKind)).required(),
  type: mixed<CommitType>()
    .oneOf(Object.values(CommitType), 'This field is required')
    .required(),
  trackingType: mixed<CommitTrackingType>()
    .oneOf(Object.values(CommitTrackingType), 'This field is required')
    .required(),
  currency: string().required(),
  superseded: boolean().required(),
  allowsExcessRollover: boolean().optional(),
  allowsShortfallRollover: boolean().optional(),
  shortfallRolloverPercentage: number()
    .moreThan(0, 'Shortfall percentage should be greater than 0')
    .max(1, 'Shortall pertange should be less or equal to 100%')
    .optional(),
  commitAmounts: array<CommitAmount[]>()
    .when(
      ['trackingType', 'numberOfYears'],
      (trackingType: CommitTrackingType, numberOfYears: any) => {
        // pass in tracking type and selected number of years.
        // Validate the number of commit ammounts based on the tracking type
        const length: number =
          trackingType === CommitTrackingType.FULL ? 1 : numberOfYears;
        return array().length(length);
      }
    )
    .of(
      object().shape({
        startDate: string().required(),
        hardAmount: number(),
        softAmount: number(),
        adjustedCommitAmount: number(),
      })
    )
    .when('type', {
      is: CommitType.SOFT,
      then: array().of(
        object().shape({
          startDate: string().required(),
          hardAmount: number(),
          softAmount: commitAmount(),
        })
      ),
    })
    .when('type', {
      is: CommitType.HARD,
      then: array().of(
        object().shape({
          startDate: string().required(),
          hardAmount: commitAmount(),
          softAmount: number(),
        })
      ),
    })
    .when('type', {
      is: CommitType.HARD_AND_SOFT,
      then: array().of(
        object().shape({
          startDate: string().required(),
          hardAmount: commitAmount(),
          softAmount: commitAmount(),
        })
      ),
    }),
});
