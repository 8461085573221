import { useMemo } from 'react';
import {
  ServiceApportionment,
  Service,
  ServiceType,
} from '../../../domain/types';
import { useCrudList } from '../../Crud/grid/useCrudList';
import { apiPaths } from '../../../utils/apiPaths';

export const useApportionedServices = (
  customerId: number,
  serviceId: number
) => {
  const { data: servicesData } = useCrudList<Service>({
    apiPath: apiPaths.services(),
  });
  const {
    data: apportionedServicesData,
    isLoading: isApportionedServiceDataLoading,
    isError: isApportionedServiceDataError,
  } = useCrudList<ServiceApportionment>({
    apiPath: apiPaths.serviceApportionments(customerId),
  });

  const { serviceIds, serviceIdsToName, selectedServiceId } = useMemo(() => {
    const apportionedServiceIds =
      apportionedServicesData?.items.map(
        (service: ServiceApportionment) => service.serviceId
      ) ?? [];

    const services =
      servicesData?.items.filter((service: Service) => {
        return (
          apportionedServiceIds.includes(service.id) &&
          service.type === ServiceType.THIRD_PARTY
        );
      }) ?? [];

    const serviceIds = services.map(({ id }) => id);
    const serviceIdsToName = services.reduce((map, service) => {
      return map.set(service.id, service.name);
    }, new Map<number, string>());

    const selectedServiceId =
      isNaN(serviceId) || !serviceIds.includes(serviceId) ? null : serviceId;

    return { serviceIds, serviceIdsToName, selectedServiceId };
  }, [servicesData, apportionedServicesData, serviceId]);

  return {
    isLoading: isApportionedServiceDataLoading,
    isError: isApportionedServiceDataError,
    serviceIds,
    serviceIdsToName,
    selectedServiceId,
  };
};
