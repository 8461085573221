import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { COMMIT_CURRENCY } from '../../../domain/constants';
import { CustomerCommitTracker } from '../../../domain/types';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { CustomersCommitTrackerProgressBar } from './CustomersCommitTrackerProgressBar';
import { useStateContext } from '../../../contexts/StateProvider';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { useState } from 'react';

interface CustomersCommitTrackerTableProps {
  isLoading: boolean;
  rows: CustomerCommitTracker[];
}

const COMMIT_GRID_ROWS = 20;

export const CustomersCommitTrackerTable: React.FC<CustomersCommitTrackerTableProps> = ({
  isLoading,
  rows,
}) => {
  const classes = useStyles();
  const { setSelectedCustomerId } = useStateContext();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: COMMIT_GRID_ROWS,
  });

  const columns: GridColDef[] = [
    {
      headerName: 'Customer Name',
      field: 'customerName',
      width: 170,
      renderCell: ({ row }) => {
        const url = '/app/dashboard/commit-tracker';

        return (
          <Link to={url} onClick={() => setSelectedCustomerId(row.customerId)}>
            {row.customerName}
          </Link>
        );
      },
    },
    {
      headerName: 'Commit Length',
      field: 'commitLength',
      width: 160,
    },
    {
      headerName: 'Total Commit Amount',
      field: 'totalCommitAmount',
      width: 200,
      renderCell: ({ row }) => (
        <div className={classes.monetaryCell}>
          <MonetaryDisplay
            value={row.totalCommitAmount}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </div>
      ),
    },
    {
      headerName: 'Commit Type',
      field: 'commitType',
      width: 140,
    },
    {
      headerName: 'Commit Period Progress',
      field: 'commitPeriodProgress',
      width: 220,
      renderCell: ({ row }) => {
        const {
          commitPeriodAmount,
          totalForecastConsumptionPeriod,
          currentCommitConsumptionToDate,
        } = row;
        const forecast =
          totalForecastConsumptionPeriod - currentCommitConsumptionToDate;

        return (
          <CustomersCommitTrackerProgressBar
            currentForecast={forecast}
            commitPeriodAmount={commitPeriodAmount}
            currentCommitConsumptionToDate={currentCommitConsumptionToDate}
          />
        );
      },
    },
    {
      headerName: 'Commit Period Amount',
      field: 'commitPeriodAmount',
      width: 210,
      renderCell: ({ row }) => (
        <div className={classes.monetaryCell}>
          <MonetaryDisplay
            value={row.commitPeriodAmount}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </div>
      ),
    },
    {
      headerName: 'Commit Period Start Date',
      field: 'commitPeriodStartDate',
      width: 220,
    },
    {
      headerName: 'Commit Period End Date',
      field: 'commitPeriodEndDate',
      width: 220,
    },
    {
      headerName: 'Consumption to Date in Current Period',
      field: 'currentCommitConsumptionToDate',
      width: 300,
      renderCell: ({ row }) => (
        <div className={classes.monetaryCell}>
          <MonetaryDisplay
            value={row.currentCommitConsumptionToDate}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </div>
      ),
    },
    {
      headerName: 'Total Forecast Consumption in Current Period',
      field: 'totalForecastConsumptionPeriod',
      width: 360,
      renderCell: ({ row }) => (
        <div className={classes.monetaryCell}>
          <MonetaryDisplay
            value={row.totalForecastConsumptionPeriod}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </div>
      ),
    },
    {
      headerName: 'Current Period Achieved Date',
      field: 'currentCommitAchievedDate',
      width: 260,
    },
    {
      headerName: 'Current Period Commit Shortfall',
      field: 'currentCommitShortfall',
      width: 270,
      renderCell: ({ row }) => (
        <div className={classes.monetaryCell}>
          <MonetaryDisplay
            value={row.currentCommitShortfall}
            currency={COMMIT_CURRENCY}
            noFractionDigits={true}
          />
        </div>
      ),
    },
  ];

  return (
    <GridWidgetContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[COMMIT_GRID_ROWS]}
        loading={isLoading}
        density="compact"
      />
    </GridWidgetContainer>
  );
};

const useStyles = makeStyles(() => ({
  gridWidgetContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  monetaryCell: {
    width: '100%',
    textAlign: 'right',
  },
  gridContainer: {
    width: '100%',
    height: 'calc(100% - 60px)',
    position: 'absolute',
    top: 60,
    display: 'block',
  },
}));
