import { Box } from '@mui/material';
import { QueryStatus } from '../../Messaging';
import React from 'react';

export const CrudDataGridError = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <QueryStatus isError={true} />
    </Box>
  );
};
