import { Identity, PagedItems } from '../domain/types';
import { RequestParams, rest, RestHandler } from 'msw';
import { ServerHandler } from './ServerHandler';

/*
 * This class mimics the CRUD endpoints for a particular entity
 * It will keep track of adding, updating and removing items so multiple requests
 * to the server in our tests will be kept up to date with changes made
 */

export class CrudHandler<T extends Identity> implements ServerHandler {
  public endpoint: string;
  public itemEndpoint: string;

  protected defaultKey = '__DEFAULT_KEY__';

  protected dataSets = new Map<string, Map<number, T>>();
  protected nextId = 1;

  constructor(
    endpoint: string,
    private initialData: T[] | { [key: string]: T[] }
  ) {
    this.endpoint = `/api${endpoint}`;
    this.itemEndpoint = `${this.endpoint}:id`;
    this.setData(initialData);
  }

  protected getKey(_params: RequestParams): string {
    return this.defaultKey;
  }

  protected getDataset(key: string): Map<number, T> {
    if (!this.dataSets.has(key)) {
      this.dataSets.set(key, new Map());
    }
    return this.dataSets.get(key) ?? new Map();
  }

  public resetData() {
    this.setData(this.initialData);
  }

  public setData(dataSet: T[] | { [key: string]: T[] }) {
    this.dataSets.clear();
    this.nextId = 1;
    if (Array.isArray(dataSet)) {
      dataSet = {
        [this.defaultKey]: dataSet,
      };
    }
    Object.entries(dataSet).forEach(([key, data]) => {
      const currentSet = new Map<number, T>();
      data.forEach((item) => {
        currentSet.set(item.id, item);
        if (item.id >= this.nextId) {
          this.nextId = item.id + 1;
        }
      });
      this.dataSets.set(key, currentSet);
    });
  }

  additionalHandlers(): RestHandler[] {
    return [];
  }

  public listHandler(): RestHandler {
    return rest.get(this.endpoint, async (req, res, ctx) => {
      const dataSet = this.getDataset(this.getKey(req.params));
      const data: PagedItems<T> = {
        items: Array.from(dataSet.values()),
        total_items: dataSet.size,
        page: 1,
        total_pages: 1,
      };
      return res(ctx.status(200), ctx.json(data));
    });
  }

  public itemHandler(): RestHandler {
    return rest.get(this.itemEndpoint, async (req, res, ctx) => {
      const dataSet = this.getDataset(this.getKey(req.params));
      const { id } = req.params;
      const item = dataSet.get(Number(id));
      if (!item) {
        return res(ctx.status(404));
      }
      return res(ctx.json(item));
    });
  }

  public createHandler(): RestHandler {
    return rest.post(this.endpoint, async (req, res, ctx) => {
      if (typeof req.body !== 'object') {
        return res(ctx.status(400));
      }
      const id = this.nextId++;
      const newItem = {
        id,
        ...req.body,
      } as T;
      const dataSet = this.getDataset(this.getKey(req.params));
      dataSet.set(id, newItem);
      return res(ctx.json(newItem));
    });
  }

  public updateHandler(): RestHandler {
    return rest.put(this.itemEndpoint, async (req, res, ctx) => {
      const { id } = req.params;
      const itemId = Number(id);
      const dataSet = this.getDataset(this.getKey(req.params));
      if (!dataSet.has(itemId)) {
        return res(ctx.status(404));
      }
      if (typeof req.body !== 'object') {
        return res(ctx.status(400));
      }
      const updatedItem = {
        id: itemId,
        ...req.body,
      } as T;
      dataSet.set(itemId, updatedItem);
      return res(ctx.json(updatedItem));
    });
  }

  public deleteHandler() {
    return rest.delete(this.itemEndpoint, async (req, res, ctx) => {
      const { id } = req.params;
      const itemId = Number(id);
      const dataSet = this.getDataset(this.getKey(req.params));
      if (!dataSet.has(itemId)) {
        return res(ctx.status(404));
      }
      dataSet.delete(itemId);
      return res(ctx.status(204));
    });
  }

  handlers(): RestHandler[] {
    return [
      ...this.additionalHandlers(),
      this.listHandler(),
      this.itemHandler(),
      this.createHandler(),
      this.updateHandler(),
      this.deleteHandler(),
    ];
  }
}
