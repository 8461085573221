import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { SkuGroup } from '../../../../domain/types';
import { VOID_ID } from '../../../../domain/constants';

export const useSkuGroups = (skuId: number) => {
  const apiPath = apiPaths.skus();

  const { data, isLoading, isError } = useQuery<SkuGroup[]>(
    apiPath.key.item(skuId),
    () => GET(apiPath.groups(skuId)),
    {
      enabled: skuId !== VOID_ID,
    }
  );

  return {
    items: data ?? [],
    isError,
    isLoading,
  };
};
