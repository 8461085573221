import { ServiceCharge } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { mockServiceChargeData } from '../data/adminData';
import { CustomerCrudHandler } from '../CustomerCrudHandler';

export const serviceChargesHandler = new CustomerCrudHandler<ServiceCharge>(
  apiPaths.serviceCharge(':customerId' as any).base,
  {
    1: mockServiceChargeData,
  }
);
