import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { POST } from '../../../../utils/api';
import { Service, ConsumptionFilters } from '../../../../domain/types';
import { API_DATE_FORMAT, VOID_ID } from '../../../../domain/constants';
import { useMemo } from 'react';
import { format } from 'date-fns';

export const useServicesFilterData = (
  filters: ConsumptionFilters,
  customerId?: number
) => {
  const gcpServicesApi = apiPaths.servicesFilter(customerId);
  const isRequestEnabled =
    filters.selectedCustomerId === (customerId ?? VOID_ID);

  const partialFilters = {
    customer_id: customerId,
    timeInterval: filters.timeInterval,
    startDate: format(new Date(filters.startDate), API_DATE_FORMAT),
    endDate: format(new Date(filters.endDate), API_DATE_FORMAT),
    usage: filters.usage,
    projectsLabelsFilter: {
      ids: filters.projectsLabels,
      inclusive: true,
    },
    resourcesLabelsFilter: {
      ids: filters.resourcesLabels,
      inclusive: true,
    },
  };

  const { data, isLoading, isError } = useQuery(
    gcpServicesApi.key.list({ ...partialFilters }),
    () => POST(gcpServicesApi.base, partialFilters),
    {
      enabled: isRequestEnabled,
    }
  );

  const services = useMemo(() => {
    return (data as Service[]) ?? [];
  }, [data]);

  return {
    services,
    isLoading,
    isError,
  };
};
