import { Redirect, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { SwitchRoute } from './SwitchRoute';
import { MenuRoutes } from '../MenuRoutes';
import { useDashboardRoutes, dashboardPaths } from './useDashboardRoutes';

export const DashboardRoutes = () => {
  const routes = useDashboardRoutes();

  return (
    <Box display="flex" flexGrow={1}>
      <Box flexGrow={1} display="flex" flexDirection="column">
        <SwitchRoute>
          <Route path={dashboardPaths.basePath} exact>
            <Redirect to={dashboardPaths.consumption} />
          </Route>
          <MenuRoutes routes={routes} />
        </SwitchRoute>
      </Box>
    </Box>
  );
};
