import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Commit, CommitTrackingType } from '../../../../domain/types';

export interface ForecastTrackingTypeFilterProps {
  value: CommitTrackingType | null;
  selectedCommit: Commit | null;
  onChange: (value: CommitTrackingType) => void;
}

export const ForecastTrackingTypeFilter = ({
  value,
  selectedCommit,
  onChange,
}: ForecastTrackingTypeFilterProps) => {
  const trackingType = selectedCommit?.trackingType;
  const isAnnual = trackingType === CommitTrackingType.ANNUAL;
  const isFull = trackingType === CommitTrackingType.FULL;
  return (
    <FormControl fullWidth>
      <InputLabel id="tracking-type-filter-label" shrink={!!value}>
        Commit Tracking Type
      </InputLabel>
      <Select
        labelId="tracking-type-filter-label"
        id="tracking-type-filter"
        value={value ?? ''}
        disabled={true}
        onChange={(e) => onChange(e.target.value as CommitTrackingType)}
      >
        {isAnnual && (
          <MenuItem value={CommitTrackingType.ANNUAL}>Annualised</MenuItem>
        )}
        {isFull && (
          <MenuItem value={CommitTrackingType.FULL}>Full Term</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
