import { ThreeDots } from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material';

type QueryStatusProps = {
  isLoading?: boolean;
  loadingTestId?: string;
  isError?: boolean;
  errorTestId?: string;
};

type QueryLoaderProps = {
  loaderColor?: string;
  testId?: string;
};

export const QueryLoader = ({ loaderColor, testId }: QueryLoaderProps) => {
  const theme = useTheme();
  return (
    <div data-testid={testId}>
      <ThreeDots
        color={loaderColor ?? theme.palette.primary.main}
        height={10}
      />
    </div>
  );
};

const QueryStatus = ({
  isLoading,
  isError,
  loadingTestId,
  errorTestId,
}: QueryStatusProps) => {
  if (isLoading) {
    return <QueryLoader testId={loadingTestId} />;
  }
  if (isError) {
    return (
      <Alert severity="error" data-testid={errorTestId}>
        An error occurred whilst loading the data
      </Alert>
    );
  }
  return null;
};

export default QueryStatus;
