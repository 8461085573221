import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Recommendation } from '../../../domain/types';
import { useRecommendationDetails } from './hooks/useRecommendationDetails';
import { useStateContext } from '../../../contexts/StateProvider';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { RecommanderIcon } from './RecommenderIcon';
import { ReactNode, useMemo } from 'react';
import { RecommenderDetails } from './RecommenderDetails';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useCloudEntityId } from './hooks/useCloudEntityId';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import CloseIcon from '@mui/icons-material/Close';
import { useRecommendationProjectId } from './hooks/useRecommendationProjectId';

const OverviewCell = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Typography className={classes.overviewLabel}>
        {label}
        {':'}
      </Typography>
      {children}
    </Box>
  );
};

const renderRecommendationAction = (subType?: string) => {
  if (!subType) {
    return '';
  }

  return subType
    .split('_')
    .map((word) => {
      const [firstLetter, ...rest] = word.toLowerCase().split('');
      return [firstLetter.toUpperCase(), ...rest].join('');
    })
    .join(' ');
};

const commitmentUsageLabels = new Map([
  ['BREAK_EVEN_POINT', 'Maximise Savings'],
  ['LOW_WATERMARK', 'Cover Stable Usage'],
]);

export const RecommenderDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  selectedRecommendation?: Recommendation;
}> = ({ open, onClose, selectedRecommendation }) => {
  const classes = useStyles();

  const { selectedCustomerId } = useStateContext();
  const { details, additionalDetails, isLoading } = useRecommendationDetails(
    selectedRecommendation?.id ?? -1,
    selectedCustomerId
  );

  const cloudEntityType = useCloudEntityId(
    selectedRecommendation?.cloudEntityType ?? ''
  );

  const { project, isLoading: projectIsLoading } = useRecommendationProjectId({
    recommenderSubtype: selectedRecommendation?.recommenderSubtype ?? '',
    targetResources: selectedRecommendation?.targetResources ?? [],
    selectedCustomerId: selectedCustomerId,
  });

  const isAdditionalInfoEnabled = useMemo(() => {
    const hasAdditionalData = additionalDetails?.length > 0;
    const isBillingCommitScoped =
      selectedRecommendation?.recommenderSubtype ===
      'BILLING_ACCOUNT_SCOPED_COMMITMENTS';

    return hasAdditionalData && isBillingCommitScoped;
  }, [selectedRecommendation, additionalDetails]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="recommender-dialog-title"
    >
      <DialogTitle id="recommender-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Recommendation Details</Typography>
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <RemoveRedEyeIcon
              fontSize="small"
              className={classes.sectionIcon}
            />
            Overview
          </Typography>
          <Box display="flex">
            <OverviewCell label="Category">
              <Box className={classes.costOverview}>
                <RecommanderIcon
                  category={
                    selectedRecommendation?.category ?? 'CATEGORY_UNSPECIFIED'
                  }
                />
                <span style={{ marginLeft: 4 }}>
                  {selectedRecommendation?.category ?? 'CATEGORY_UNSPECIFIED'}
                </span>
              </Box>
            </OverviewCell>
            <OverviewCell label="Priority">
              <Chip
                variant="outlined"
                size="small"
                label={selectedRecommendation?.priority}
              />
            </OverviewCell>
            <OverviewCell label="Status">
              <Chip
                variant="outlined"
                size="small"
                label={selectedRecommendation?.state}
              />
            </OverviewCell>
            <OverviewCell label="Refreshed">
              {selectedRecommendation?.date}
            </OverviewCell>
            {selectedRecommendation?.cost && (
              <OverviewCell label="Cost">
                <MonetaryDisplay
                  value={selectedRecommendation?.cost}
                  currency={selectedRecommendation?.currencyCode}
                />
              </OverviewCell>
            )}
          </Box>
        </Box>
        <Box className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <AccountTreeIcon fontSize="small" className={classes.sectionIcon} />
            Resources
          </Typography>
          <Typography className={classes.resourceSubHeader}>
            {cloudEntityType}
          </Typography>
          {selectedRecommendation?.cloudEntityId}
          {projectIsLoading && <QueryLoader />}
          {!projectIsLoading && project && (
            <Box mt={1} display="flex">
              <Box mr={3}>
                <Typography className={classes.resourceSubHeader}>
                  Project id
                </Typography>
                {project.gcpProjectId}
              </Box>
              <Box mr={3}>
                <Typography className={classes.resourceSubHeader}>
                  Project Name
                </Typography>
                {project.gcpProjectName}
              </Box>
              <Box>
                <Typography className={classes.resourceSubHeader}>
                  Project Number
                </Typography>
                {project.gcpProjectNumber}
              </Box>
            </Box>
          )}
          <Typography
            className={classes.resourceSubHeader}
            style={{ marginTop: 8 }}
          >
            Target Resources
          </Typography>
          {selectedRecommendation?.targetResources.map((resource, index) => (
            <div key={index}>{resource}</div>
          ))}
        </Box>
        <Box className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <DescriptionIcon fontSize="small" className={classes.sectionIcon} />
            Description
          </Typography>
          <Typography className={classes.resourceSubHeader}>
            {renderRecommendationAction(
              selectedRecommendation?.recommenderSubtype
            )}
          </Typography>
          {isAdditionalInfoEnabled ? (
            <>
              {additionalDetails.map((item, index) => (
                <Box mb={2} key={index}>
                  {item.description}
                  <Box display="flex" alignItems="center">
                    <Box mr={3} mt={1}>
                      Committment Options
                    </Box>
                    <Box mt={1} position="relative">
                      <div
                        style={{
                          width: 2,
                          background: 'black',
                          height: 27,
                          position: 'absolute',
                          top: 20,
                          left: -12,
                        }}
                      ></div>
                      <div
                        style={{
                          width: 10,
                          background: 'black',
                          height: 2,
                          position: 'absolute',
                          top: 30,
                          left: -22,
                        }}
                      ></div>
                      {item.options.map((option: any, index) => {
                        return (
                          <Box
                            key={index}
                            minWidth={300}
                            mt={1}
                            mb={1}
                            position="relative"
                          >
                            <div
                              style={{
                                width: 10,
                                background: 'black',
                                height: 2,
                                position: 'absolute',
                                top: 10,
                                left: -12,
                              }}
                            ></div>
                            <span
                              className={classes.additionalDetailsAlgorithm}
                            >
                              {commitmentUsageLabels.get(option.algorithm)}
                            </span>
                            <MonetaryDisplay
                              value={option.cost}
                              currency={option.currencyCode}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            selectedRecommendation?.description
          )}
        </Box>
        <Box className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <CodeIcon fontSize="small" className={classes.sectionIcon} />
            Details
          </Typography>
          {isLoading ? (
            <QueryLoader />
          ) : (
            <RecommenderDetails details={details ?? {}} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  jsonContainer: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
  },
  resourceSubHeader: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  overviewLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  overviewCell: {
    marginRight: theme.spacing(2),
  },
  sectionTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  section: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  costOverview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  sectionIcon: {
    marginRight: theme.spacing(0.5),
  },
  additionalDetailsLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  additionalDetailsAlgorithm: {
    marginRight: theme.spacing(1),
    minWidth: 140,
    display: 'inline-block',
  },
}));
