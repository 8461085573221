import firebase from 'firebase/app';
import 'firebase/auth';
import { setupE2E, isE2E_Enabled } from '../setupE2EAutomation';

if (isE2E_Enabled()) {
  setupE2E(firebase);
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
});

export default firebase;
