import { Controller, useFormContext } from 'react-hook-form';
import { BillingAccount } from '../../../domain/types';
import React from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { TextField } from '@mui/material';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { BillingAccountValues } from './BillingAccountSchema';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { CurrencyCodeField } from '../../Fields/CurrencyCodeField';

export const BillingAccountForm: CrudFormComponent<BillingAccount> = ({
  item,
}) => {
  const { selectedCustomerId } = useStateContext();
  const { register, errors, control } = useFormContext<BillingAccountValues>();

  return (
    <>
      <input
        type="hidden"
        name="customerId"
        value={selectedCustomerId}
        ref={register}
      />
      <CrudFormControl error={errors.billingAccountId}>
        <TextField
          id="billingAccountId"
          name="billingAccountId"
          inputRef={register}
          defaultValue={item?.billingAccountId}
          error={!!errors.billingAccountId}
          label="Account ID"
        />
      </CrudFormControl>
      <CrudFormControl error={errors.currency}>
        <Controller
          control={control}
          name="currency"
          defaultValue={item?.currency ?? null}
          render={(props) => (
            <CurrencyCodeField
              {...props}
              label="Currency"
              error={!!errors.currency}
            />
          )}
        />
      </CrudFormControl>
    </>
  );
};
