import { ReactNode, useCallback } from 'react';
import { ModalActions, ModalContent, ModalTitle } from '../../Modals/Modal';
import { Box, Button, Alert } from '@mui/material';
import QueryStatus from '../../Messaging/QueryStatus';
import Dialog from '@mui/material/Dialog';
import { useFormContext } from 'react-hook-form';
import { CrudValueMapper } from '../CrudContainer';

export interface CrudFormModalProps<FormValues, SubmittedValues> {
  title: string;
  buttonText: string;
  mutatingButtonText: string;
  children: ReactNode;
  onSubmit: (data: SubmittedValues) => void;
  onClose: () => void;
  isDataLoading: boolean;
  hasDataError: boolean;
  isMutating: boolean;
  hasMutationError: boolean;
  mutationErrorMessage?: string;
  valueMapper: CrudValueMapper<FormValues, SubmittedValues>;
}

export function CrudFormModal<FormValues, SubmittedValues>({
  title,
  buttonText,
  mutatingButtonText,
  children,
  onSubmit,
  onClose,
  isDataLoading,
  hasDataError,
  isMutating,
  hasMutationError,
  mutationErrorMessage,
  valueMapper,
}: CrudFormModalProps<FormValues, SubmittedValues>) {
  const modalId = 'crud-form-modal';

  const renderContent = useCallback(() => {
    if (isDataLoading || hasDataError) {
      return <QueryStatus isLoading={isDataLoading} isError={hasDataError} />;
    }
    return children;
  }, [children, hasDataError, isDataLoading]);

  const { handleSubmit } = useFormContext<FormValues>();

  const renderMutationError = (errorMessage?: string) => {
    if (errorMessage) {
      return <>Error: {errorMessage}</>;
    }
    return <>There was an error submitting your data. Please try again.</>;
  };

  return (
    <Dialog
      date-testid="crud-modal"
      onClose={onClose}
      aria-labelledby={modalId}
      open
      fullWidth
    >
      <form
        onSubmit={handleSubmit((values) =>
          onSubmit(valueMapper(values as FormValues))
        )}
        noValidate
      >
        <ModalTitle id={modalId} onClose={onClose} showCloseIcon>
          {title}
        </ModalTitle>
        <ModalContent dividers>
          {renderContent()}
          {hasMutationError && (
            <Box mt={3}>
              <Alert severity="error">
                {renderMutationError(mutationErrorMessage)}
              </Alert>
            </Box>
          )}
        </ModalContent>
        <ModalActions data-testid="modal-actions">
          {!isDataLoading && (
            <>
              {!hasDataError && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isMutating}
                >
                  {isMutating ? mutatingButtonText : buttonText}
                </Button>
              )}
              <Button variant="text" disabled={isMutating} onClick={onClose}>
                {hasDataError ? 'Close' : 'Cancel'}
              </Button>
            </>
          )}
        </ModalActions>
      </form>
    </Dialog>
  );
}
