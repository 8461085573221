import { Route, Switch, SwitchProps } from 'react-router';
import { NotFoundRoute } from './NotFoundRoute';

/*
 * Using this instead of the default <Switch> will ensure that all nested
 * Switch blocks will handle not found paths properly
 */

export const SwitchRoute = ({ children, ...switchProps }: SwitchProps) => {
  return (
    <Switch {...switchProps}>
      {children}
      <Route path="*" component={NotFoundRoute} />
    </Switch>
  );
};
