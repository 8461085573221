import { useMemo, useState } from 'react';
import { PageHeader } from '../../PageHeader';
import { Box } from '@mui/material';
import { useSkuGroups } from '../SkuGroupSku/SkuGroupSku';
import { SkuAdminGrid } from './SkuAdminGrid';
import { SearchModel } from './types';
import { useSkuAllocation } from './hooks/useSkuAllocation';
import { SkusAdminFiltersPanel } from './SkusAdminFiltersPanel';
import { SkusAdminAssignPanel } from './SkusAdminAssignPanel';
import { VOID_ID } from '../../../domain/constants';

export const SkusAdmin = () => {
  const [filterUngrouped, setFilterUngrouped] = useState(false);

  const { items: skuGroups } = useSkuGroups();
  const [selectedSkuGroup, setSelectedSkuGroup] = useState<number>(VOID_ID);
  const [selectedSkus, setSelectedSkus] = useState<number[]>([]);
  const [searchModel, setSearchModel] = useState<SearchModel>({
    search: '',
    field: '',
  });
  const { allocate, isLoading: isAllocateLoading } = useSkuAllocation();

  const allocationEnabled = useMemo(() => {
    return (
      !isAllocateLoading &&
      selectedSkus.length > 0 &&
      selectedSkuGroup !== VOID_ID
    );
  }, [selectedSkus, isAllocateLoading, selectedSkuGroup]);

  return (
    <>
      <PageHeader title="All SKUs" />
      <Box display="flex" alignItems="center" mb={2}>
        <SkusAdminFiltersPanel
          filterUngrouped={filterUngrouped}
          onFilterUngrouped={(checked) => setFilterUngrouped(checked)}
          searchModel={searchModel}
          onSearchModelChange={(model) => setSearchModel(model)}
        />

        <SkusAdminAssignPanel
          allocationEnabled={allocationEnabled}
          onAllocate={() =>
            allocate({ skuIds: selectedSkus, skuGroupId: selectedSkuGroup })
          }
          onSetSelectedGroup={(skuGroupId) => setSelectedSkuGroup(skuGroupId)}
          skuGroups={skuGroups}
        />
      </Box>
      <SkuAdminGrid
        filterUngrouped={filterUngrouped}
        searchModel={searchModel}
        onSearchModelChange={(model) => setSearchModel(model)}
        onSkusSelectionChange={(skusIds: number[]) => setSelectedSkus(skusIds)}
      />
    </>
  );
};
