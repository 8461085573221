import { Controller, useFormContext } from 'react-hook-form';
import {
  BudgetAllocation,
  BudgetAllocationAmountType,
  BudgetAllocationProject,
  BudgetAllocationType,
  RemainingBudget,
} from '../../../domain/types';
import React, { useMemo } from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { BudgetAllocationValues } from './BudgetAllocationSchema';
import { useParams } from 'react-router-dom';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import {
  Autocomplete,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useGetAvailableGroupsOrProjects } from './useGetAvailableGroupsOrProjects';
import { useGetRemainingBudget } from './useGetRemainingBudget';
import { useGetCustomerCurrency } from './useGetCustomerCurrency';
import { useGetBudget } from './useGetBudget';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { PercentageDisplay } from '../../PercentageDisplay';

export const BudgetAllocationForm: CrudFormComponent<BudgetAllocation> = ({
  item,
}) => {
  const { selectedCustomerId } = useStateContext();
  const { budgetId } = useParams<{ budgetId: string }>();
  const {
    register,
    errors,
    control,
    watch,
    setValue,
  } = useFormContext<BudgetAllocationValues>();

  const amountType = watch('amountType');

  const clearAmountField = () => {
    setValue('amount', '');
  };

  const { data: budget } = useGetBudget(selectedCustomerId, Number(budgetId));

  const {
    data: availableProjects,
    isLoading: availableProjectsLoading,
  } = useGetAvailableGroupsOrProjects(selectedCustomerId, Number(budgetId));

  const { data: remainingBudget } = useGetRemainingBudget(
    selectedCustomerId,
    Number(budgetId)
  );

  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  let projectItems: BudgetAllocationProject[] = item
    ? [item.projectOrGroup, ...(availableProjects ?? [])]
    : availableProjects ?? [];

  if (budget?.allocationType === BudgetAllocationType.PROJECT) {
    projectItems = projectItems.map((item) => ({
      id: item.id,
      name: `${item.gcpProjectName} (${item.gcpProjectId})`,
    }));
  }

  const remainingBudgetIncludingCurrent: RemainingBudget = useMemo(() => {
    if (!remainingBudget) {
      return {
        absolute: 0,
        percentage: 0,
      };
    }
    if (!item || !budget) {
      return remainingBudget;
    }
    if (item.amountType === BudgetAllocationAmountType.ABSOLUTE) {
      const itemPercentage = (1 / budget.amount) * item.amount;
      return {
        absolute: remainingBudget.absolute + item.amount,
        percentage: remainingBudget.percentage + itemPercentage,
      };
    }
    const itemAbsolute = budget.amount * item.amount;
    return {
      absolute: remainingBudget.absolute + itemAbsolute,
      percentage: remainingBudget.percentage + item.amount,
    };
  }, [item, budget, remainingBudget]);

  const defaultProjectGroup = item?.projectOrGroup
    ? {
        ...item.projectOrGroup,
        name: `${item.projectOrGroup.gcpProjectName} (${item.projectOrGroup.gcpProjectId})`,
      }
    : null;

  return (
    <>
      <input
        type="hidden"
        name="maximumAmount"
        value={
          amountType === BudgetAllocationAmountType.ABSOLUTE
            ? remainingBudgetIncludingCurrent?.absolute
            : remainingBudgetIncludingCurrent?.percentage
        }
        ref={register}
      />
      <CrudFormControl error={errors.projectOrGroup}>
        <Controller
          control={control}
          name="projectOrGroup"
          defaultValue={defaultProjectGroup ?? null}
          render={({ value, onChange, ...props }) => (
            <Autocomplete<BudgetAllocationProject>
              {...props}
              value={value}
              onChange={(e, value) => {
                onChange(value);
              }}
              loading={availableProjectsLoading}
              options={projectItems}
              getOptionLabel={(item: BudgetAllocationProject) => item.name}
              isOptionEqualToValue={(
                option: BudgetAllocationProject,
                value: BudgetAllocationProject
              ) => {
                return option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    budget?.allocationType === BudgetAllocationType.GROUP
                      ? 'Project Group'
                      : 'Project'
                  }
                  error={!!errors.projectOrGroup}
                />
              )}
            />
          )}
          project
        />
      </CrudFormControl>
      <CrudFormControl error={errors.amountType}>
        <Controller
          control={control}
          name="amountType"
          defaultValue={item?.amountType ?? ''}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="amount-type-label">Amount Type</InputLabel>
              <Select
                onChange={(e) => {
                  clearAmountField();
                  onChange(e);
                }}
                value={value}
                ref={register}
                id="amountType"
                labelId="amount-type-label"
              >
                <MenuItem value={BudgetAllocationAmountType.ABSOLUTE}>
                  Absolute Value ({currency})
                </MenuItem>
                <MenuItem value={BudgetAllocationAmountType.PERCENTAGE}>
                  Percentage %
                </MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>

      <CrudFormControl error={errors.amount}>
        {amountType === BudgetAllocationAmountType.PERCENTAGE ? (
          <>
            <p>
              Remaining budget allocation percentage:{' '}
              <PercentageDisplay
                value={remainingBudgetIncludingCurrent?.percentage}
              />
            </p>
            <Controller
              control={control}
              name="amount"
              defaultValue={item?.amount}
              render={({ onChange, value, ...props }) => (
                <TextField
                  {...props}
                  type="number"
                  value={value ? Math.round(value * 10000) / 100 : ''}
                  onChange={(e) =>
                    onChange(
                      e.target.value === ''
                        ? ''
                        : Number(e.target.value.replace(/[^0-9.]/g, '')) / 100
                    )
                  }
                  label="Amount"
                  name="amount"
                  id="amount"
                  error={!!errors.amount}
                  InputLabelProps={{ htmlFor: 'amount' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        ) : (
          <>
            <p>
              Remaining budget allocation:{' '}
              <MonetaryDisplay
                value={remainingBudgetIncludingCurrent?.absolute}
                currency={currency}
              />
            </p>
            <Controller
              id="amount"
              name="amount"
              control={control}
              defaultValue={item?.amount ?? ''}
              render={(props) => (
                <MonetaryValueField
                  error={!!errors.amount}
                  label="Amount"
                  id="amount"
                  InputProps={props}
                />
              )}
            />
          </>
        )}
      </CrudFormControl>
    </>
  );
};
