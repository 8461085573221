import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import { SkuGroup } from '../../../domain/types';
import { WidgetContainer } from '../../WidgetContainer';

interface ComponentProps {
  skuGroups: SkuGroup[];
  onSetSelectedGroup: (skuGroupId: number) => void;
  onAllocate: () => void;
  allocationEnabled: boolean;
}

export const SkusAdminAssignPanel = ({
  skuGroups,
  onSetSelectedGroup,
  onAllocate,
  allocationEnabled,
}: ComponentProps) => {
  const getOptionLabel = (id: number) => {
    return skuGroups.find((item) => item.id === id)?.groupName ?? '';
  };

  return (
    <WidgetContainer>
      <Box pt={3} pb={2} pr={2} pl={2}>
        <Box display="flex" alignItems="flex-end" width={400}>
          <Autocomplete<number>
            fullWidth
            options={skuGroups.map(({ id }) => id)}
            onChange={(_, value) => onSetSelectedGroup(value ?? -1)}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField {...params} label="SKU group" />
            )}
          />

          <Button
            disabled={!allocationEnabled}
            onClick={onAllocate}
            style={{ width: 200, marginLeft: 16 }}
            size="small"
            color="primary"
            startIcon={<AddBox fontSize="small" />}
          >
            Add to group
          </Button>
        </Box>
      </Box>
    </WidgetContainer>
  );
};
