import { useCrudList } from '../../Crud/grid/useCrudList';
import { ProjectGroup } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useProjectGroups = (customerId: number, groupId: number) => {
  const { data, isLoading, isError } = useCrudList<ProjectGroup>({
    apiPath: apiPaths.projectGroup(customerId),
  });

  // split the returned data into:
  // - a list of project group ids for the autocomplete options
  // - a map of id->name to display each option to the user
  const {
    projectGroupIds,
    projectGroupIdsToName,
    selectedGroupId,
  } = useMemo(() => {
    const projectGroups = data?.items ?? [];
    const projectGroupIds = projectGroups.map(({ id }) => id);
    const projectGroupIdsToName = projectGroups.reduce((map, group) => {
      return map.set(group.id, group.name);
    }, new Map<number, string>());

    const selectedGroupId =
      isNaN(groupId) || !projectGroupIds.includes(groupId) ? null : groupId;

    return { projectGroupIds, projectGroupIdsToName, selectedGroupId };
  }, [data?.items, groupId]);

  return {
    isLoading,
    isError,
    projectGroupIds,
    projectGroupIdsToName,
    selectedGroupId,
  };
};
