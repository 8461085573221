import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import {
  Note,
  Done,
  Timelapse,
  Error as ErrorIcon,
  Cancel,
  DoneAll,
  Autorenew,
  PlaylistAddCheck,
} from '@mui/icons-material';
import { formatDuration, intervalToDuration } from 'date-fns';
import { ReactNode, useState } from 'react';
import {
  DATA_GRID_PAGE_LIMIT,
  UI_DATE_TIME_FORMAT,
} from '../../../domain/constants';
import {
  ProcessResult,
  ProcessStatus,
  SystemLock,
} from '../../../domain/types';
import { DateDisplay } from '../../DateDisplay';
import { useSystemManagementData } from './useSystemManagementData';
import { SystemManagementProcessDialog } from './SystemManagementProcessDialog';
import { GridWidgetContainer } from '../../GridWidgetContainer';
import { PageHeader } from '../../PageHeader';

const statusIcons = new Map([
  [ProcessStatus.COMPLETE, <DoneAll fontSize="small" />],
  [ProcessStatus.RUNNING, <Autorenew fontSize="small" />],
  [ProcessStatus.QUEUED, <PlaylistAddCheck fontSize="small" />],
]);

const resultIcons = new Map([
  [ProcessResult.SUCCESS, <Done fontSize="small" />],
  [ProcessResult.PENDING, <Timelapse fontSize="small" />],
  [ProcessResult.FAILED, <ErrorIcon fontSize="small" />],
  [ProcessResult.ABORTED, <Cancel fontSize="small" />],
]);

interface IconCellProps<T> {
  value: T;
  icons: Map<T, ReactNode>;
}

function IconCell<T>({ value, icons }: IconCellProps<T>) {
  const classes = useStyles();
  const icon = icons.get(value);

  return (
    <div className={classes.stateCell}>
      {icon}
      <span className={classes.stateCellLabel}>{value}</span>
    </div>
  );
}

export const SystemManagement: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GRID_PAGE_LIMIT,
  });
  const [dialogOpened, setDialogOpened] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState<SystemLock | null>(
    null
  );
  const classes = useStyles();
  const { items, isLoading, totalItems } = useSystemManagementData(
    paginationModel.page
  );

  const handleOpenProcess = (row: unknown) => {
    setSelectedProcess(row as SystemLock);
    setDialogOpened(true);
  };

  const columns: GridColDef[] = [
    {
      renderHeader: () => <span></span>,
      field: 'details',
      width: 60,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => handleOpenProcess(row)}
            color="primary"
            aria-label="process details"
            component="span"
            size="large"
          >
            <Note />
          </IconButton>
        );
      },
    },
    {
      headerName: 'Task Name',
      field: 'stateValues',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { task_name } = row?.stateValues;
        return <div>{task_name}</div>;
      },
    },
    {
      headerName: 'Status',
      field: 'processStatus',
      width: 130,
      renderCell: ({ row }) => (
        <IconCell<ProcessStatus>
          value={row?.processStatus}
          icons={statusIcons}
        />
      ),
    },
    {
      headerName: 'Result',
      field: 'processResult',
      width: 130,
      renderCell: ({ row }) => (
        <IconCell<ProcessResult>
          value={row?.processResult}
          icons={resultIcons}
        />
      ),
    },
    {
      headerName: 'Start',
      field: 'timeBegin',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { timeBegin } = row;

        return timeBegin ? (
          <DateDisplay format={UI_DATE_TIME_FORMAT} date={timeBegin} />
        ) : (
          <></>
        );
      },
    },
    {
      headerName: 'End',
      field: 'timeEnd',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { timeEnd } = row;

        return timeEnd ? (
          <DateDisplay format={UI_DATE_TIME_FORMAT} date={timeEnd} />
        ) : (
          <></>
        );
      },
    },
    {
      headerName: 'Duration',
      field: 'duration',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { timeBegin, timeEnd } = row;
        const duration = intervalToDuration({
          start: new Date(timeBegin),
          end: new Date(timeEnd),
        });

        return <div>{formatDuration(duration)}</div>;
      },
    },
    {
      headerName: 'Retries',
      field: 'retry',
      flex: 0.5,
      renderCell: ({ row }) => {
        const { stateValues } = row;

        return <div>{stateValues?.retry ?? 0}</div>;
      },
    },
    {
      headerName: 'Process ID',
      field: 'processId',
      flex: 0.5,
    },
  ];

  return (
    <>
      <PageHeader title="System Monitoring" />
      <GridWidgetContainer className={classes.gridContainer}>
        <DataGrid
          getRowId={({ processId }) => processId}
          density="compact"
          columns={columns}
          disableColumnFilter
          paginationMode="server"
          rows={items}
          loading={isLoading}
          rowCount={totalItems}
          paginationModel={paginationModel}
          pageSizeOptions={[15]}
          onPaginationModelChange={(model) => setPaginationModel(model)}
          columnBuffer={columns.length - 1}
        />
      </GridWidgetContainer>
      <SystemManagementProcessDialog
        selectedProcess={selectedProcess}
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  stateCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  stateCellLabel: {
    marginLeft: theme.spacing(0.5),
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
}));
