import React, { useEffect, useState } from 'react';
import { Project } from '../../../domain/types';
import { Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowModel,
} from '@mui/x-data-grid';
import { CrudDataGridError } from '../../Crud/grid/CrudDataGridError';
import { GridWidgetContainer } from '../../GridWidgetContainer';

interface UngroupedProjectsTableProps {
  projects: Project[];
  loading: boolean;
  error: any;
  onSelectionChanged: (projects: Project[]) => void;
}

interface SelectableProject extends Project {
  selected?: boolean;
  visible?: boolean;
}

export const UngroupedProjectsTable = ({
  projects,
  loading,
  error,
  onSelectionChanged,
}: UngroupedProjectsTableProps) => {
  const classes = useStyles();

  const [selectedProjects, setSelectedProjects] = useState<SelectableProject[]>(
    []
  );

  useEffect(
    () =>
      setSelectedProjects(
        (projects || []).map((project) => ({
          ...project,
          selected: false,
          visible: true,
        }))
      ),
    [projects]
  );

  const setProjectSelected = (id: number, selected: boolean) => {
    const projects = selectedProjects?.map((project) => {
      if (project.id === id) {
        return {
          ...project,
          selected,
        };
      }
      return project;
    });

    setSelectedProjects(projects);

    onSelectionChanged(projects?.filter((project) => project.selected) ?? []);
  };

  const setSelectedAllProjects = (selected: boolean) => {
    const projects = selectedProjects?.map((project) => ({
      ...project,
      selected: !!(selected && project.visible),
    }));

    setSelectedProjects(projects);

    onSelectionChanged(projects?.filter((project) => project.selected) ?? []);
  };

  const visibleProjects = selectedProjects?.filter(
    (project: SelectableProject) => project.visible
  );

  const allProjectsSelected =
    visibleProjects.length > 0 &&
    visibleProjects.every(({ selected }) => selected === true);

  const filtersChangedCallback = (params: GridFilterModel) => {
    const projects = selectedProjects?.map((project) => ({
      ...project,
      selected: false,
      visible: !!params.items.find(
        // TODO: check
        (visible: GridRowModel) => visible.id === project.id
      ),
    }));

    setSelectedProjects(projects);
  };

  const columns: GridColDef[] = [
    {
      field: 'selected',
      width: 40,
      headerClassName: classes.checkboxHeader,
      cellClassName: classes.checkbox,
      renderHeader: () =>
        selectedProjects?.length > 0 ? (
          <Checkbox
            checked={allProjectsSelected}
            onChange={(e) => {
              setSelectedAllProjects(e.target.checked);
            }}
            size="small"
            color="primary"
          />
        ) : (
          <span />
        ),
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: ({ row: { id, selected } }) => (
        <Checkbox
          checked={selected}
          onChange={(e) => {
            setProjectSelected(id as number, e.target.checked);
          }}
          size="small"
          color="primary"
        />
      ),
    },
    {
      headerName: 'Project ID',
      field: 'gcpProjectId',
      flex: 1,
    },
    {
      headerName: 'Project Name',
      field: 'gcpProjectName',
      flex: 1,
    },
  ];

  return (
    <GridWidgetContainer>
      {error ? (
        <CrudDataGridError />
      ) : (
        <DataGrid
          className={classes.grid}
          // data
          columns={columns}
          rows={selectedProjects ?? []}
          // state
          loading={loading}
          //error={error}
          //components={{
          //  ErrorOverlay: CrudDataGridError,
          //}}
          // layout
          density="compact"
          disableRowSelectionOnClick
          onFilterModelChange={filtersChangedCallback}
        />
      )}
    </GridWidgetContainer>
  );
};

const useStyles = makeStyles({
  grid: {
    '&.MuiDataGrid-root': {
      border: 'none',
    },
  },
  checkboxHeader: {
    '&.MuiDataGrid-colCell': {
      padding: 0,
    },
  },
  checkbox: {
    '&.MuiDataGrid-cell': {
      padding: 0,
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
});
