import { addMilliseconds, formatDuration, intervalToDuration } from 'date-fns';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;

const msToHours = (milliseconds: number): string => {
  if (!milliseconds) {
    return '';
  }

  const hours = milliseconds / ONE_HOUR_IN_MS;

  return hours.toLocaleString('en-GB', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

const humanizeMilliseconds = (milliseconds: number): string => {
  if (!milliseconds) {
    return '';
  }

  const start = new Date(0);
  const end = addMilliseconds(start, milliseconds);
  const diff = intervalToDuration({
    start,
    end,
  });

  return formatDuration(diff);
};

export const formatBigQueryUsageDuration = (
  milliseconds: number
): { hours: string; formatted: string } => {
  return {
    hours: msToHours(milliseconds),
    formatted: humanizeMilliseconds(milliseconds),
  };
};
