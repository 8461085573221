import { SystemConfigurationResource } from '../../components/Admin/SystemConfiguration/SystemConfigurationTypes';
import {
  ApportionmentAllocationType,
  ApportionmentSplitType,
  BillingAccount,
  Budget,
  BudgetAllocation,
  BudgetAllocationAmountType,
  BudgetAllocationType,
  Commit,
  CommitTrackingType,
  CommitType,
  Customer,
  Project,
  ProjectContactType,
  ProjectGroup,
  PurchaseOrder,
  RemainingBudget,
  Role,
  Service,
  ServiceType,
  ServiceApportionment,
  ServiceCharge,
  BillingServiceCharge,
  User,
  ServiceDefault,
  SystemLock,
  ProcessStatus,
  ProcessResult,
  CustomerDiscount,
  SkuGroup,
  SkuGroupingSource,
  CommitKind,
} from '../../domain/types';

export const mockBillingAccountData: BillingAccount[] = Array.from(
  { length: 10 },
  (_, index) => ({
    id: index + 1,
    currency: index % 2 ? 'GBP' : 'USD',
    billingAccountId: `BILLING-${index + 1}`,
    customerId: 1,
  })
);

export const mockCustomerData: Customer[] = Array.from(
  { length: 40 },
  (_, index) => ({
    id: index + 1,
    name: `Customer ${index + 1}`,
    allowedDomains: ['test.com'],
    customer_project_groups: [{}],
  })
);

export const mockProjects: Project[] = Array.from(
  { length: 40 },
  (_, index) => ({
    id: index + 1,
    customerId: index + 1,
    gcpProjectId: `PROJECT-${index + 1}`,
    gcpProjectName: `Project ${index + 1}`,
    description: `PROJECT-${index + 1}`,
    name: `PROJECT-${index + 1}`,
    visible: true,
  })
);

export const mockSysAdminUserData: User[] = [
  {
    id: 1,
    email: 'sysadmin@test.com',
    role: Role.SYSTEM_ADMIN,
    customers: [],
    restrictedProjectGroups: [],
    restrictedProjects: [],
  },
  {
    id: 2,
    email: 'admin@customer1.com',
    role: Role.CUSTOMER_ADMIN,
    customers: [mockCustomerData[0]],
    restrictedProjectGroups: [],
    restrictedProjects: [],
  },
];

export const mockCustomerAdminUserData: User[] = [mockSysAdminUserData[1]];

export const mockProjectGroupData: ProjectGroup[] = Array.from(
  { length: 20 },
  (_, index) => ({
    id: index + 1,
    name: `Project Group ${index + 1}`,
    type: 'Default',
    customerId: 1,
  })
);

export const mockProjectData: Project[] = [
  {
    id: 1,
    customerId: 1,
    gcpProjectId: 'project-1',
    gcpProjectName: 'Project 1',
    description: 'This is Project 1',
    contacts: {
      [ProjectContactType.BUSINESS_OWNER]: {
        name: 'Owner 1',
        email: 'owner1@test.com',
      },
      [ProjectContactType.PRODUCT_MANAGER]: {
        name: 'Manager 1',
        email: 'manager1@test.com',
      },
    },
  },
  {
    id: 2,
    customerId: 1,
    gcpProjectId: 'project-2',
    gcpProjectName: 'Project 2',
    description: 'This is Project 2',
    contacts: {
      [ProjectContactType.BUSINESS_OWNER]: {
        name: 'Owner 2',
        email: 'owner2@test.com',
      },
    },
  },
  {
    id: 3,
    customerId: 1,
    gcpProjectId: 'project-3',
    gcpProjectName: 'Project 3',
    description: 'This is Project 3',
  },
];

export const mockCommitData: Commit[] = [
  {
    id: 1,
    customerId: 1,
    startDate: '2021-01-01',
    endDate: '2023-12-31',
    type: CommitType.SOFT,
    trackingType: CommitTrackingType.ANNUAL,
    numberOfYears: 3,
    currency: 'GBP',
    superseded: false,
    allowsExcessRollover: false,
    kind: CommitKind.SERVICE,
    commitAmounts: [
      { startDate: '2021-01-01', softAmount: 20000 },
      { startDate: '2022-01-01', softAmount: 22500 },
      { startDate: '2023-01-01', softAmount: 25000 },
    ],
  },
  {
    id: 2,
    customerId: 1,
    startDate: '2024-01-01',
    endDate: '2028-01-01',
    type: CommitType.HARD,
    trackingType: CommitTrackingType.ANNUAL,
    numberOfYears: 3,
    currency: 'GBP',
    superseded: false,
    allowsExcessRollover: false,
    kind: CommitKind.SERVICE,
    commitAmounts: [
      { startDate: '2024-01-01', hardAmount: 22500 },
      { startDate: '2025-01-01', hardAmount: 25000 },
      { startDate: '2026-01-01', hardAmount: 27500 },
    ],
  },
  {
    id: 3,
    customerId: 1,
    startDate: '2027-01-01',
    endDate: '2029-12-31',
    type: CommitType.HARD_AND_SOFT,
    trackingType: CommitTrackingType.ANNUAL,
    numberOfYears: 3,
    currency: 'GBP',
    superseded: false,
    allowsExcessRollover: false,
    kind: CommitKind.SERVICE,
    commitAmounts: [
      { startDate: '2027-01-01', hardAmount: 17500, softAmount: 22500 },
      { startDate: '2028-01-01', hardAmount: 22500, softAmount: 25000 },
      { startDate: '2029-01-01', hardAmount: 25000, softAmount: 27500 },
    ],
  },
  {
    id: 4,
    customerId: 1,
    startDate: '2030-01-01',
    endDate: '2032-12-31',
    type: CommitType.HARD_AND_SOFT,
    trackingType: CommitTrackingType.FULL,
    numberOfYears: 3,
    currency: 'GBP',
    superseded: false,
    allowsExcessRollover: false,
    kind: CommitKind.SERVICE,
    commitAmounts: [
      { startDate: '2030-01-01', hardAmount: 70000, softAmount: 100000 },
    ],
  },
];

export const mockBudgetsData: Budget[] = [
  {
    id: 1,
    allocationType: BudgetAllocationType.PROJECT,
    amount: 100000,
    customerId: 1,
    startDate: '2021-12-21',
    endDate: '2022-12-21',
  },
  {
    id: 2,
    allocationType: BudgetAllocationType.GROUP,
    amount: 100000,
    customerId: 1,
    startDate: '2022-12-21',
    endDate: '2023-12-21',
  },
];

export const mockBudgetAllocationsData: BudgetAllocation[] = [
  {
    id: 1,
    projectOrGroup: {
      id: 1,
      name: 'Project 1',
      gcpProjectId: 'project-1',
      gcpProjectName: 'Project 1',
    },
    amountType: BudgetAllocationAmountType.PERCENTAGE,
    amount: 0.4,
  },
  {
    id: 2,
    projectOrGroup: {
      id: 2,
      name: 'Project 2',
      gcpProjectId: 'project-2',
      gcpProjectName: 'Project 2',
    },
    amountType: BudgetAllocationAmountType.ABSOLUTE,
    amount: 20000,
  },
];

export const mockRemainingBudgetData: RemainingBudget = {
  absolute: 700000,
  percentage: 70,
};

const [firstBudget, secondBudget] = mockBudgetsData;
export const mockPurchaseOrderData: PurchaseOrder[] = [
  {
    id: 1,
    customerId: 1,
    poNumber: 'pn-12345',
    date: '2021-04-01',
    renewalDate: '2021-06-01',
    amount: 100_000,
    currency: 'GBP',
    budget: {
      id: firstBudget.id,
      startDate: firstBudget.startDate,
      endDate: firstBudget.endDate,
    },
  },
  {
    id: 2,
    customerId: 1,
    poNumber: 'pn-23456',
    date: '2021-06-11',
    renewalDate: '2021-12-01',
    amount: 75_000,
    currency: 'GBP',
    budget: {
      id: firstBudget.id,
      startDate: firstBudget.startDate,
      endDate: firstBudget.endDate,
    },
  },
  {
    id: 3,
    customerId: 1,
    poNumber: 'pn-34567',
    date: '2021-09-23',
    renewalDate: '2021-12-01',
    amount: 6500.56,
    currency: 'USD',
    budget: {
      id: secondBudget.id,
      startDate: secondBudget.startDate,
      endDate: secondBudget.endDate,
    },
  },
];

export const mockServiceData: Service[] = [
  {
    id: 1,
    name: 'Looker',
    type: ServiceType.THIRD_PARTY,
  },
  {
    id: 2,
    name: 'Data Bricks',
    type: ServiceType.THIRD_PARTY,
  },
  {
    id: 3,
    name: 'Auth0',
    type: ServiceType.THIRD_PARTY,
  },
  {
    id: 4,
    name: 'Okta',
    type: ServiceType.THIRD_PARTY,
  },
  {
    id: 5,
    name: 'Passport',
    type: ServiceType.THIRD_PARTY,
  },
  {
    id: 20,
    name: 'SendGrid Mock',
    type: ServiceType.GCP,
    commitIncluded: true,
  },
  {
    id: 21,
    name: 'Support Mock',
    type: ServiceType.GCP,
    commitIncluded: false,
  },
];

export const mockServiceApportionmentsData: ServiceApportionment[] = [
  {
    id: 1,
    serviceId: 1,
    serviceName: 'Test-01',
    allocationType: ApportionmentAllocationType.ALL_PROJECTS,
    splitType: ApportionmentSplitType.EVEN_SPLIT,
  },
  {
    id: 2,
    serviceId: 2,
    serviceName: 'Test-01',
    allocationType: ApportionmentAllocationType.PROJECT,
    splitType: ApportionmentSplitType.EVEN_SPLIT,
    apportionments: [
      {
        projectId: 1,
      },
      {
        projectId: 2,
      },
    ],
  },
  {
    id: 3,
    serviceId: 5,
    serviceName: 'Test-01',
    allocationType: ApportionmentAllocationType.PROJECT_GROUP,
    splitType: ApportionmentSplitType.EVEN_SPLIT,
    apportionments: [
      {
        projectGroupId: 1,
      },
      {
        projectGroupId: 2,
      },
    ],
  },
  {
    id: 4,
    serviceId: 3,
    serviceName: 'Test-01',
    allocationType: ApportionmentAllocationType.PROJECT,
    splitType: ApportionmentSplitType.PERCENTAGE_OF_SPEND,
    apportionments: [
      {
        projectId: 1,
        percentage: 0.25,
      },
      {
        projectId: 2,
        percentage: 0.25,
      },
      {
        projectId: 3,
        percentage: 0.5,
      },
    ],
  },
  {
    id: 5,
    serviceId: 4,
    serviceName: 'Test-01',
    allocationType: ApportionmentAllocationType.PROJECT_GROUP,
    splitType: ApportionmentSplitType.PERCENTAGE_OF_SPEND,
    apportionments: [
      {
        projectGroupId: 1,
        percentage: 0.6,
      },
      {
        projectGroupId: 2,
        percentage: 0.4,
      },
    ],
  },
];

export const mockServiceChargeData: ServiceCharge[] = [
  {
    id: 1,
    customerId: 1,
    serviceId: 1,
    amount: 10,
    currency: 'USD',
    invoiceNumber: 'LOOK2123125',
    invoiceDate: '2021-05-01',
    settleDate: '2021-06-03',
    toUsd: 1,
  },
  {
    id: 2,
    customerId: 1,
    serviceId: 2,
    amount: 20,
    currency: 'USD',
    invoiceNumber: 'LOOK2123124',
    invoiceDate: '2021-01-01',
    settleDate: '2021-06-01',
    toUsd: 1,
  },
];

export const mockServiceDefaultData: ServiceDefault[] = [
  {
    serviceName: 'BigQuery',
    countsTowardCommit: true,
    serviceId: 'AAAA-ABCBS-123',
    type: 'GCP',
    dateOfFirstCharge: '2022-01-01',
    id: 1,
  },
];

export const mockSystemConfigData = (
  resourceType: SystemConfigurationResource
) => {
  const services = [
    {
      serviceName: 'BigQuery',
      countsTowardCommit: true,
      serviceId: 'AAAA-ABCBS-123',
      type: 'GCP',
      dateOfFirstCharge: '2022-01-01',
      lastEdit: '2023-10-30',
      lastCustomerEdit: '2023-10-30',
      id: 1,
    },
  ];

  const skuGroups = [
    {
      id: 1,
      countsTowardCommit: true,
      dateOfFirstCharge: '2022-01-01',
      lastEdit: '2023-10-30',
      lastCustomerEdit: '2023-10-30',
      skuGroupName: 'Test API SKU Group',
      skuGroupId: 'AAAA-BBBB-0000',
      source: 'API',
    },
  ];

  return resourceType === 'SKU_GROUP' ? skuGroups : services;
};

export const mockBillingServiceChargesData: BillingServiceCharge[] = [
  {
    id: 1,
    ids: [1, 2],
    serviceId: 20,
    serviceIds: [20],
    serviceName: 'SendGrid Mock',
    serviceType: 'GCP',
    cost: 10,
    credit: 5,
    netCost: 5,
    usageDate: '2020-01-15',
  },
  {
    id: 2,
    ids: [3, 4],
    serviceId: 21,
    serviceIds: [21],
    serviceName: 'Support Mock',
    serviceType: 'GCP',
    cost: 30,
    credit: 10,
    netCost: 20,
    usageDate: '2020-01-15',
  },
];

export const mockCustomerServicesData = [
  {
    id: 10005,
    name: 'App Engine',
    type: ServiceType.GCP,
    commitIncluded: true,
  },
  {
    id: 10001,
    name: 'Cloud SQL',
    type: ServiceType.GCP,
    commitIncluded: false,
  },
  {
    id: 217,
    name: 'MongoDB Inc. MongoDB Atlas',
    type: ServiceType.GCP,
    commitIncluded: false,
  },
  {
    id: 10006,
    name: 'Street View Static API',
    type: ServiceType.GCP,
    commitIncluded: false,
  },
  {
    id: 11003,
    name: 'ThirdPartyService',
    type: ServiceType.THIRD_PARTY,
    commitIncluded: false,
  },
];

export const customersCommitTrackerData = Array.from(
  { length: 40 },
  (_, index) => {
    const seed = 3 % (index + 1);
    const totalCommitAmount =
      60_000 + seed * index + (index % 3 === 0 ? 2003000 : 0);

    const commitPeriodAmount = 40_000 + seed * index;

    const currentCommitConsumptionToDate =
      commitPeriodAmount - (5000 + seed * index);

    const totalForecastConsumptionPeriod =
      currentCommitConsumptionToDate + 3000 + (index % 7 ? 10_000 : 0);

    return {
      id: index,
      customerId: index + 1,
      customerName: `Customer ${index + 1}`,
      commitLength: seed,
      totalCommitAmount,
      commitType: index % 3 === 0 ? CommitType.SOFT : CommitType.HARD,
      commitPeriodAmount,
      commitPeriodStartDate: `2021-03-0${seed}`,
      commitPeriodEndDate: `2021-03-0${seed}`,
      currentCommitConsumptionToDate,
      totalForecastConsumptionPeriod,
      currentCommitAchievedDate: '2021-06-05',
      currentCommitShortfall: 20000_000,
    };
  }
) as any;

const processId = '39ec9d9e-e808-4472-aca5-f4032ac039c8';
export const systemSyncData: SystemLock[] = [
  {
    processId,
    processStatus: ProcessStatus.COMPLETE,
    processResult: ProcessResult.SUCCESS,
    stateValues: {
      task_name: 'sync_billing_acc_charges',
      process_id: processId,
      prep_job_id: '9618e5f2-1d2a-44eb-addf-e4f0fcd20740',
      earliest_usage_date: '2022-10-10',
      usage_dates: [
        "'2022-10-31'",
        "'2022-11-01'",
        "'2022-10-30'",
        "'2022-10-13'",
        "'2022-10-10'",
        "'2022-10-25'",
      ],
      job_id: 'fb688022-6841-4208-a389-93cd10cefb7f',
      next_page: null,
    },
    timeBegin: '2022-11-02T04:30:03.856590+00:00',
    timeEnd: '2022-11-02T04:32:11.898311+00:00',
  },
];

export const skuGroups: SkuGroup[] = [
  '3rd party Software',
  'A2 VMs (1 Year CUD)',
  'On-Demand VMs',
  'Cloud Commitment Agreement 2021',
  'On-Demand Compute',
  'Enterprise Agreement 2023',
  'Cloud Commitment Agreement 2021.1',
  'Dynamic EDP 2020',
  'GCP Reseller',
  'Enterprise Agreement Excluding Preemptible Compute & Premium Operating Systems',
  'Dynamic EDP 2020 Excluding Preemptible Compute & Premium Operating Systems',
  'IWC for Security Foundations.2023',
  'Enterprise Agreement 2021.1 Premium',
  'Security',
  'Enterprise Agreement 2023',
  'BigQuery',
  'Dynamic EDP 2020',
  'Enterprise Agreement Excluding Preemptible Compute & Premium Operating Systems',
].map((groupName, index) => ({
  groupName,
  id: index + 1,
  source: SkuGroupingSource.API,
  createdAt: '2023-01-01',
  updatedAt: '2023-01-02',
}));

export const customerDiscounts: CustomerDiscount[] = [
  {
    id: 1,
    percentage: 7,
    startDate: '2023-01-01',
    endDate: '2023-02-01',
    createAt: '2023-01-01',
    updateAt: '2023-01-01',
    exceptions: skuGroups
      .map((item, index) => ({
        id: index,
        skuGroupId: item.id,
        percentage: 0,
      }))
      .slice(0, 4),
  },
  {
    id: 2,
    percentage: 7,
    startDate: '2023-03-01',
    endDate: '2023-07-01',
    createAt: '2023-01-01',
    updateAt: '2023-01-01',
    exceptions: [],
  },
];
