import { ComponentType } from 'react';
import { AxiosError } from 'axios';

export type ApiErrorResponse = AxiosError<{ detail: string }>;

export type Identity = {
  id: number;
};

export type CustomerIdentity = Identity & {
  customerId: number;
};

export type PagedRequest = {
  page: number;
  size: number;
};

export const SYSTEM_ROLE_PREFIX = 'system:';
export const CUSTOMER_ROLE_PREFIX = 'customer:';
export enum Role {
  SYSTEM_ADMIN = 'system:admin',
  CUSTOMER_ADMIN = 'customer:admin',
  CUSTOMER_VIEWER = 'customer:viewer',
  INTERNAL_VIEWER = 'internal:viewer',
}
export const ALL_ROLES = Object.values(Role);
export const CUSTOMER_ROLES = Object.values(Role).filter((role) =>
  role.startsWith(CUSTOMER_ROLE_PREFIX)
);

export type PagedItems<T> = {
  page: number;
  total_items: number;
  total_pages: number;
  items: T[];
};

export interface RouteDetail {
  label: string;
  path: string;
  // link is an optional value which will be used for the clickable link instead of the path
  // this is useful when the path contains params you want to match on but the link does not
  // e.g. path = /test/:id, link = /test/1
  link?: string;
  Component: ComponentType;
  indent?: boolean;
  roles?: Role[];
}

/*
 * Admin
 */

// models an identity for form values by replacing any child Identity objects with id:number
// e.g. FormValues<User> => customer will be a number now and not the Customer object
export type FormValues<T, ReturnType = number> = {
  [K in keyof T]: T[K] extends Identity
    ? ReturnType
    : T[K] extends Identity[]
    ? ReturnType[]
    : T[K] extends Identity | null
    ? ReturnType | null
    : T[K] extends Identity | undefined
    ? ReturnType | undefined
    : T[K];
};
export type Editable<T> = T extends Identity ? Omit<T, 'id'> : T;

export type Customer = Identity & {
  name: string;
  allowedDomains: string[];
  customer_project_groups: any[];
  sdmUserId?: number;
  sdmFullName?: string;
  sdmEmail?: string;
};

export type BillingAccount = Identity & {
  customerId: number;
  billingAccountId: string;
  currency: string;
};

export type User = Identity & {
  email: string;
  role: Role;
  customers: Customer[];
  restrictedProjectGroups: ProjectGroup[];
  restrictedProjects: Project[];
};

export enum ProjectContactType {
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
}

export type ProjectContact = {
  name: string;
  email: string;
};

export type Project = CustomerIdentity & {
  gcpProjectId: string;
  gcpProjectName?: string;
  description: string;
  contacts?: {
    [key in ProjectContactType]?: ProjectContact;
  };
};

export type ProjectGroupProject = Pick<
  Project,
  'id' | 'gcpProjectId' | 'gcpProjectName'
>;

export type ProjectGroup = CustomerIdentity & {
  name: string;
  type: string;
  projects?: ProjectGroupProject[];
};

export type CommitAmount = {
  startDate: string;
  softAmount?: number | undefined;
  hardAmount?: number | undefined;
  adjustedCommitAmount?: number | undefined;
};

export enum CommitType {
  SOFT = 'Soft',
  HARD = 'Hard',
  HARD_AND_SOFT = 'Hard and Soft',
}

export enum CommitTrackingType {
  ANNUAL = 'ANNUAL',
  FULL = 'FULL',
}

export enum CommitKind {
  SKU = 'SKU',
  SERVICE = 'SERVICE',
}

export type Commit = Identity & {
  customerId: number;
  startDate: string;
  numberOfYears: number;
  endDate: string;
  type: CommitType;
  trackingType: CommitTrackingType;
  currency: string;
  superseded: boolean;
  allowsExcessRollover?: boolean;
  allowsShortfallRollover?: boolean;
  shortfallRolloverPercentage?: number;
  commitAmounts: CommitAmount[];
  kind: CommitKind;
};

export enum BudgetAllocationType {
  GROUP = 'GROUP',
  PROJECT = 'PROJECT',
}

export type Budget = Identity & {
  customerId: number;
  startDate: string;
  endDate: string;
  amount: number;
  allocationType: BudgetAllocationType;
};

export enum BudgetAllocationAmountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

export type BudgetAllocationProject = {
  gcpProjectName?: string;
  gcpProjectId?: string;
  name: string;
  id: number;
};

export type BudgetAllocation = Identity & {
  amountType: BudgetAllocationAmountType;
  amount: number;
  projectOrGroup: BudgetAllocationProject;
};

export type RemainingBudget = {
  absolute: number;
  percentage: number;
};

export type PurchaseOrder = CustomerIdentity & {
  poNumber: string;
  date: string;
  renewalDate: string;
  currency: string;
  amount: number;
  budget: {
    id: number;
    startDate: string;
    endDate: string;
  };
};

type SystemConfigDefaultItem = Identity & {
  dateOfFirstCharge?: string;
  lastEdit?: string;
  lastCustomerEdit?: string;
  countsTowardCommit: boolean;
};

export type ServiceDefault = SystemConfigDefaultItem & {
  serviceName: string;
  serviceId: string;
  type: string;
};

export type SKUGroupCommitDefault = SystemConfigDefaultItem & {
  skuGroupName: string;
  skuGroupId: string;
  source: SkuGroupingSource;
};

export enum ServiceType {
  GCP = 'GCP',
  THIRD_PARTY = 'Third Party',
}

export enum GCPLabelType {
  PROJECT = 'PROJECT',
  RESOURCE = 'RESOURCE',
}

export type GCPLabel = Identity & {
  name: string;
  type: GCPLabelType;
};

export type Service = Identity & {
  name: string;
  type?: ServiceType;
  commitIncluded?: boolean;
  gcp_service_id?: string;
};

export enum ApportionmentAllocationType {
  PROJECT = 'PROJECT',
  PROJECT_GROUP = 'PROJECT_GROUP',
  ALL_PROJECTS = 'ALL_PROJECTS',
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
}

export enum ApportionmentSplitType {
  EVEN_SPLIT = 'EVEN_SPLIT',
  PERCENTAGE_OF_SPEND = 'PERCENTAGE_OF_SPEND',
}

export type Apportionment = {
  projectId?: number;
  projectName?: string;
  projectGroupId?: number;
  percentage?: number;
};

export type ServiceApportionment = Identity & {
  serviceId: number;
  serviceName?: string;
  gcpServiceId?: string;
  allocationType: ApportionmentAllocationType;
  splitType?: ApportionmentSplitType;
  apportionments?: Apportionment[];
};

export type ServiceCharge = Identity & {
  customerId: number;
  serviceId: number;
  amount: number;
  currency: string;
  invoiceNumber: string;
  invoiceDate: string;
  settleDate: string;
  toUsd: number;
};

export type BillingServiceCharge = Identity & {
  ids: number[];
  serviceId: number;
  serviceName: string;
  serviceIds?: number[];
  serviceType?: string;
  cost: number;
  credit: number;
  netCost: number;
  usageDate: string;
};

/*
 * Reporting
 */

export type UnitFilter = {
  ids: number[];
  inclusive: boolean;
};

export type ReportTimeInterval = 'none' | 'doy' | 'month' | 'quarter';
export type ReportBreakdown =
  | 'overall'
  | 'project'
  | 'service'
  | 'sku'
  | 'project_group'
  | 'customer'
  | 'billing_account'
  | 'project_label'
  | 'resource_label';
export type ReportUsage = 'consumption_date' | 'invoice_date';
export type ReportCostType = 'net' | 'gross';

export type ConsumptionFilters = {
  currency?: string;
  timeInterval: ReportTimeInterval;
  breakdown: ReportBreakdown;
  usage: ReportUsage;
  startDate: Date;
  endDate: Date;
  projectFilters?: {
    groups: number[];
    projects: number[];
  };
  services: number[];
  projectsLabels: number[];
  resourcesLabels: number[];
  selectedCustomerId?: number;
  customers?: number[];
  billingAccounts?: number[];
  sdms?: number[];
  projectLabel?: string;
  resourceLabel?: string;
  skus?: number[];
  skusGroups?: number[];
};

export type ConsumptionFiltersDto = {
  currency?: string;
  timeInterval: ReportTimeInterval;
  breakdown?: ReportBreakdown;
  usage?: ReportUsage;
  startDate: string;
  endDate: string;
  summary?: boolean;
  breakdownKey?: string;
  sdmsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  projectGroupsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  projectsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  serviceFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  projectsLabelsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  resourcesLabelsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  customerFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  billingAccountFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  skusFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  skusGroupsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
};

export type ConsumptionValue = {
  name: string;
  project?: string;
  project_name?: string;
  service?: string;
  net_cost: number;
  gross_cost: number;
  discount: number;
  discount_percentage?: number;
  net_forecast?: number;
  gross_forecast?: number;
  project_group?: string;
  customer?: string;
  billing_account?: string;
  sku?: string;
  project_label?: string;
  resource_label?: string;
};

export type ConsumptionItemValue = ConsumptionValue & {
  item: string;
};

export type ConsumptionData = {
  currency: string;
  nativeCurrency?: string;
  items: ConsumptionValue[];
};

export type ConsumptionItemData = {
  currency: string;
  items: ConsumptionItemValue[];
};

export type BQUsageBreakdown = 'project' | 'user_email' | 'job_id';

export type BQUsageFilters = {
  startDate: Date;
  endDate: Date;
  breakdown: BQUsageBreakdown;
  selectedCustomerId: number;
  timeInterval: ReportTimeInterval;
  projectFilters: {
    groups: number[];
    projects: number[];
  };
  userEmailFilters: string[];
};

export type BQUsageFiltersDTO = {
  startDate: string;
  endDate: string;
  breakdown: BQUsageBreakdown;
  timeInterval: ReportTimeInterval;
  summary?: boolean;
  projectsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  projectGroupsFilter?: {
    ids: number[];
    inclusive: boolean;
  };
  userEmailFilter?: string[];
};

export type BQUsageValue = {
  project_id?: string;
  project_name?: string;
  user_email?: string;
  name?: string;
  total_bytes_billed?: number;
  total_bytes_processed?: number;
  total_slot_ms?: number;
  avg_slots_used?: number;
  indicative_cost: number;
  job_duration_ms?: number;
};

export type BQUsageData = {
  currency: string;
  items: BQUsageValue[];
};

export type BQUsageOnboardingModel = Identity & {
  projectId: string;
  customerId: number;
  enabled?: boolean;
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type NameTotal = {
  name: string;
  total: number;
};

export type CommitTrackerFilters = {
  selectedCommit: Commit | null;
  selectedYear: CommitAmount | null;
  selectedType: CommitType | null;
  selectedTrackingType: CommitTrackingType | null;
  timeInterval: ReportTimeInterval;
};

export type CommitTrackerFiltersDto = {
  customerId: number;
  commitId: number;
  timeInterval: ReportTimeInterval;
  startDate: string;
  endDate: string;
};

export type CommitShortfall = {
  softAmountShortfall?: number;
  hardAmountShortfall?: number;
};

export type CommitTrackerData = {
  currency: string;
  consumption: NameTotal[];
  forecast: NameTotal[];
  excessRollover: number;
  shortfall?: CommitShortfall;
};

export type AggregatedConsumptionItemValue = {
  [key: string]: number;
};

export type AggregatedConsumptionByCost = {
  net: AggregatedConsumptionItemValue[];
  gross: AggregatedConsumptionItemValue[];
};

export type AggregatedConsumptionItemData = {
  currency: string;
  groupedData: AggregatedConsumptionByCost;
};

export type ServiceSummaryData = {
  currency: string;
  items: ServiceSummaryValue[];
};

export type ServiceSummaryValue = {
  id: number;
  service: string;
  total: number;
};

export type SummaryTotalInfo = {
  net: number;
  gross: number;
  discount?: number;
};

export type BurndownBudgetDetails = Budget & {
  currentBudget: number;
  unallocated: {
    absolute: number;
    percentage: number;
  };
  formattedDates: {
    // formatted dates for chart reference lines:
    start: string;
    end: string;
    runOut: string;
  };
  runOutDate: string;
};

export type BudgetRemainingItemData = {
  currency: string;
  consumption: NameTotal[];
  forecast: NameTotal[];
  budget_remaining: NameTotal[];
  budget: BurndownBudgetDetails;
};

export type PurchaseOrderSummary = {
  amount: number;
  burnoutDate: string;
  currentBallance: number;
  date: Date;
  id: number;
  poNumber: string;
  renewalDate: string;
  endDate: string;
};

export type PurchaseOrderRemainingData = {
  consumption: NameTotal[];
  forecast: NameTotal[];
  currency: string;
  purchaseOrderRemaining: NameTotal[];
  purchaseOrderSummary: PurchaseOrderSummary;
};

export type AggregatedBurndownItem = {
  name: string;
  remaining: number;
  consumption?: number;
  forecast?: number;
};

export type AggregatedBurndownData = {
  currency: string;
  budget: BurndownBudgetDetails;
  items: AggregatedBurndownItem[];
};

export type AggregatedBurndownChartData = {
  currency: string;
  items: AggregatedBurndownItem[];
};

export type BurndownFilters = {
  timeInterval: ReportTimeInterval;
  projectFilters?: {
    groups: number[];
    projects: number[];
  };
};

export type BurndownFiltersDto = {
  timeInterval: ReportTimeInterval;
  projectGroupId?: number;
  projectId?: number;
};

export type SystemStatus = {
  lastSyncTime?: string;
};

export type CustomerCommitTracker = Identity & {
  customerName: string;
  customerId: number;
  commitLength: number;
  commitPeriodStartDate: string;
  commitPeriodEndDate: string;
  commitAmount: number;
  commitType: CommitType;
  amountInCurrentTerm: number;
  actualConsumptionToDate: number;
  forecastConsumption: number;
  commitAchiviedDate: string;
  commitShortfall: number;
};

export enum ProcessStatus {
  COMPLETE = 'COMPLETE',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
}

export enum ProcessResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  PENDING = 'PENDING',
}

export type SystemLock = {
  processId: string;
  processStatus: ProcessStatus;
  processResult: ProcessResult;
  timeBegin: string;
  timeEnd?: string;
  stateValues: object;
};

export type RecommendationPriority =
  | 'P1'
  | 'P2'
  | 'P3'
  | 'P4'
  | 'PRIORITY_UNSPECIFIED';

export type RecommendationState =
  | 'STATE_UNSPECIFIED'
  | 'ACTIVE'
  | 'CLAIMED'
  | 'SUCCEEDED'
  | 'FAILED'
  | 'DISMISSED'
  | 'DELETED';

export type RecommendationCategory =
  | 'CATEGORY_UNSPECIFIED'
  | 'COST'
  | 'SECURITY'
  | 'PERFORMANCE'
  | 'MANAGEABILITY'
  | 'SUSTAINABILITY'
  | 'RELIABILITY';

export type RecommendationEntity =
  | 'PROJECT_NUMBER'
  | 'BILLING_ACCOUNT_ID'
  | 'ORGANIZATION_ID'
  | 'FOLDER_ID';

export type RecommendationResource = {
  name: string;
  project?: string;
};

export type RecommendationAdditionalData = {
  id: number;
  description: string;
  currency_code: string;
  cost: number;
  algorithm: string;
};

export type Recommendation = Identity & {
  priority: RecommendationPriority;
  category: RecommendationCategory;
  state: RecommendationState;
  description: string;
  date: string;
  targetResources: string[];
  project: string;
  recommenderSubtype: string;
  cloudEntityId: string;
  cloudEntityType: RecommendationEntity;
  cost?: number;
  currencyCode?: string;
  additionalData: RecommendationAdditionalData[];
  algorithm: string;
};

export type RecommendationDetails = Identity & {
  recommendationId: number;
  details: Object;
};

export type RecommendationSummary = {
  idleMachines: number;
  cost: number;
  impact: any;
};

export type RecommenderOnboardingModel = Identity & {
  bqTableId: string;
};

export type CustomerViewerSettings = {
  restrictedProjectGroups: number[];
  restrictedProjects: number[];
};

export type DisclaimerMessage = Identity & {
  title: string;
  description: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
  createdBy?: string;
  disabledBy?: string;
  lastUpdate?: string;
};

export enum SkuGroupingSource {
  API = 'API',
  USER_ENTRY = 'USER_ENTRY',
}

export type SkuGroup = Identity & {
  gcpGroupId?: string;
  groupName: string;
  source: SkuGroupingSource;
  createdAt: string;
  updatedAt: string;
};

export type CommitSkuGroup = SkuGroup & {
  commitIncluded: boolean;
};

export type Sku = Identity & {
  id: number;
  gcpSkuId: string;
  skuName: string;
};

export type SkuGroupedItem = Sku & {
  serviceName: string;
  gcpServiceId: string;
  createdAt: string;
  updateAt: string;
  source: SkuGroupingSource;
};

export type SkuItem = SkuGroupedItem & {
  groupsCount: number;
};

export type CustomerDiscountException = {
  percentage: number;
  skuGroupId: number;
  temporaryUID?: string;
  rank?: number;
};

export type CustomerDiscount = Identity & {
  temporaryUID?: string;
  startDate: string;
  endDate?: string;
  percentage: number;
  createAt?: string;
  updateAt?: string;
  exceptions: CustomerDiscountException[];
  dirty?: boolean;
  deleted?: boolean;
};

export type CustomerDiscountSummary = {
  customerId: number;
  percentage: number;
  exceptionsCounter: number;
};

export type CustomerSDM = Identity & {
  email: string;
  fullName: string;
};

export type ConsumptionSKU = Identity & {
  skuName: string;
  gcpSkuId: string;
  serviceId: number;
  serviceName: string;
  gcpServiceId: string;
};
