import { ServerHandler } from '../ServerHandler';
import { apiPaths } from '../../utils/apiPaths';
import { rest } from 'msw';

class Handler implements ServerHandler {
  handlers() {
    const url = apiPaths.sdms.base;

    return [
      rest.get(`/api${url}`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json([]));
      }),
    ];
  }

  resetData() {}
}

export const customerSDMHandler = new Handler();
