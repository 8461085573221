import { number, object, SchemaOf } from 'yup';

export type ProjectGroupProjectFormValues = {
  project_id: number;
};

export const ProjectGroupProjectSchema: SchemaOf<ProjectGroupProjectFormValues> = object(
  {
    project_id: number().required(),
  }
).defined();
