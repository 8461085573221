import { Button, styled } from '@mui/material';
import Modal from '../../../Modals/Modal';
import { useCustomerDiscounts } from '../hooks/useCustomerDiscounts';
import { Box } from '@mui/system';
import { DiscountCard } from './DiscountCard';
import { QueryLoader } from '../../../Messaging/QueryStatus';
import { CustomerAdminStateProvider } from '../providers/CustomerAdminStateProvider';
import { useCustomerAdminState } from '../hooks/useCustomerAdminState';
import { useEffect } from 'react';
import { VOID_ID } from '../../../../domain/constants';
import { uniqueId } from 'lodash';
import { useDiscountMutation } from '../hooks/useDiscountMutation';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  selectedCustomerId: number;
}

const QueryLoaderContainer = styled(Box)(() => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  bottom: 0,
  height: '100%',
  width: 'calc(100% - 20px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.733)',
}));

const LoadingBox = () => {
  return (
    <Box
      height={180}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <QueryLoader />
    </Box>
  );
};

const CustomerDiscountModalContent = ({
  selectedCustomerId,
}: {
  selectedCustomerId: number;
}) => {
  const { items, isLoading } = useCustomerDiscounts(selectedCustomerId);
  const { actions, discounts } = useCustomerAdminState();
  useEffect(() => {
    const discounts = items.map((discount) => {
      discount.temporaryUID = uniqueId();

      const exceptions = discount.exceptions.map((item) => ({
        ...item,
        temporaryUID: uniqueId(),
      }));

      return { ...discount, exceptions, dirty: false };
    });

    actions.setCustomerDiscounts(discounts);
  }, [items, actions]);

  const discountsCards = discounts
    .filter((item) => !item.deleted)
    .map((item) => <DiscountCard key={item.temporaryUID} item={item} />);

  return (
    <Box>
      <Button
        variant="contained"
        disabled={isLoading}
        onClick={() => {
          actions.addCustomerDiscount({
            id: VOID_ID,
            temporaryUID: uniqueId(),
            startDate: '',
            exceptions: [],
            percentage: 0,
          });
        }}
      >
        Add Discount
      </Button>
      <Box mt={2}>{isLoading ? <LoadingBox /> : discountsCards}</Box>
    </Box>
  );
};

const CustomerDiscountModal = ({
  open,
  onClose,
  selectedCustomerId,
}: ModalProps) => {
  const { discounts } = useCustomerAdminState();
  const { mutate, isLoading } = useDiscountMutation();

  const handleSave = () => {
    mutate(
      {
        customerId: selectedCustomerId,
        discounts,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      maxWidth="lg"
      open={open}
      onClose={onClose}
      id="customer-discounts-admin"
      title="Customer Discounts Admin"
      content={
        <>
          {isLoading && (
            <QueryLoaderContainer>
              <QueryLoader />
            </QueryLoaderContainer>
          )}
          <CustomerDiscountModalContent
            selectedCustomerId={selectedCustomerId}
          />
        </>
      }
      actions={
        <div>
          <Button variant="contained" onClick={handleSave} disabled={isLoading}>
            Save
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            Close
          </Button>
        </div>
      }
    />
  );
};

export const CustomerDiscountModalContainer = ({
  onClose,
  open,
  selectedCustomerId,
}: ModalProps) => {
  return (
    <CustomerAdminStateProvider>
      <CustomerDiscountModal
        onClose={onClose}
        open={open}
        selectedCustomerId={selectedCustomerId}
      />
    </CustomerAdminStateProvider>
  );
};
