import { useMemo } from 'react';
import {
  CommitAmount,
  Commit,
  CommitTrackerFilters,
  CommitTrackerFiltersDto,
} from '../../domain/types';
import { format, subMonths } from 'date-fns';
import { calculateCommitEndDate } from '../../utils/dateUtils';
import { API_DATE_FORMAT } from '../../domain/constants';

const getDateRange = (
  selectedCommit: Commit | null,
  selectedYear: CommitAmount | null
) => {
  const sixMonthsAgo = subMonths(Date.now(), 6);
  const startDate = new Date(
    selectedYear?.startDate ?? selectedCommit?.startDate ?? sixMonthsAgo
  );
  const endDate = new Date(calculateCommitEndDate(selectedCommit, startDate));

  return [startDate, endDate];
};

export const useCommitTrackerFilters = (
  customerId: number,
  filters: CommitTrackerFilters
): CommitTrackerFiltersDto | undefined => {
  return useMemo(() => {
    const { selectedCommit, selectedYear, timeInterval } = filters;
    const [startDate, endDate] = getDateRange(selectedCommit, selectedYear);

    if (!selectedCommit || selectedCommit?.customerId !== customerId) {
      return undefined;
    }

    const filtersDto: CommitTrackerFiltersDto = {
      customerId,
      commitId: selectedCommit.id,
      timeInterval,
      startDate: format(startDate, API_DATE_FORMAT),
      endDate: format(endDate, API_DATE_FORMAT),
    };

    return filtersDto;
  }, [customerId, filters]);
};
