import { useEffect, useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { addDays } from 'date-fns';
import { useCookie } from '../../hooks/useCookie';
import { CONSENT_COOKIE_KEY } from '../../domain/constants';

const POLICY_WORDING = `
This website stores cookies on your computer. These cookies are
used to collect information about how you interact with our
website and allow us to remember you. We use this information in
order to improve and customize your browsing experience and for
analytics and metrics about our visitors both on this website and
other media. To find out more about the cookies we use, see our
Privacy Policy.
`;

const COOKIE_DISCLAIMER = `
If you decline, your information won’t be tracked when you visit
this website. A single cookie will be used in your browser to
remember your preference not to be tracked.
`;

export function CookieConsent() {
  const classes = useStyles();
  const [cookie, setCookie] = useCookie(CONSENT_COOKIE_KEY);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);

  useEffect(() => {
    const isPopupVisible = cookie === undefined;
    setDisplayPopup(isPopupVisible);
  }, [cookie]);

  const acceptCookieSettings = () => {
    const expires = addDays(new Date(), 30).toUTCString();
    setCookie({
      key: CONSENT_COOKIE_KEY,
      value: 'true',
      expires,
      path: '/',
    });
  };

  const declineCookieSettings = () => {
    const SESSION_MAX_AGE_DAYS = 30;
    const expires = addDays(new Date(), SESSION_MAX_AGE_DAYS).toUTCString();

    setCookie({
      key: CONSENT_COOKIE_KEY,
      value: 'false',
      path: '/',
      expires,
    });
  };

  useEffect(() => {
    const isConsent = cookie ? JSON.parse(cookie.value) : false;

    if (isConsent) {
      gtag('consent', 'update', {
        // @ts-ignore
        analytics_storage: 'granted',
      });
    } else {
      gtag('consent', 'update', {
        // @ts-ignore
        analytics_storage: 'denied',
      });
    }
  }, [cookie]);

  return (
    <>
      {displayPopup && (
        <div className={classes.cookieConsentContainer}>
          <div className={classes.cookieConsentInner}>
            <div className={classes.cookieParagraph}>{POLICY_WORDING}</div>
            <div className={classes.cookieParagraph}>{COOKIE_DISCLAIMER}</div>
            <div className={classes.cookieConfirmation}>
              <button
                data-testid="cookie-consent_accept-btn"
                className={`${classes.cookieButton} ${classes.acceptButton}`}
                onClick={acceptCookieSettings}
              >
                Accept
              </button>
              <button
                data-testid="cookie-consent_decline_btn"
                className={`${classes.cookieButton} ${classes.declineButton}`}
                onClick={declineCookieSettings}
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    cookieConsentContainer: {
      position: 'fixed',
      maxWidth: 'calc(100% - 2rem)',
      width: '80ch',
      bottom: '1rem',
      right: '1rem',
      top: 'auto',
      left: 'auto',
      borderRadius: '1rem',
      fontSize: '.8rem',
      lineHeight: '1.6',
      overflow: 'hidden',
      zIndex: 9000,
    },
    cookieConsentInner: {
      background: '#271d51',
      color: 'white',
      padding: '1rem',
    },
    cookieConfirmation: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      margin: '10px 0 0',
      letterSpacing: '1px',
    },
    cookieParagraph: {
      marginBottom: '1rem',
      fontWeight: 300,
    },
    cookieButton: {
      border: 'none',
      font: 'inherit',
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '1rem',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: '2em',
      padding: '0.5em 1.6em',
      outline: 'none',
      textShadow: 'none',
      display: 'inline-block',
      margin: '6px',
    },
    acceptButton: {
      background: '#f5b12b',
      color: '#271d51',
    },
    declineButton: {
      color: '#fff',
      border: '2px solid #fff',
      background: 'transparent',
    },
  })
);
