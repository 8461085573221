import { useCrudList } from '../../Crud/grid/useCrudList';
import { Service } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useMemo } from 'react';

export const useServices = (customerId: number, serviceId: number) => {
  const { data, isLoading, isError } = useCrudList<Service>({
    apiPath: apiPaths.services(),
  });

  // split the returned data into:
  // - a list of service ids for the autocomplete options
  // - a map of id->name to display each option to the user
  const { serviceIds, serviceIdsToName, selectedServiceId } = useMemo(() => {
    const services = data?.items ?? [];
    const serviceIds = services.map(({ id }) => id);
    const serviceIdsToName = services.reduce((map, service) => {
      return map.set(service.id, service.name);
    }, new Map<number, string>());

    const selectedServiceId =
      isNaN(serviceId) || !serviceIds.includes(serviceId) ? null : serviceId;

    return { serviceIds, serviceIdsToName, selectedServiceId };
  }, [data?.items, serviceId]);

  return {
    isLoading,
    isError,
    serviceIds,
    serviceIdsToName,
    selectedServiceId,
  };
};
