import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerDiscount,
  CustomerDiscountException,
} from '../../../../domain/types';

export interface CustomerDiscountState {
  discounts: CustomerDiscount[];
  removed: number[];
}

export const initialState: CustomerDiscountState = {
  discounts: [],
  removed: [],
};

const findDiscountIndex = (
  state: CustomerDiscountState,
  discountUID: string
) => {
  const { discounts } = state;
  return discounts.findIndex((item) => item.temporaryUID === discountUID);
};

export const customerDiscountSlice = createSlice({
  name: 'customerDiscountSlice',
  initialState,
  reducers: {
    setDiscounts: (state, action: PayloadAction<CustomerDiscount[]>) => {
      const discounts = action.payload;
      state.discounts = discounts;
    },
    addDiscount: (state, action: PayloadAction<CustomerDiscount>) => {
      state.discounts.push({ ...action.payload, dirty: true });
    },
    removeDiscount: (state, action: PayloadAction<string>) => {
      const index = findDiscountIndex(state, action.payload);
      if (index === -1) {
        return state;
      }

      state.discounts[index].deleted = true;
    },
    updateDiscount: (state, action: PayloadAction<CustomerDiscount>) => {
      const index = findDiscountIndex(state, action.payload.temporaryUID ?? '');
      if (index === -1) {
        return state;
      }

      state.discounts[index] = { ...action.payload, dirty: true };
    },
    addDiscountException: (
      state,
      action: PayloadAction<{
        discountUID: string;
        exception: CustomerDiscountException;
      }>
    ) => {
      const index = findDiscountIndex(state, action.payload.discountUID);
      if (index === -1) {
        return state;
      }
      state.discounts[index].dirty = true;
      state.discounts[index].exceptions.push(action.payload.exception);
    },
    updateDiscountException: (
      state,
      action: PayloadAction<{
        discountUID: string;
        exception: CustomerDiscountException;
      }>
    ) => {
      const { discountUID, exception } = action.payload;
      const index = findDiscountIndex(state, discountUID);
      if (index === -1) {
        return state;
      }

      const currentException = state.discounts[index].exceptions.findIndex(
        (item) => item.temporaryUID === exception.temporaryUID
      );
      if (currentException === -1) {
        return state;
      }

      state.discounts[index].dirty = true;
      state.discounts[index].exceptions[currentException] = exception;
    },
    removeDiscountException: (
      state,
      action: PayloadAction<{
        discountUID: string;
        temporaryUID: string;
      }>
    ) => {
      const { discountUID, temporaryUID } = action.payload;
      const index = findDiscountIndex(state, discountUID);
      if (index === -1) {
        return state;
      }

      const currentException = state.discounts[index].exceptions.findIndex(
        (item) => item.temporaryUID === temporaryUID
      );
      if (currentException === -1) {
        return state;
      }

      state.discounts[index].dirty = true;
      state.discounts[index].exceptions.splice(currentException, 1);
    },
  },
});
