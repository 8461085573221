export const VOID_ID = 0;

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const CHART_MONTHLY_DATE_FORMAT = 'MMM-yyyy';
export const UI_DATE_FORMAT = 'dd/MMM/yyyy';
export const UI_MONTHLY_DATE_FORMAT = 'MMM/yyyy';
export const UI_DATE_TIME_FORMAT = 'dd/MMM/yyyy HH:mm';
export const MIN_MONEY_VALUE = 0.01;

// Sized to fit US$100,000,000.00
export const Y_AXIS_WIDTH = 80;

export const DOLLAR_CURRENCY = 'USD';

export const COMMIT_CURRENCY = DOLLAR_CURRENCY;

export const CHART_TOOLTIP_ITEM_COUNT = 20;

export const API_MONTHLY_DATE_REGEX = /^[\d]{4}-[\d]{2}$/;

export const CONSUMPTION_REPORT_KEY = 'consumption_report';

export const BUDGET_BURDOWN_REPORT_KEY = 'budget_burdown_report';

export const COMMIT_TRACKER_REPORT_KEY = 'commit_tracker_report';

export const PURCHASE_ORDERS_REPORT_KEY = 'purchase_orders_report';

export const BQ_USAGE_REPORT_KEY = 'bq_usage_report_key';

export const LS_SELECTED_CUSTOMER_ID = 'billingSelectedCustomerId';

export const REPORT_KEYS = [
  CONSUMPTION_REPORT_KEY,
  BUDGET_BURDOWN_REPORT_KEY,
  COMMIT_TRACKER_REPORT_KEY,
  PURCHASE_ORDERS_REPORT_KEY,
  BQ_USAGE_REPORT_KEY,
];

export const CONSENT_COOKIE_KEY = 'cs_set';

export const DATA_GRID_PAGE_LIMIT = 15;

export const DATA_GRID_PAGE_OPTIONS = [DATA_GRID_PAGE_LIMIT, 25, 50, 100];
