import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridWidgetContainer } from '../../../GridWidgetContainer';
import {
  SystemConfigurationResource,
  SystemConfigItems,
  SystemConfigurationItem,
} from '../SystemConfigurationTypes';
import { DateDisplay } from '../../../DateDisplay';
import { Checkbox } from '@mui/material';
import { useMemo } from 'react';

interface ComponentProps {
  resourceType: SystemConfigurationResource;
  isLoading: boolean;
  items: SystemConfigurationItem[];
  selection: SystemConfigItems;
  setSelection: (id: number, checked: boolean) => void;
}

const serviceColumns = [
  {
    headerName: 'Service Name',
    field: 'serviceName',
    flex: 0.5,
  },
  {
    headerName: 'Service ID',
    field: 'serviceId',
    flex: 0.5,
  },
  {
    headerName: 'Type',
    field: 'type',
    flex: 0.5,
  },
];

const skuGroupColumns = [
  {
    headerName: 'SKU Group Name',
    field: 'skuGroupName',
    flex: 0.5,
  },
  {
    headerName: 'SKU Group Id',
    field: 'skuGroupId',
    flex: 0.5,
  },
  {
    headerName: 'Source',
    field: 'source',
    flex: 0.5,
  },
];

export const SystemConfigurationGrid = ({
  resourceType,
  isLoading,
  selection,
  setSelection,
  items,
}: ComponentProps) => {
  const columns: GridColDef<SystemConfigurationItem>[] = useMemo(() => {
    const cols: GridColDef<SystemConfigurationItem>[] = [
      {
        headerName: 'Counts Towards Commit',
        field: 'countsTowardCommit',
        width: 190,
        renderCell: ({ row }) => {
          const checked = !!selection[row.id]?.selected;

          return (
            <Checkbox
              checked={checked}
              size="small"
              color="primary"
              onChange={(_, checked) => setSelection(row.id, checked)}
            />
          );
        },
      },
      {
        headerName: 'Last Applied Edit',
        field: 'lastCustomerEdit',
        flex: 0.5,
        renderCell: ({ row }) =>
          row.lastCustomerEdit ? (
            <DateDisplay date={row.lastCustomerEdit} />
          ) : (
            <></>
          ),
      },
      {
        headerName: 'Last Default Edit',
        field: 'lastEdit',
        flex: 0.5,
        renderCell: ({ row }) =>
          row.lastEdit ? <DateDisplay date={row.lastEdit} /> : <></>,
      },
      {
        headerName: 'Date of first charge',
        field: 'dateOfFirstCharge',
        flex: 0.5,
        renderCell: ({ row }) =>
          row.dateOfFirstCharge ? (
            <DateDisplay date={row.dateOfFirstCharge} />
          ) : (
            <></>
          ),
      },
    ];

    const resourceColumns =
      resourceType === 'SKU_GROUP' ? skuGroupColumns : serviceColumns;
    cols.splice(1, 0, ...resourceColumns);

    return cols;
  }, [setSelection, selection, resourceType]);

  return (
    <GridWidgetContainer>
      <DataGrid
        density="compact"
        loading={isLoading}
        columns={columns}
        columnBuffer={columns.length}
        rows={items}
        pageSizeOptions={[15, 50, 100]}
      />
    </GridWidgetContainer>
  );
};
