import {
  RestHandler,
  MockedRequest,
  DefaultRequestBody,
  rest,
  RequestParams,
} from 'msw';
import { PagedItems, Service, ServiceType } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { mockCustomerServicesData } from '../data/adminData';

class CustomerServicesHandler extends CustomerCrudHandler<Service> {
  private includeAllServices(val: string | null) {
    return val === 'true';
  }

  private generateAllServices(): Service[] {
    return Array.from({ length: 5 }, (_, index) => ({
      id: index + 5000,
      name: `Service ${index}`,
      type: ServiceType.GCP,
      commitIncluded: false,
    }));
  }

  private getServices(
    params: RequestParams,
    includeAllServices: boolean
  ): Service[] {
    const dataSet = this.getDataset(this.getKey(params));
    let services = Array.from(dataSet.values());

    if (includeAllServices) {
      services = [...services, ...this.generateAllServices()];
    }

    return services;
  }

  public listHandler(): RestHandler<MockedRequest<DefaultRequestBody>> {
    return rest.get(this.endpoint, (req, res, ctx) => {
      const includeAllServices = this.includeAllServices(
        req.url.searchParams.get('include_all')
      );

      const services = this.getServices(req.params, includeAllServices);
      const data: PagedItems<Service> = {
        items: services,
        total_items: 0,
        page: 1,
        total_pages: 1,
      };
      return res(ctx.status(200), ctx.json(data));
    });
  }

  public updateHandler(): RestHandler<MockedRequest<DefaultRequestBody>> {
    return rest.put(`${this.endpoint}:id`, (req, res, ctx) => {
      const { id } = req.params;
      const itemId = Number(id);
      const dataSet = this.getDataset(this.getKey(req.params));
      const item = dataSet.get(itemId);
      if (!item) {
        return res(ctx.status(404));
      }
      if (typeof req.body !== 'object') {
        return res(ctx.status(400));
      }

      const updatedItem = {
        ...item,
        commitIncluded: req.body.commitIncluded,
      };

      dataSet.set(itemId, updatedItem);
      return res(ctx.json(updatedItem));
    });
  }
}

export const customerServicesHandler = new CustomerServicesHandler(
  apiPaths.customerServices(':customerId' as any).base,
  {
    1: mockCustomerServicesData,
  }
);
