import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';

export type ChartItemName = string | number | null;

type SubscriberFunction = (item?: ChartItemName) => void;

interface ChartProviderInternalState {
  subscribers: SubscriberFunction[];
}

export interface ChartContainerContextProps {
  subscribeToActiveElement: (fn: SubscriberFunction) => () => void;
  setActiveHoverElement: (itemName?: ChartItemName) => void;
}

const ChartProviderContext = createContext<ChartContainerContextProps>({
  subscribeToActiveElement: () => () => {},
  setActiveHoverElement: () => {},
});

export const useChartContainerContext = () => {
  return useContext(ChartProviderContext);
};

interface ComponentProps {
  children: ReactNode;
}

export function ChartProvider({ children }: ComponentProps) {
  const ref = useRef<ChartProviderInternalState>({ subscribers: [] });
  const currentValue = useRef<ChartItemName | undefined>(undefined);

  const value = useMemo(() => {
    return {
      setActiveHoverElement: (itemName?: ChartItemName) => {
        if (itemName === currentValue.current) {
          return;
        }

        currentValue.current = itemName;
        ref.current.subscribers.forEach((subscriber: SubscriberFunction) => {
          subscriber(itemName);
        });
      },
      subscribeToActiveElement: (fn: SubscriberFunction) => {
        const { subscribers } = ref.current;
        subscribers.push(fn);
        fn(currentValue.current);
        return () => {
          const index = subscribers.findIndex(
            (subscriber) => subscriber === fn
          );
          if (index !== -1) {
            subscribers.splice(index, 1);
          }
        };
      },
    };
  }, []);

  return (
    <ChartProviderContext.Provider value={value}>
      {children}
    </ChartProviderContext.Provider>
  );
}
