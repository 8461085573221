import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { getSystemStatus } from '../data/statusData';

class StatusHandler implements ServerHandler {
  public resetData() {}

  public handlers() {
    return [
      // last sync time:
      rest.get('/api/status/last-sync-time', async (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(getSystemStatus()));
      }),
    ];
  }
}

export const statusHandler = new StatusHandler();
