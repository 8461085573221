import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  Typography,
} from '@mui/material';
import { DisclaimerMessage } from '../../../domain/types';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';

interface ComponentProps {
  model: DisclaimerMessage;
  onDisable: (id: number) => void;
  onEdit: (id: number) => void;
}

const CardSubHeader = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <DateRangeIcon fontSize="small" />
        Start Date: {startDate}
      </Box>{' '}
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <DateRangeIcon fontSize="small" />
        End Date: {endDate}
      </Box>
    </>
  );
};

export function DisclaimerMessageCard({
  model,
  onDisable,
  onEdit,
}: ComponentProps) {
  return (
    <Card>
      <Box padding={2}>
        <Typography variant="h5">{model.title}</Typography>
        <CardSubHeader startDate={model.startDate} endDate={model.endDate} />
      </Box>
      <Divider />
      <Box padding={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <DescriptionIcon fontSize="small" />
          <Typography variant="body2">Message Description</Typography>
        </Box>

        <Typography>{model.description}</Typography>
      </Box>
      <Divider />
      <Box padding={2}>
        {model.createdBy && (
          <Typography>Created By: {model.createdBy}</Typography>
        )}

        {model.disabledBy && (
          <Typography>Disabled By: {model.disabledBy}</Typography>
        )}
      </Box>
      {!model.disabledBy && (
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onDisable(model.id)}
          >
            Disable
          </Button>
          <Button onClick={() => onEdit(model.id)}>Edit</Button>
        </CardActions>
      )}
    </Card>
  );
}
