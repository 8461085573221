import Alert from '@mui/material/Alert';

type NoItemsProps = {
  forMessage: string;
  severity?: 'info' | 'warning';
};

const NoItems = ({ forMessage, severity = 'info' }: NoItemsProps) => {
  return (
    <Alert severity={severity}>There are currently no {forMessage}.</Alert>
  );
};

export default NoItems;
