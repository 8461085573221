import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { useMemo } from 'react';
import { VOID_ID } from '../../../../domain/constants';

export const useBigQueryUsageUsers = (customerId: number) => {
  const apiPath = apiPaths.bigQueryUsageUsers(customerId);

  const { data, isLoading } = useQuery<string[]>(
    apiPath.key.list(customerId),
    () => GET(apiPath.base),
    {
      enabled: customerId !== VOID_ID,
    }
  );

  return useMemo(
    () => ({
      items: data ?? [],
      isLoading,
    }),
    [data, isLoading]
  );
};
