export const fromLocalStorage = (key: string, selectedCustomerId: number) => {
  try {
    const item = window.localStorage.getItem(key);

    const fetched = item ? JSON.parse(item) : null;

    if (fetched?.selectedCustomerId === selectedCustomerId) {
      return fetched;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const saveToLocalStorage = (
  key: string,
  value: any,
  selectedCustomerId: number
) => {
  try {
    const saved = {
      ...value,
      selectedCustomerId,
    };

    window.localStorage.setItem(key, JSON.stringify(saved));
  } catch (error) {
    return null;
  }
};

export const removeFromLocalStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    return null;
  }
};

export const removeKeysFromLocalStorage = (keys: string[]) => {
  keys.forEach((key) => {
    removeFromLocalStorage(key);
  });
};
