import { SystemConfigurationControls } from './components/SystemConfigurationControls';
import { useSystemConfigurationItems } from './hooks/useSystemConfigurationItems';
import { PageHeader } from '../../PageHeader';
import { SystemConfigurationGrid } from './components/SystemConfigurationGrid';
import { useState } from 'react';
import { SystemConfigurationResource } from './SystemConfigurationTypes';
import { SystemConfigurationApplyAllModal } from './components/SystemConfigurationApplyAllModal';

export const SystemConfiguration = () => {
  const [resourceType, setResourceType] = useState<SystemConfigurationResource>(
    'SKU_GROUP'
  );
  const {
    items,
    isLoading,
    selection,
    setSelection,
  } = useSystemConfigurationItems(resourceType);

  const [modalOpen, setModalOpen] = useState(false);

  const onSave = () => {
    setModalOpen(true);
  };

  return (
    <>
      <PageHeader title="System Configuration Admin" />

      <SystemConfigurationControls
        onSave={onSave}
        disabled={isLoading}
        resourceType={resourceType}
        setResourceType={setResourceType}
        selection={selection}
      />

      <SystemConfigurationGrid
        isLoading={isLoading}
        resourceType={resourceType}
        items={items}
        selection={selection}
        setSelection={setSelection}
      />

      <SystemConfigurationApplyAllModal
        resourceType={resourceType}
        selection={selection}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};
