import { QueryKey } from 'react-query';
import { ReportBreakdown } from '../domain/types';

/*
 * Example usage:
 *
 * Get a list of things:
 * useQuery(
 *   apiPaths.customer.key.list({ page: 1 }),     // key
 *   () => GET(apiPaths.customer.list({ page 1 }) // path
 * );
 *
 * Update something:
 * useMutation(
 *   () => PUT(apiPaths.billingAccount(customerId).item(billingAccountId))
 * )
 *
 * // Invalidate all CUSTOMER namespace requests
 * queryClient.invalidateQueries([ApiNamespace.CUSTOMER]);
 *
 */

export enum ApiNamespace {
  STATIC = 'static', // static data which will never change
  ALL_CUSTOMERS = 'all_customers', // data related to customers as a group
  CUSTOMER = 'customer', // data related to a specific customer
  USERS = 'users', // data related to all users
  CHART_DATA = 'chart_data', // chart data
  EXPORT = 'export', // csv export
  SYSTEM_STATUS = 'system_status', // system status data
}

export enum ApiKeyType {
  LIST = 'LIST',
  ITEM = 'ITEM',
}

interface ApiPathKey {
  namespace: () => QueryKey;
  list: (params?: unknown) => QueryKey;
  item: (id: number) => QueryKey;
}

export type ApiPath = {
  base: string;
  item: (id: number) => string;
  key: ApiPathKey;

  unmap?: (id: number) => string;
};

export type ChartApiPath = Omit<ApiPath, 'item'>;

export type ExportApiPath = Omit<ApiPath, 'item'>;

export const createKey = (...namespace: (string | number)[]): ApiPathKey => {
  return {
    namespace: () => namespace,
    list: (params?: unknown) =>
      params
        ? [...namespace, ApiKeyType.LIST, params]
        : [...namespace, ApiKeyType.LIST],
    item: (id: number) => [...namespace, ApiKeyType.ITEM, id],
  };
};

export const apiPaths = {
  // system status:
  lastSyncTime: {
    base: '/status/last-sync-time',
    key: createKey(ApiNamespace.SYSTEM_STATUS, 'last-sync-time'),
  },
  // static
  currency: {
    base: '/currencies/',
    item: (id: number) => `/currencies/${id}`,
    key: createKey(ApiNamespace.STATIC, 'currency'),
  },
  // customers
  customer: {
    base: '/customers/',
    item: (id: number) => `/customers/${id}`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'customer'),
  },
  sdms: {
    base: '/customers-sdms/',
    item: (id: number) => `/customers-sdms/${id}`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'customers-sdms'),
  },
  customerDiscounts: (customerId: number) => {
    const base = `/customers/${customerId}/discounts`;

    return {
      base,
      item: (discountId: number) => `${base}/${discountId}`,
      key: createKey(
        ApiNamespace.ALL_CUSTOMERS,
        customerId,
        'customer-discounts'
      ),
    };
  },
  customersDiscountsSummary: () => {
    const base = '/customers/discounts_summary';

    return {
      base,
      key: createKey(ApiNamespace.ALL_CUSTOMERS, 'customers-discounts-summary'),
    };
  },
  customerSkuGroups: (customerId: number) => ({
    base: `/customers/${customerId}/sku-groups/`,
    item: (id: number) => `/customers/${customerId}/sku-groups/${id}`,
    key: createKey(ApiNamespace.CUSTOMER, customerId, 'sku-groups'),
  }),
  skuGroups: () => ({
    base: '/sku-groups/',
    item: (id: number) => `/sku-groups/${id}`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'sku-groups'),
  }),
  skuGroupsSkus: (skuGroupId: number) => ({
    base: `/sku-groups/${skuGroupId}/skus`,
    allocate: `/sku-groups/${skuGroupId}/skus/allocate`,
    deallocate: `/sku-groups/${skuGroupId}/skus/deallocate`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, skuGroupId, 'sku-groups-skus'),
  }),
  skus: () => ({
    base: '/skus/',
    groups: (skuId: number) => `/skus/${skuId}/groups`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'skus'),
  }),
  // customer commits
  commits: (customerId: number) => ({
    base: `/customers/${customerId}/commits/`,
    item: (id: number) => `/customers/${customerId}/commits/${id}`,
    key: createKey(ApiNamespace.CUSTOMER, customerId, 'commit'),
  }),
  // specific customer
  billingAccount: (customerId: number) => {
    const base = `/customers/${customerId}/billing-accounts/`;
    return {
      base,
      item: (id: number) => `/customers/${customerId}/billing-accounts/${id}`,
      unmap: (id: number) =>
        `/customers/${customerId}/billing-accounts/${id}/unmap`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'billingAccount'),
    };
  },
  allocateBillingAccount: (customerId: number) => ({
    base: `/customers/${customerId}/billing-accounts/allocate/`,
  }),
  budget: (customerId: number) => {
    const base = `/customers/${customerId}/budgets/`;
    return {
      base,
      available: `${base}available`,
      active: `${base}active`,
      item: (budgetId: number) => `${base}${budgetId}`,
      remainingBudget: (budgetId: number) =>
        `${base}${budgetId}/remaining-budget`,
      availableProjects: (budgetId: number) =>
        `${base}${budgetId}/projects/available`,
      availableProjectGroups: (budgetId: number) =>
        `${base}${budgetId}/project-groups/available`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'budget'),
    };
  },
  budgetAllocations: (customerId: number, budgetId: number) => {
    const base = `/customers/${customerId}/budgets/${budgetId}/allocations/`;
    return {
      base,
      available: `${base}available`,
      item: (id: number) => `${base}${id}`,
      key: createKey(
        ApiNamespace.CUSTOMER,
        customerId,
        'budgetAllocation',
        budgetId
      ),
    };
  },
  project: (customerId: number) => ({
    base: `/customers/${customerId}/projects/`,
    item: (id: number) => `/customers/${customerId}/projects/${id}`,
    itemByNumber: (projectNumber: string) =>
      `/customers/${customerId}/projects/number/${projectNumber}`,
    key: createKey(ApiNamespace.CUSTOMER, customerId, 'project'),
  }),
  unassociated: (customerId: number) => ({
    base: `/customers/${customerId}/projects/unassociated`,
    key: createKey(ApiNamespace.CUSTOMER, customerId, 'unassociated'),
  }),
  associateProjects: (customerId: any, projectGroupId: any) => ({
    base: `/customers/${customerId}/project-groups/${projectGroupId}/associate_projects`,
  }),
  projectGroup: (customerId: number) => ({
    base: `/customers/${customerId}/project-groups/`,
    item: (id: number) => `/customers/${customerId}/project-groups/${id}`,
    key: createKey(ApiNamespace.CUSTOMER, customerId, 'projectGroup'),
  }),
  projectGroupProjects: (customerId: number, projectGroupId: number) => {
    const base = `/customers/${customerId}/project-groups/${projectGroupId}/projects/`;
    return {
      base,
      available: `${base}available`,
      item: (id: number) => `${base}${id}`,
      key: createKey(
        ApiNamespace.CUSTOMER,
        customerId,
        'projectGroupProject',
        projectGroupId
      ),
    };
  },
  purchaseOrder: (customerId: number) => {
    const base = `/customers/${customerId}/purchase-orders/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'purchaseOrder'),
    };
  },
  services: () => ({
    base: '/services/',
    item: (id: number) => `/services/${id}`,
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'service'),
  }),
  servicesFilter: (customerId?: number) => {
    const base = customerId
      ? `/data/consumption/${customerId}/services`
      : '/data/consumption/services';

    return {
      base,
      key: createKey(
        customerId ? ApiNamespace.CUSTOMER : ApiNamespace.ALL_CUSTOMERS,
        'service/gcp'
      ),
    };
  },
  customerLabels: (customerId?: number) => ({
    base: `/customers/${customerId}/labels/`,
    key: createKey(ApiNamespace.CUSTOMER, customerId ?? '', 'labels'),
  }),
  consumptionSKUs: (customerId: number) => ({
    base: `/data/consumption/${customerId}/skus`,
    key: createKey(ApiNamespace.CHART_DATA, customerId, 'skus'),
  }),
  serviceApportionments: (customerId: number) => {
    const base = `/customers/${customerId}/service-apportionments/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'serviceApportionment'),
    };
  },
  serviceCharge: (customerId: number) => {
    const base = `/customers/${customerId}/service-charges/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'serviceCharge'),
    };
  },
  systemConfiguration: () => {
    const base = '/data/system-configuration';
    return {
      base,
      item: (id: number) => `${base}/${id}`,
      key: createKey(ApiNamespace.ALL_CUSTOMERS, 'system-configuration'),
    };
  },
  customerServices: (customerId: number) => {
    const base = `/customers/${customerId}/services/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'customerServices'),
    };
  },
  billingApportionmentServices: (customerId: number) => {
    const base = `/customers/${customerId}/services/apportionment_services`;
    return {
      base,
      item: (id: number) => `${base}/${id}`,
      key: createKey(
        ApiNamespace.CUSTOMER,
        customerId,
        'apportionmentServices'
      ),
    };
  },
  billingServiceCharges: (customerId: number) => {
    const base = `/customers/${customerId}/billing-service-charges/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'billingServiceCharge'),
    };
  },

  // users
  currentUser: {
    base: '/users/current',
    key: createKey(ApiNamespace.USERS, 'current'),
  },
  users: {
    base: '/users/',
    item: (id: number) => `/users/${id}`,
    key: createKey(ApiNamespace.USERS, 'users'),
  },
  // /api/customers/{customer_id}/users
  customerUsers: (customerId: number) => {
    const base = `/customers/${customerId}/users/`;
    return {
      base,
      item: (id: number) => `${base}${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'users'),
    };
  },
  customerViewerSettings: {
    base: '/users/customer-viewer-settings',
    key: createKey(ApiNamespace.USERS, 'customerViwerSettings'),
  },

  // charting data:
  consumptionData: (customerId?: number, breakdown?: ReportBreakdown) => ({
    base: `/data/consumption${customerId ? `/${customerId}` : ''}`,
    key: createKey(
      ApiNamespace.CHART_DATA,
      customerId ?? '__ALL__',
      breakdown ?? '__OVERALL__',
      'consumption'
    ),
  }),

  consumptionExport: (customerId?: number, breakdown?: ReportBreakdown) => ({
    base: `/data/consumption${customerId ? `/${customerId}` : ''}/export`,
    key: createKey(
      ApiNamespace.EXPORT,
      customerId ?? '__ALL__',
      breakdown ?? '__OVERALL__',
      'consumption'
    ),
  }),

  consumptionCurrentMonthForecastData: (
    customerId?: number,
    breakdown?: ReportBreakdown
  ) => ({
    base: `/data/consumption/current_month_forecast${
      customerId ? `/${customerId}` : ''
    }`,
    key: createKey(
      ApiNamespace.CHART_DATA,
      customerId ?? '__ALL__',
      breakdown ?? '__OVERALL__',
      'consumptionCurrentMonthForecast'
    ),
  }),
  bigQueryUsage: (customerId?: number) => {
    const base = `/data/bq-metrics/${customerId}/`;
    return {
      base,
      key: createKey(ApiNamespace.CHART_DATA, customerId ?? '__ALL__'),
    };
  },
  bigQueryUsageUsers: (customerId: number) => ({
    base: `/data/bq-metrics/${customerId}/users`,
    key: createKey(ApiNamespace.CHART_DATA, customerId, 'users', 'bq-metrics'),
  }),
  bigQueryUsageOnboarding: (customerId: number) => ({
    base: `/data/bq-metrics/${customerId}/bq-metrics-onboarding`,
    key: createKey(
      ApiNamespace.CHART_DATA,
      customerId,
      'onboarding',
      'bq-metrics'
    ),
  }),
  commitTracker: (customerId?: number) => ({
    base: '/data/commit-tracker',
    key: createKey(
      ApiNamespace.CHART_DATA,
      customerId ?? '__ALL__',
      'commit-tracker'
    ),
  }),
  commitTrackerExport: (customerId?: number) => ({
    base: '/data/commit-tracker/export',
    key: createKey(
      ApiNamespace.EXPORT,
      customerId ?? '__ALL__',
      'commit-tracker'
    ),
  }),
  customersCommitTracker: () => {
    return {
      base: '/data/customers-commit-tracker',
      key: createKey(ApiNamespace.ALL_CUSTOMERS, 'customers-commit-tracker'),
    };
  },
  disclaimerManager: () => {
    return {
      base: '/data/disclaimers',
      key: createKey(ApiNamespace.ALL_CUSTOMERS, 'disclaimer'),
    };
  },
  disclaimerMessageActive: () => {
    return {
      base: '/data/disclaimers/active',
      key: createKey(ApiNamespace.CUSTOMER, 'disclaimer-active'),
    };
  },
  unmappedBillingAccounts: {
    base: 'billing-accounts/unmapped',
  },
  billingAccounts: {
    base: '/data/billing-accounts/',
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'billing_accounts'),
  },
  budgetRemainingData: (customerId: number) => ({
    base: `/data/budget-remaining/${customerId}`,
    key: createKey(ApiNamespace.CHART_DATA, customerId),
  }),
  budgetRemainingExport: (customerId: number) => ({
    base: `/data/budget-remaining/${customerId}/export`,
    key: createKey(ApiNamespace.EXPORT, customerId),
  }),
  purchaseOrdersRemainingData: (customerId: number) => ({
    base: `/data/budget-remaining/purchase-orders/${customerId}`,
    key: createKey(ApiNamespace.CHART_DATA, customerId),
  }),
  purchaseOrderExport: (customerId: number) => ({
    base: `/data/budget-remaining/purchase-orders/${customerId}/export`,
    key: createKey(ApiNamespace.EXPORT, customerId),
  }),
  recommender: (customerId: number) => {
    const base = `/data/recommendations/${customerId}`;

    return {
      base,
      item: (id: number) => `${base}/${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId),
    };
  },
  recommenderDetails: (customerId: number) => {
    const base = `/data/recommendations/${customerId}/details`;

    return {
      base,
      item: (id: number) => `${base}/${id}`,
      key: createKey(ApiNamespace.CUSTOMER, customerId),
    };
  },
  recommenderSummary: (customerId: number) => {
    const base = `/data/recommendations/${customerId}/summary`;

    return {
      base,
      key: createKey(ApiNamespace.CUSTOMER, customerId, 'recommender-summary'),
    };
  },
  recommenderOnboarding: (customerId: number) => {
    const base = `/data/recommendations/${customerId}/recommender_onboarding`;

    return {
      base,
      key: createKey(
        ApiNamespace.CUSTOMER,
        customerId,
        'recommender-onboarding'
      ),
    };
  },
  systemSyncs: () => ({
    base: '/system_syncs/',
    key: createKey(ApiNamespace.ALL_CUSTOMERS, 'system_syncs'),
  }),
};
