import {
  RecommendationCategory,
  RecommendationEntity,
  RecommendationPriority,
  RecommendationState,
} from '../../../../domain/types';
import { RecommenderSort } from '../RecommenderFiltersProvider';

interface RecommenderFiltersParams {
  category: RecommendationCategory[];
  state: RecommendationState[];
  search: string;
  orderBy: RecommenderSort;
  priority: RecommendationPriority[];
  entities: RecommendationEntity[];
}

interface RecommenderFilterDTO {
  categoryFilter?: string[];
  stateFilter?: string[];
  searchFilter?: string;
  orderFilter?: RecommenderSort;
  priorityFilter?: string[];
  entityFilter?: string;
}

export const useRecommenderParams = ({
  category,
  state,
  search,
  orderBy,
  priority,
  entities,
}: RecommenderFiltersParams) => {
  const dto: RecommenderFilterDTO = {};

  if (category?.length) {
    dto.categoryFilter = category;
  }

  if (state?.length) {
    dto.stateFilter = state;
  }

  if (search) {
    dto.searchFilter = search;
  }

  if (orderBy) {
    dto.orderFilter = orderBy;
  }

  if (priority?.length) {
    dto.priorityFilter = priority;
  }

  if (entities?.length) {
    dto.entityFilter = entities[0];
  }

  return dto;
};
