import React, { useEffect, useState } from 'react';
import { BillingAccount } from '../../../domain/types';
import { Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CrudDataGridError } from '../../Crud/grid/CrudDataGridError';
import { GridWidgetContainer } from '../../GridWidgetContainer';

interface UnmappedBillingAccountsTableProps {
  billingAccounts: BillingAccount[];
  loading: boolean;
  error: any;
  onSelectionChanged: (accounts: BillingAccount[]) => void;
}

interface SelectableBillingAccount extends BillingAccount {
  selected: boolean;
}

export const UnmappedBillingAccountsTable = ({
  billingAccounts,
  loading,
  error,
  onSelectionChanged,
}: UnmappedBillingAccountsTableProps) => {
  const classes = useStyles();

  const [selectedAccounts, setSelectedAccounts] = useState<
    SelectableBillingAccount[]
  >();

  useEffect(
    () =>
      setSelectedAccounts(
        billingAccounts.map((billingAccount) => ({
          ...billingAccount,
          selected: false,
        }))
      ),
    [billingAccounts]
  );

  const setAccountSelected = (id: number, selected: boolean) => {
    const accounts = selectedAccounts?.map((account) => {
      if (account.id === id) {
        return {
          ...account,
          selected,
        };
      }
      return account;
    });
    setSelectedAccounts(accounts);
    onSelectionChanged(accounts?.filter((account) => account.selected) ?? []);
  };

  const columns: GridColDef[] = [
    {
      field: 'selected',
      width: 40,
      headerClassName: classes.checkbox,
      cellClassName: classes.checkbox,
      renderHeader: () => <span />,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: ({ row: { id, selected } }) => (
        <Checkbox
          checked={selected}
          onChange={(e) => {
            setAccountSelected(id as number, e.target.checked);
          }}
          size="small"
          color="primary"
        />
      ),
    },
    {
      headerName: 'Name',
      field: 'billingAccountId',
      flex: 1,
    },
  ];

  return (
    <GridWidgetContainer>
      {error ? (
        <CrudDataGridError />
      ) : (
        <DataGrid
          // data
          columns={columns}
          rows={selectedAccounts ?? []}
          // state
          loading={loading}
          //components={{
          //  ErrorOverlay: CrudDataGridError,
          //}}
          density="compact"
          disableRowSelectionOnClick
        />
      )}
    </GridWidgetContainer>
  );
};

const useStyles = makeStyles({
  checkbox: {
    '&.MuiDataGrid-cell': {
      padding: 0,
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
});
