import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { POST } from '../../../../utils/api';
import { VOID_ID } from '../../../../domain/constants';
import { BQUsageData, BQUsageFilters } from '../../../../domain/types';
import { useBigQueryUsageFilters } from './useBigQueryUsageFilters';
import { useMemo } from 'react';
interface HookProps {
  customerId: number;
  filters: BQUsageFilters;
  summary: boolean;
}

export const useBigQueryUsageData = ({
  customerId,
  filters,
  summary,
}: HookProps) => {
  const filtersDTO = useBigQueryUsageFilters({ filters, summary });
  const apiPath = apiPaths.bigQueryUsage(customerId);

  const { data, isLoading, isError } = useQuery<BQUsageData>(
    apiPath.key.list(filtersDTO),
    () => POST(apiPath.base, filtersDTO),
    {
      enabled: customerId !== VOID_ID,
      retry: false,
    }
  );

  return useMemo(() => {
    return {
      isLoading,
      currency: data?.currency ?? 'USD',
      items: data?.items ?? [],
      isError,
    };
  }, [data, isLoading, isError]);
};
