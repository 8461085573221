import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { useStateContext } from '../../../../contexts/StateProvider';
import {
  SystemConfigItems,
  SystemConfigurationItem,
  SystemConfigurationResource,
} from '../SystemConfigurationTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useSystemConfigurationItems = (
  resourceType: SystemConfigurationResource
) => {
  const [selectedItems, setSelectedItems] = useState<SystemConfigItems>({});
  const apiPath = apiPaths.systemConfiguration();
  const { showNotification } = useStateContext();

  const { data, isLoading } = useQuery<SystemConfigurationItem[]>(
    apiPath.key.list(resourceType),
    () =>
      GET(apiPath.base, {
        resource_type: resourceType === 'SKU_GROUP' ? 'SKU' : 'SERVICE',
      }),
    {
      onError: () => {
        showNotification({
          message: 'There was an error. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  const items = useMemo(() => data ?? [], [data]);

  useEffect(() => {
    const selection = items.reduce((acc, item) => {
      acc[item.id] = {
        selected: item.countsTowardCommit,
        dirty: false,
      };

      return acc;
    }, {} as SystemConfigItems);

    setSelectedItems(() => selection);
  }, [items, resourceType]);

  const setSelection = useCallback(
    (id: number, checked: boolean) => {
      const selection = { ...selectedItems };
      selection[id] = { selected: checked, dirty: true };
      setSelectedItems(selection);
    },
    [selectedItems]
  );

  return useMemo(
    () => ({
      setSelection,
      items,
      isLoading,
      selection: selectedItems,
    }),
    [items, setSelection, isLoading, selectedItems]
  );
};
