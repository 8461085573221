import { useMemo } from 'react';
import { Alert } from '@mui/material';
import {
  ReportFiltersProvider,
  useFilters,
} from '../../../contexts/ReportFiltersProvider';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID, COMMIT_TRACKER_REPORT_KEY } from '../../../domain/constants';
import {
  Commit,
  CommitAmount,
  CommitShortfall,
  CommitTrackerFilters,
  CommitTrackerFiltersDto,
  CommitType,
  NameTotal,
} from '../../../domain/types';
import { CommitTrackerChart } from '../../Charts/CommitTrackerChart';
import { useCommitTrackerData } from '../../Charts/useCommitTrackerData';
import { CommitTrackerFilterBar } from './CommitTrackerFilterBar';
import { CommitTrackerReportTitle } from './CommitTrackerReportTitle';
import { addYears, isWithinInterval } from 'date-fns';
import { CommitIntersectionTile } from './CommitIntersectionTile';
import { ShortfallTile } from './ShortfallTile';
import { useGetCommits } from '../../../hooks/useGetCommits';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { useCommitTrackerReportIntersections } from './useCommitTrackerReportIntersections';
import { GenericReport } from '../GenericReport';
import { fromLocalStorage } from '../../../utils/localStorage';
import { ReportExportManager } from '../../Charts/ReportExportManager';
import { apiPaths } from '../../../utils/apiPaths';
import { monetaryFormat } from '../../MonetaryDisplay';
import { ChartWidgetContainer } from '../../ChartWidgetContainer';
import { PageHeader } from '../../PageHeader';
import { ChartHeader } from '../../Charts/ChartHeader';

function findCurrentCommit(commits?: Commit[]): Commit | undefined {
  if (!commits?.length) {
    return undefined;
  }
  const now = Date.now();
  return commits
    .filter((commit) => commit.superseded === false)
    .find((commit) =>
      isWithinInterval(now, {
        start: new Date(commit.startDate),
        end: new Date(commit.endDate),
      })
    );
}

function findCurrentCommitAmount(commit?: Commit): CommitAmount | undefined {
  const now = new Date();
  return commit?.commitAmounts.find((item) => {
    const start = new Date(item.startDate);
    return isWithinInterval(now, {
      start: start,
      end: addYears(start, 1),
    });
  });
}

function defaultFiltersForCommits(commits?: Commit[]): CommitTrackerFilters {
  const currentCommit = findCurrentCommit(commits);
  const currentYear = findCurrentCommitAmount(currentCommit);
  return {
    selectedCommit: currentCommit ?? null,
    selectedYear: currentYear ?? null,
    selectedType: currentCommit?.type ?? null,
    selectedTrackingType: currentCommit?.trackingType ?? null,
    timeInterval: 'doy',
  };
}

export const CommitTrackerReport = () => {
  const { selectedCustomerId } = useStateContext();

  const { data: commits, isLoading: commitsLoading } = useGetCommits(
    selectedCustomerId
  );

  const initialFilters = useMemo(() => defaultFiltersForCommits(commits), [
    commits,
  ]);

  const defaultFilters = useMemo(
    () =>
      fromLocalStorage(COMMIT_TRACKER_REPORT_KEY, selectedCustomerId) ||
      initialFilters,
    [initialFilters, selectedCustomerId]
  );

  if (selectedCustomerId === VOID_ID) {
    return <Alert severity="info">Select a customer to view the report.</Alert>;
  }

  if (commitsLoading) {
    return <QueryLoader testId="commit-burnup-report-loading" />;
  }

  return (
    <ReportFiltersProvider<CommitTrackerFilters>
      defaultFilters={defaultFilters}
      initialFilters={initialFilters}
      reportKey={COMMIT_TRACKER_REPORT_KEY}
    >
      <CommitTrackerReportContainer
        commits={commits}
        commitsLoading={commitsLoading}
      />
    </ReportFiltersProvider>
  );
};

type CommitTrackerReportContainerProps = {
  commits: Commit[];
  commitsLoading: boolean;
};

const renderTiles = ({
  commitType,
  softIntersection,
  hardIntersection,
  shortfall,
  currency,
  isLoading,
}: {
  commitType: CommitType;
  softIntersection: NameTotal | null;
  hardIntersection: NameTotal | null;
  shortfall?: CommitShortfall;
  currency?: string;
  isLoading: boolean;
}) => {
  const hasSoft = [CommitType.SOFT, CommitType.HARD_AND_SOFT].includes(
    commitType
  );
  const hasHard = [CommitType.HARD, CommitType.HARD_AND_SOFT].includes(
    commitType
  );

  const hardShortfall =
    shortfall?.hardAmountShortfall &&
    monetaryFormat(shortfall.hardAmountShortfall, currency);

  const softShortfall =
    shortfall?.softAmountShortfall &&
    monetaryFormat(shortfall.softAmountShortfall, currency);

  return (
    <>
      {hasHard && hardShortfall && (
        <ShortfallTile
          commitType={CommitType.HARD}
          amount={hardShortfall}
          isLoading={isLoading}
        />
      )}
      {hasSoft && softShortfall && (
        <ShortfallTile
          commitType={CommitType.SOFT}
          amount={softShortfall}
          isLoading={isLoading}
        />
      )}
      {hasHard && (
        <CommitIntersectionTile
          isLoading={isLoading}
          commitType={CommitType.HARD}
          intersection={hardIntersection}
        />
      )}
      {hasSoft && (
        <CommitIntersectionTile
          isLoading={isLoading}
          commitType={CommitType.SOFT}
          intersection={softIntersection}
        />
      )}
    </>
  );
};

export const CommitTrackerReportContainer = ({
  commits,
  commitsLoading,
}: CommitTrackerReportContainerProps) => {
  const { selectedCustomerId } = useStateContext();

  const {
    filters,
    appliedFilters,
    applyFilters,
    setFilterValue,
    resetFilters,
  } = useFilters<CommitTrackerFilters>();

  const hasCommits = commits.length > 0;

  const {
    data,
    isLoading,
    isError,
    rawDataRequestFilters,
  } = useCommitTrackerData({
    customerId: selectedCustomerId,
    filters: appliedFilters,
    enabled: !commitsLoading,
  });

  const currency = data?.currency;

  const {
    softIntersection,
    hardIntersection,
  } = useCommitTrackerReportIntersections(data, appliedFilters);

  const currentCommitShortfall = data?.shortfall;

  const exportApiPath = apiPaths.commitTrackerExport(selectedCustomerId);

  const exportFilters = {
    ...rawDataRequestFilters,
    timeInterval: 'month',
  } as CommitTrackerFiltersDto;

  return (
    <>
      <GenericReport
        renderPageHeader={() => (
          <PageHeader title="Commit Tracker">
            <ReportExportManager
              path={exportApiPath}
              filters={exportFilters}
              type={COMMIT_TRACKER_REPORT_KEY}
            />
          </PageHeader>
        )}
        renderTiles={() => {
          if (appliedFilters.selectedYear && appliedFilters.selectedType) {
            return renderTiles({
              commitType: appliedFilters.selectedType,
              softIntersection,
              hardIntersection,
              shortfall: currentCommitShortfall,
              currency,
              isLoading,
            });
          }
          return null;
        }}
        renderFilters={() => {
          if (hasCommits) {
            return (
              <CommitTrackerFilterBar
                filters={filters}
                setFilterValue={setFilterValue}
                onSubmit={applyFilters}
                resetFilters={resetFilters}
                commits={commits}
                commitsLoading={commitsLoading}
              />
            );
          }
          return null;
        }}
        renderReport={() =>
          hasCommits ? (
            <ChartWidgetContainer legend>
              <ChartHeader
                renderTitle={() => (
                  <CommitTrackerReportTitle filters={appliedFilters} />
                )}
              />
              <CommitTrackerChart
                data={data}
                selectedCommit={appliedFilters.selectedCommit}
                selectedYear={appliedFilters.selectedYear}
                selectedType={appliedFilters.selectedType}
                softIntersection={softIntersection}
                hardIntersection={hardIntersection}
                isLoading={commitsLoading || isLoading}
                isError={isError}
              />
            </ChartWidgetContainer>
          ) : (
            <Alert severity="error">There are no commits to display.</Alert>
          )
        }
      />
    </>
  );
};
