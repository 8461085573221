import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { useFormContext } from 'react-hook-form';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import {
  Project,
  ProjectContact,
  ProjectContactType,
} from '../../../domain/types';
import { ProjectFormValues } from './ProjectSchema';
import {
  DeepMap,
  FieldError,
  UseFormMethods,
} from 'react-hook-form/dist/types';
import { useState } from 'react';

export const ProjectForm: CrudFormComponent<Project> = ({ item }) => {
  const { register, errors } = useFormContext<ProjectFormValues>();

  const businessOwnerItem = item?.contacts?.[ProjectContactType.BUSINESS_OWNER];
  const businessOwnerErrors =
    errors.contacts?.[ProjectContactType.BUSINESS_OWNER];

  const productManagerItem =
    item?.contacts?.[ProjectContactType.PRODUCT_MANAGER];
  const productManagerErrors =
    errors.contacts?.[ProjectContactType.PRODUCT_MANAGER];

  return (
    <>
      <CrudFormControl>
        <TextField
          id="projectId"
          label="Project ID"
          value={item?.gcpProjectId ?? ''}
          disabled={true}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.description}>
        <TextField
          id="description"
          name="description"
          inputRef={register}
          label="Description"
          defaultValue={item?.description ?? ''}
          error={!!errors.description}
        />
      </CrudFormControl>
      <ContactSection
        testId="business-owner-section"
        checkboxLabel="Business Owner"
        register={register}
        errors={businessOwnerErrors}
        initialItem={businessOwnerItem}
        contactType={ProjectContactType.BUSINESS_OWNER}
      />
      <ContactSection
        testId="product-manager-section"
        checkboxLabel="Product Manager"
        register={register}
        errors={productManagerErrors}
        initialItem={productManagerItem}
        contactType={ProjectContactType.PRODUCT_MANAGER}
      />
    </>
  );
};

interface ContactSectionProps {
  checkboxLabel: string;
  testId: string;
  register: UseFormMethods<ProjectFormValues>['register'];
  errors?: DeepMap<ProjectContact, FieldError>;
  contactType: ProjectContactType;
  initialItem?: ProjectContact;
}
const ContactSection = ({
  register,
  errors,
  contactType,
  initialItem,
  checkboxLabel,
  testId,
}: ContactSectionProps) => {
  const [expanded, setExpanded] = useState(!!initialItem);
  const nameField = `contacts.${contactType}.name`;
  const nameError = errors?.name;
  const emailField = `contacts.${contactType}.email`;
  const emailError = errors?.email;
  return (
    <div>
      <FormControlLabel
        label={checkboxLabel}
        control={
          <Checkbox
            checked={expanded}
            color="primary"
            onChange={(e) => setExpanded(e.target.checked)}
          />
        }
      />
      <Collapse in={expanded} unmountOnExit>
        <Grid container spacing={2} data-testid={testId}>
          <Grid item xs={6}>
            <CrudFormControl error={nameError}>
              <TextField
                id={nameField}
                name={nameField}
                inputRef={register}
                label="Name"
                defaultValue={initialItem?.name ?? ''}
                error={!!nameError}
              />
            </CrudFormControl>
          </Grid>
          <Grid item xs={6}>
            <CrudFormControl error={emailError}>
              <TextField
                id={emailField}
                name={emailField}
                inputRef={register}
                label="Email"
                defaultValue={initialItem?.email ?? ''}
                error={!!emailError}
              />
            </CrudFormControl>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};
