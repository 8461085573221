import { Card, Dialog, DialogTitle, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SystemLock } from '../../../domain/types';

export const SystemManagementProcessDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  selectedProcess: SystemLock | null;
}> = ({ open, onClose, selectedProcess }) => {
  const classes = useStyles();
  const processRows = [
    {
      label: 'Process ID',
      field: selectedProcess?.processId,
    },
    {
      label: 'Process Status',
      field: selectedProcess?.processStatus,
    },
    {
      label: 'Process Result',
      field: selectedProcess?.processResult,
    },
    {
      label: 'Time Begin',
      field: selectedProcess?.timeBegin,
    },
    {
      label: 'Time End',
      field: selectedProcess?.timeEnd,
    },
  ];

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Process Overview</DialogTitle>
      <div className={classes.dialogBody}>
        {processRows.map((row) => (
          <div className={classes.statusRow} key={row.label}>
            <span className={classes.statusRowLabel}>{row.label}</span>
            <span>{row.field}</span>
          </div>
        ))}
        <Card variant="outlined">
          <pre>{JSON.stringify(selectedProcess?.stateValues, null, 2)}</pre>
        </Card>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  statusRow: {
    marginBottom: theme.spacing(2),
  },
  statusRowLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  dialogBody: {
    padding: theme.spacing(4),
  },
}));
