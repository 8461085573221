import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DisclaimerMessage, PagedItems } from '../../../../domain/types';
import { GET, GET_PAGED } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';

export const useDisclaimerData = () => {
  const disclaimersApiPath = apiPaths.disclaimerManager();
  const activeDisclaimerPath = apiPaths.disclaimerMessageActive();

  const {
    data: activeDisclaimer,
    isLoading: isActiveLoading,
  } = useQuery<DisclaimerMessage>(
    activeDisclaimerPath.key.namespace(),
    () => GET(activeDisclaimerPath.base),
    {
      retry: false,
    }
  );

  const {
    data: historicalDisclaimers,
    isLoading: isHistoricalLoading,
    isError,
  } = useQuery<PagedItems<DisclaimerMessage>>(
    disclaimersApiPath.key.list(),
    () => GET_PAGED(disclaimersApiPath.base),
    {
      enabled: !isActiveLoading,
      retry: false,
    }
  );

  return useMemo(() => {
    let items = historicalDisclaimers?.items ?? [];
    const lastUpdateToDate = (lastUpdate?: string) =>
      new Date(lastUpdate ?? new Date());
    items.sort((first, second) => {
      const firstDate = lastUpdateToDate(first.lastUpdate);
      const secondDate = lastUpdateToDate(second.lastUpdate);

      return secondDate.getTime() - firstDate.getTime();
    });

    items = activeDisclaimer ? [activeDisclaimer, ...items] : items;

    return {
      items,
      isLoading: isHistoricalLoading || isActiveLoading,
      isError,
    };
  }, [
    activeDisclaimer,
    historicalDisclaimers,
    isHistoricalLoading,
    isActiveLoading,
    isError,
  ]);
};
