import { number, object, SchemaOf, string } from 'yup';
import { BillingAccount, Editable, FormValues } from '../../../domain/types';

export type BillingAccountValues = Editable<FormValues<BillingAccount>>;

export const BillingAccountSchema: SchemaOf<BillingAccountValues> = object({
  customerId: number().required(),
  billingAccountId: string().required(),
  currency: string().required().nullable(),
});
