import { Box } from '@mui/system';
import { CustomerDiscount } from '../../../../domain/types';
import { Card, IconButton, Typography, styled } from '@mui/material';
import { DatePickerField } from '../../../Fields/DatePickerField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { PercentageInput } from './PercentageInput';
import { DiscountCardExceptions } from './DiscountCardExceptions';
import { useCustomerAdminState } from '../hooks/useCustomerAdminState';
import { useDiscountRanges } from '../hooks/useDiscountRanges';

const CardLabel = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  display: 'inline-block',
  marginRight: theme.spacing(1),
}));

const ExceptionsButton = ({
  collapsed,
  onClick,
}: {
  collapsed: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton onClick={() => onClick()}>
      {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

const StyledDatePicker = styled(DatePickerField)(() => ({
  width: 140,
}));

interface ComponentProps {
  item: CustomerDiscount;
}

export const DiscountCard = ({ item }: ComponentProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const { actions } = useCustomerAdminState();
  const intervals = useDiscountRanges(item);

  const handleUpdateDiscount = (
    field: keyof CustomerDiscount,
    value: string | number | null
  ) => {
    actions.updateCustomerDiscount({
      ...item,
      [field]: value,
    });
  };

  return (
    <Box data-testid="customer-discounts-card" component={Card} mb={2} p={2}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flex={1}
        >
          <Box display="flex" alignItems="center" mr={3}>
            <CardLabel>Start Date</CardLabel>{' '}
            <StyledDatePicker
              minDate={intervals?.start.min}
              maxDate={intervals?.start.max}
              onChange={(value) => handleUpdateDiscount('startDate', value)}
              value={item.startDate ? new Date(item.startDate) : null}
            />
          </Box>
          <Box display="flex" alignItems="center" mr={3}>
            <CardLabel>End Date</CardLabel>{' '}
            <StyledDatePicker
              disabled={!item.startDate}
              minDate={intervals?.end.min}
              maxDate={intervals?.end.max}
              onChange={(value) => handleUpdateDiscount('endDate', value)}
              value={item.endDate ? new Date(item.endDate) : null}
            />
          </Box>
          <Box>
            <PercentageInput
              value={item.percentage}
              onChange={(value) => handleUpdateDiscount('percentage', value)}
            />
          </Box>
        </Box>
        <Box mr={2}>
          <ExceptionsButton
            collapsed={collapsed}
            onClick={() => setCollapsed((prevState) => !prevState)}
          />
          <Typography display="inline-block" variant="button">
            {item.exceptions.length}
          </Typography>
        </Box>
        <IconButton
          onClick={() =>
            actions.removeCustomerDiscount(item.temporaryUID ?? '')
          }
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      {collapsed ? (
        <DiscountCardExceptions
          discountUID={item.temporaryUID ?? ''}
          exceptions={item.exceptions}
        />
      ) : null}
    </Box>
  );
};
