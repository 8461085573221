import { ButtonGroup, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStateContext } from '../../../contexts/StateProvider';
import clsx from 'clsx';

export const NetGrossSelector = () => {
  const classes = useStyles();
  const { reportCostType, setReportCostType } = useStateContext();

  return (
    <ButtonGroup size="small" variant="outlined" aria-label="cost type">
      <Button
        className={clsx(
          classes.selectorButton,
          reportCostType === 'net' && classes.selected
        )}
        onClick={() => setReportCostType('net')}
      >
        NET
      </Button>
      <Button
        className={clsx(
          classes.selectorButton,
          reportCostType === 'gross' && classes.selected
        )}
        onClick={() => setReportCostType('gross')}
      >
        GROSS
      </Button>
    </ButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  selectorButton: {
    width: 52,
    height: 26,
    fontSize: 12,
  },
  selected: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light,
      color: 'white',
    },
  },
}));
