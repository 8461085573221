import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { ConsumptionSKU } from '../../../../domain/types';
import { VOID_ID } from '../../../../domain/constants';

export const useConsumptionSKUS = (customerId: number) => {
  const apiPath = apiPaths.consumptionSKUs(customerId);

  const { data, isLoading } = useQuery<ConsumptionSKU[]>(
    apiPath.key.list(),
    () => GET(apiPath.base),
    {
      enabled: customerId !== VOID_ID,
    }
  );

  return {
    skus: data ?? [],
    isLoading,
  };
};
