import Link from '@mui/material/Link';
import Modal, { ModalProps } from './Modal';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Customer, PagedItems } from '../../domain/types';
import { GET_PAGED } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { useStateContext } from '../../contexts/StateProvider';
import { Box, Button, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { VOID_ID } from '../../domain/constants';
import { useHistory } from 'react-router-dom';
import { dashboardPaths } from '../Routes/useDashboardRoutes';

export type CustomersModalProps = Pick<ModalProps, 'open' | 'onClose'>;

const CustomersModal = ({ open, onClose }: CustomersModalProps) => {
  const history = useHistory();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const { selectedCustomerId, setSelectedCustomerId } = useStateContext();
  const hasSelectedCustomer = selectedCustomerId !== VOID_ID;

  const [searchedCustomer, setSearchedCustomer] = useState('');

  const { data, isLoading, error } = useQuery<PagedItems<Customer>>(
    apiPaths.customer.key.list(),
    () => GET_PAGED(apiPaths.customer.base),
    {
      retry: false,
      keepPreviousData: true,
      enabled: open,
    }
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const { id, name } = row;
          return (
            <Link
              component="button"
              variant="body2"
              title={`Select ${name}`}
              onClick={() => {
                setSelectedCustomerId(Number(id));
                onClose();
              }}
            >
              {name}
            </Link>
          );
        },
      },
    ];
  }, [onClose, setSelectedCustomerId]);

  const visibleRows = useMemo(() => {
    return (data?.items ?? []).filter((customer) =>
      customer.name
        .toLocaleLowerCase()
        .includes(searchedCustomer.toLocaleLowerCase())
    );
  }, [data?.items, searchedCustomer]);

  const clearSelectedCustomer = () => {
    setSelectedCustomerId(VOID_ID);
    history.push(dashboardPaths.basePath);
    onClose();
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchedCustomer(e.target.value);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
  };

  return (
    <Modal
      title={
        <Box display="flex" width="100%">
          <Box flexGrow={1}>Select a customer</Box>
          {hasSelectedCustomer && (
            <Button color="primary" onClick={clearSelectedCustomer}>
              Clear selected customer
            </Button>
          )}
        </Box>
      }
      content={
        <Box height={550}>
          {!error && (
            <Box mb={2}>
              <TextField
                fullWidth
                label="Find customer"
                id="findCustomer"
                value={searchedCustomer}
                onChange={onSearch}
              />
            </Box>
          )}
          {error ? (
            <div>An error occurred.</div>
          ) : (
            <DataGrid
              // data
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
              rows={visibleRows}
              pageSizeOptions={[10]}
              // state
              loading={isLoading}
              // layout
              autoHeight
              density="compact"
              // pagination
              rowCount={visibleRows.length}
            />
          )}
        </Box>
      }
      onClose={onClose}
      showCloseIcon={false}
      open={open}
      id="customers-dialog"
    />
  );
};

export default CustomersModal;
