import { useMemo } from 'react';
import {
  ConsumptionFilters,
  GCPLabel,
  ReportBreakdown,
} from '../../../../domain/types';

interface Props {
  projectsLabels: GCPLabel[];
  resourcesLabels: GCPLabel[];
  breakdown: ReportBreakdown;
}

interface ReturnValue {
  breakdownLabelsOptions: string[];
  breakdownLabelsItemLabel: string;
  breakdownLabelsFilterKey: keyof ConsumptionFilters;
}

const parseLabels = (labels: GCPLabel[]) => {
  const labelsKeys: string[] = labels.map((label) => label.name.split(':')[0]);
  return Array.from(new Set(labelsKeys));
};

export const useBreakdownLabels = ({
  projectsLabels,
  resourcesLabels,
  breakdown,
}: Props): ReturnValue => {
  const labelsKeys = useMemo(() => {
    return {
      projects: parseLabels(projectsLabels),
      resources: parseLabels(resourcesLabels),
    };
  }, [projectsLabels, resourcesLabels]);

  return useMemo(() => {
    switch (breakdown) {
      case 'project_label':
        return {
          breakdownLabelsOptions: labelsKeys.projects,
          breakdownLabelsItemLabel: 'Projects Labels',
          breakdownLabelsFilterKey: 'projectLabel',
        };
      case 'resource_label':
        return {
          breakdownLabelsOptions: labelsKeys.resources,
          breakdownLabelsItemLabel: 'Resource Labels',
          breakdownLabelsFilterKey: 'resourceLabel',
        };
      default:
        return {
          breakdownLabelsOptions: [],
          breakdownLabelsItemLabel: 'Projects Labels',
          breakdownLabelsFilterKey: 'projectLabel',
        };
    }
  }, [labelsKeys, breakdown]);
};
