import {
  BudgetAllocationType,
  BudgetRemainingItemData,
  ConsumptionData,
  ConsumptionValue,
  CommitTrackerData,
  ReportBreakdown,
  ReportTimeInterval,
  GCPLabel,
  GCPLabelType,
} from '../../domain/types';
import { add, format } from 'date-fns';

const START_DATE = new Date('2020-01-01');
const MONTHLY_LENGTH = 12;
const DAILY_LENGTH = 100;

const getMonth = (index: number) =>
  format(add(START_DATE, { months: index }), 'MMM-yyyy');

const getDay = (index: number) =>
  format(add(START_DATE, { days: index }), 'dd-MMM-yyyy');

const getRandom = (amount: number, randomness: number) => {
  const jitter = Math.random() * randomness - randomness / 2;
  return Math.max(0, amount + jitter);
};

const applyRandomOffsets = (source: ConsumptionValue[], randomness: number) => {
  return source.map((item) => {
    const gross_cost = getRandom(item.gross_cost, randomness);
    const discount = getRandom(item.discount, randomness);
    const net_cost = gross_cost + discount;
    return { ...item, net_cost, gross_cost, discount };
  });
};

const createForecastValues = (
  source: ConsumptionValue[],
  splitIndex: number
) => {
  const consumption = source.slice(0, splitIndex).map(({ name, net_cost }) => ({
    name: name ?? 'name',
    total: net_cost,
  }));
  const forecast = source.slice(splitIndex).map(({ name, net_cost }) => ({
    name: name ?? 'name',
    total: net_cost,
  }));
  return { consumption, forecast };
};

const getValuesFromGrossCost = (gross_cost: number) => {
  const discount_percentage = 10;
  const discount = gross_cost / discount_percentage;

  return {
    gross_cost,
    discount,
    discount_percentage: discount_percentage / 100, // api returns values divided by 100 for percentage
    net_cost: gross_cost - discount,
  };
};

const getConsumptionData = (interval: ReportTimeInterval) => {
  const costMultiplier = interval === 'month' ? 500 : 50;
  const length = interval === 'month' ? MONTHLY_LENGTH : DAILY_LENGTH;

  return {
    currency: 'USD',
    items: Array.from({ length }, (_, index) => ({
      name: interval === 'month' ? getMonth(index) : getDay(index),
      ...getValuesFromGrossCost((index + 1) * costMultiplier),
    })),
  };
};

export const mockConsumptionDataMonthly = getConsumptionData('month');
export const mockConsumptionDataDaily = getConsumptionData('doy');

const getConsumptionBreakdown = (
  interval: ReportTimeInterval,
  entity:
    | 'project'
    | 'service'
    | 'sku'
    | 'project_group'
    | 'customer'
    | 'billing_account'
    | 'project_label'
    | 'resource_label',
  summary: boolean | undefined
) => {
  const itemLength = 24;
  const costMultiplier = interval === 'month' ? 500 : 5;
  const length = interval === 'month' ? MONTHLY_LENGTH : DAILY_LENGTH;
  const currency = interval === 'month' ? 'USD' : 'GBP';

  const entities = {
    project: 'Project',
    service: 'Service',
    sku: 'Sku',
    project_group: 'Project Group',
    customer: 'Customer',
    billing_account: 'Billing Account',
    project_label: 'Project Label',
    resource_label: 'Resource Label',
  };

  // summary information:
  if (summary) {
    return {
      currency,
      items: Array.from({ length: itemLength }, (_, entityIndex) => ({
        name: 'Overall',
        [entity]: `${entities[entity] ?? ''} ${entityIndex + 1}`,
        ...getValuesFromGrossCost(
          (entityIndex + 1) * 10000 * (entityIndex + 1)
        ),
      })),
    };
  }

  return {
    currency,
    items: Array.from({ length }, (_, index) =>
      Array.from({ length: itemLength }, (_, entityIndex) => ({
        name: interval === 'month' ? getMonth(index) : getDay(index),
        [entity]: `${entities[entity] ?? ''} ${entityIndex + 1}`,
        ...getValuesFromGrossCost(
          (index + 1) * costMultiplier * (entityIndex + 1)
        ),
      }))
    ).flat(),
  };
};

export const getMockConsumptionData = ({
  timeInterval,
  breakdown,
  summary,
}: {
  timeInterval: ReportTimeInterval;
  breakdown: ReportBreakdown;
  summary?: boolean;
}): ConsumptionData => {
  const unhandled = {
    currency: 'GBP',
    items: [],
  };

  // not yet working with quarter-based data:
  if (['quarter'].includes(timeInterval)) {
    return unhandled;
  }

  // none: summary (for report table, over the entire period)
  // breakdown: overall, project, service, project_group
  if (breakdown === 'overall') {
    return getConsumptionData(timeInterval);
  } else if (
    [
      'project',
      'service',
      'sku',
      'project_group',
      'customer',
      'billing_account',
      'project_label',
      'resource_label',
    ].includes(breakdown)
  ) {
    return getConsumptionBreakdown(timeInterval, breakdown, summary);
  }

  return unhandled;
};

const forecastDataMonthlyCache = new Map<number, CommitTrackerData>();
const forecastDataDailyCache = new Map<number, CommitTrackerData>();

const getMockForecast = (
  customerId: number,
  { currency, items }: ConsumptionData,
  cache: Map<number, CommitTrackerData>,
  randomness: number,
  seriesLength: number
) => {
  if (cache.has(customerId)) {
    return cache.get(customerId)!;
  }

  const consumptionData = applyRandomOffsets(items, randomness);
  const { consumption, forecast } = createForecastValues(
    consumptionData,
    // half and half + 1:
    Math.floor(seriesLength / 2) + 1
  );

  const data = {
    currency,
    consumption,
    forecast,
    excessRollover: 0,
  };
  cache.set(customerId, data);
  return data;
};

export const getMockForecastDataMonthly = (
  customerId: number
): CommitTrackerData => {
  const randomness = 500;
  return getMockForecast(
    customerId,
    mockConsumptionDataMonthly,
    forecastDataMonthlyCache,
    randomness,
    MONTHLY_LENGTH
  );
};

export const getMockForecastDataDaily = (
  customerId: number
): CommitTrackerData => {
  const randomness = 40;
  return getMockForecast(
    customerId,
    mockConsumptionDataDaily,
    forecastDataDailyCache,
    randomness,
    DAILY_LENGTH
  );
};

export const getBurndownData = (
  interval: ReportTimeInterval
): BudgetRemainingItemData => {
  const costMultiplier = interval === 'month' ? 500 : 12;
  let budgetAmount = 30_000;
  let initialBudget = budgetAmount;
  const length = interval === 'month' ? MONTHLY_LENGTH : DAILY_LENGTH;
  const consumption = Array.from({ length }, (_, index) => ({
    name: interval === 'month' ? getMonth(index) : getDay(index),
    total: costMultiplier * (index + 1),
  }));
  const budget_remaining: number[] = [];

  consumption.forEach(({ total }) => {
    budgetAmount -= total;
    budget_remaining.push(budgetAmount);
  });

  const forecast = consumption.splice(length / 2);

  // split halfway point between consumption and forecast so that
  // we can see the stacked data:
  consumption.push({
    name: interval === 'month' ? getMonth(length / 2) : getDay(length / 2),
    total: consumption[length / 2 - 1].total / 2,
  });
  forecast[0].total = forecast[0].total / 2;

  return {
    currency: 'USD',
    consumption,
    forecast,
    budget_remaining: budget_remaining.map((value, index) => ({
      total: value,
      name: interval === 'month' ? getMonth(index) : getDay(index),
    })),
    budget: {
      id: 1,
      customerId: 1,
      startDate: '01-Jan-2020',
      endDate: '09-Apr-2020',
      runOutDate: '11-Mar-2020',
      formattedDates: {
        start: interval === 'month' ? 'Jan-2020' : '01-Jan-2020',
        end: interval === 'month' ? 'Dec-2020' : '09-Apr-2020',
        runOut: interval === 'month' ? 'Nov-2020' : '11-Mar-2020',
      },
      amount: initialBudget,
      unallocated: {
        absolute: 70_000,
        percentage: 0.7,
      },
      allocationType: BudgetAllocationType.GROUP,
      currentBudget: 1950,
    },
  };
};

/**
 * Mock server response for project and service labels.
 * @route /api/customers/{customerId}/labels/
 */
export const generateConsumptionLabelsData = (length: number = 20) => {
  const payload: { projects: GCPLabel[]; resources: GCPLabel[] } = {
    projects: [],
    resources: [],
  };
  for (let id = 1; id <= length; id++) {
    payload.projects.push({
      id,
      name: `project-label:${id}`,
      type: GCPLabelType.PROJECT,
    });
    payload.resources.push({
      id,
      name: `service-label:${id}`,
      type: GCPLabelType.RESOURCE,
    });
  }
  return payload;
};

/**
 * Mock server response for consumption filter services
 * @route /api/data/consumption/{customerId}/services
 */
export const generateConsumptionFilterServices = (length: number) => {
  return Array.from({ length }, (_, index) => ({
    id: index + 1,
    name: `Service ${index + 1}`,
  }));
};

// Data is programatically generated we don't store mock data all the time
// an arbitrary trehsold was choosen to establish when a project group is not
// allocated to a budget.
const UNALLOCATED_PROJECT_GROUP_ID_TRESHOLD = 4;

export const isProjectGroupIdUnallocated = (
  projectGroupId: number | undefined
): boolean => {
  return !!projectGroupId
    ? projectGroupId <= UNALLOCATED_PROJECT_GROUP_ID_TRESHOLD
    : true;
};
