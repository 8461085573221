import React, { useCallback, useMemo, useState } from 'react';
import { useFilters } from '../../../contexts/ReportFiltersProvider';
import { useStateContext } from '../../../contexts/StateProvider';
import { CONSUMPTION_REPORT_KEY, VOID_ID } from '../../../domain/constants';
import {
  ConsumptionFilters,
  ConsumptionFiltersDto,
  ConsumptionItemValue,
  Project,
} from '../../../domain/types';
import { ConsumptionChart } from '../../Charts/ConsumptionChart';
import { useConsumptionData } from '../../Charts/useConsumptionData';
import { GenericReport } from '../GenericReport';
import { SummaryTile } from '../Tiles/SummaryTile';
import { ConsumptionFilterBar } from './ConsumptionFilterBar';
import { ConsumptionReportTitle } from './ConsumptionReportTitle';
import { NetGrossSelector } from './NetGrossSelector';
import { ConsumptionByItemChart } from '../../Charts/ConsumptionByItemChart';
import { ConsumptionItemSummariesGrid } from '../../Charts/ConsumptionItemSummariesGrid';
import { ReportExportManager } from '../../Charts/ReportExportManager';
import { apiPaths } from '../../../utils/apiPaths';
import { ChartProvider } from '../../Charts/ChartProvider';
import { useProjectGroups } from '../../../hooks/useProjectGroups';
import { useConsumptionDrillDown } from './hooks/useConsumptionDrillDown';
import { ChartHeader } from '../../Charts/ChartHeader';
import { PageHeader } from '../../PageHeader';
import { ChartWidgetContainer } from '../../ChartWidgetContainer';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FunctionsIcon from '@mui/icons-material/Functions';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PieChartIcon from '@mui/icons-material/PieChart';
import { ConsumptionDisclaimer } from './ConsumptionDisclaimer';

const BILLING_ACCOUNT_PROJECT_PREFIX = 'Billing Account Level Charge';

export const ConsumptionReportContainer = () => {
  const { selectedCustomerId, reportCostType } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;
  const customerId = isCustomerSelected ? selectedCustomerId : undefined;

  const {
    filters,
    appliedFilters,
    applyFilters,
    setFilterValue,
    resetFilters,
  } = useFilters<ConsumptionFilters>();
  const {
    rawData,
    formattedData,
    summaryData,
    groupedSummaryData,
    itemMap,
    averageGross,
    averageNet,
    totals,
    isLoading,
    isError,
    rawDataRequestFilters,
    nativeCurrency,
  } = useConsumptionData({
    customerId,
    filters: appliedFilters,
  });
  const [projects, setProjects] = useState<Partial<Project>[]>([]);

  const { state, actions } = useProjectGroups({
    includeProjects: true,
    includeUngroupedProjects: true,
    projectsDefaultFilters: filters.projectFilters,
    includeBillingAccountCharges: true,
  });
  const onDrillDown = useConsumptionDrillDown(state, actions);
  const { breakdown } = appliedFilters;

  const averageTitle = useMemo(() => {
    return `Avg ${
      appliedFilters.timeInterval === 'doy' ? 'Daily' : 'Monthly'
    } Spend`;
  }, [appliedFilters]);

  const selectedCostType = useMemo(
    () => ({
      dataKey: reportCostType === 'gross' ? 'gross_cost' : 'net_cost',
      name: 'Actual Cost',
    }),
    [reportCostType]
  );

  // reverse array so highest cost is at the top of the stack/area
  const chartItems = useMemo(() => {
    return groupedSummaryData?.items.slice().reverse() ?? [];
  }, [groupedSummaryData]);

  const onProjectsLoad = useCallback((projects: Partial<Project>[]) => {
    setProjects(projects);
  }, []);

  // Add project name for summary data
  const consumptionSummaryData = useMemo(() => {
    if (summaryData?.items) {
      const projectItems = summaryData.items.map(
        (projectItem: ConsumptionItemValue) => {
          const project = projects.find(
            (project) => project.gcpProjectId === projectItem.project
          );
          const projectName = projectItem.project?.includes(
            BILLING_ACCOUNT_PROJECT_PREFIX
          )
            ? projectItem.project
            : project?.gcpProjectName;

          return { ...projectItem, project_name: projectName };
        }
      );

      summaryData.items = projectItems;
    }

    return summaryData && { ...summaryData };
  }, [summaryData, projects]);

  const exportApiPath = apiPaths.consumptionExport(customerId, breakdown);

  const exportFilters = {
    ...rawDataRequestFilters,
    costType: reportCostType,
  } as ConsumptionFiltersDto;
  return (
    <>
      <GenericReport
        renderPageHeader={() => (
          <PageHeader title={'Consumption'}>
            {customerId && (
              <ReportExportManager
                path={exportApiPath}
                filters={exportFilters as ConsumptionFiltersDto}
                type={CONSUMPTION_REPORT_KEY}
              />
            )}
          </PageHeader>
        )}
        renderTiles={() => (
          <>
            <SummaryTile
              isLoading={isLoading}
              title="Total Gross Cost"
              value={totals?.gross}
              currency={rawData?.currency ?? formattedData?.currency}
              icon={<PieChartIcon fontSize="large" />}
            />
            <SummaryTile
              isLoading={isLoading}
              title="Total Net Cost"
              value={totals?.net}
              currency={rawData?.currency ?? formattedData?.currency}
              icon={<MonetizationOnIcon fontSize="large" />}
            />
            <SummaryTile
              isLoading={isLoading}
              title="Total Discount"
              value={totals?.discount ? -totals.discount : undefined}
              currency={rawData?.currency ?? formattedData?.currency}
              icon={<LocalOfferIcon fontSize="large" />}
            />
            {breakdown === 'overall' && (
              <SummaryTile
                isLoading={isLoading}
                title={averageTitle}
                value={reportCostType === 'gross' ? averageGross : averageNet}
                currency={rawData?.currency ?? formattedData?.currency}
                icon={<FunctionsIcon fontSize="large" />}
              />
            )}
          </>
        )}
        renderFilters={() => (
          <ConsumptionFilterBar
            state={state}
            actions={actions}
            currency={rawData?.currency}
            reportIsLoading={isLoading}
            nativeCurrency={nativeCurrency}
            filters={filters}
            appliedFilters={appliedFilters}
            setFilterValue={setFilterValue}
            resetFilters={resetFilters}
            onSubmit={applyFilters}
            onProjectsLoad={onProjectsLoad}
          />
        )}
        renderDisclaimer={() => <ConsumptionDisclaimer />}
        renderReport={() => {
          if (breakdown === 'overall') {
            return (
              <ChartWidgetContainer legend>
                <ChartHeader
                  renderTitle={() => (
                    <ConsumptionReportTitle filters={appliedFilters} />
                  )}
                  renderSideControls={() => <NetGrossSelector />}
                />
                <ConsumptionChart
                  onDrillDown={onDrillDown}
                  data={rawData}
                  isLoading={isLoading}
                  isError={isError}
                  appliedFilters={appliedFilters}
                  selectedCostType={selectedCostType}
                />
              </ChartWidgetContainer>
            );
          } else {
            return (
              <ChartProvider>
                <ChartWidgetContainer>
                  <ChartHeader
                    renderTitle={() => (
                      <ConsumptionReportTitle filters={appliedFilters} />
                    )}
                    renderSideControls={() => <NetGrossSelector />}
                  />
                  <ConsumptionByItemChart
                    onDrillDown={onDrillDown}
                    data={formattedData}
                    costType={reportCostType}
                    isLoading={isLoading}
                    isError={isError}
                    timeInterval={appliedFilters.timeInterval}
                    chartItems={chartItems}
                    itemMap={itemMap}
                  />
                </ChartWidgetContainer>
                <ConsumptionItemSummariesGrid
                  data={consumptionSummaryData}
                  totals={totals}
                  filters={appliedFilters}
                  loading={isLoading}
                  error={isError}
                />
              </ChartProvider>
            );
          }
        }}
      />
    </>
  );
};
