import { useEffect, useMemo } from 'react';
import { SetReportFilterValue } from '../../../contexts/ReportFiltersProvider';
import {
  BudgetAllocationType,
  BurndownFilters,
  Role,
} from '../../../domain/types';
import { FilterBar } from '../FilterBar';
import { FilterBarItem } from '../FilterBarItem';
import { TimeIntervalFilter } from '../FilterFields/ConsumptionTimeIntervalFilter';
import { ProjectGroupsFilter } from '../../Charts/Filtering/ProjectGroupsFilter';
import { UseProjectGroupActions } from '../../../hooks/useProjectGroups';
import { useSingleSelection } from './useSingleSelection';
import { ProjectGroupsState } from '../../../hooks/projectGroupsSlice';
import { useAuthContext } from '../../../contexts/AuthProvider';

export interface BudgetBurndownFilterBarProps {
  filters: BurndownFilters;
  allocationType: BudgetAllocationType | null;
  setFilterValue: SetReportFilterValue<BurndownFilters>;
  onSubmit: () => void;
  resetFilters: () => void;
  state: ProjectGroupsState;
  actions: UseProjectGroupActions;
}

export const BudgetBurndownFilterBar = ({
  filters,
  allocationType,
  setFilterValue,
  onSubmit,
  resetFilters,
  state,
  actions,
}: BudgetBurndownFilterBarProps) => {
  const { userRole } = useAuthContext();
  const selectionLevel = useMemo(() => {
    return allocationType === BudgetAllocationType.GROUP ? 'group' : 'project';
  }, [allocationType]);

  const selection = useSingleSelection(state, selectionLevel);

  useEffect(() => {
    if (
      !selection.isSingleSelection ||
      state.selectionState !== 'none' ||
      userRole === Role.CUSTOMER_VIEWER
    ) {
      return;
    }

    selection.projectId !== -1
      ? actions.selectProject(selection.groupId, selection.projectId)
      : actions.selectGroup(selection.groupId);

    onSubmit();
  }, [selection, actions, state.selectionState, onSubmit, userRole]);

  return (
    <FilterBar
      onSubmit={onSubmit}
      onReset={() => {
        actions.resetStates();
        resetFilters();
      }}
    >
      <FilterBarItem>
        <TimeIntervalFilter
          value={filters.timeInterval}
          onChange={(val) => {
            setFilterValue('timeInterval', val);
          }}
        />
      </FilterBarItem>
      <FilterBarItem>
        <ProjectGroupsFilter
          state={state}
          actions={actions}
          multiSelect={false}
          selectionLevel={selectionLevel}
        />
      </FilterBarItem>
    </FilterBar>
  );
};
