import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import { Commit, CommitAmount } from '../../../../domain/types';
import {
  formatDate,
  calculateCommitEndDate,
} from '../../../../utils/dateUtils';
import { MonetaryDisplay } from '../../../MonetaryDisplay';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface ForecastYearFilterProps {
  value: CommitAmount | null;
  selectedCommit: Commit | null;
  onChange: (val: CommitAmount | null) => void;
}

const getItemName = (
  dateRange: CommitAmount,
  selectedCommit: Commit | null
) => {
  const startDate = dateRange.startDate;
  const endDate = calculateCommitEndDate(selectedCommit, startDate);

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

type CommitAmountOptionProps = {
  selectedCommit: Commit | null;
  commitAmount: CommitAmount;
  currency: string | undefined;
};

const CommitAmountOption = ({
  selectedCommit,
  commitAmount,
  currency,
}: CommitAmountOptionProps) => (
  <Box flexDirection="column">
    <Typography>{getItemName(commitAmount, selectedCommit ?? null)}</Typography>
    {commitAmount.hardAmount && (
      <Typography variant="body2" color="textSecondary">
        Hard Commit:{' '}
        <MonetaryDisplay value={commitAmount.hardAmount} currency={currency} />
      </Typography>
    )}
    {commitAmount.softAmount && (
      <Typography variant="caption" color="textSecondary">
        Soft Commit:{' '}
        <MonetaryDisplay value={commitAmount.softAmount} currency={currency} />
      </Typography>
    )}
  </Box>
);

export const ForecastYearFilter = ({
  value,
  selectedCommit,
  onChange,
}: ForecastYearFilterProps) => {
  return (
    <Autocomplete<CommitAmount>
      sx={{
        minWidth: '284px',
      }}
      value={value}
      onChange={(e, value) => {
        onChange(value);
      }}
      defaultValue={selectedCommit?.commitAmounts[0] ?? null}
      clearIcon=""
      options={selectedCommit?.commitAmounts ?? []}
      getOptionLabel={(item: CommitAmount) =>
        getItemName(item, selectedCommit ?? null)
      }
      isOptionEqualToValue={(option: CommitAmount, value: CommitAmount) => {
        return option.startDate === value.startDate;
      }}
      getOptionDisabled={(option: CommitAmount) =>
        new Date() < new Date(option.startDate)
      }
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <CommitAmountOption
            selectedCommit={selectedCommit}
            commitAmount={option}
            currency={selectedCommit?.currency}
          />
        </MenuItem>
      )}
      renderInput={(params) => <TextField {...params} label="Commit Period" />}
    />
  );
};
