import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { SnackBarNotificationProps } from '../components/Messaging/Snackbar';
import {
  LS_SELECTED_CUSTOMER_ID,
  REPORT_KEYS,
  VOID_ID,
} from '../domain/constants';
import { ReportCostType } from '../domain/types';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { removeKeysFromLocalStorage } from '../utils/localStorage';

export type NotificationArgs = Omit<SnackBarNotificationProps, 'onClose'>;

export type StateContextProps = {
  selectedCustomerId: number;
  setSelectedCustomerId: (id: number) => void;
  selectedCustomerName: string | undefined;
  setSelectedCustomerName: (id: string | undefined) => void;
  notification: NotificationArgs;
  showNotification: (
    props: Pick<NotificationArgs, 'message' | 'severity'>
  ) => void;
  closeNotification: () => void;
  reportCostType: ReportCostType;
  setReportCostType: (costType: ReportCostType) => void;
};

export const StateContext = React.createContext<StateContextProps>({
  selectedCustomerId: VOID_ID,
  setSelectedCustomerId: () => {},
  selectedCustomerName: undefined,
  setSelectedCustomerName: () => {},
  notification: { message: '' },
  showNotification: () => {},
  closeNotification: () => {},
  reportCostType: 'net',
  setReportCostType: () => {},
});

interface StateProviderProps {
  children: ReactNode;
  defaultSelectedCustomerId?: number;
  defaultReportCostType?: ReportCostType;
}
const StateProvider = ({
  children,
  defaultSelectedCustomerId = VOID_ID,
  defaultReportCostType = 'net',
}: StateProviderProps) => {
  const [selectedCustomerId, setSelectedCustomerId] = useLocalStorage(
    LS_SELECTED_CUSTOMER_ID,
    defaultSelectedCustomerId
  );
  const [selectedCustomerName, setSelectedCustomerName] = useState<any>('');

  const [notification, setNotification] = useState<NotificationArgs>({
    message: '',
  });
  const [reportCostType, setReportCostType] = useState<ReportCostType>(
    defaultReportCostType
  );
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };
  const showNotification = ({
    severity,
    message,
  }: Pick<NotificationArgs, 'message' | 'severity'>) => {
    setNotification({
      open: true,
      severity,
      message,
    });
  };

  const previousCustomerId = useRef(selectedCustomerId);

  useEffect(() => {
    if (previousCustomerId.current !== selectedCustomerId) {
      removeKeysFromLocalStorage(REPORT_KEYS);
    }
  }, [selectedCustomerId]);

  return (
    <StateContext.Provider
      value={{
        selectedCustomerId,
        setSelectedCustomerId,
        selectedCustomerName,
        setSelectedCustomerName,
        notification,
        showNotification,
        closeNotification,
        reportCostType,
        setReportCostType,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

const useStateContext = () => {
  return React.useContext(StateContext);
};

export { StateProvider, useStateContext };
