import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../utils/localStorage';
import { useStateContext } from './StateProvider';

export type SetReportFilterValue<T> = <K extends keyof T>(
  key: K,
  value: T[K],
  apply?: boolean
) => void;

export type ReportFiltersContextProps<T> = {
  filters: T;
  appliedFilters: T;
  setFilterValue: SetReportFilterValue<T>;
  applyFilters: () => void;
  resetFilters: () => void;
};

export const ReportFiltersContext = createContext<
  ReportFiltersContextProps<any>
>({
  filters: {},
  appliedFilters: {},
  setFilterValue: () => {},
  applyFilters: () => {},
  resetFilters: () => {},
});

interface ReportFilterProviderProps<T> {
  children: ReactNode;
  defaultFilters: T;
  initialFilters: T;
  reportKey: string;
}

export function ReportFiltersProvider<T>({
  defaultFilters,
  initialFilters,
  children,
  reportKey,
}: ReportFilterProviderProps<T> & { children: ReactNode }) {
  const { selectedCustomerId } = useStateContext();

  const [filters, setFilters] = useState<{
    active: T;
    applied: T;
  }>({ active: defaultFilters, applied: defaultFilters });

  useEffect(
    () =>
      setFilters({
        active: defaultFilters,
        applied: defaultFilters,
      }),
    [defaultFilters]
  );

  const setFilterValue = useCallback<SetReportFilterValue<T>>(
    (key, value, apply) => {
      setFilters(({ active, applied }) => {
        const nextActive = { ...active, ...{ [key]: value } };
        return {
          active: nextActive,
          applied: apply ? nextActive : applied,
        };
      });
    },
    []
  );

  const applyFilters = useCallback(() => {
    setFilters(({ active }) => {
      saveToLocalStorage(reportKey, active, selectedCustomerId);

      return {
        active,
        applied: active,
      };
    });
  }, [reportKey, selectedCustomerId]);

  const resetFilters = useCallback(() => {
    setFilters({
      active: initialFilters,
      applied: initialFilters,
    });

    removeFromLocalStorage(reportKey);
  }, [reportKey, initialFilters]);

  const value = useMemo<ReportFiltersContextProps<T>>(
    () => ({
      filters: filters.active,
      appliedFilters: filters.applied,
      setFilterValue,
      applyFilters,
      resetFilters,
    }),
    [
      filters.active,
      filters.applied,
      setFilterValue,
      applyFilters,
      resetFilters,
    ]
  );

  return (
    <ReportFiltersContext.Provider value={value}>
      {children}
    </ReportFiltersContext.Provider>
  );
}

export function useFilters<T>(): ReportFiltersContextProps<T> {
  return useContext(ReportFiltersContext) as ReportFiltersContextProps<T>;
}
