import { CommitType, NameTotal } from '../../../domain/types';
import { formatDate } from '../../../utils/dateUtils';
import { Tile } from '../Tiles/Tile';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { Grid } from '@mui/material';

interface CommitIntersectionTileProps {
  commitType: CommitType.SOFT | CommitType.HARD;
  intersection: NameTotal | null;
  isLoading: boolean;
}

export const CommitIntersectionTile = ({
  commitType,
  intersection,
  isLoading,
}: CommitIntersectionTileProps) => {
  return (
    <Grid item>
      <Tile
        icon={<MergeTypeIcon fontSize="large" />}
        isLoading={isLoading}
        heading={`${
          commitType === CommitType.HARD ? 'Hard' : 'Soft'
        } Commit Achieved`}
        subHeading={
          intersection ? (
            formatDate(intersection.name)
          ) : (
            <small>After Commit End Date</small>
          )
        }
      />
    </Grid>
  );
};
