import { Button } from '@mui/material';
import Modal from '../../../Modals/Modal';
import {
  SystemConfigItems,
  SystemConfigurationResource,
} from '../SystemConfigurationTypes';
import { useSaveSystemConfiguration } from '../hooks/useSaveSystemConfiguration';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  selection: SystemConfigItems;
  resourceType: SystemConfigurationResource;
}

export const SystemConfigurationApplyAllModal = ({
  open,
  onClose,
  selection,
  resourceType,
}: ComponentProps) => {
  const { saveSystemConfiguration } = useSaveSystemConfiguration();

  const applyHandler = ({ applyToAll }: { applyToAll: boolean }) => {
    if (!selection) {
      return;
    }
    saveSystemConfiguration({
      applyToAll,
      resourceType,
      selection,
    });

    onClose();
  };

  return (
    <Modal
      id="apply-to-all-modal"
      open={open}
      onClose={onClose}
      title="Apply Changes"
      content={'Apply change to all existing customers ?'}
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => applyHandler({ applyToAll: true })}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={() => applyHandler({ applyToAll: false })}
            color="inherit"
          >
            No
          </Button>
        </>
      }
    />
  );
};
