import { useEffect, useRef } from 'react';
import { useStateContext } from '../contexts/StateProvider';
import { VOID_ID } from '../domain/constants';
import { useCustomerIdSearchParam } from './useCustomerIdSearchParam';

export const useSyncCustomerIdSearchParam = () => {
  const [customerIdParam, setCustomerIdParam] = useCustomerIdSearchParam();
  const { selectedCustomerId, setSelectedCustomerId } = useStateContext();
  const firstTimeLoad = useRef<boolean>(true);

  /**
   * When the app loads for the first time set the customer id
   * present in the URL search params.
   */
  useEffect(() => {
    if (firstTimeLoad.current && customerIdParam !== VOID_ID) {
      setSelectedCustomerId(customerIdParam);
      firstTimeLoad.current = false;
    }
  }, [setSelectedCustomerId, customerIdParam]);

  /**
   * When a customer is selected set the customer id in the URL search params.
   */
  useEffect(() => {
    setCustomerIdParam(selectedCustomerId);
  }, [selectedCustomerId, customerIdParam, setCustomerIdParam]);
};
