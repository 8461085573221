import {
  Budget,
  BudgetAllocationType,
  Editable,
  FormValues,
} from '../../../domain/types';
import { mixed, number, object, SchemaOf, string } from 'yup';

export type BudgetValues = Editable<FormValues<Budget>>;

export const BudgetSchema: SchemaOf<BudgetValues> = object({
  customerId: number().required(),
  startDate: string().required(),
  endDate: string().required(),
  amount: number().required(),
  allocationType: mixed<BudgetAllocationType>()
    .oneOf(Object.values(BudgetAllocationType))
    .required(),
});
