import {
  BudgetRemainingItemData,
  AggregatedBurndownItem,
  PurchaseOrderRemainingData,
} from '../domain/types';

/**
 * aggregates burndown items by reducing over the budget_remaining
 * items (name: date -> total: amount)
 * and composing a result array made up of the consumption/forecast
 * array values for each date, such that the result array has consumption and
 * forecast values for each item
 */
export const formatBurndownData = (
  data: BudgetRemainingItemData | PurchaseOrderRemainingData,
  isPurcaseOrder?: boolean
): AggregatedBurndownItem[] => {
  // the budget_remaining property has the entire series
  const remainingData = isPurcaseOrder
    ? (data as PurchaseOrderRemainingData).purchaseOrderRemaining
    : (data as BudgetRemainingItemData).budget_remaining;

  return remainingData.map(({ name, total }) => ({
    name,
    remaining: total,
    consumption: data.consumption.find(
      (consumption) => consumption.name === name
    )?.total,
    forecast: data.forecast.find((forecast) => forecast.name === name)?.total,
  }));
};
