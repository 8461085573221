import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { apiPaths } from '../../utils/apiPaths';
import { systemSyncData } from '../data/adminData';
import { SystemLock } from '../../domain/types';

class SystemSyncHandler implements ServerHandler {
  private items: SystemLock[];

  constructor() {
    this.items = systemSyncData;
  }

  public resetData() {
    this.items = systemSyncData;
  }

  public handlers() {
    return [
      rest.get(`/api${apiPaths.systemSyncs().base}`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            page: 1,
            page_size: 15,
            total_items: 1,
            total_pages: 1,
            items: this.items,
          })
        );
      }),
    ];
  }
}

export const systemSyncHandler = new SystemSyncHandler();
