import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, useTheme } from '@mui/material';
import { Bar, Label, Line, ReferenceLine } from 'recharts';
import {
  ReportFiltersProvider,
  useFilters,
} from '../../../contexts/ReportFiltersProvider';
import { useStateContext } from '../../../contexts/StateProvider';
import {
  BudgetAllocationType,
  BurndownFilters,
  Role,
} from '../../../domain/types';
import { BudgetBurndownFilterBar } from './BudgetBurndownFilterBar';
import { BudgetBurndownChart } from '../../Charts/BudgetBurndownChart';
import { useBudgetBurndownData } from '../../Charts/useBudgetBurndownData';
import { ChartTitle } from '../ChartTitle';
import { GenericReport } from '../GenericReport';
import { VOID_ID, BUDGET_BURDOWN_REPORT_KEY } from '../../../domain/constants';
import { SummaryTile } from '../Tiles/SummaryTile';
import { fromLocalStorage } from '../../../utils/localStorage';
import { useActiveBudget } from './useActiveBudget';
import { ReportExportManager } from '../../Charts/ReportExportManager';
import { apiPaths } from '../../../utils/apiPaths';
import { PageHeader } from '../../PageHeader';
import { ChartWidgetContainer } from '../../ChartWidgetContainer';
import { ChartHeader } from '../../Charts/ChartHeader';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useProjectGroups } from '../../../hooks/useProjectGroups';
import { useAuthContext } from '../../../contexts/AuthProvider';
import { useIsEmptySelection } from './useIsEmptySelection';

export const BudgetBurndownReport = () => {
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  const initialFilters: BurndownFilters = {
    timeInterval: 'month',
  };

  const defaultFilters: BurndownFilters =
    fromLocalStorage(BUDGET_BURDOWN_REPORT_KEY, selectedCustomerId) ||
    initialFilters;

  if (!isCustomerSelected) {
    return <Alert severity="info">Select a customer to view the report.</Alert>;
  }

  return (
    <ReportFiltersProvider<BurndownFilters>
      defaultFilters={defaultFilters}
      initialFilters={initialFilters}
      reportKey={BUDGET_BURDOWN_REPORT_KEY}
    >
      <BudgetBurndownReportContainer />
    </ReportFiltersProvider>
  );
};

const BudgetBurndownReportContainer = () => {
  const { selectedCustomerId: customerId } = useStateContext();
  const { activeBudget } = useActiveBudget(customerId);
  const { userRole } = useAuthContext();

  const [
    budgetAllocationType,
    setBudgetAllocationType,
  ] = useState<BudgetAllocationType | null>(null);
  const theme = useTheme();

  const {
    filters,
    appliedFilters,
    applyFilters,
    setFilterValue,
    resetFilters,
  } = useFilters<BurndownFilters>();

  const { state, actions } = useProjectGroups({
    includeProjects: true,
    projectsDefaultFilters: filters.projectFilters,
    isOnBudgetContext: true,
  });

  const isEmptySelection = useIsEmptySelection(appliedFilters);

  useEffect(() => {
    if (!state.loaded || userRole !== Role.CUSTOMER_VIEWER) {
      return;
    }

    const groupsKeys = Object.keys(state.projectGroups);
    if (groupsKeys.length === 0) {
      return;
    }

    const groupKey: number = +groupsKeys[0];
    const projectsKeys = Object.keys(state.projectGroups[groupKey].projects);
    const projectKey: number = +projectsKeys[0];

    if (isEmptySelection) {
      if (budgetAllocationType === 'GROUP') {
        actions.selectGroup(groupKey);
      } else {
        actions.selectProject(groupKey, projectKey);
      }
      applyFilters();
    }
  }, [
    appliedFilters,
    state,
    isEmptySelection,
    budgetAllocationType,
    actions,
    applyFilters,
    userRole,
  ]);

  const {
    data,
    rawDataRequestFilters,
    isLoading,
    isError,
  } = useBudgetBurndownData({
    customerId,
    filters: appliedFilters,
    isEnabled: state.loaded,
  });

  useEffect(() => {
    if (activeBudget) {
      setBudgetAllocationType(activeBudget.allocationType);
    }
  }, [activeBudget]);

  const renderConsumption = useCallback(() => {
    return appliedFilters.timeInterval === 'month' ? (
      <Bar
        dataKey="consumption"
        fill={theme.palette.primary.main}
        stackId={1}
      />
    ) : null;
  }, [appliedFilters.timeInterval, theme.palette.primary.main]);

  const renderForecast = useCallback(() => {
    return appliedFilters.timeInterval === 'month' ? (
      <Bar dataKey="forecast" fill={theme.palette.warning.main} stackId={1} />
    ) : null;
  }, [appliedFilters.timeInterval, theme.palette.warning.main]);

  const renderBurndown = useCallback(() => {
    return (
      <Line
        type="monotone"
        dataKey="remaining"
        strokeWidth={3}
        stroke={theme.palette.error.light}
      />
    );
  }, [theme.palette.error.light]);

  const chartTitle = useMemo(
    () =>
      `Budget Burndown by ${
        appliedFilters.timeInterval === 'month' ? 'Month' : 'Day'
      }`,
    [appliedFilters.timeInterval]
  );

  const noDataMessage = useMemo(() => {
    if (appliedFilters.projectFilters) {
      const { groups, projects } = appliedFilters.projectFilters;

      if (groups.length) {
        return 'There is no allocation for the selected group';
      } else if (projects.length) {
        return 'There is no allocation for the selected project';
      } else {
        return 'There is no allocation';
      }
    } else {
      return 'There is currently no active budget';
    }
  }, [appliedFilters.projectFilters]);

  const exportApiPath = apiPaths.budgetRemainingExport(customerId);

  const showUnallocated = useMemo(() => {
    if (!appliedFilters.projectFilters) {
      return true;
    }

    const { groups, projects } = appliedFilters.projectFilters;
    return groups.length === 0 && projects.length === 0;
  }, [appliedFilters.projectFilters]);

  return (
    <>
      <GenericReport
        renderPageHeader={() => (
          <PageHeader title="Budget Burndown">
            <ReportExportManager
              path={exportApiPath}
              filters={rawDataRequestFilters}
              type={BUDGET_BURDOWN_REPORT_KEY}
            />
          </PageHeader>
        )}
        renderTiles={() => (
          <>
            <SummaryTile
              icon={<MonetizationOnIcon fontSize="large" />}
              isLoading={isLoading}
              title="Total Budget"
              value={data?.budget.amount}
              currency={data?.currency}
              testId="total-budget-tile"
            />

            {showUnallocated && (
              <SummaryTile
                icon={<ShoppingBasketIcon fontSize="large" />}
                isLoading={isLoading}
                title="Unallocated Budget"
                value={data?.budget.unallocated.absolute}
                currency={data?.currency}
                testId="unallocated-budget-tile"
              />
            )}
          </>
        )}
        renderFilters={() => (
          <BudgetBurndownFilterBar
            allocationType={budgetAllocationType}
            filters={filters}
            setFilterValue={setFilterValue}
            resetFilters={resetFilters}
            onSubmit={applyFilters}
            state={state}
            actions={actions}
          />
        )}
        renderReport={() => (
          <ChartWidgetContainer legend>
            <ChartHeader
              renderTitle={() => <ChartTitle heading={chartTitle} />}
            />
            <BudgetBurndownChart
              data={data}
              noDataMessage={noDataMessage}
              renderConsumption={renderConsumption}
              renderForecast={renderForecast}
              renderBurndown={renderBurndown}
              renderRenewalDate={() => (
                <ReferenceLine
                  x={data?.budget.formattedDates.end}
                  stroke={theme.palette.grey[600]}
                  strokeWidth={2}
                >
                  <Label position="insideTopRight" dx={-7}>
                    Renewal Date
                  </Label>
                </ReferenceLine>
              )}
              renderForecastEndDate={() => (
                <ReferenceLine
                  x={data?.budget.formattedDates.runOut}
                  stroke={theme.palette.error.light}
                  strokeWidth={6}
                  style={{ opacity: 0.8 }}
                  strokeDasharray="16 16"
                >
                  <Label
                    position="insideTopRight"
                    fill={theme.palette.error.light}
                    angle={-90}
                    dx={-20}
                    dy={25}
                  >
                    Forecast End Date
                  </Label>
                </ReferenceLine>
              )}
              isLoading={isLoading}
              isError={isError}
            />
          </ChartWidgetContainer>
        )}
      />
    </>
  );
};
