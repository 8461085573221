import { number, object, SchemaOf, string } from 'yup';

export type PurchaseOrderFormValues = {
  date: string;
  renewalDate: string;
  amount: number;
  currency: string;
  budgetId?: number | null;
  poNumber: string;
};

export const PurchaseOrderSchema: SchemaOf<PurchaseOrderFormValues> = object({
  date: string()
    .required()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      'This field must be in the format YYYY-MM-DD'
    ),
  renewalDate: string()
    .required()
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      'This field must be in the format YYYY-MM-DD'
    ),
  amount: number().required().moreThan(0),
  currency: string().required().length(3),
  budgetId: number().nullable(),
  poNumber: string().required().max(50),
}).defined();
