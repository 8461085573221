import { Box, styled } from '@mui/system';
import { SkuItem } from '../../../domain/types';
import Modal from '../../Modals/Modal';
import {
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { adminPaths } from '../../Routes/AdminRoutes';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSkuAllocation } from './hooks/useSkuAllocation';
import { VOID_ID } from '../../../domain/constants';
import { useSkuGroups } from './hooks/useSkuGroups';
import { QueryLoader } from '../../Messaging/QueryStatus';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  skuItem: SkuItem | null;
}

const StyledListItemIcon = styled(LinkIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
  textAlign: 'start',
  ml: 4,
  display: 'inline-block',
}));

const StyledListItem = styled(ListItem)(() => ({
  paddingLeft: 0,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(1),
  height: 24,
}));

const LoadingBox = ({ height }: { height: number }) => {
  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <p>Loading...</p>
      <QueryLoader />
    </Box>
  );
};

const SkuGroups = ({ skuId }: { skuId: number }) => {
  const { deallocate, isLoading: isLoadingDeallocate } = useSkuAllocation();
  const { items: skuGroups, isLoading } = useSkuGroups(skuId);
  const loading = isLoading || isLoadingDeallocate;

  return (
    <>
      <Divider sx={{ mt: 2 }} />

      <Box mt={2}>
        <Typography variant="body1" fontWeight="medium">
          Groups
        </Typography>
        {loading && <LinearProgress />}

        <Box
          maxHeight={300}
          sx={{
            overflowY: 'auto',
          }}
        >
          {isLoading ? (
            <LoadingBox height={100} />
          ) : (
            <List dense>
              {skuGroups.map((item) => (
                <StyledListItem
                  key={item.id}
                  divider
                  secondaryAction={
                    <IconButton
                      disabled={loading}
                      onClick={() => {
                        deallocate({ skuIds: [skuId], skuGroupId: item.id });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <StyledLink
                    target="_blank"
                    to={adminPaths.skuGroupSku(item.id)}
                  >
                    <StyledListItemIcon />
                  </StyledLink>

                  <ListItemText>{item.groupName}</ListItemText>
                </StyledListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </>
  );
};

const ModalContent = ({ skuItem }: { skuItem: SkuItem | null }) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box flex="1">
          <Typography variant="body1" fontWeight="medium">
            GCP SKU ID
          </Typography>{' '}
          {skuItem?.gcpSkuId}
        </Box>
        <Box flex="1">
          <Typography variant="body1" fontWeight="medium">
            SKU Name
          </Typography>{' '}
          {skuItem?.skuName}
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box flex={1}>
          <Typography variant="body1" fontWeight="medium">
            GCP Service ID
          </Typography>{' '}
          {skuItem?.gcpServiceId}
        </Box>
        <Box flex={1}>
          <Typography variant="body1" fontWeight="medium">
            Service Name
          </Typography>{' '}
          {skuItem?.serviceName}
        </Box>
      </Box>
      {skuItem?.groupsCount ? (
        <SkuGroups skuId={skuItem?.id ?? VOID_ID} />
      ) : null}
    </Box>
  );
};

export const SkuDetailsModal = ({ open, onClose, skuItem }: ComponentProps) => {
  return (
    <Modal
      id="sku-details-modal"
      title="SKU Details"
      content={<ModalContent skuItem={skuItem} />}
      open={open}
      onClose={onClose}
    />
  );
};
