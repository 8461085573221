import { useQuery } from 'react-query';
import { Commit, PagedItems } from '../domain/types';
import { apiPaths } from '../utils/apiPaths';
import { GET_PAGED } from '../utils/api';
import { VOID_ID } from '../domain/constants';
import { sortCommitsByDateAsc } from '../utils/dateUtils';

export const useGetCommits = (selectedCustomerId: number) => {
  const { data: commits, isLoading, isError } = useQuery<PagedItems<Commit>>(
    apiPaths.commits(selectedCustomerId).key.list(selectedCustomerId),
    () => GET_PAGED(apiPaths.commits(selectedCustomerId).base),
    {
      enabled: selectedCustomerId !== VOID_ID,
    }
  );

  const sortedData = sortCommitsByDateAsc(commits?.items ?? []);

  return {
    data: sortedData,
    isLoading: isLoading,
    isError: isError,
  };
};
