import * as React from 'react';
import SnackBar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { forwardRef } from 'react';

export type SnackBarNotificationProps = {
  open?: boolean;
  message: string;
  onClose: () => void;
} & Pick<AlertProps, 'severity'>;

const Alert = forwardRef(
  (props: AlertProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <MuiAlert ref={ref} variant="filled" {...props} />
  )
);

const SnackbarNotification = ({
  open,
  message,
  severity,
  onClose,
}: SnackBarNotificationProps) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <SnackBar
      data-testid="application-notification"
      open={open}
      autoHideDuration={2500}
      onClose={onClose}
      action={action}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </SnackBar>
  );
};

export default SnackbarNotification;
