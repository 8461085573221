import { Alert, Box, Button, Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import firebase from 'firebase/app';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import { dashboardPaths } from './useDashboardRoutes';
import { isE2E_Enabled } from '../../setupE2EAutomation';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const LoginRoute = () => {
  const history = useHistory();
  const {
    setUser,
    setLoadingAuthState,
    authorizationError,
    authenticated,
  } = useContext(AuthContext);
  const location = useLocation<{ prevPath: string }>();
  const [username, setUsername] = useState<string>('');
  const ise2e = isE2E_Enabled();

  const logIn = () => {
    setLoadingAuthState(true);

    firebase
      .auth()
      .signInWithPopup(ise2e ? (username as any) : googleAuthProvider)
      .then((res) => {
        if (res && res.user) {
          setUser(res.user);
          history.push(location.state?.prevPath ?? dashboardPaths.basePath);
        }
      });
  };

  const useStyles = makeStyles({
    root: {
      maxWidth: 275,
      margin: '2em auto',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  const classes = useStyles();

  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: dashboardPaths.basePath,
        }}
      />
    );
  }

  return (
    <>
      <Card variant="outlined" className={classes.root}>
        <CardContent className={classes.content}>
          <img src="/logo.png" width={204} height={77} alt="appsflow logo" />

          {authorizationError && (
            <Box mt={2} mb={3}>
              <Alert severity="error">
                You are not authorized to use this application
              </Alert>
            </Box>
          )}
          {ise2e ? (
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              name="user"
              id="user"
            />
          ) : (
            ''
          )}
          <Button
            id="login-button"
            color="inherit"
            onClick={logIn}
            endIcon={<OpenInNewIcon />}
          >
            Login
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
