import { useMemo } from 'react';
import {
  RecommendationCategory,
  RecommendationEntity,
  RecommendationPriority,
  RecommendationState,
} from '../../../../domain/types';

const priorities: RecommendationPriority[] = [
  'P1',
  'P2',
  'P3',
  'P4',
  'PRIORITY_UNSPECIFIED',
];

const states: RecommendationState[] = [
  'ACTIVE',
  'CLAIMED',
  'SUCCEEDED',
  'FAILED',
  'DISMISSED',
  'STATE_UNSPECIFIED',
  'DELETED',
];

const categories: RecommendationCategory[] = [
  'COST',
  'SECURITY',
  'PERFORMANCE',
  'MANAGEABILITY',
  'SUSTAINABILITY',
  'RELIABILITY',
  'CATEGORY_UNSPECIFIED',
];

const recommenderEntities: RecommendationEntity[] = [
  'PROJECT_NUMBER',
  'BILLING_ACCOUNT_ID',
  'FOLDER_ID',
  'ORGANIZATION_ID',
];

export interface RecommenderFilterOption<T> {
  label: string;
  value: T;
}

const convertToDropdownOptions = <T>(
  options: T[]
): RecommenderFilterOption<T>[] => {
  return options.map((option: T) => ({
    value: option,
    label: `${option}`,
  }));
};

export const useRecommenderFilterOptions = () => {
  return useMemo(
    () => ({
      prioritiesOptions: convertToDropdownOptions<RecommendationPriority>(
        priorities
      ),
      statesOptions: convertToDropdownOptions<RecommendationState>(states),
      categoriesOptions: convertToDropdownOptions<RecommendationCategory>(
        categories
      ),
      recommenderEntities: convertToDropdownOptions<RecommendationEntity>(
        recommenderEntities
      ),
    }),
    []
  );
};
