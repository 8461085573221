import { Bars } from 'react-loader-spinner';
import { appTheme } from '../theme/appTheme';
import { styled } from '@mui/system';

const LoaderContainer = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export function ApplicationLoader() {
  return (
    <LoaderContainer>
      <Bars
        width={80}
        height={80}
        visible
        color={appTheme.palette.primary.main}
      />
    </LoaderContainer>
  );
}
