interface Paths {
  [key: string]: string | ((...args: any[]) => string);
}
type PathsWithBase<T> = T & { basePath: string };

export const createRoutePaths = <T extends Paths>(
  routes: T,
  basePath: string
): PathsWithBase<T> => {
  return Object.entries(routes).reduce(
    (routesWithBasePath, [name, path]) => {
      return {
        ...routesWithBasePath,
        [name]:
          typeof path === 'string'
            ? `${basePath}${path}`
            : function (...args: any[]) {
                return `${basePath}${path.apply(routes, args)}`;
              },
      };
    },
    {
      basePath,
    } as PathsWithBase<T>
  );
};
