import { SetReportFilterValue } from '../../../../contexts/ReportFiltersProvider';
import { ConsumptionFilters, SkuGroup } from '../../../../domain/types';
import { useSkuGroups } from '../../../Admin/Customer/hooks/useSkuGroups';
import { FilterBarItem } from '../../FilterBarItem';
import { DropdownFilter } from './DropdownFilter/DropdownFilter';
import MemoryIcon from '@mui/icons-material/Memory';

interface ComponentProps {
  selectedSkuGroups: number[];
  setFilterValue: SetReportFilterValue<ConsumptionFilters>;
}

export const SkuGroupsFilter = ({
  selectedSkuGroups,
  setFilterValue,
}: ComponentProps) => {
  const { items: skuGroups, isLoading } = useSkuGroups();

  return (
    <FilterBarItem>
      <DropdownFilter<SkuGroup, number>
        title="SKUs Groups"
        testId="skus-groups-filter"
        value={selectedSkuGroups ?? []}
        onChange={(skusGroups: number[]) =>
          setFilterValue('skusGroups', skusGroups)
        }
        items={skuGroups}
        isLoading={isLoading}
        getItemKey={(item: SkuGroup) => item.id}
        getItemLabel={(item: SkuGroup) => {
          return item.groupName;
        }}
        itemLabel="SKUs Groups"
        icon={<MemoryIcon />}
      />
    </FilterBarItem>
  );
};
