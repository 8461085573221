import { FormProvider, useForm } from 'react-hook-form';
import useYupValidationResolver from '../../../hooks/useYupValidationResolver';
import { ReactNode } from 'react';
import { CrudValidationSchema } from '../CrudContainer';

export interface CrudFormProviderProps {
  validationSchema: CrudValidationSchema;
  children: ReactNode;
}

export function CrudFormProvider<T>({
  validationSchema,
  children,
}: CrudFormProviderProps) {
  const form = useForm<T>({
    resolver: useYupValidationResolver(validationSchema),
    reValidateMode: 'onBlur',
  });
  return <FormProvider {...form}>{children}</FormProvider>;
}
