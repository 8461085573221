import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { ServiceCharge } from '../../../domain/types';
import { DatePickerField } from '../../Fields/DatePickerField';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { ServiceChargeFormValues } from './ServiceChargeSchema';
import { useApportionedServices } from './useApportionedServices';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import { startOfMonth } from 'date-fns';
import { useGetCustomerCurrency } from '../BudgetAllocations/useGetCustomerCurrency';
import { useStateContext } from '../../../contexts/StateProvider';
import { API_DATE_FORMAT } from '../../../domain/constants';
import { formatDate } from '../../../utils/dateUtils';

export const ServiceChargeForm: CrudFormComponent<ServiceCharge> = ({
  item,
}) => {
  const {
    register,
    errors,
    control,
    watch,
  } = useFormContext<ServiceChargeFormValues>();

  const { selectedCustomerId } = useStateContext();
  const serviceId = watch('serviceId', item?.serviceId);
  const {
    serviceIds,
    serviceIdsToName,
    selectedServiceId,
  } = useApportionedServices(selectedCustomerId, serviceId);

  const { currency } = useGetCustomerCurrency(selectedCustomerId);
  const hiddenCurrency = watch('currency', currency ?? 'USD');

  const invoiceDate = watch(
    'invoiceDate',
    item?.invoiceDate ?? formatDate(startOfMonth(new Date()), API_DATE_FORMAT)
  );

  return (
    <>
      <CrudFormControl error={errors.serviceId}>
        <Controller
          control={control}
          name="serviceId"
          defaultValue={serviceId ?? ''}
          render={({ onChange }) => (
            <>
              <InputLabel id="service-label">Service</InputLabel>
              <Select
                onChange={onChange}
                value={selectedServiceId ?? ''}
                id="serviceId"
                labelId="service-label"
              >
                {serviceIds?.map((id) => (
                  <MenuItem key={id} value={id}>
                    {serviceIdsToName.get(id)}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </CrudFormControl>

      <CrudFormControl error={errors.invoiceNumber}>
        <TextField
          id="invoiceNumber"
          name="invoiceNumber"
          inputRef={register}
          label="Invoice Number"
          defaultValue={item?.invoiceNumber}
          error={!!errors.invoiceNumber}
        ></TextField>
      </CrudFormControl>

      <CrudFormControl error={errors.amount}>
        <Controller
          name="amount"
          control={control}
          defaultValue={item?.amount ?? ''}
          render={(props) => (
            <MonetaryValueField
              error={!!errors.amount}
              label="Amount"
              id="amount"
              InputProps={props}
              currency={currency}
            />
          )}
        />
      </CrudFormControl>

      <input
        name="currency"
        type="hidden"
        value={hiddenCurrency}
        ref={register}
      />

      <CrudFormControl error={errors.toUsd}>
        <Controller
          name="toUsd"
          control={control}
          defaultValue={item?.toUsd ?? ''}
          render={(props) => (
            <TextField
              type="number"
              error={!!errors.amount}
              label="To USD Rate"
              id="toUsd"
              InputProps={{ ...props }}
            />
          )}
        />
      </CrudFormControl>

      <CrudFormControl error={errors.invoiceDate}>
        <Controller
          control={control}
          name="invoiceDate"
          defaultValue={invoiceDate}
          render={({ onChange, value }) => (
            <DatePickerField
              id="invoiceDate"
              value={value ? new Date(value) : null}
              views={['year', 'month']}
              onChange={onChange}
              label="Invoice Date"
            />
          )}
        />
      </CrudFormControl>

      <CrudFormControl error={errors.settleDate}>
        <Controller
          control={control}
          name="settleDate"
          defaultValue={item?.settleDate ?? null}
          render={({ onChange, value }) => (
            <DatePickerField
              id="settleDate"
              value={value ? new Date(value) : null}
              minDate={invoiceDate}
              onChange={onChange}
              label="Settle Date"
            />
          )}
        />
      </CrudFormControl>
    </>
  );
};
