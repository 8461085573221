import { useQuery } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET_PAGED } from '../../../../utils/api';
import { CustomerDiscountSummary, PagedItems } from '../../../../domain/types';
import { useMemo } from 'react';

export const useCustomersDiscountsSummary = () => {
  const apiPath = apiPaths.customersDiscountsSummary();

  const { data } = useQuery<PagedItems<CustomerDiscountSummary>>(
    apiPath.key.list(),
    () => GET_PAGED(apiPath.base)
  );

  return useMemo(() => {
    const customerMap = new Map<number, CustomerDiscountSummary>();
    data?.items.forEach((item) => {
      customerMap.set(item.customerId, item);
    });

    return customerMap;
  }, [data?.items]);
};
