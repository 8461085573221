import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const NotFoundRoute = () => {
  const { pathname, search } = useLocation();

  const request = useMemo(() => {
    return [pathname, ...(search ? [`?${search}`] : [])].join('');
  }, [pathname, search]);

  return (
    <Box padding={6}>
      <Typography variant="h6" gutterBottom={true}>
        404 Error
      </Typography>
      <Typography variant="body2">
        The requested URL {request} was not found
      </Typography>
    </Box>
  );
};
