import { Redirect, Route } from 'react-router-dom';
import { createRoutePaths } from '../../utils/createRoutePaths';
import { SwitchRoute } from './SwitchRoute';
import { Box } from '@mui/material';
import { useStateContext } from '../../contexts/StateProvider';
import { VOID_ID } from '../../domain/constants';
import { UserAdmin } from '../Admin/User/UserAdmin';
import { BillingAccountAdmin } from '../Admin/BillingAccount/BillingAccountAdmin';
import { CustomerAdmin } from '../Admin/Customer/CustomerAdmin';
import { CommitAdmin } from '../Admin/Commits/CommitAdmin';
import { Role, RouteDetail } from '../../domain/types';
import { MenuRoutes } from '../MenuRoutes';
import { ProjectGroupAdmin } from '../Admin/ProjectGroup/ProjectGroupAdmin';
import { ProjectGroupProjectAdmin } from '../Admin/ProjectGroupProject/ProjectGroupProjectAdmin';
import { UngroupedProjectsAdmin } from '../Admin/UngroupedProjects/UngroupedProjectsAdmin';
import { ProjectAdmin } from '../Admin/Project/ProjectAdmin';
import { BudgetAdmin } from '../Admin/Budget/BudgetAdmin';
import { BudgetAllocationAdmin } from '../Admin/BudgetAllocations/BudgetAllocationAdmin';
import { PurchaseOrderAdmin } from '../Admin/PurchaseOrder/PurchaseOrderAdmin';
import { UnmappedBillingAccountsAdmin } from '../Admin/UnmappedBillingAccounts/UnmappedBillingAccountsAdmin';
import { ServiceAdmin } from '../Admin/Services/ServiceAdmin';
import { ServiceApportionmentsAdmin } from '../Admin/ServiceApportionments/ServiceApportionmentsAdmin';
import { ServiceChargeAdmin } from '../Admin/ServiceCharges/ServiceChargeAdmin';
import { BillingServiceChargesAdmin } from '../Admin/BillingServices/BillingServiceChargesAdmin';
import { SystemConfiguration } from '../Admin/SystemConfiguration/SystemConfiguration';
import { SystemManagement } from '../Admin/SystemManagement/SystemManagement';
import { RecommenderOnboarding } from '../Dashboard/Recommender/RecommenderOnboarding';
import { useAuthContext } from '../../contexts/AuthProvider';
import { useMemo } from 'react';
import { DisclaimerManager } from '../Admin/DisclaimerManager/DisclaimerManager';
import { SkuGroups } from '../Admin/SkuGroups/SkuGroups';
import { SkuGroupSku } from '../Admin/SkuGroupSku/SkuGroupSku';
import { SkusAdmin } from '../Admin/Skus/SkusAdmin';
import { BigQueryAnalyticsOnboarding } from '../Admin/BigQueryAnalyticsOnboarding/BigQueryAnalyticsOnboarding';

export const adminPaths = createRoutePaths(
  {
    customers: '/customers',
    users: '/users',
    projects: '/projects',
    billingAccounts: '/billing-accounts',
    commits: '/commits',
    budgets: '/budgets',
    budgetAllocations: (budgetId?: number | string) => {
      const base = '/budget-allocations';
      if (budgetId === undefined) {
        return base;
      } else {
        return `${base}/${budgetId}`;
      }
    },
    projectGroups: '/project-groups',
    projectGroupProjects: (projectGroupId?: number | string) => {
      const base = '/project-group-projects';
      if (projectGroupId === undefined) {
        return base;
      } else {
        return `${base}/${projectGroupId}`;
      }
    },
    purchaseOrders: '/purchase-orders',
    unmappedBillingAccounts: '/unmapped-billing-accounts',
    billingServiceCharges: '/billing-service-charges',
    services: '/services',
    serviceApportionments: '/service-apportionments',
    serviceCharges: '/service-charges',
    systemConfiguration: '/system-configuration',
    ungroupedProjects: '/ungrouped-projects',
    systemManagement: '/system-monitoring',
    recommenderOnboarding: '/recommender-onboarding',
    bigQueryAnalyticsOnboarding: '/bq-metrics-onboarding',
    disclaimerManager: '/disclaimer-manager',
    skuGroups: '/sku-groups',
    skuGroupSku: (skuGroupId?: number | string) => {
      const base = '/sku-group-sku';
      if (skuGroupId === undefined) {
        return base;
      }

      return `${base}/${skuGroupId}`;
    },
    skusAdmin: '/skus-admin',
  },
  '/app/admin'
);

// Admin pages not related to any specific customer
export const nonCustomerRoutes: RouteDetail[] = [
  {
    label: 'Users',
    path: adminPaths.users,
    Component: UserAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'Customers',
    path: adminPaths.customers,
    Component: CustomerAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Unmapped Billing Accounts',
    path: adminPaths.unmappedBillingAccounts,
    Component: UnmappedBillingAccountsAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Services',
    path: adminPaths.services,
    Component: ServiceAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'SKU Groups',
    path: adminPaths.skuGroups,
    Component: SkuGroups,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Grouped SKUs',
    path: adminPaths.skuGroupSku(':groupId?'),
    link: adminPaths.skuGroupSku(),
    Component: SkuGroupSku,
    roles: [Role.SYSTEM_ADMIN],
    indent: true,
  },
  {
    label: 'All SKUs',
    path: adminPaths.skusAdmin,
    Component: SkusAdmin,
    roles: [Role.SYSTEM_ADMIN],
    indent: true,
  },
  {
    label: 'System Configuration',
    path: adminPaths.systemConfiguration,
    Component: SystemConfiguration,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'System Monitoring',
    path: adminPaths.systemManagement,
    Component: SystemManagement,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Disclaimer Manager',
    path: adminPaths.disclaimerManager,
    Component: DisclaimerManager,
    roles: [Role.SYSTEM_ADMIN],
  },
];

// Admin pages relating to a specific customer
export const customerSpecificRoutes: RouteDetail[] = [
  {
    label: 'Recommender Onboarding',
    path: adminPaths.recommenderOnboarding,
    Component: RecommenderOnboarding,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'BQ Analytics Onboarding',
    path: adminPaths.bigQueryAnalyticsOnboarding,
    Component: BigQueryAnalyticsOnboarding,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'Service Apportionments',
    path: adminPaths.serviceApportionments,
    Component: ServiceApportionmentsAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Third Party Charges',
    path: adminPaths.serviceCharges,
    Component: ServiceChargeAdmin,
    roles: [Role.SYSTEM_ADMIN],
    indent: true,
  },
  {
    label: 'Billing Account Charges',
    path: adminPaths.billingServiceCharges,
    Component: BillingServiceChargesAdmin,
    roles: [Role.SYSTEM_ADMIN],
    indent: true,
  },
  {
    label: 'Projects',
    path: adminPaths.projects,
    Component: ProjectAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'Billing Accounts',
    path: adminPaths.billingAccounts,
    Component: BillingAccountAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Project Groups',
    path: adminPaths.projectGroups,
    Component: ProjectGroupAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'Grouped Projects',
    path: adminPaths.projectGroupProjects(':groupId?'),
    link: adminPaths.projectGroupProjects(),
    Component: ProjectGroupProjectAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
    indent: true,
  },
  {
    label: 'Ungrouped Projects',
    path: adminPaths.ungroupedProjects,
    Component: UngroupedProjectsAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
    indent: true,
  },
  {
    label: 'Budgets',
    path: adminPaths.budgets,
    Component: BudgetAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
  {
    label: 'Allocations',
    path: adminPaths.budgetAllocations(':budgetId?'),
    link: adminPaths.budgetAllocations(),
    Component: BudgetAllocationAdmin,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
    indent: true,
  },
  {
    label: 'Commits',
    path: adminPaths.commits,
    Component: CommitAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
  {
    label: 'Purchase Orders',
    path: adminPaths.purchaseOrders,
    Component: PurchaseOrderAdmin,
    roles: [Role.SYSTEM_ADMIN],
  },
];

export const useAdminRoutes = () => {
  const { userRole } = useAuthContext();
  const adminRoutes = useMemo(() => {
    return userRole === Role.CUSTOMER_ADMIN
      ? [
          {
            label: 'Users',
            path: adminPaths.users,
            Component: UserAdmin,
            roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
          },
          ...customerSpecificRoutes,
        ]
      : customerSpecificRoutes;
  }, [userRole]);

  return adminRoutes;
};

export const AdminRoutes = () => {
  const { selectedCustomerId } = useStateContext();
  const { userRole } = useAuthContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;
  const adminRoutes = useAdminRoutes();

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <SwitchRoute>
        <Route path={adminPaths.basePath} exact>
          <Redirect to={adminPaths.users} />
        </Route>
        <>
          {userRole === Role.SYSTEM_ADMIN && (
            <MenuRoutes routes={nonCustomerRoutes} />
          )}
          {isCustomerSelected && <MenuRoutes routes={adminRoutes} />}
        </>
      </SwitchRoute>
    </Box>
  );
};
