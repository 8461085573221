import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import {
  Apportionment,
  ApportionmentAllocationType,
  ApportionmentSplitType,
  Project,
  ProjectGroup,
  Service,
  ServiceApportionment,
} from '../../../domain/types';
import { useStateContext } from '../../../contexts/StateProvider';
import { ServiceApportionmentFormValues } from './ServiceApportionmentSchema';
import { useListUnapportionedServices } from './useListUnapportionedServices';
import {
  Autocomplete,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useListProjectGroups } from './useListProjectGroups';
import { useListProjects } from './useListProjects';
import { FieldError } from 'react-hook-form/dist/types';
import { useAllServices } from '../Services/useAllServices';
import { percentageFormat } from '../../PercentageDisplay';

export const ServiceApportionmentForm: CrudFormComponent<ServiceApportionment> = ({
  item,
}) => {
  const {
    register,
    control,
    errors,
    watch,
  } = useFormContext<ServiceApportionmentFormValues>();
  const { selectedCustomerId } = useStateContext();
  const serviceId = watch('serviceId', item?.serviceId);
  const { services, serviceIdsToName } = useAllServices(
    selectedCustomerId,
    serviceId
  );
  const {
    unapportionedServices,
    isLoading: unapportionedServicesLoading,
  } = useListUnapportionedServices(selectedCustomerId);

  const { projects, projectIdsToName } = useListProjects(selectedCustomerId);
  const { projectGroups, projectGroupIdsToName } = useListProjectGroups(
    selectedCustomerId,
    true
  );

  const defaultId = item?.serviceId;

  const allocationType = watch('allocationType', item?.allocationType);
  const splitType = watch('splitType', item?.splitType);
  const selectableApportionItems = useMemo<Apportionment[]>(() => {
    if (
      allocationType === ApportionmentAllocationType.ALL_PROJECTS ||
      allocationType === ApportionmentAllocationType.BILLING_ACCOUNT
    ) {
      return [];
    }
    return allocationType === ApportionmentAllocationType.PROJECT
      ? projects.map((project: Project) => ({
          projectId: project.id,
        }))
      : projectGroups.map((projectGroup: ProjectGroup) => ({
          projectGroupId: projectGroup.id,
        }));
  }, [allocationType, projects, projectGroups]);

  const getApportionmentItemName = useCallback<
    (appportionmentItem: Apportionment) => string
  >(
    (appportionmentItem: Apportionment): string =>
      allocationType === ApportionmentAllocationType.PROJECT
        ? `${projectIdsToName.get(Number(appportionmentItem.projectId))}`
        : `${projectGroupIdsToName.get(
            Number(appportionmentItem.projectGroupId)
          )}`,
    [allocationType, projectIdsToName, projectGroupIdsToName]
  );

  const displayService = (serviceId: number) => {
    const name = serviceIdsToName.get(serviceId);
    const currentService = services.filter(
      (service) => service.id === serviceId
    )[0];
    const gcpServiceId = currentService?.gcp_service_id
      ? `, ${currentService.gcp_service_id}`
      : '';
    const type = currentService?.type ?? '';

    return type ? `${name} (${type}${gcpServiceId})` : name;
  };

  const [previousAllocationType, setPreviousAllocationType] = useState<
    ApportionmentAllocationType | undefined
  >(item?.allocationType);

  const [selectedApportionments, setSelectedApportionments] = useState<
    Apportionment[]
  >(item?.apportionments ?? []);

  useEffect(() => {
    // Clear out apportionments if the allocation type changes
    if (allocationType !== previousAllocationType) {
      setPreviousAllocationType(allocationType);
      setSelectedApportionments([]);
    }
  }, [allocationType, previousAllocationType]);

  return (
    <>
      <CrudFormControl error={errors.serviceId}>
        <Controller
          control={control}
          name="serviceId"
          defaultValue={defaultId ?? ''}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="service-label">Service</InputLabel>
              <Select
                onChange={onChange}
                value={value}
                id="serviceId"
                labelId="service-label"
              >
                {defaultId && (
                  <MenuItem key={'default' + defaultId} value={defaultId}>
                    {displayService(defaultId)}
                  </MenuItem>
                )}
                {unapportionedServices?.map((service: Service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {displayService(service.id)}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.allocationType}>
        <Controller
          control={control}
          name="allocationType"
          defaultValue={item?.allocationType ?? ''}
          disabled={!!item?.allocationType}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="allocation-type-label">
                Allocation Type
              </InputLabel>
              <Select
                onChange={(e) => {
                  setSelectedApportionments([]);
                  onChange(e);
                }}
                value={value}
                ref={register}
                id="allocationType"
                labelId="allocation-type-label"
              >
                <MenuItem value={ApportionmentAllocationType.ALL_PROJECTS}>
                  All Projects
                </MenuItem>
                <MenuItem value={ApportionmentAllocationType.BILLING_ACCOUNT}>
                  Billing Account
                </MenuItem>
                <MenuItem value={ApportionmentAllocationType.PROJECT}>
                  Projects
                </MenuItem>
                <MenuItem value={ApportionmentAllocationType.PROJECT_GROUP}>
                  Project Groups
                </MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      {(allocationType === ApportionmentAllocationType.PROJECT ||
        allocationType === ApportionmentAllocationType.PROJECT_GROUP) && (
        <>
          <CrudFormControl error={errors.splitType}>
            <Controller
              control={control}
              name="splitType"
              defaultValue={item?.splitType ?? ''}
              disabled={!!item?.splitType}
              render={({ onChange, value }) => (
                <>
                  <InputLabel id="split-type-label">Split Type</InputLabel>
                  <Select
                    onChange={onChange}
                    value={value}
                    ref={register}
                    id="splitType"
                    labelId="split-type-label"
                  >
                    <MenuItem value={ApportionmentSplitType.EVEN_SPLIT}>
                      Even Split
                    </MenuItem>
                    <MenuItem
                      value={ApportionmentSplitType.PERCENTAGE_OF_SPEND}
                    >
                      Percentage of spend
                    </MenuItem>
                  </Select>
                </>
              )}
            />
          </CrudFormControl>
          <CrudFormControl
            error={(errors.apportionments as unknown) as FieldError}
          >
            <Autocomplete<Apportionment, true>
              options={selectableApportionItems}
              getOptionLabel={getApportionmentItemName}
              isOptionEqualToValue={(
                option: Apportionment,
                value: Apportionment
              ) => {
                return (
                  getApportionmentItemName(option) ===
                  getApportionmentItemName(value)
                );
              }}
              renderOption={(props, item) => (
                <MenuItem
                  key={`${item.projectId}-${item.projectGroupId}`}
                  {...props}
                >
                  {getApportionmentItemName(item)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    allocationType === ApportionmentAllocationType.PROJECT
                      ? 'Projects'
                      : 'Project Groups'
                  }
                />
              )}
              loading={unapportionedServicesLoading}
              onChange={(_, value: Apportionment[]) =>
                setSelectedApportionments(value)
              }
              value={selectedApportionments}
              multiple
            />
          </CrudFormControl>
          {splitType === ApportionmentSplitType.PERCENTAGE_OF_SPEND && (
            <Typography variant="h6">Assign Percentages</Typography>
          )}

          {(selectedApportionments ?? []).map((apportionment, index) => {
            const fieldName = `apportionments[${index}]`;
            const projectOrGroup =
              allocationType === ApportionmentAllocationType.PROJECT
                ? 'projectId'
                : 'projectGroupId';

            return (
              <Fragment
                key={
                  index +
                  `${apportionment + getApportionmentItemName(apportionment)}`
                }
              >
                <input
                  name={`${fieldName}.${projectOrGroup}`}
                  type="hidden"
                  value={apportionment[projectOrGroup]}
                  ref={register}
                />
                {splitType === ApportionmentSplitType.PERCENTAGE_OF_SPEND && (
                  <div>
                    <CrudFormControl
                      error={
                        (errors.apportionments?.[index]
                          ?.percentage as unknown) as FieldError
                      }
                    >
                      <Controller
                        name={`${fieldName}.percentage`}
                        control={control}
                        defaultValue={() =>
                          percentageFormat(
                            Number(apportionment.percentage),
                            false,
                            true
                          )
                        }
                        render={({ onChange, value }) => (
                          <TextField
                            id={`${getApportionmentItemName(
                              apportionment
                            )}percentage`}
                            value={value}
                            onChange={(e) => {
                              const val = parseInt(e.target.value) || 0;
                              onChange(val);
                            }}
                            label={`${getApportionmentItemName(
                              apportionment
                            )} percentage`}
                            InputLabelProps={{
                              htmlFor: `${getApportionmentItemName(
                                apportionment
                              )}percentage`,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </CrudFormControl>
                  </div>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
