import { Autocomplete, TextField } from '@mui/material';
import { adminPaths } from '../../Routes/AdminRoutes';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Route } from 'react-router';
import { CrudContainer } from '../../Crud/CrudContainer';
import {
  BudgetAllocation,
  BudgetAllocationAmountType,
  BudgetAllocationProject,
  BudgetAllocationType,
} from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useHistory } from 'react-router-dom';
import { useStateContext } from '../../../contexts/StateProvider';
import {
  BudgetAllocationSchema,
  BudgetAllocationValues,
} from './BudgetAllocationSchema';
import { BudgetAllocationForm } from './BudgetAllocationForm';
import { GridColDef } from '@mui/x-data-grid';
import { useGetBudget } from './useGetBudget';
import { useCallback, useMemo } from 'react';
import { useGetCustomerCurrency } from './useGetCustomerCurrency';
import { MonetaryDisplay } from '../../MonetaryDisplay';
import { NoItems } from '../../Messaging';
import { percentageFormat } from '../../PercentageDisplay';

export interface BudgetAllocationsContainerProps {
  budgetIds: number[];
  selectedBudgetId?: number | null;
  getBudgetName: (budgetId: number) => string;
}

export const BudgetAllocationsContainer = ({
  budgetIds,
  selectedBudgetId,
  getBudgetName,
}: BudgetAllocationsContainerProps) => {
  const { selectedCustomerId } = useStateContext();
  const { push } = useHistory();
  const { data: budget } = useGetBudget(selectedCustomerId, selectedBudgetId);
  const { currency } = useGetCustomerCurrency(selectedCustomerId);

  const getAbsoluteAllocationValue = useCallback(
    (row: BudgetAllocation): number => {
      if (!budget) {
        return 0;
      }
      return row.amountType === BudgetAllocationAmountType.PERCENTAGE
        ? row.amount * budget?.amount
        : row.amount;
    },
    [budget]
  );

  const columns: GridColDef[] = useMemo(() => {
    let columns: GridColDef[] = [
      {
        headerName: 'Amount',
        field: 'amount',
        flex: 1,
        renderCell: (params) => {
          const absoluteDisplay = (
            <MonetaryDisplay
              value={getAbsoluteAllocationValue(params.row as BudgetAllocation)}
              currency={currency}
            />
          );
          if (params.row.amountType === BudgetAllocationAmountType.ABSOLUTE) {
            return absoluteDisplay;
          } else {
            return (
              <>
                {percentageFormat(params.row.amount)} ({absoluteDisplay})
              </>
            );
          }
        },
        sortComparator: (_v1, _v2, param1, param2) =>
          getAbsoluteAllocationValue(param1.value as BudgetAllocation) -
          getAbsoluteAllocationValue(param2.value as BudgetAllocation),
      },
    ];

    if (budget?.allocationType === BudgetAllocationType.GROUP) {
      columns = [
        {
          headerName: 'Group Name',
          field: 'projectOrGroup',
          flex: 1,
          valueGetter: (params) => {
            const budgetAllocation = params.value as BudgetAllocationProject;
            return budgetAllocation.name;
          },
        },
        ...columns,
      ];
    } else {
      columns = [
        {
          headerName: 'Project ID',
          field: 'projectOrGroup',
          flex: 1,
          valueGetter: (params) => {
            const budgetAllocation = params.value as BudgetAllocationProject;
            return budgetAllocation.gcpProjectId;
          },
        },
        {
          headerName: 'Project Name',
          field: 'gcpProjectName',
          flex: 1,
          renderCell: ({ row }) => {
            return <div>{row?.projectOrGroup?.gcpProjectName}</div>;
          },
        },
        ...columns,
      ];
    }

    return columns;
  }, [budget?.allocationType, getAbsoluteAllocationValue, currency]);

  if (currency) {
    return (
      <>
        <Autocomplete<number>
          options={budgetIds}
          onChange={(_, value) => {
            push(adminPaths.budgetAllocations(value ?? undefined));
          }}
          value={selectedBudgetId}
          getOptionLabel={(id) => getBudgetName(id)}
          renderInput={(params) => (
            <CrudFormControl>
              <TextField {...params} label="Select Budget" />
            </CrudFormControl>
          )}
        />
        <Route path={adminPaths.budgetAllocations(':budgetId')}>
          {selectedBudgetId && (
            <CrudContainer<BudgetAllocation, BudgetAllocationValues>
              name="Budget Allocations"
              apiPath={apiPaths.budgetAllocations(
                selectedCustomerId,
                Number(selectedBudgetId)
              )}
              columns={columns}
              defaultSortColumn="projectOrGroup"
              FormComponent={BudgetAllocationForm}
              validationSchema={BudgetAllocationSchema}
            />
          )}
        </Route>
      </>
    );
  }

  return <NoItems forMessage="billing accounts" severity="warning" />;
};
