import { Budget } from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { useQuery } from 'react-query';
import { GET } from '../../../utils/api';

const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};

export const useGetBudget = (
  customerId: number,
  budgetId: number | null | undefined
) => {
  const { data, isLoading, isError } = useQuery<Budget>(
    apiPaths.budget(customerId).key.item(Number(budgetId)),
    () => GET(apiPaths.budget(customerId).item(Number(budgetId))),
    { enabled: !isNullOrUndefined(budgetId) }
  );
  return { data, isLoading, isError };
};
