import axios from 'axios';
import { PagedRequest } from '../domain/types';

axios.defaults.baseURL = '/api';

export async function GET(
  path: string,
  params?: Record<string, string | number | boolean>
) {
  const urlParams = params
    ? new URLSearchParams(params as Record<string, string>)
    : null;

  return axios
    .get(urlParams ? `${path}?${urlParams}` : path)
    .then((res) => res.data);
}

const DEFAULT_PAGING: PagedRequest = { page: 0, size: 1000000 };
export async function GET_PAGED(
  path: string,
  { page, size }: PagedRequest = DEFAULT_PAGING,
  params?: Record<string, string | number | boolean>
) {
  return GET(path, {
    page: page + 1,
    size,
    ...params,
  });
}

export async function POST<T, Values>(path: string, data: Values): Promise<T> {
  return axios.post(path, data).then((res) => res.data);
}

export async function PUT<T>(path: string, data: T) {
  return axios.put(path, data).then((res) => res.data);
}

export async function PATCH<T>(path: string, data?: T) {
  return axios.patch(path, data).then((res) => res.data);
}

export async function DELETE(path: string) {
  return axios.delete(path);
}
