import { testHandler } from './handlers/testHandlers';
import { customersHandler } from './handlers/customersHandler';
import { staticHandler } from './handlers/staticHandlers';
import { ServerHandler } from './ServerHandler';
import { projectsHandler } from './handlers/projectsHandler';
import { projectGroupsHandler } from './handlers/projectGroupsHandler';
import { projectGroupProjectsHandler } from './handlers/projectGroupProjectsHandler';
import { chartHandler } from './handlers/chartHandlers';
import {
  billingAccountsHandler,
  allBillingAccountsHandler,
} from './handlers/billingAccountsHandler';
import { commitsHandler } from './handlers/commitsHandler';
import { budgetsHandler } from './handlers/budgetsHandler';
import { budgetAllocationsHandler } from './handlers/budgetAllocationsHandler';
import { purchaseOrdersHandler } from './handlers/purchaseOrdersHandler';
import {
  customerAdminUsersHandler,
  customerViewerSettingsHandler,
  sysAdminUsersHandler,
} from './handlers/usersHandler';
import { allocateBillingAccountsHandler } from './handlers/allocateBillingAccountsHandler';
import { servicesHandler } from './handlers/servicesHandler';
import {
  billingServiceApportionmentsHandler,
  serviceApportionmentsHandler,
} from './handlers/servicesApportionmentsHandler';
import { serviceChargesHandler } from './handlers/serviceChargesHandler';
import { statusHandler } from './handlers/statusHandler';
import { billingServiceChargesHandler } from './handlers/billingServiceChargesHandler';
import { customerLabelsHandler } from './handlers/customerLabelsHandler';
import { customerServicesHandler } from './handlers/customerServicesHandler';
import { customersCommitHandler } from './handlers/customersCommitTrackerHandler';
import { systemConfigHandler } from './handlers/systemConfigHandler';
import { recommenderReportHandler } from './handlers/recommenderHandler';
import { systemSyncHandler } from './handlers/systemSyncHandler';
import { disclaimerMessageHandler } from './handlers/disclaimerMessageHandler';
import { skuGroupsHandler } from './handlers/skuGroupsHandler';
import { customerDiscountsHandler } from './handlers/customerDiscountsHandler';
import { customerSDMHandler } from './handlers/customersSDMHandler';
import { customerSkuHandler } from './handlers/customerSkuHandler';

export const handlers: ServerHandler[] = [
  staticHandler,
  testHandler,
  customersHandler,
  customerAdminUsersHandler,
  customerViewerSettingsHandler,
  sysAdminUsersHandler,
  servicesHandler,
  allocateBillingAccountsHandler,
  allBillingAccountsHandler,
  billingAccountsHandler,
  projectsHandler,
  projectGroupProjectsHandler,
  projectGroupsHandler,
  commitsHandler,
  purchaseOrdersHandler,
  staticHandler,
  chartHandler,
  budgetsHandler,
  budgetAllocationsHandler,
  serviceApportionmentsHandler,
  serviceChargesHandler,
  systemConfigHandler,
  statusHandler,
  billingServiceChargesHandler,
  customerLabelsHandler,
  billingServiceApportionmentsHandler,
  customerServicesHandler,
  customersCommitHandler,
  recommenderReportHandler,
  systemSyncHandler,
  disclaimerMessageHandler,
  skuGroupsHandler,
  customerSDMHandler,
  customerDiscountsHandler,
  customerSkuHandler,
];
