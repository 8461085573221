import { Legend, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartLoader } from './ChartLoader';
import {
  AggregatedBurndownItem,
  AggregatedBurndownChartData,
} from '../../domain/types';
import React, { ReactNode } from 'react';
import { useMonetaryFormat } from '../MonetaryDisplay';
import { Y_AXIS_WIDTH } from '../../domain/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from './ChartContainer';

type RenderFunc = (data: AggregatedBurndownItem[]) => ReactNode;

interface BudgetBurndownProps {
  data?: AggregatedBurndownChartData;
  noDataMessage: string;
  renderConsumption: RenderFunc;
  renderForecast: RenderFunc;
  renderBurndown: RenderFunc;
  renderForecastEndDate: RenderFunc;
  renderRenewalDate: RenderFunc;
  isLoading: boolean;
  isError: boolean;
}

export const BudgetBurndownChart = ({
  data,
  noDataMessage,
  renderConsumption,
  renderForecast,
  renderBurndown,
  renderForecastEndDate,
  renderRenewalDate,
  isLoading,
  isError,
}: BudgetBurndownProps) => {
  const formatter = useMonetaryFormat(data?.currency);
  const yAxisFormatter = useMonetaryFormat(data?.currency, true);
  const theme = useTheme();

  return (
    <ChartLoader<AggregatedBurndownItem[]>
      dataReady={(data) => data.length > 0}
      noDataMessage={noDataMessage}
      onData={(data: AggregatedBurndownItem[]) => (
        <ChartContainer data={data}>
          <XAxis
            dataKey="name"
            tick={{ fontSize: 12, dy: 5 }}
            tickLine={false}
          />
          <ReferenceLine y={0} stroke={theme.palette.common.black} />
          <YAxis tickFormatter={yAxisFormatter} width={Y_AXIS_WIDTH} />
          <Tooltip formatter={formatter} />
          <Legend wrapperStyle={{ position: 'relative' }} />
          {renderConsumption(data)}
          {renderForecast(data)}
          {renderBurndown(data)}
          {renderForecastEndDate(data)}
          {renderRenewalDate(data)}
        </ChartContainer>
      )}
      data={data?.items}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
