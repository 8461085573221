import { DisclaimerMessage } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { rest, RestHandler } from 'msw';
import { ServerHandler } from '../ServerHandler';

class Handler implements ServerHandler {
  private dataset: DisclaimerMessage[] = [];

  constructor() {
    this.dataset = [];
  }

  resetData() {
    this.dataset = [];
  }

  handlers(): RestHandler[] {
    const apiPath = apiPaths.disclaimerManager();
    const activeMessagePath = apiPaths.disclaimerMessageActive();

    return [
      rest.get(`/api${apiPath.base}`, (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            total_pages: 0,
            total_items: this.dataset.length,
            page_size: 100,
            page: 1,
            items: this.dataset,
          })
        );
      }),
      rest.get(`/api${activeMessagePath.base}`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(null));
      }),
      rest.post(`/api${apiPath.base}`, (req, res, ctx) => {
        const disclaimer = req.body as DisclaimerMessage;
        disclaimer.id = Math.random();
        disclaimer.createdBy = 'test@appsbroker.com';

        this.dataset = [disclaimer, ...this.dataset];
        return res(ctx.status(200), ctx.json(disclaimer));
      }),
    ];
  }
}

export const disclaimerMessageHandler = new Handler();
