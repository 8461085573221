import { useMemo } from 'react';

export interface MonetaryDisplayProps {
  value: number | string;
  currency?: string;
  noFractionDigits?: boolean;
  shortForm?: boolean;
}

export const MonetaryDisplay = ({
  value,
  currency,
  noFractionDigits,
  shortForm,
}: MonetaryDisplayProps) => {
  return <>{monetaryFormat(value, currency, noFractionDigits, shortForm)}</>;
};

export const monetaryFormat = (
  value: number | string,
  currency?: string,
  noFractionDigits: boolean = false,
  shortForm: boolean = false
): string => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return '';
  }
  const shortFormat = Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
    currency: currency ?? 'USD',
    style: 'currency',
  });

  return shortForm
    ? shortFormat.format(+value)
    : numberValue.toLocaleString('en-GB', {
        style: currency ? 'currency' : 'decimal',
        currency,
        maximumFractionDigits: noFractionDigits ? 0 : 2,
        minimumFractionDigits: noFractionDigits ? 0 : 2,
      });
};

export const useMonetaryFormat = (currency?: string, shortForm?: boolean) =>
  useMemo(
    () => (value: number | string) =>
      monetaryFormat(value, currency, false, shortForm),
    [currency, shortForm]
  );
