import { Controller, useFormContext } from 'react-hook-form';
import {
  Commit,
  CommitType,
  CommitTrackingType,
  CommitKind,
} from '../../../domain/types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { CommitFormValues } from './CommitSchema';
import {
  addDays,
  addYears,
  compareAsc,
  format,
  differenceInCalendarYears,
} from 'date-fns';
import { MonetaryValueField } from '../../Fields/MonetaryValueField';
import React, { useCallback, useMemo } from 'react';
import { DatePickerField } from '../../Fields/DatePickerField';
import { API_DATE_FORMAT, COMMIT_CURRENCY } from '../../../domain/constants';
import { formatDate } from '../../../utils/dateUtils';
import { CommitAmount } from './CommitAmount';
import {
  formatToPercentage,
  stringToPercentage,
} from '../../../utils/stringUtils';

export const commitHasElement = (
  commit: CommitType,
  element: CommitType.HARD | CommitType.SOFT
) => commit === element || commit === CommitType.HARD_AND_SOFT;

export const CommitForm: CrudFormComponent<Commit> = ({ item }) => {
  const {
    register,
    errors,
    control,
    watch,
  } = useFormContext<CommitFormValues>();

  const startDate = watch(
    'startDate',
    item?.startDate ?? format(new Date(), API_DATE_FORMAT)
  );

  const calculateNumberOfYears = () => {
    const fieldEndDate = item?.endDate ?? startDate;

    // add a day back in when calculating difference to stop issues with year changes
    // eg: startDate: 2020-01-01, endDate: 2021-12-31
    const difference = differenceInCalendarYears(
      addDays(new Date(fieldEndDate), 1),
      new Date(startDate)
    );
    return difference > 0 ? difference : 1;
  };

  const trackingType = watch('trackingType', item?.trackingType);
  const numberOfYears = watch('numberOfYears', calculateNumberOfYears());
  const allowsShortfallRollover = watch(
    'allowsShortfallRollover',
    item?.allowsShortfallRollover
  );

  const currency = COMMIT_CURRENCY;
  const commitAmounts = watch(
    'commitAmounts',
    item?.commitAmounts.sort((commitA, commitB) => {
      return compareAsc(
        new Date(commitA.startDate),
        new Date(commitB.startDate)
      );
    }) ?? []
  );

  const numerOfCommitAmounts =
    trackingType === CommitTrackingType.FULL ? 1 : numberOfYears;

  const commitType = watch('type', item?.type);

  const getCommitLabel = (index: number) => {
    const yearsToAdd =
      numerOfCommitAmounts === 1 && numberOfYears > 1
        ? numberOfYears
        : index + 1;
    return `${formatDate(addYears(new Date(startDate), index))} - ${formatDate(
      addDays(addYears(new Date(startDate), yearsToAdd), -1)
    )}`;
  };

  const totalCommitAmount = useMemo(
    () =>
      commitAmounts?.reduce(
        (total, { hardAmount = 0, softAmount = 0 }) =>
          total + softAmount + hardAmount,
        0
      ),
    [commitAmounts]
  );

  const isRolloverActive: boolean = useMemo(
    () =>
      commitType === CommitType.HARD &&
      trackingType === CommitTrackingType.ANNUAL,
    [commitType, trackingType]
  );

  // End date in inclusive, so should always be start date + number of years - 1 day
  const getEndDate = useCallback(() => {
    let endDate = addYears(new Date(startDate), numberOfYears);
    return format(addDays(endDate, -1), API_DATE_FORMAT);
  }, [startDate, numberOfYears]);

  return (
    <>
      <CrudFormControl error={errors.superseded}>
        <Controller
          control={control}
          id="superseded"
          name="superseded"
          defaultValue={item?.superseded ?? false}
          render={({ onChange, value, ...props }) => (
            <FormControlLabel
              label="Superseded"
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  {...props}
                />
              }
            />
          )}
        />
      </CrudFormControl>
      {isRolloverActive && (
        <>
          <CrudFormControl error={errors.allowsExcessRollover}>
            <Controller
              control={control}
              id="allowsExcessRollover"
              name="allowsExcessRollover"
              defaultValue={item?.allowsExcessRollover ?? false}
              render={({ onChange, value, ...props }) => (
                <FormControlLabel
                  label="Excess Rollover Allowed"
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      {...props}
                    />
                  }
                />
              )}
            />
          </CrudFormControl>

          <CrudFormControl error={errors.allowsShortfallRollover}>
            <Controller
              control={control}
              id="allowsShortfallRollover"
              name="allowsShortfallRollover"
              defaultValue={item?.allowsShortfallRollover ?? false}
              render={({ onChange, value, ...props }) => (
                <FormControlLabel
                  label="Shortfall Rollover Allowed"
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      {...props}
                    />
                  }
                />
              )}
            />
          </CrudFormControl>
        </>
      )}

      {allowsShortfallRollover && (
        <CrudFormControl error={errors?.shortfallRolloverPercentage}>
          <Controller
            control={control}
            id="shortfallRolloverPercentage"
            name="shortfallRolloverPercentage"
            defaultValue={item?.shortfallRolloverPercentage ?? 0}
            render={({ onChange, value, ...props }) => (
              <TextField
                {...props}
                type="number"
                label="Shortfall Percentage"
                onChange={(e) => onChange(stringToPercentage(e.target.value))}
                id="shortfallRolloverPercentage"
                value={formatToPercentage(value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </CrudFormControl>
      )}
      <CrudFormControl error={errors.type}>
        <Controller
          control={control}
          name="type"
          defaultValue={item?.type ?? ''}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="type-label">Commit Type</InputLabel>
              <Select
                onChange={onChange}
                value={value}
                ref={register}
                id="type"
                labelId="type-label"
              >
                {Object.values(CommitType).map((ct) => (
                  <MenuItem value={ct} key={ct}>
                    {ct}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.trackingType}>
        <Controller
          control={control}
          name="trackingType"
          defaultValue={item?.trackingType ?? ''}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="trackingType-label">
                Commit Tracking Type
              </InputLabel>
              <Select
                onChange={onChange}
                value={value}
                id="trackingType"
                labelId="trackingType-label"
              >
                <MenuItem value={CommitTrackingType.ANNUAL}>
                  Annualised
                </MenuItem>
                <MenuItem value={CommitTrackingType.FULL}>Full Term</MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.kind}>
        <Controller
          control={control}
          name="kind"
          defaultValue={item?.kind ?? CommitKind.SKU}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="commit-kind">Commit Kind</InputLabel>
              <Select
                onChange={onChange}
                value={value}
                id="commit-kind"
                labelId="commit-kind"
              >
                <MenuItem value={CommitKind.SKU}>SKU</MenuItem>
                <MenuItem value={CommitKind.SERVICE}>Service</MenuItem>
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <CrudFormControl>
        <Controller
          control={control}
          name="currency"
          defaultValue={currency}
          render={() => (
            <TextField
              id="currency"
              label="Currency"
              disabled
              value={currency}
              ref={register}
            ></TextField>
          )}
        />
      </CrudFormControl>
      <CrudFormControl>
        <MonetaryValueField
          label="Total Commit Amount"
          id="totalCommitAmount"
          currency={currency}
          value={totalCommitAmount}
          disabled
        />
      </CrudFormControl>
      <CrudFormControl error={errors.startDate}>
        <Controller
          control={control}
          name="startDate"
          defaultValue={startDate}
          render={({ onChange, value }) => (
            <DatePickerField
              id="startDate"
              value={value ? new Date(value) : null}
              onChange={onChange}
              label="Start Date"
            />
          )}
        />
      </CrudFormControl>
      <input name="endDate" type="hidden" value={getEndDate()} ref={register} />
      <CrudFormControl error={errors.numberOfYears}>
        <Controller
          control={control}
          name="numberOfYears"
          defaultValue={calculateNumberOfYears()}
          render={({ onChange, value }) => (
            <>
              <InputLabel id="numberOfYearsLabel" htmlFor="numberOfYears">
                Number of Years
              </InputLabel>
              <Select
                id="numberOfYears"
                labelId="numberOfYearsLabel"
                onChange={onChange}
                value={value}
                ref={register}
              >
                {new Array(10).fill(0).map((_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </CrudFormControl>
      <h3>Commit amounts:</h3>
      {Array.from({ length: numerOfCommitAmounts }).map((_, index) => {
        const fieldName = `commitAmounts[${index}]`;
        return (
          <div key={fieldName}>
            <h5>{getCommitLabel(index)}</h5>
            <input
              name={`${fieldName}.startDate`}
              type="hidden"
              value={format(
                addYears(new Date(startDate), index),
                API_DATE_FORMAT
              )}
              ref={register}
            />
            <Grid container spacing={2}>
              {commitHasElement(commitType, CommitType.HARD) && (
                <CommitAmount
                  width={commitType === CommitType.HARD_AND_SOFT ? 6 : 12}
                  currency={currency}
                  error={errors.commitAmounts?.[index]?.hardAmount}
                  fieldName={fieldName}
                  defaultValue={item?.commitAmounts[index]?.hardAmount ?? ''}
                  label="Hard Commit"
                  control={control}
                  commitType={CommitType.HARD}
                />
              )}
              {commitHasElement(commitType, CommitType.SOFT) && (
                <CommitAmount
                  width={commitType === CommitType.HARD_AND_SOFT ? 6 : 12}
                  currency={currency}
                  error={errors.commitAmounts?.[index]?.softAmount}
                  fieldName={fieldName}
                  defaultValue={item?.commitAmounts[index]?.softAmount ?? ''}
                  label="Soft Commit"
                  control={control}
                  commitType={CommitType.SOFT}
                />
              )}
            </Grid>
          </div>
        );
      })}
    </>
  );
};
