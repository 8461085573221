import { useQuery } from 'react-query';
import {
  BudgetAllocationProject,
  BudgetAllocationType,
} from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { GET } from '../../../utils/api';
import { useGetBudget } from './useGetBudget';

export const useGetAvailableGroupsOrProjects = (
  customerId: number,
  budgetId: number
) => {
  const {
    data: budget,
    isLoading: getBudgetLoading,
    isError: getBudgetIsError,
  } = useGetBudget(customerId, budgetId);

  const path =
    budget?.allocationType === BudgetAllocationType.PROJECT
      ? apiPaths.budget(customerId).availableProjects(budgetId)
      : apiPaths.budget(customerId).availableProjectGroups(budgetId);

  const { data, isLoading, isError } = useQuery<BudgetAllocationProject[]>(
    path,
    () => GET(path),
    {
      enabled: !!budget?.allocationType,
    }
  );
  return {
    data,
    isLoading: isLoading || getBudgetLoading,
    isError: isError || getBudgetIsError,
  };
};
