import { useState } from 'react';
import { ApiErrorResponse, CommitSkuGroup } from '../../../../domain/types';
import { useMutation, useQueryClient } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import { useStateContext } from '../../../../contexts/StateProvider';
import { PUT } from '../../../../utils/api';

interface UpdatedCommits {
  [key: string]: boolean;
}

export const useHandleSkuGroupCommitUpdates = () => {
  const [commitsToUpdate, setCommitsToUpdate] = useState<UpdatedCommits>({});
  const queryClient = useQueryClient();
  const { selectedCustomerId, showNotification } = useStateContext();
  const apiPath = apiPaths.customerSkuGroups(selectedCustomerId);

  const setUpdatedCommits = (
    row: CommitSkuGroup,
    updatedCommitIncluded: boolean
  ) => {
    if (updatedCommitIncluded === row.commitIncluded) {
      const { [row.id]: removing, ...rest } = commitsToUpdate;
      setCommitsToUpdate(rest);
    } else {
      setCommitsToUpdate({
        ...commitsToUpdate,
        [row.id]: updatedCommitIncluded,
      });
    }
  };

  const { mutate, isLoading } = useMutation<unknown, ApiErrorResponse, any>(
    async () => {
      return await PUT(apiPath.base, {
        commitsToUpdate: Object.keys(commitsToUpdate),
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiPath.key.list());
        showNotification({
          message: 'Changes applied successfully',
          severity: 'success',
        });
        setCommitsToUpdate({});
      },
      onError: () => {
        showNotification({
          message: 'There was an error. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  return {
    commitsToUpdate,
    setUpdatedCommits,
    saveCommitsToUpdate: mutate,
    isLoading,
  };
};
