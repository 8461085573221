import { RouteDetail } from '../domain/types';
import { ListItemLink } from './ListItemLink';
import makeStyles from '@mui/styles/makeStyles';
import { CallMissedOutgoing } from '@mui/icons-material';
import { hasRole } from '../hooks/useHasRole';
import { useAuthContext } from '../contexts/AuthProvider';
import { NavIcon } from './NavIcon';

interface MenuLinksProps {
  routes: RouteDetail[];
}

export const MenuLinks = ({ routes }: MenuLinksProps) => {
  const classes = useStyles();
  const { userRole } = useAuthContext();

  return (
    <>
      {routes.map(({ label, path, link, indent, roles }) => {
        if (roles && !hasRole(userRole, roles)) {
          return null;
        }
        return (
          <ListItemLink
            indent={indent}
            key={path}
            primary={label}
            to={link ?? path}
            icon={
              indent ? (
                <CallMissedOutgoing className={classes.indentIcon} />
              ) : (
                <NavIcon path={path} />
              )
            }
          />
        );
      })}
    </>
  );
};

const useStyles = makeStyles({
  indentIcon: {
    position: 'relative',
    top: -3,
    transform: 'rotate(45deg)',
  },
});
