const cloudEntityMap = new Map<string, string>([
  ['BILLING_ACCOUNT_ID', 'Billing Account Id'],
  ['PROJECT_NUMBER', 'Project Id'],
  ['FOLDER_ID', 'Folder Id'],
  ['ORGANIZATION_ID', 'Organization Id'],
]);

export const useCloudEntityId = (cloudEntityId: string) => {
  return cloudEntityMap.get(cloudEntityId);
};
