import { useQuery } from 'react-query';
import { GET } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { formatToUpdatedTime } from '../../utils/dateUtils';
import { SystemStatus } from '../../domain/types';

interface Response {
  data?: SystemStatus;
  isLoading: boolean;
  isError: boolean;
}

export const useLastSyncData = (): Response => {
  const { data, isLoading, isError } = useQuery<SystemStatus>(
    apiPaths.lastSyncTime.key.namespace(),
    () => GET(apiPaths.lastSyncTime.base)
  );

  const { lastSyncTime } = data ?? {};

  return {
    data: {
      lastSyncTime: lastSyncTime
        ? formatToUpdatedTime(lastSyncTime)
        : undefined,
    },
    isLoading,
    isError,
  };
};
