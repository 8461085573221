import { useCallback, useMemo } from 'react';
import { VOID_ID } from '../domain/constants';
import { useSearchParams } from './useSearchParams';

const CUSTOMER_ID_KEY = 'customerId';

export const useCustomerIdSearchParam = (): [number, (cId: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const customerId: number = useMemo(() => {
    const hasCustomerIdSet = searchParams[CUSTOMER_ID_KEY]?.length > 0;

    return hasCustomerIdSet ? +searchParams[CUSTOMER_ID_KEY][0] : VOID_ID;
  }, [searchParams]);

  const setCustomerId = useCallback(
    (customerIdParam: number) => {
      const payload = customerIdParam === VOID_ID ? [] : [`${customerIdParam}`];

      setSearchParams({
        customerId: payload,
      });
    },
    [setSearchParams]
  );

  return [customerId, setCustomerId];
};
