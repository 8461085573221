import { GET_PAGED } from '../../../utils/api';
import { apiPaths } from '../../../utils/apiPaths';
import { useQuery } from 'react-query';
import { PagedItems, SystemLock } from '../../../domain/types';
import { DATA_GRID_PAGE_LIMIT } from '../../../domain/constants';
import { useEffect, useState } from 'react';

export const useSystemManagementData = (page: number) => {
  const [totalItems, setTotalItems] = useState(0);
  const apiPath = apiPaths.systemSyncs();

  const { data, isLoading } = useQuery<PagedItems<SystemLock>>(
    apiPath.key.list(page + 1),
    () =>
      GET_PAGED(apiPath.base, {
        page: page,
        size: DATA_GRID_PAGE_LIMIT,
      })
  );

  useEffect(() => {
    if (totalItems === 0) {
      setTotalItems(data?.total_items ?? 0);
    }
  }, [data, totalItems]);

  return {
    isLoading,
    items: data?.items ?? [],
    totalItems,
  };
};
