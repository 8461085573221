import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { BQUsageBreakdown } from '../../../../domain/types';

interface ComponentProps {
  onChange: (value: BQUsageBreakdown) => void;
  value: BQUsageBreakdown;
}

export const BigQueryUsageBreakdownFilter = ({
  value,
  onChange,
}: ComponentProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="breakdown-by-filter">Breakdown by</InputLabel>
      <Select
        labelId="breakdown-by-filter-label"
        id="breakdown-by-filter"
        value={value}
        onChange={(e) => onChange(e.target.value as BQUsageBreakdown)}
      >
        <MenuItem value="user_email">User Email</MenuItem>
        <MenuItem value="project">Project</MenuItem>
        <MenuItem value="project_group">Project Group</MenuItem>
      </Select>
    </FormControl>
  );
};
