import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { BQUsageFilters, BQUsageValue } from '../../../../domain/types';
import { useFilters } from '../../../../contexts/ReportFiltersProvider';
import { useEffect, useMemo, useState } from 'react';
import { MonetaryDisplay } from '../../../MonetaryDisplay';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getResolvedArrayValue } from '../../../../utils/arrayUtils';
import { useChartSeriesColors } from '../../../../theme/useChartSeriesColors';
import { bytesToTiB } from '../../../../utils/bytesToTiB';
import { Tooltip } from '@mui/material';
import { formatBigQueryUsageDuration } from '../utils/formatBigQueryUsageDuration';
import { ReportGridContainer } from '../../../ReportGridContainer';

interface ComponentProps {
  items: BQUsageValue[];
  isLoading: boolean;
  currency: string;
}

const DEFAULT_GRID_PAGE_SIZE = 10;

export const BigUsageDataGrid = ({
  items,
  isLoading,
  currency,
}: ComponentProps) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_GRID_PAGE_SIZE,
  });
  const { appliedFilters } = useFilters<BQUsageFilters>();
  const colors = useChartSeriesColors();
  const cols = useMemo(() => {
    const cols: GridColDef<BQUsageValue>[] = [
      {
        renderHeader: () => <span></span>,
        field: 'id',
        renderCell: ({ value }: GridRenderCellParams) => {
          return (
            <FiberManualRecordIcon
              style={{
                fill: `${getResolvedArrayValue(colors, value as number)}`,
              }}
            />
          );
        },
        sortable: false,
        hideSortIcons: true,
        width: 50,
      },
      {
        headerName: 'Indicative Cost',
        field: 'indicative_cost',
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) =>
          renderMonetary(value, currency),
      },
      {
        headerName: 'Total TiB Billed',
        field: 'total_bytes_billed',
        flex: 1,
        renderCell: ({ value }) => {
          return bytesToTiB(value ?? 0);
        },
      },
      {
        headerName: 'Total TiB Processed',
        field: 'total_bytes_processed',
        flex: 1,
        renderCell: ({ value }) => {
          return bytesToTiB(value ?? 0);
        },
      },
      {
        headerName: 'Total Slot Duration Hours',
        field: 'total_slot_ms',
        flex: 1,
        renderCell: ({ value }) => {
          const { hours, formatted } = formatBigQueryUsageDuration(value);
          return (
            <Tooltip title={formatted}>
              <span>{hours}</span>
            </Tooltip>
          );
        },
      },
      {
        headerName: 'Avg Slots Used',
        field: 'avg_slots_used',
        flex: 1,
      },
      {
        headerName: 'Job Duration Hours',
        field: 'job_duration_ms',
        flex: 1,
        renderCell: ({ value }) => {
          const { hours, formatted } = formatBigQueryUsageDuration(value);
          return (
            <Tooltip title={formatted}>
              <span>{hours}</span>
            </Tooltip>
          );
        },
      },
    ];

    if (appliedFilters.breakdown === 'project') {
      cols.splice(
        1,
        0,
        {
          headerName: 'Project ID',
          field: 'project',
          flex: 1,
        },
        {
          headerName: 'Project Name',
          field: 'project_name',
          flex: 1,
        }
      );
    } else if (appliedFilters.breakdown === 'user_email') {
      cols.splice(1, 0, {
        headerName: 'User Email',
        field: 'user_email',
        flex: 1,
      });
    } else if (appliedFilters.breakdown === 'job_id') {
      cols.splice(
        1,
        0,
        {
          headerName: 'Job Id',
          field: 'job_id',
          flex: 1,
        },
        {
          headerName: 'Job Type',
          field: 'job_type',
          flex: 1,
        },
        {
          headerName: 'User Email',
          field: 'user_email',
          flex: 1,
        }
      );
    } else if (appliedFilters.breakdown === 'project_group') {
      cols.splice(1, 0, {
        headerName: 'Project Group',
        field: 'project_group',
        flex: 1,
      });
    }

    return cols;
  }, [appliedFilters.breakdown, currency, colors]);

  const dataItems = useMemo(() => {
    return items.map((item, index) => ({ ...item, id: index }));
  }, [items]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: DEFAULT_GRID_PAGE_SIZE,
    });
  }, [appliedFilters]);

  return (
    <ReportGridContainer>
      <DataGrid
        paginationModel={paginationModel}
        onPaginationModelChange={(model: GridPaginationModel) =>
          setPaginationModel(model)
        }
        columns={cols}
        rows={dataItems}
        loading={isLoading}
        columnBuffer={cols.length}
        autoHeight
        density="compact"
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </ReportGridContainer>
  );
};

const renderMonetary = (value: number | string, currency?: string) => {
  return <MonetaryDisplay value={Number(value)} currency={currency} />;
};
