import { Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RecommenderFilter } from './RecommenderFilter';
import { FilterList } from '@mui/icons-material';
import { useRecommenderFilterOptions } from './hooks/useRecommenderFilterOptions';
import { useRecommenderFilters } from './hooks/useRecommenderFilters';
import { RecommenderSearch } from './RecommenderSearch';
import { RecommenderSortFilter } from './RecommenderSortFilter';
import { useCallback } from 'react';
import { WidgetContainer } from '../../WidgetContainer';

export const RecommenderFilterBar = () => {
  const classes = useStyles();
  const filtersContext = useRecommenderFilters();
  const {
    categoriesOptions,
    statesOptions,
    prioritiesOptions,
  } = useRecommenderFilterOptions();

  const onSearchChange = useCallback(
    (searchTerm: string) => {
      filtersContext.applySearch(searchTerm);
    },
    [filtersContext]
  );

  return (
    <WidgetContainer className={classes.container}>
      <FilterList className={classes.filtersIcon} />
      <Box mr={4} width={200}>
        <RecommenderFilter
          options={categoriesOptions}
          label="Impact"
          value={filtersContext.filters.category}
          onChange={(value: any) => {
            const category = value;
            filtersContext.applyFilters({
              category: category,
            });
          }}
        />
      </Box>
      <Box mr={4} width={100}>
        <RecommenderFilter
          options={prioritiesOptions}
          label="Priority"
          value={filtersContext.filters.priority}
          onChange={(value: any) => {
            const priority = value;
            filtersContext.applyFilters({
              priority: priority,
            });
          }}
        />
      </Box>

      <Box mr={4} width={100}>
        <RecommenderFilter
          options={statesOptions}
          label="Status"
          value={filtersContext.filters.state}
          onChange={(value: any) => {
            const state = value;
            filtersContext.applyFilters({
              state: state,
            });
          }}
        />
      </Box>
      <Box mr={4} width={130}>
        <RecommenderSortFilter
          value={filtersContext.sortBy}
          onChange={(value) => filtersContext.applySort(value)}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" flex={1}>
        <RecommenderSearch
          value={filtersContext.search}
          onSearch={onSearchChange}
        />
      </Box>
    </WidgetContainer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  filtersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  firstFiltersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  secondFiltersRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  filtersIcon: {
    marginRight: theme.spacing(1),
  },
  kpi: {
    width: 240,
  },
  kpiRows: {
    display: 'flex',
  },
  kpiBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
    position: 'relative',
  },
}));
