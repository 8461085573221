import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

export interface ConsumptionDateFilterProps {
  value: Date;
  onChange: (val: Date) => void;
  label: string;
}

export const ConsumptionDateFilter = ({
  value,
  label,
  onChange,
}: ConsumptionDateFilterProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={(date) => {
          if (date) {
            onChange(date);
          }
        }}
        format="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
};
