import { useMemo } from 'react';
import { ProjectGroupsState } from '../../../hooks/projectGroupsSlice';

export const useSingleSelection = (
  state: ProjectGroupsState,
  selectionLevel: 'project' | 'group'
) => {
  return useMemo(() => {
    const selection: {
      groupId: number;
      projectId: number;
      isSingleSelection: boolean;
    } = {
      groupId: -1,
      projectId: -1,
      isSingleSelection: false,
    };

    const groupsIds = Object.keys(state.projectGroups);
    if (groupsIds.length !== 1) {
      return selection;
    }

    const groupKey = groupsIds[0];
    const projectsKeys = Object.keys(
      state.projectGroups[groupKey as any]?.projects ?? {}
    );

    if (selectionLevel === 'project' && projectsKeys.length > 1) {
      return selection;
    }

    selection.isSingleSelection = true;
    selection.groupId = +groupKey;
    selection.projectId = selectionLevel === 'project' ? +projectsKeys[0] : -1;
    return selection;
  }, [state.projectGroups, selectionLevel]);
};
