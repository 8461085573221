import { ApiPath } from '../../../utils/apiPaths';
import React, { ReactNode } from 'react';
import { CrudFormModal } from '../form/CrudFormModal';
import { useCrudCreate } from './useCrudCreate';
import { CrudValueMapper, CustomCrudAction } from '../CrudContainer';

export interface CrudCreateModalProps<FormValues, SubmittedValues> {
  name: string;
  children: ReactNode;
  apiPath: ApiPath;
  onClose: () => void;
  valueMapper: CrudValueMapper<FormValues, SubmittedValues>;
  customCreate: CustomCrudAction;
}

export function CrudCreateModal<T, FormValues, SubmittedValues>({
  name,
  children,
  apiPath,
  onClose,
  valueMapper,
  customCreate,
}: CrudCreateModalProps<FormValues, SubmittedValues>) {
  const { onSubmit, isCreating, hasError, errorMessage } = useCrudCreate<
    T,
    SubmittedValues
  >({
    apiPath,
    onSuccess: () => {
      onClose();
    },
  });

  const title = customCreate?.title || `Create ${name}`;
  const mutatingButtonText = customCreate?.gerund || 'Creating...';
  const buttonText = customCreate?.buttonText || 'Create';

  return (
    <CrudFormModal<FormValues, SubmittedValues>
      title={title}
      mutatingButtonText={mutatingButtonText}
      buttonText={buttonText}
      onSubmit={onSubmit}
      onClose={onClose}
      isDataLoading={false}
      hasDataError={false}
      isMutating={isCreating}
      hasMutationError={hasError}
      mutationErrorMessage={errorMessage}
      valueMapper={valueMapper}
    >
      {children}
    </CrudFormModal>
  );
}
