import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReportTimeInterval } from '../../../domain/types';

export interface TimeIntervalFilterProps {
  value: ReportTimeInterval;
  onChange: (value: ReportTimeInterval) => void;
}

export const TimeIntervalFilter = ({
  value,
  onChange,
}: TimeIntervalFilterProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="time-interval-filter">Display</InputLabel>
      <Select
        labelId="time-interval-filter-label"
        id="time-interval-filter"
        value={value}
        onChange={(e) => onChange(e.target.value as ReportTimeInterval)}
      >
        <MenuItem value="doy">Daily</MenuItem>
        <MenuItem value="month">Monthly</MenuItem>
      </Select>
    </FormControl>
  );
};
