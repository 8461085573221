import { useMutation, useQueryClient } from 'react-query';
import { POST } from '../../../utils/api';
import { ApiPath } from '../../../utils/apiPaths';
import { useCallback } from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { ApiErrorResponse } from '../../../domain/types';

interface Response<Values> {
  onSubmit: (values: Values) => void;
  isCreating: boolean;
  hasError: boolean;
  errorMessage?: string;
}

interface Props {
  apiPath: ApiPath;
  onSuccess: () => void;
}
export const useCrudCreate = <T, Values>({
  apiPath,
  onSuccess,
}: Props): Response<Values> => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();
  const { mutate, isLoading, isError, error } = useMutation<
    T,
    ApiErrorResponse,
    Values
  >((data: Values) => POST(apiPath.base, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(apiPath.key.list());
      showNotification({
        message: 'Item created successfully',
        severity: 'success',
      });
      onSuccess();
    },
  });

  const onSubmit = useCallback(
    (values: Values) => {
      mutate(values);
    },
    [mutate]
  );

  return {
    onSubmit,
    isCreating: isLoading,
    hasError: isError,
    errorMessage: error?.response?.data.detail,
  };
};
