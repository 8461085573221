import { CommitType } from '../../../domain/types';
import { Tile } from '../Tiles/Tile';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid } from '@mui/material';

interface ShortfallTileProps {
  commitType: CommitType.SOFT | CommitType.HARD;
  amount: string;
  isLoading: boolean;
}

export const ShortfallTile = ({
  commitType,
  amount,
  isLoading,
}: ShortfallTileProps) => {
  return (
    <Grid item>
      <Tile
        icon={<TrendingUpIcon fontSize="large" />}
        isLoading={isLoading}
        heading={`${
          commitType === CommitType.HARD ? 'Hard' : 'Soft'
        } Shortfall`}
        subHeading={<small>{amount}</small>}
        severity="danger"
      />
    </Grid>
  );
};
