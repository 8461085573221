import { Commit } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { mockCommitData } from '../data/adminData';

export const commitsHandler = new CustomerCrudHandler<Commit>(
  apiPaths.commits(':customerId' as any).base,
  {
    1: mockCommitData,
  }
);
