import { number, object, SchemaOf, string } from 'yup';
import { Editable, FormValues, ServiceCharge } from '../../../domain/types';
import { MIN_MONEY_VALUE } from '../../../domain/constants';

export type ServiceChargeFormValues = Editable<
  FormValues<Omit<ServiceCharge, 'customerId'>>
>;

export const ServiceChargeSchema: SchemaOf<ServiceChargeFormValues> = object({
  serviceId: number().required(),
  currency: string().required().length(3),
  amount: number()
    .min(MIN_MONEY_VALUE, `Value should be at least ${MIN_MONEY_VALUE}`)
    .required(),
  invoiceNumber: string().required().max(40),
  invoiceDate: string().required(),
  settleDate: string().required(),
  toUsd: number().required(),
});
