import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CrudFormControl } from '../../Crud/form/CrudFormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { ProjectGroupFormValues } from './ProjectGroupSchema';
import { CrudFormComponent } from '../../Crud/form/CrudFormComponent';
import { ProjectGroup } from '../../../domain/types';
import { useStateContext } from '../../../contexts/StateProvider';

export const ProjectGroupForm: CrudFormComponent<ProjectGroup> = ({ item }) => {
  const {
    register,
    control,
    errors,
  } = useFormContext<ProjectGroupFormValues>();
  const { selectedCustomerId } = useStateContext();

  const projectGroupTypes = ['Default'];

  return (
    <>
      <input
        type="hidden"
        name="customerId"
        value={selectedCustomerId}
        ref={register}
      />
      <CrudFormControl error={errors.name}>
        <TextField
          id="name"
          name="name"
          inputRef={register}
          label="Name"
          defaultValue={item?.name}
          error={!!errors.name}
        />
      </CrudFormControl>
      <CrudFormControl error={errors.type}>
        <InputLabel id="type-label">Type</InputLabel>
        <Controller
          name="type"
          control={control}
          defaultValue={item?.type ?? 'Default'}
          render={({ ...props }) => (
            <Select labelId="type-label" id="type" {...props}>
              {projectGroupTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </CrudFormControl>
    </>
  );
};
