import Checkbox from '@mui/material/Checkbox';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React from 'react';

export interface AllSelectorProps {
  multiSelect: boolean;
  selected: boolean;
  indeterminate?: boolean;
  toggleAll: () => void;
  clearAll: () => void;
}

export const AllSelector = ({
  multiSelect,
  selected,
  indeterminate,
  toggleAll,
  clearAll,
}: AllSelectorProps) => {
  const classes = useStyles();
  if (multiSelect) {
    return (
      <Checkbox
        data-testid="cb-toggleall"
        inputProps={{
          'aria-label': 'Toggle all',
        }}
        size="small"
        color="primary"
        checked={selected}
        indeterminate={indeterminate}
        onChange={toggleAll}
        className={classes.checkbox}
        disableRipple={true}
      />
    );
  }
  return (
    <IconButton
      size="small"
      component="small"
      color="primary"
      onClick={clearAll}
      disabled={!indeterminate}
      data-testid={'clear-selection'}
      aria-label={selected ? 'Clear selection' : ''}
      disableRipple={true}
    >
      <RemoveCircleIcon />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0, 1),
  },
}));
