import { PagedItems, ProjectGroup } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CustomerCrudHandler } from '../CustomerCrudHandler';
import { mockProjectGroupData } from '../data/adminData';
import { rest, RestHandler } from 'msw';

class Handler extends CustomerCrudHandler<ProjectGroup> {
  private includeProjects(val: string | null) {
    return val === 'true';
  }

  listHandler(): RestHandler {
    return rest.get(this.endpoint, (req, res, ctx) => {
      const includeProjects = this.includeProjects(
        req.url.searchParams.get('include_projects')
      );
      const dataSet = this.getDataset(this.getKey(req.params));
      const groups = Array.from(dataSet.values());
      const items = includeProjects
        ? groups
        : groups.map(({ projects, ...group }) => group);

      const data: PagedItems<ProjectGroup> = {
        items,
        total_items: dataSet.size,
        page: 1,
        total_pages: 1,
      };
      return res(ctx.status(200), ctx.json(data));
    });
  }
}

export const projectGroupsHandler = new Handler(
  apiPaths.projectGroup(':customerId' as any).base,
  {
    1: mockProjectGroupData,
  }
);
