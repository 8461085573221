import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Toolbar,
  Typography,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import { BillingAccount, Customer, PagedItems } from '../../../domain/types';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useQueryClient } from 'react-query';
import { apiPaths } from '../../../utils/apiPaths';
import { GET_PAGED, POST } from '../../../utils/api';
import { UnmappedBillingAccountsTable } from './UnmappedBillingAccountsTable';
import { AddBox } from '@mui/icons-material';
import { useStateContext } from '../../../contexts/StateProvider';

export const UnmappedBillingAccountsAdmin = () => {
  const classes = useStyles();
  const { showNotification } = useStateContext();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [selectedBillingAccounts, setSelectedBillingAccounts] = useState<
    BillingAccount[]
  >([]);

  const queryClient = useQueryClient();

  const { data: customers, isLoading: isCustomersLoading } = useQuery<
    PagedItems<Customer>
  >(apiPaths.customer.key.list(), () => GET_PAGED(apiPaths.customer.base), {
    retry: false,
    keepPreviousData: true,
  });

  const {
    data: unmappedBillingAccounts,
    isLoading: isLoadingUnmappedBillingAccounts,
    error: unmappedBillingAccountsError,
  } = useQuery<PagedItems<BillingAccount>>(
    apiPaths.unmappedBillingAccounts.base,
    () => GET_PAGED(apiPaths.unmappedBillingAccounts.base)
  );

  const {
    isLoading: isAssignAccountsLoading,
    refetch: sendAssignAccounts,
  } = useQuery(
    apiPaths.allocateBillingAccount(selectedCustomer?.id ?? 0).base,
    () =>
      POST(apiPaths.allocateBillingAccount(selectedCustomer?.id ?? 0).base, {
        ids: selectedBillingAccounts.map((account) => account.id),
      }),
    {
      enabled: false,
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiPaths.unmappedBillingAccounts.base
        );
        showNotification({
          message: 'Billing accounts assigned successfully',
          severity: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: 'Unable to assign billing accounts. Please try again.',
          severity: 'error',
        });
      },
    }
  );

  const isAssignButtonDisabled =
    !selectedCustomer ||
    selectedBillingAccounts.length < 1 ||
    isAssignAccountsLoading;

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography variant="h6">Manage Unassigned Billing Accounts</Typography>
        <Box ml={4} className={classes.buttons}>
          <Autocomplete<Customer>
            sx={{
              width: '250px',
              mr: 2,
            }}
            options={customers?.items ?? []}
            value={selectedCustomer}
            onChange={(e, value) => {
              setSelectedCustomer(value);
            }}
            getOptionLabel={(item: Customer) => item.name}
            isOptionEqualToValue={(option: Customer, value: Customer) => {
              return option.name === value.name;
            }}
            renderOption={(props, item) => (
              <MenuItem key={item.id} {...props}>
                {item.name}
              </MenuItem>
            )}
            renderInput={(params) => <TextField {...params} label="Customer" />}
            loading={isCustomersLoading}
          />
          <Button
            size="small"
            color="primary"
            disabled={isAssignButtonDisabled}
            startIcon={<AddBox fontSize="small" />}
            onClick={() => sendAssignAccounts()}
          >
            Assign Selected
          </Button>
        </Box>
      </Toolbar>
      <UnmappedBillingAccountsTable
        billingAccounts={unmappedBillingAccounts?.items ?? []}
        loading={isLoadingUnmappedBillingAccounts}
        error={unmappedBillingAccountsError}
        onSelectionChanged={setSelectedBillingAccounts}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ccc',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0.2),
  },
  buttons: {
    display: 'flex',
    'flex-direction': 'horizontal',
    '& .MuiButton-root': {
      marginRight: theme.spacing(2),
    },
  },
}));
