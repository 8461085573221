import firebase from '../config/firebase';
import { useEffect } from 'react';
import { Customer, Role, User } from '../domain/types';
import { useQuery } from 'react-query';
import { GET } from '../utils/api';
import { apiPaths } from '../utils/apiPaths';
import axios, { AxiosRequestConfig } from 'axios';

interface AuthReauthenticateProps {
  reauthenticate: () => void;
  setAuthToken: (authToken: string | null) => void;
}
export const useAuthReauthenticate = ({
  reauthenticate,
  setAuthToken,
}: AuthReauthenticateProps) => {
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error) => {
        if (error.response !== undefined) {
          const { status } = error.response;
          if (status === 401) {
            setAuthToken(null);
            reauthenticate();
          } else {
            throw error;
          }
        }
      }
    );
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [reauthenticate, setAuthToken]);
};

interface AuthTokenUpdateProps {
  setAuthToken: (authToken: string | null) => void;
  setUser: (user: firebase.User | null) => void;
  setLoadingAuthState: (loading: boolean) => void;
}

export const useAuthTokenUpdate = ({
  setAuthToken,
  setUser,
  setLoadingAuthState,
}: AuthTokenUpdateProps) => {
  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onIdTokenChanged(async (user: firebase.User | null) => {
        const idToken = await user?.getIdToken();
        setAuthToken(idToken ?? null);

        setUser(user);

        if (user === null) {
          setLoadingAuthState(false);
        }
      });

    return () => {
      unsubscribe();
    };
  }, [setAuthToken, setLoadingAuthState, setUser]);
};

interface AuthHeaderUpdateProps {
  authToken: string | null;
  setInterceptor: (interceptor: number | null) => void;
}

export const useAuthHeaderUpdate = ({
  authToken,
  setInterceptor,
}: AuthHeaderUpdateProps) => {
  useEffect(() => {
    let authInterceptor: number;
    if (authToken) {
      authInterceptor = axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          config.headers['Authorization'] = `Bearer ${authToken}`;

          return config;
        },
        () => {
          Promise.reject();
        }
      );
      setInterceptor(authInterceptor);
    }
    return () => {
      axios.interceptors.request.eject(authInterceptor);
    };
  }, [authToken, setInterceptor]);
};

interface GetCurrentUserProps {
  user: firebase.User | null;
  interceptor: number | null;
  setLoadingAuthState: (loading: boolean) => void;
  setAuthorizationError: (error: boolean) => void;
  setUserRole: (user: Role | null) => void;
  setLinkedCustomer: (customer: Customer | null) => void;
}

export const useGetCurrentUser = ({
  user,
  interceptor,
  setLoadingAuthState,
  setAuthorizationError,
  setUserRole,
  setLinkedCustomer,
}: GetCurrentUserProps) => {
  useQuery<User>(
    apiPaths.currentUser.key.namespace(),
    () => GET(apiPaths.currentUser.base),
    {
      retry: false,
      enabled: user !== null && interceptor !== null,
      onError: async () => {
        await firebase.auth().signOut();
        setLoadingAuthState(false);
        setAuthorizationError(true);
      },
      onSuccess: (userData: User) => {
        setUserRole(userData.role);
        setLinkedCustomer(
          userData.customers.length === 1 ? userData.customers[0] : null
        );
        setLoadingAuthState(false);
      },
    }
  );
};
