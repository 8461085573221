import { useEffect, useState } from 'react';

export enum SelectionState {
  None = 'None',
  Selected = 'Selected',
  Some = 'Some',
}

const areItemsSelected = <T, K>(
  selectedKeys: K[],
  items: T[],
  getItemKey: (item: T) => K
): boolean => {
  return items.some((item: T) => selectedKeys.indexOf(getItemKey(item)) !== -1);
};

export const useFilterSelection = <T, K>(
  selectedKeys: K[],
  filteredItems: T[],
  items: T[],
  getItemKey: (item: T) => K
) => {
  const [selection, setSelection] = useState<SelectionState>(
    SelectionState.None
  );

  useEffect(() => {
    const isSelectionPartial: boolean = areItemsSelected(
      selectedKeys,
      filteredItems,
      getItemKey
    );

    if (!selectedKeys || selectedKeys.length === 0) {
      setSelection(SelectionState.None);
    } else if (selectedKeys.length === items.length) {
      setSelection(SelectionState.Selected);
    } else if (isSelectionPartial) {
      setSelection(SelectionState.Some);
    } else if (!isSelectionPartial) {
      setSelection(SelectionState.None);
    }
  }, [selectedKeys, filteredItems, items, getItemKey]);

  return selection;
};
