import { RestHandler, rest } from 'msw';
import { CustomerCommitTracker } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { CrudHandler } from '../CrudHandler';
import { customersCommitTrackerData } from '../data/adminData';

export class CustomersCommitTrackerHandler extends CrudHandler<CustomerCommitTracker> {
  public listHandler(): RestHandler {
    return rest.get(this.endpoint, (req, res, ctx) => {
      const data = this.getDataset(this.getKey(req.params));
      const customersCommits = Array.from(data.values());

      return res(ctx.status(200), ctx.json(customersCommits));
    });
  }
}

export const customersCommitHandler = new CustomersCommitTrackerHandler(
  apiPaths.customersCommitTracker().base,
  customersCommitTrackerData
);
