import { Alert, Grid, useTheme } from '@mui/material';
import { Bar, Label, Line, ReferenceLine } from 'recharts';
import { useStateContext } from '../../../contexts/StateProvider';
import { PURCHASE_ORDERS_REPORT_KEY, VOID_ID } from '../../../domain/constants';
import { BudgetBurndownChart } from '../../Charts/BudgetBurndownChart';
import { usePurchaseOrdersBurndownData } from '../../Charts/usePurchaseOrdersBurndownData';
import { ChartTitle } from '../ChartTitle';
import { GenericReport } from '../GenericReport';
import { useCallback } from 'react';
import { SummaryTile } from '../Tiles/SummaryTile';
import { Tile } from '../Tiles/Tile';
import { apiPaths } from '../../../utils/apiPaths';
import { ReportExportManager } from '../../Charts/ReportExportManager';
import { PageHeader } from '../../PageHeader';
import { ChartWidgetContainer } from '../../ChartWidgetContainer';
import { ChartHeader } from '../../Charts/ChartHeader';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const PurchaseOrderBurndownReport = () => {
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  if (!isCustomerSelected) {
    return <Alert severity="info">Select a customer to view the report.</Alert>;
  }

  return <PurchaseOrderReportContainer />;
};

const PurchaseOrderReportContainer = () => {
  const { selectedCustomerId: customerId } = useStateContext();

  const { data, isLoading, isError } = usePurchaseOrdersBurndownData({
    customerId,
  });

  const theme = useTheme();

  const renderConsumption = useCallback(() => {
    return (
      <Bar
        dataKey="consumption"
        fill={theme.palette.primary.main}
        stackId={1}
      />
    );
  }, [theme.palette.primary.main]);

  const renderBurndown = useCallback(() => {
    return (
      <Line
        type="monotone"
        dataKey="remaining"
        strokeWidth={3}
        stroke={theme.palette.error.light}
      />
    );
  }, [theme.palette.error.light]);

  const renderForecast = useCallback(() => {
    return (
      <Bar dataKey="forecast" fill={theme.palette.warning.main} stackId={1} />
    );
  }, [theme.palette.warning.main]);

  const exportApiPath = apiPaths.purchaseOrderExport(customerId);

  return (
    <>
      <GenericReport
        renderPageHeader={() => (
          <PageHeader title="Purchase Order Burndown">
            <ReportExportManager
              path={exportApiPath}
              type={PURCHASE_ORDERS_REPORT_KEY}
            />
          </PageHeader>
        )}
        renderTiles={() => (
          <>
            <SummaryTile
              isLoading={isLoading}
              title="Current PO Balance"
              value={data?.purchaseOrderSummary.currentBallance}
              currency={data?.currency}
              testId="total-budget-tile"
              icon={<MonetizationOnIcon fontSize="large" />}
            />
            <Grid item>
              <Tile
                isLoading={isLoading}
                heading="PO Renewal"
                subHeading={data?.purchaseOrderSummary.renewalDate ?? '-'}
                icon={<CalendarTodayIcon fontSize="large" />}
              />
            </Grid>
            <Grid item>
              <Tile
                isLoading={isLoading}
                heading="PO exhausted"
                subHeading={data?.purchaseOrderSummary.burnoutDate ?? '-'}
                icon={<HourglassEmptyIcon fontSize="large" />}
              />
            </Grid>
          </>
        )}
        renderReport={() => (
          <ChartWidgetContainer legend>
            <ChartHeader
              renderTitle={() => (
                <ChartTitle heading="Purchase Order Burndown" />
              )}
            />
            <BudgetBurndownChart
              data={data}
              noDataMessage={''}
              renderConsumption={renderConsumption}
              renderForecast={renderForecast}
              renderBurndown={renderBurndown}
              isError={isError}
              isLoading={isLoading}
              renderForecastEndDate={() => (
                <ReferenceLine
                  x={data?.purchaseOrderSummary.burnoutDate}
                  stroke={theme.palette.error.light}
                  strokeWidth={6}
                  style={{ opacity: 0.8 }}
                  strokeDasharray="16 16"
                >
                  <Label
                    position="insideTopRight"
                    fill={theme.palette.error.light}
                    angle={-90}
                    dx={-20}
                    dy={25}
                  >
                    PO exhausted
                  </Label>
                </ReferenceLine>
              )}
              renderRenewalDate={() => (
                <ReferenceLine
                  x={data?.purchaseOrderSummary.renewalDateFormatted}
                  stroke={theme.palette.grey[600]}
                  strokeWidth={2}
                >
                  <Label position="insideTopRight" dx={-7}>
                    PO renewal
                  </Label>
                </ReferenceLine>
              )}
            />
          </ChartWidgetContainer>
        )}
      />
    </>
  );
};
