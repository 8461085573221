import { useQuery } from 'react-query';
import { GET } from '../../../utils/api';
import { apiPaths } from '../../../utils/apiPaths';
import { PageHeader } from '../../PageHeader';
import { CustomersCommitTrackerTable } from './CustomersCommitTrackerTable';

export const CustomersCommitTrackerReport: React.FC = () => {
  const apiPath = apiPaths.customersCommitTracker().base;

  const { data, isLoading } = useQuery(apiPath, () => GET(apiPath));
  return (
    <>
      <PageHeader title="Appsbroker Customers Commit Tracker" />
      <CustomersCommitTrackerTable
        isLoading={isLoading}
        rows={
          data?.map((row: any, index: number) => ({ ...row, id: index })) ?? []
        }
      />
    </>
  );
};
