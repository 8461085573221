import { useMemo } from 'react';
import { BQUsageBreakdown, BQUsageFilters } from '../../../../domain/types';
import { ChartTitle } from '../../ChartTitle';

interface ComponentProps {
  filters: BQUsageFilters;
}

const breakdownLabels = new Map<BQUsageBreakdown, string>([
  ['project', 'Project'],
  ['user_email', 'User Email'],
]);

export const BigQueryUsageReportTitle = ({ filters }: ComponentProps) => {
  const title = useMemo(() => {
    const { timeInterval, breakdown } = filters;
    const aggregationInterval = timeInterval === 'doy' ? 'Day' : 'Month';

    return `${breakdownLabels.get(
      breakdown
    )} Indicative Cost by ${aggregationInterval}`;
  }, [filters]);

  return <ChartTitle heading={title} />;
};
