import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import clsx from 'clsx';

type ListItemLinkProps = {
  icon?: ReactElement;
  indent?: boolean;
  primary: string;
  afterClick?: (event: any) => void;
} & NavLinkProps;

export const ListItemLink = ({
  indent,
  icon,
  primary,
  afterClick,
  ...linkProps
}: ListItemLinkProps) => {
  const classes = useStyles();

  return (
    <li>
      <ListItemButton
        component={NavLink}
        onClick={afterClick}
        activeClassName={clsx('Mui-selected', classes.selectedItem)}
        classes={{
          selected: classes.selectedItem,
        }}
        className={clsx(classes.listItem, {
          [classes.indentedItem]: indent,
          'indented-menu-link': indent,
        })}
        {...linkProps}
      >
        {icon && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
};

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 24,
    marginRight: theme.spacing(3),
  },
  indentedItem: {
    marginLeft: theme.spacing(2),
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  selectedItem: {
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));
