import { useQuery } from 'react-query';
import {
  BudgetRemainingItemData,
  AggregatedBurndownData,
  BurndownFilters,
  BurndownFiltersDto,
} from '../../domain/types';
import { POST } from '../../utils/api';
import { apiPaths } from '../../utils/apiPaths';
import { formatBurndownData } from '../../utils/burndownUtils';
import { useBurndownFilters } from './useBurndownFilters';

interface Props {
  customerId: number;
  filters: BurndownFilters;
  isEnabled: boolean;
}

interface Response {
  data?: AggregatedBurndownData;
  rawDataRequestFilters?: BurndownFiltersDto;
  isLoading: boolean;
  isError: boolean;
}

export const useBudgetBurndownData = ({
  customerId,
  filters,
  isEnabled,
}: Props): Response => {
  const requestFilters = useBurndownFilters(filters);

  const apiPath = apiPaths.budgetRemainingData(customerId);
  const { data, isLoading, isError } = useQuery<BudgetRemainingItemData>(
    apiPath.key.list(requestFilters),
    () =>
      POST<BudgetRemainingItemData, BurndownFiltersDto>(
        apiPath.base,
        requestFilters
      ),
    {
      enabled: isEnabled,
    }
  );

  if (!data) {
    return {
      data: undefined,
      isLoading,
      isError,
    };
  }

  return {
    data: {
      budget: data.budget,
      items: formatBurndownData(data),
      currency: data.currency,
    },
    rawDataRequestFilters: requestFilters,
    isLoading,
    isError,
  };
};
