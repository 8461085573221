import { Button, ButtonGroup } from '@mui/material';
import { WidgetContainer } from '../../../WidgetContainer';
import { Box, styled } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import {
  SystemConfigItems,
  SystemConfigurationResource,
} from '../SystemConfigurationTypes';
import { useMemo } from 'react';

const StyledButton = styled(Button)(() => ({
  width: 140,
}));

interface ComponentProps {
  onSave: () => void;
  disabled: boolean;
  resourceType: SystemConfigurationResource;
  setResourceType: (resType: SystemConfigurationResource) => void;
  selection: SystemConfigItems;
}

export const SystemConfigurationControls = ({
  onSave,
  disabled,
  resourceType,
  setResourceType,
  selection,
}: ComponentProps) => {
  const saveDisabled = useMemo(() => {
    const hasChanges = Object.values(selection).some((item) => item.dirty);
    return disabled || !hasChanges;
  }, [selection, disabled]);

  return (
    <Box mb={2}>
      <WidgetContainer>
        <Box p={2}>
          <ButtonGroup
            disabled={disabled}
            variant="contained"
            aria-label="resource type"
          >
            <StyledButton
              data-testid="sku-groups_tab-btn"
              color={resourceType === 'SKU_GROUP' ? 'primary' : 'inherit'}
              onClick={() => setResourceType('SKU_GROUP')}
            >
              SKUs Groups
            </StyledButton>
            <StyledButton
              data-testid="services_tab-btn"
              color={resourceType === 'SERVICE' ? 'primary' : 'inherit'}
              onClick={() => setResourceType('SERVICE')}
            >
              Services
            </StyledButton>
          </ButtonGroup>

          <Button
            data-testid="save-system-conf_btn"
            sx={{ ml: 2 }}
            disabled={saveDisabled}
            onClick={onSave}
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Box>
      </WidgetContainer>
    </Box>
  );
};
