import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLayoutEffect, useMemo, useState } from 'react';
import { DOLLAR_CURRENCY } from '../../../../domain/constants';
import { QueryLoader } from '../../../Messaging/QueryStatus';
import makeStyles from '@mui/styles/makeStyles';

export interface TimeIntervalFilterProps {
  value?: string;
  nativeCurrency?: string;
  isLoading: boolean;
  onChange: (value: string) => void;
}

const getCurrencySymbol = (currency?: string) => {
  if (!currency) {
    return;
  } else if (currency === DOLLAR_CURRENCY) {
    return '$';
  }

  const formatter = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    useGrouping: false,
  });

  return formatter.format(1).charAt(0);
};

export const ConsumptionCurrencyFilter = ({
  value,
  nativeCurrency,
  isLoading,
  onChange,
}: TimeIntervalFilterProps) => {
  const [loading, setIsLoading] = useState(false);
  const classes = useStyles();
  const currencySymbol = getCurrencySymbol(nativeCurrency);

  const items = useMemo(() => {
    const items = [
      <MenuItem key={DOLLAR_CURRENCY} value={DOLLAR_CURRENCY}>
        $ {DOLLAR_CURRENCY}
      </MenuItem>,
    ];
    if (nativeCurrency && nativeCurrency !== DOLLAR_CURRENCY) {
      items.push(
        <MenuItem key={nativeCurrency} value={nativeCurrency}>
          {currencySymbol} {nativeCurrency}
        </MenuItem>
      );
    }

    return items;
  }, [nativeCurrency, currencySymbol]);

  /**
   * Allow label input to focus before the loader will start.
   */
  useLayoutEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="currency-filter">Currency</InputLabel>
        {loading && (
          <div className={classes.loader}>
            <QueryLoader />
          </div>
        )}
        {!loading && (
          <Select
            labelId="currency-filter-label"
            id="currency-filter"
            value={value ?? nativeCurrency ?? DOLLAR_CURRENCY}
            onChange={(e) => onChange(e.target.value as string)}
          >
            {items}
          </Select>
        )}
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: theme.spacing(2),
  },
}));
