import { ServiceDefault } from '../../domain/types';
import { apiPaths } from '../../utils/apiPaths';
import { mockSystemConfigData } from '../data/adminData';
import { rest, RestHandler } from 'msw';
import { CrudHandler } from '../CrudHandler';

class Handler extends CrudHandler<ServiceDefault> {
  createHandler(): RestHandler {
    return rest.post(this.endpoint, async (req, res, ctx) => {
      return res(ctx.status(404));
    });
  }

  deleteHandler(): RestHandler {
    return rest.delete(this.endpoint, async (req, res, ctx) => {
      return res(ctx.status(404));
    });
  }

  listHandler(): RestHandler {
    return rest.get(this.endpoint, async (req, res, ctx) => {
      const searchParams = new URLSearchParams(req.url.search);
      const paramValue = searchParams.get('resource_type');
      const resourceType = paramValue === 'SKU' ? 'SKU_GROUP' : 'SERVICE';
      return res(ctx.status(200), ctx.json(mockSystemConfigData(resourceType)));
    });
  }

  updateHandler(): RestHandler {
    return rest.patch(this.endpoint, async (req, res, ctx) => {
      return res(ctx.status(204));
    });
  }
}

export const systemConfigHandler = new Handler(
  apiPaths.systemConfiguration().base,
  []
);
