import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { Service } from '../../../domain/types';
import { GridColDef } from '@mui/x-data-grid';
import { ServiceFormValues, getServiceSchema } from './ServiceSchema';
import { ServiceForm } from './ServiceForm';
import { useStateContext } from '../../../contexts/StateProvider';
import { VOID_ID } from '../../../domain/constants';
import { useMemo, useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export const ServiceAdmin = () => {
  const [onlyUsageServices, setOnlyUsageServices] = useState(true);
  const { selectedCustomerId } = useStateContext();
  const isCustomerSelected = selectedCustomerId !== VOID_ID;

  const columns: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        headerName: 'Service Name',
        field: 'name',
        flex: 0.5,
      },
      {
        headerName: 'Service ID',
        field: 'gcp_service_id',
        flex: 0.5,
      },
      {
        headerName: 'Type',
        field: 'type',
        flex: 1,
      },
    ];

    if (isCustomerSelected) {
      columns.push({
        headerName: 'Counts towards commit',
        field: 'commitIncluded',
        flex: 0.5,
        renderCell: ({ row }) =>
          row.commitIncluded ? <span>true</span> : <span>false</span>,
      });
    }

    return columns;
  }, [isCustomerSelected]);

  const handleIncludeAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOnlyUsageServices(event.target.checked);
  };

  return (
    <CrudContainer<Service, ServiceFormValues>
      CrudHeaderControls={
        <>
          {isCustomerSelected && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={onlyUsageServices}
                    onChange={handleIncludeAllChange}
                  />
                }
                label="Used Services Only"
              />
            </FormGroup>
          )}
        </>
      }
      name="Services"
      apiPath={
        isCustomerSelected
          ? apiPaths.customerServices(selectedCustomerId)
          : apiPaths.services()
      }
      crudApiParams={{ include_all: !onlyUsageServices }}
      columns={columns}
      defaultSortColumn="name"
      FormComponent={ServiceForm}
      validationSchema={getServiceSchema(isCustomerSelected)}
      enableCreate={!isCustomerSelected}
      enableDelete={!isCustomerSelected}
    />
  );
};
