import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReportBreakdown } from '../../../../domain/types';
import { useBreakdownOptions } from '../hooks/useBreakdownOptions';
import StorageIcon from '@mui/icons-material/Storage';

export interface ConsumptionBreakdownFilterProps {
  value: ReportBreakdown;
  isCustomerSelected: boolean;
  onChange: (value: ReportBreakdown) => void;
}

export const ConsumptionBreakdownFilter = ({
  value,
  isCustomerSelected,
  onChange,
}: ConsumptionBreakdownFilterProps) => {
  const classes = useStyes();

  const options = useBreakdownOptions(isCustomerSelected);
  const menuItems = options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <FormControl fullWidth className={classes.inputContainer}>
      <StorageIcon className={classes.inputIcon} fontSize="small" />

      <InputLabel id="breakdown-by-filter">Breakdown by</InputLabel>
      <Select
        labelId="breakdown-by-filter-label"
        id="breakdown-by-filter"
        value={value}
        onChange={(e) => onChange(e.target.value as ReportBreakdown)}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

const useStyes = makeStyles((theme) => ({
  inputContainer: {
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: theme.spacing(4),
    },
  },
  inputIcon: {
    color: theme.palette.grey[500],
    position: 'absolute',
    bottom: theme.spacing(1),
    left: 0,
  },
}));
