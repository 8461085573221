import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Role } from '../../domain/types';
import { useHasRole } from '../../hooks/useHasRole';
import { dashboardPaths } from './useDashboardRoutes';

export interface RoleRestrictedRouteProps extends RouteProps {
  requiredRole: Role | Role[];
}

export const RoleRestrictedRoute = ({
  requiredRole,
  ...rest
}: RoleRestrictedRouteProps) => {
  const authorized = useHasRole(
    ...(Array.isArray(requiredRole) ? requiredRole : [requiredRole])
  );

  return authorized ? (
    <Route {...rest} />
  ) : (
    <Redirect to={{ pathname: dashboardPaths.basePath }} />
  );
};
