import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

interface ChartTitleProps {
  heading: string;
  subHeading?: string;
}

export const ChartTitle = ({ heading, subHeading }: ChartTitleProps) => {
  const classes = useStyles();

  return (
    <div className={classes.chartTitleContainer}>
      <Typography role="heading" className={classes.chartTitle}>
        {heading}
      </Typography>
      {subHeading && (
        <Typography role="heading" className={classes.chartSubtitle}>
          {subHeading}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chartTitle: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[900],
  },
  chartSubtitle: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[700],
  },
  chartTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
