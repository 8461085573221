import { ServerHandler } from '../ServerHandler';
import { rest } from 'msw';
import { mockCurrencyData } from '../data/staticData';
import { mockBillingAccountData } from '../data/adminData';

class StaticHandlers implements ServerHandler {
  public resetData() {}

  public handlers() {
    return [
      // currencies
      rest.get('/api/currencies', async (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockCurrencyData));
      }),
      // Current logged in user
      rest.get('/api/users/current', async (_req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            role: 'system:admin',
            id: 1001,
            email: 'admin@appsbroker.com',
            customers: [],
          })
        );
      }),
      // unassigned billing accounts
      rest.get('/api/billing-accounts/unmapped', async (_req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({ items: mockBillingAccountData })
        );
      }),
    ];
  }
}

export const staticHandler = new StaticHandlers();
