import { CrudContainer } from '../../Crud/CrudContainer';
import { apiPaths } from '../../../utils/apiPaths';
import { BillingAccount } from '../../../domain/types';
import { BillingAccountForm } from './BillingAccountForm';
import {
  BillingAccountSchema,
  BillingAccountValues,
} from './BillingAccountSchema';
import { useStateContext } from '../../../contexts/StateProvider';
import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    headerName: 'Account ID',
    field: 'billingAccountId',
    flex: 1,
  },
  {
    headerName: 'Currency',
    field: 'currency',
    flex: 1,
  },
];

export const BillingAccountAdmin = () => {
  const { selectedCustomerId } = useStateContext();

  const customDelete = {
    verb: 'PATCH',
    apiPath: 'unmap',
    buttonText: 'Unmap',
    action: 'unmap',
    gerund: 'Unmapping',
    past: 'unmapped',
  };

  return (
    <CrudContainer<BillingAccount, BillingAccountValues>
      name="Billing Account"
      apiPath={apiPaths.billingAccount(selectedCustomerId)}
      columns={columns}
      defaultSortColumn="billingAccountId"
      FormComponent={BillingAccountForm}
      validationSchema={BillingAccountSchema}
      customDelete={customDelete}
    />
  );
};
