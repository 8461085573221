import { CommitTrackerFilters } from '../../../domain/types';
import { useMemo } from 'react';
import { formatDate, calculateCommitEndDate } from '../../../utils/dateUtils';
import { ChartTitle } from '../ChartTitle';

interface CommitTrackerReportTitleProps {
  filters: CommitTrackerFilters;
}

export const CommitTrackerReportTitle = ({
  filters,
}: CommitTrackerReportTitleProps) => {
  const { heading, subHeading } = useMemo(() => {
    if (!filters.selectedCommit) {
      return {
        heading: 'Commit Burnup',
      };
    }
    if (!filters.selectedYear) {
      return {
        heading: 'Commit Burnup',
        subHeading: `${formatDate(
          filters.selectedCommit.startDate
        )} - ${formatDate(filters.selectedCommit.endDate)}`,
      };
    }

    const endDate = calculateCommitEndDate(
      filters.selectedCommit,
      filters.selectedYear.startDate
    );

    return {
      heading: `Commit Burnup by ${
        filters.timeInterval === 'doy' ? 'Day' : 'Month'
      }`,
      subHeading: `${formatDate(filters.selectedYear.startDate)} - ${formatDate(
        new Date(endDate)
      )}`,
    };
  }, [filters]);

  return <ChartTitle heading={heading} subHeading={subHeading} />;
};
