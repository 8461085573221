import { Grid } from '@mui/material';
import { Theme, styled } from '@mui/system';
import { ReactNode } from 'react';

interface GenericReportProps {
  renderTitle?: () => ReactNode;
  renderTiles?: () => ReactNode;
  renderFilters?: () => ReactNode;
  renderReport: () => ReactNode;
  renderExport?: () => ReactNode;
  renderPageHeader?: () => ReactNode;
  renderDisclaimer?: () => ReactNode;
}

const NAVBAR_HEIGHT = 64;

const CONTAINER_OFFSET = NAVBAR_HEIGHT;

const spacingToNumber = (strPixels: string): number => {
  return Number(strPixels.slice(0, -2));
};

const computeContainerOffset = (theme: Theme) => {
  const CONTAINER_TOP_MARGIN = spacingToNumber(theme.spacing(2));

  return (
    CONTAINER_OFFSET + CONTAINER_TOP_MARGIN - spacingToNumber(theme.spacing(2))
  );
};

const GenericReportRoot = styled('div')({
  display: 'flex',
  flexGrow: 1,
});

const GenericReportReport = styled('div')(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
  minHeight: 0,
  height: `calc(100vh - ${computeContainerOffset(theme)}px)`,
  width: '100%',
  marginBottom: `-${theme.spacing(3)}`,
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingRight: theme.spacing(3),
}));

const GenericReportTiles = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(2),
}));

const GenericReportContainer = styled('div')(({ theme }) => ({
  minHeight: 0,
  paddingBottom: theme.spacing(2),
  width: '100%',
}));

export const GenericReport = ({
  renderPageHeader,
  renderTiles,
  renderFilters,
  renderReport,
  renderDisclaimer,
}: GenericReportProps) => {
  return (
    <GenericReportRoot data-testid="generic-report-root">
      <GenericReportReport>
        {renderPageHeader && renderPageHeader()}
        {renderTiles && (
          <GenericReportTiles container spacing={2}>
            {renderTiles()}
          </GenericReportTiles>
        )}
        <GenericReportContainer>{renderReport()}</GenericReportContainer>
        {renderDisclaimer && renderDisclaimer()}
      </GenericReportReport>
      {renderFilters && renderFilters()}
    </GenericReportRoot>
  );
};
