import { useQuery } from 'react-query';
import { GCPLabel } from '../../../../domain/types';
import { apiPaths } from '../../../../utils/apiPaths';
import { GET } from '../../../../utils/api';
import { useMemo } from 'react';

interface CustomerLabelsResponse {
  projects: GCPLabel[];
  resources: GCPLabel[];
}

export const useLabelsFilterData = (customerId?: number) => {
  const apiPath = apiPaths.customerLabels(customerId);

  const { data, isLoading, isError } = useQuery<CustomerLabelsResponse>(
    apiPath.key.namespace(),
    () => GET(apiPath.base),
    {
      enabled: !!customerId,
    }
  );

  const customerLabels = useMemo(() => {
    return (
      data ?? {
        projects: [],
        resources: [],
      }
    );
  }, [data]);

  return {
    customerLabels,
    isLoading,
    isError,
  };
};
