export interface PercentageDisplayProps {
  value: number | string;
  symbol?: boolean;
  wholeNumber?: boolean;
}

export const PercentageDisplay = ({
  value,
  symbol = true,
  wholeNumber = false,
}: PercentageDisplayProps) => {
  return <>{percentageFormat(value, symbol, wholeNumber)}</>;
};

export const percentageFormat = (
  value: number | string,
  symbol = true,
  wholeNumber = false
): string => {
  let numberValue = Number(value);
  if (isNaN(numberValue)) {
    return '';
  }
  const maximumFractionDigits = wholeNumber ? 0 : 2;

  const style = symbol ? 'percent' : 'decimal';

  // Multiplication is needed when no symbol is used as it uses style decimal which doesn’t format the number e.g 0.4 to 40
  numberValue = symbol ? Number(value) : Number(value) * 100;

  return numberValue.toLocaleString('en-GB', {
    style: style,
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });
};
