import { useMutation, useQueryClient } from 'react-query';
import { apiPaths } from '../../../../utils/apiPaths';
import {
  SystemConfigItems,
  SystemConfigurationResource,
} from '../SystemConfigurationTypes';
import { PUT } from '../../../../utils/api';
import { ApiErrorResponse } from '../../../../domain/types';
import { useStateContext } from '../../../../contexts/StateProvider';

interface MutateProps {
  selection: SystemConfigItems;
  resourceType: SystemConfigurationResource;
  applyToAll: boolean;
}

const getSystemConfigurationDTO = (selection: SystemConfigItems) => {
  return Object.keys(selection)
    .filter((key) => selection[+key].dirty)
    .map((key) => ({
      id: +key,
      countsTowardCommit: selection[+key].selected,
    }));
};

const saveSystemConfiguration = async ({
  selection,
  resourceType,
  applyToAll,
}: MutateProps) => {
  const services = getSystemConfigurationDTO(selection);
  const apiPath = apiPaths.systemConfiguration();
  return await PUT(apiPath.base, {
    services,
    applyToAll,
    resourceType: resourceType === 'SKU_GROUP' ? 'SKU' : 'SERVICE',
  });
};

export const useSaveSystemConfiguration = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();
  const apiPath = apiPaths.systemConfiguration();

  const { mutate, isLoading } = useMutation<
    unknown,
    ApiErrorResponse,
    MutateProps
  >(saveSystemConfiguration, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(apiPath.key.list());
      showNotification({
        message: 'Changes applied successfully',
        severity: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: 'There was an error. Please try again.',
        severity: 'error',
      });
    },
  });

  return {
    saveSystemConfiguration: mutate,
    isLoading,
  };
};
