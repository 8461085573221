import { useTheme } from '@mui/material';
import {
  Label,
  Legend,
  Line,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Commit,
  CommitAmount,
  CommitType,
  CommitTrackerData,
  NameTotal,
} from '../../domain/types';
import { ChartLoader } from './ChartLoader';
import { useMonetaryFormat } from '../MonetaryDisplay';
import { Y_AXIS_WIDTH } from '../../domain/constants';
import { useMemo } from 'react';
import { ChartContainer } from './ChartContainer';

interface CommitTrackerChartProps {
  data?: CommitTrackerData;
  selectedCommit: Commit | null;
  selectedYear: CommitAmount | null;
  selectedType: CommitType | null;
  softIntersection: NameTotal | null;
  hardIntersection: NameTotal | null;
  isLoading: boolean;
  isError: boolean;
}

export const CommitTrackerChart = ({
  data,
  selectedYear,
  selectedType,
  softIntersection,
  hardIntersection,
  isLoading,
  isError,
}: CommitTrackerChartProps) => {
  const theme = useTheme();
  const formatter = useMonetaryFormat(data?.currency);
  const yAxisFormatter = useMonetaryFormat(data?.currency, true);
  const maxYRange = useMemo(() => {
    const max = data
      ? [
          ...data.consumption.map(({ total }) => total),
          ...data.forecast.map(({ total }) => total),
          ...(selectedYear
            ? [
                selectedYear.hardAmount ?? 0,
                selectedYear.adjustedCommitAmount ?? 0,
                selectedYear.softAmount ?? 0,
              ]
            : []),
        ].reduce((a, b) => {
          return Math.max(a, b);
        })
      : 0;

    // up to the nearest 1000:
    return Math.ceil(max / 1000) * 1000;
  }, [data, selectedYear]);

  const continuationSegment = useMemo(() => {
    // if no data, or either no consumption or forecast data, there's no need
    // for a continuation segment:
    if (!data || !data.consumption.length || !data.forecast.length) {
      return undefined;
    }

    const start = data.consumption[data.consumption.length - 1];
    const end = data.forecast[0];

    // if there is a gap between consumption end and forecast start,
    // then use a continuation line between the two:
    if (Date.parse(end.name) > Date.parse(start.name)) {
      return [
        { x: start.name, y: start.total },
        { x: end.name, y: end.total },
      ];
    }

    return undefined;
  }, [data]);

  const hardCommitOpacity = selectedYear?.adjustedCommitAmount ? 0.5 : 1;

  return (
    <ChartLoader<CommitTrackerData>
      dataReady={(chartData) =>
        chartData.forecast.length > 0 || chartData.consumption.length > 0
      }
      onData={(chartData: CommitTrackerData) => (
        <>
          <ChartContainer>
            <XAxis
              id="name"
              dataKey="name"
              tick={{ fontSize: 12, dy: 5 }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              id="total"
              dataKey="total"
              tickFormatter={yAxisFormatter}
              width={Y_AXIS_WIDTH}
              domain={[0, maxYRange]}
              // enough for the biggest y-value commit reference line, should it be at max y
              padding={{ top: 25 }}
            />
            <Tooltip formatter={formatter} />
            <Legend wrapperStyle={{ position: 'relative' }} />
            <Line
              dataKey="total"
              data={chartData.consumption}
              name="Consumption"
              stroke={theme.palette.primary.main}
              dot={false}
              animationDuration={250}
              animationEasing="linear"
            />
            <ReferenceLine
              stroke={theme.palette.info.main}
              strokeDasharray="3 3"
              segment={continuationSegment}
            />
            <Line
              dataKey="total"
              data={chartData.forecast}
              name="Forecast"
              stroke={theme.palette.info.main}
              strokeDasharray="2 2"
              dot={false}
              animationBegin={250}
              animationDuration={250}
              animationEasing="linear"
            />
            {(selectedType === CommitType.SOFT ||
              selectedType === CommitType.HARD_AND_SOFT) && (
              <ReferenceLine
                y={selectedYear?.softAmount}
                stroke="red"
                strokeDasharray="3 3"
              >
                <Label
                  value={`Soft Commit (${formatter(
                    selectedYear?.softAmount ?? ''
                  )})`}
                  position="insideBottomLeft"
                  fill="red"
                />
              </ReferenceLine>
            )}
            {(selectedType === CommitType.HARD ||
              selectedType === CommitType.HARD_AND_SOFT) && (
              <ReferenceLine
                y={selectedYear?.hardAmount}
                stroke="red"
                strokeDasharray="3 3"
                opacity={hardCommitOpacity}
              >
                <Label
                  value={`Hard Commit (${formatter(
                    selectedYear?.hardAmount ?? ''
                  )})`}
                  position="insideBottomLeft"
                  fill="red"
                  opacity={hardCommitOpacity}
                />
              </ReferenceLine>
            )}
            {selectedYear?.adjustedCommitAmount && (
              <ReferenceLine
                y={selectedYear?.adjustedCommitAmount}
                stroke="red"
                strokeDasharray="4 4"
              >
                <Label
                  value={`Adjusted Commit Target (${formatter(
                    selectedYear?.adjustedCommitAmount ?? ''
                  )})`}
                  position="insideTopRight"
                  fill="red"
                />
              </ReferenceLine>
            )}
            {softIntersection && (
              <ReferenceDot
                x={softIntersection.name}
                y={softIntersection.total}
                r={6}
                stroke="red"
                fill="red"
                fillOpacity="0.2"
              />
            )}
            {hardIntersection && (
              <ReferenceDot
                x={hardIntersection.name}
                y={hardIntersection.total}
                r={6}
                stroke="red"
                fill="red"
                fillOpacity="0.2"
              />
            )}
          </ChartContainer>
        </>
      )}
      data={data}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
