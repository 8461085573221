import { Box, Button, Toolbar, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useRouteMatch } from 'react-router-dom';
import { CustomCrudHeader, CustomCrudAction } from './CrudContainer';

export interface CrudHeaderProps {
  name: string;
  selectedRowId: number | null;
  enableCreate: boolean;
  enableDelete: boolean;
  CrudHeaderControls?: JSX.Element;
  customHeader?: CustomCrudHeader;
  customCreate?: CustomCrudAction;
  customDelete?: CustomCrudAction;
}

export const CrudHeader = ({
  name,
  selectedRowId,
  enableCreate,
  enableDelete,
  CrudHeaderControls,
  customHeader,
  customCreate,
  customDelete,
}: CrudHeaderProps) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const createButtonText = customCreate?.buttonText || 'Create';
  const deleteButtonText = customDelete?.buttonText || 'Delete';

  const title = customHeader?.title || `${name} Admin`;

  return (
    <Toolbar className={classes.root}>
      <Typography variant="h6">{title}</Typography>
      <Box ml={4} className={classes.buttons}>
        {enableCreate && (
          <Button
            data-testid="grid_create_btn"
            size="small"
            variant="text"
            color="primary"
            component={Link}
            startIcon={<AddIcon fontSize="small" />}
            to={`${match.url}/create`}
          >
            {createButtonText}
          </Button>
        )}
        {enableDelete && (
          <Button
            data-testid="grid_delete_btn"
            size="small"
            color="primary"
            component={Link}
            to={`${match.url}/${selectedRowId}/delete`}
            disabled={!selectedRowId}
            startIcon={<DeleteIcon fontSize="small" />}
          >
            {deleteButtonText}
          </Button>
        )}
        {CrudHeaderControls}
      </Box>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ccc',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0.2),
  },
  buttons: {
    '& .MuiButton-root': {
      marginRight: theme.spacing(2),
    },
  },
}));
