import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RecommenderFilterOption } from './hooks/useRecommenderFilterOptions';

interface ComponentProps<T> {
  options: RecommenderFilterOption<T>[];
  label: string;
  value: T[];
  onChange: (value: T[]) => void;
  singleSelect?: boolean;
}

export function RecommenderFilter<T extends string>({
  options,
  label,
  value,
  onChange,
  singleSelect,
}: ComponentProps<T>) {
  const classes = useStyles();
  const handleChange = (event: SelectChangeEvent<T[]>) => {
    const values = event.target.value as T[];
    onChange([values].flat());
  };

  const items = options?.map((option: RecommenderFilterOption<T>) => (
    <MenuItem key={option.value} value={option.value}>
      {!singleSelect && (
        <Checkbox
          color="primary"
          size="small"
          checked={value.indexOf(option.value) > -1}
        />
      )}
      <ListItemText primary={option.label} />
    </MenuItem>
  ));

  return (
    <FormControl fullWidth className={classes.container}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={!singleSelect}
        renderValue={(selected) => (selected as string[]).join(', ')}
        value={value}
        onChange={handleChange}
      >
        {items}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
}));
