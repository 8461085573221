import { useCallback } from 'react';
import { ValidationError } from 'yup';
import { transformToNestObject } from 'react-hook-form';

const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: transformToNestObject(
            (errors.inner ?? []).reduce(
              (allErrors: ValidationError[], currentError: ValidationError) => {
                return currentError.path
                  ? {
                      ...allErrors,
                      [currentError.path]: {
                        type: currentError.type ?? 'validation',
                        message: currentError.message,
                      },
                    }
                  : allErrors;
              },
              {}
            )
          ),
        };
      }
    },
    [validationSchema]
  );

export default useYupValidationResolver;
