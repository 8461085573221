import { WidgetContainer } from './WidgetContainer';
import { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

interface ComponentProps {
  children: ReactNode;
  className?: ClassNameMap | string;
  style?: CSSProperties;
}

export function GridWidgetContainer({
  children,
  className,
  style,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <WidgetContainer
        style={style}
        className={clsx(classes.widgetContainer, className)}
      >
        {children}
      </WidgetContainer>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  widgetContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& .MuiDataGrid-root': {
      border: 'none',
    },
  },
}));
