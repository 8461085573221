import { PagedItems, Project } from '../../domain/types';
import { CrudHandler } from '../CrudHandler';
import { apiPaths } from '../../utils/apiPaths';
import { RequestParams, rest, RestHandler } from 'msw';
import { mockProjectData } from '../data/adminData';

class Handler extends CrudHandler<Project> {
  getKey(params: RequestParams) {
    const { customerId, projectGroupId } = params;
    return `${customerId}_${projectGroupId}`;
  }

  createHandler(): RestHandler {
    return rest.post<{ project_id: number }>(
      this.endpoint,
      async (req, res, ctx) => {
        const { project_id } = req.body;
        const project = mockProjectData.find(
          (project) => project.id === project_id
        );
        if (!project) {
          return res(ctx.status(404));
        }
        this.getDataset(this.getKey(req.params)).set(project.id, project);
        return res(ctx.status(204));
      }
    );
  }

  updateHandler(): RestHandler {
    return rest.put(`${this.endpoint}:id`, async (req, res, ctx) => {
      return res(ctx.status(404));
    });
  }

  additionalHandlers() {
    return [
      rest.get(
        `/api${
          apiPaths.projectGroupProjects(
            ':customerId' as any,
            ':projectGroupId' as any
          ).available
        }`,
        async (req, res, ctx) => {
          const items = Array.from(
            this.getDataset(this.getKey(req.params)).values()
          );
          const availableItems = mockProjectData.filter(
            (project) => !items.map(({ id }) => id).includes(project.id)
          );
          const data: PagedItems<Project> = {
            items: availableItems,
            total_items: availableItems.length,
            page: 1,
            total_pages: 1,
          };
          return res(ctx.status(200), ctx.json(data));
        }
      ),
    ];
  }
}

export const projectGroupProjectsHandler = new Handler(
  apiPaths.projectGroupProjects(
    ':customerId' as any,
    ':projectGroupId' as any
  ).base,
  {
    '1_1': [mockProjectData[0], mockProjectData[1]],
  }
);
