import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import { CustomerDiscountException, SkuGroup } from '../../../../domain/types';
import { PercentageInput } from './PercentageInput';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useCustomerAdminState } from '../hooks/useCustomerAdminState';
import { useMemo } from 'react';
import { VOID_ID } from '../../../../domain/constants';

interface ComponentProps {
  exception: CustomerDiscountException;
  skuGroupsOptions: SkuGroup[];
  onChange: (exception: CustomerDiscountException) => void;
  onActionHandler: (exception: CustomerDiscountException) => void;
  placeholder?: boolean;
}

export const DiscountCardExceptionItem = ({
  exception,
  onChange,
  onActionHandler,
  placeholder,
  skuGroupsOptions,
}: ComponentProps) => {
  const { skuGroups } = useCustomerAdminState();

  const handleExceptionUpdate = (
    field: keyof CustomerDiscountException,
    value: number | null
  ) => {
    onChange({
      ...exception,
      [field]: value,
    });
  };

  const actionDisabled = useMemo(
    () => !exception.skuGroupId || exception.skuGroupId === VOID_ID,
    [exception.skuGroupId]
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-end"
      mb={2}
    >
      <Autocomplete<number>
        id="discount-exception-autocomplete"
        options={skuGroupsOptions.map((item) => item.id)}
        value={exception.skuGroupId}
        onChange={(e, value) => handleExceptionUpdate('skuGroupId', value)}
        getOptionLabel={(option) =>
          skuGroups.find((item) => item.id === option)?.groupName ?? ''
        }
        renderInput={(params) => <TextField {...params} label="Sku Group" />}
        sx={{
          width: 350,
          mr: 2,
        }}
      />
      <PercentageInput
        value={exception.percentage}
        onChange={(value) => handleExceptionUpdate('percentage', value)}
      />
      <IconButton
        disabled={actionDisabled}
        onClick={() => onActionHandler(exception)}
      >
        {placeholder ? <AddIcon /> : <DeleteIcon />}
      </IconButton>
    </Box>
  );
};
