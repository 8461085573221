import { Identity } from '../../domain/types';
import { CrudHandler } from '../CrudHandler';
import { rest } from 'msw';
export interface TestItem extends Identity {
  name: string;
  color: string;
  animal: string;
}

const testCrudData = [
  {
    id: 1,
    name: 'Pete',
    color: 'Blue',
    animal: 'Cat',
  },
  {
    id: 2,
    name: 'Etienne',
    color: 'Purple',
    animal: 'Dog',
  },
  {
    id: 3,
    name: 'Shane',
    color: 'Red',
    animal: 'Rabbit',
  },
];

class TestHandler extends CrudHandler<TestItem> {
  public additionalHandlers() {
    return [
      rest.get(
        `${this.endpoint}simulate-401-response`,
        async (_req, res, ctx) => {
          return res(ctx.status(401));
        }
      ),
    ];
  }
}

export const testHandler = new TestHandler('/test/', testCrudData);
