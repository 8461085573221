import { POST } from '../../../../utils/api';
import { apiPaths } from '../../../../utils/apiPaths';
import { useQuery } from 'react-query';
import { useStateContext } from '../../../../contexts/StateProvider';
import { useMemo } from 'react';
import { useRecommenderFilters } from './useRecommenderFilters';
import { useRecommenderParams } from './useRecommenderParams';

export const useRecommenderData = () => {
  const { selectedCustomerId: customerId } = useStateContext();
  const filtersContext = useRecommenderFilters();
  const params = useRecommenderParams({
    category: filtersContext.filters.category,
    state: filtersContext.filters.state,
    search: filtersContext.search,
    orderBy: filtersContext.sortBy,
    priority: filtersContext.filters.priority,
    entities: filtersContext.filters.entities,
  });

  const apiPath = apiPaths.recommender(customerId);
  const { data, isLoading } = useQuery<any>(apiPath.key.list(params), () =>
    POST(apiPath.base, params)
  );

  const summaryPath = apiPaths.recommenderSummary(customerId);
  const { data: summaryData, isLoading: isSummaryLoading } = useQuery<any>(
    summaryPath.key.list(params),
    () => POST(summaryPath.base, params)
  );

  return useMemo(() => {
    return {
      data: data ?? [],
      isLoading: isLoading || isSummaryLoading,
      summary: summaryData,
    };
  }, [data, isLoading, summaryData, isSummaryLoading]);
};
