import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import { WidgetContainer } from './WidgetContainer';
import clsx from 'clsx';

interface ComponentProps {
  children: ReactNode;
  legend?: boolean;
}

export function ChartWidgetContainer({ children, legend }: ComponentProps) {
  const classes = useStyles();

  return (
    <WidgetContainer
      className={clsx(
        classes.chartContainer,
        legend && classes.legendContainer
      )}
    >
      {children}
    </WidgetContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  legendContainer: {
    paddingBottom: theme.spacing(4),
  },
}));
