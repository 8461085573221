import { CrudHeader } from './CrudHeader';
import React, { useCallback, useState } from 'react';
import { ApiPath } from '../../utils/apiPaths';
import { useCrudList } from './grid/useCrudList';
import { GridColDef } from '@mui/x-data-grid';
import { CrudDataGrid } from './grid/CrudDataGrid';
import { AnyObjectSchema } from 'yup';
import { CrudDeleteModal } from './delete/CrudDeleteModal';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { CrudCreateModal } from './create/CrudCreateModal';
import { CrudFormProvider } from './form/CrudFormProvider';
import {
  CrudUpdateModal,
  CrudUpdateModalManual,
} from './update/CrudUpdateModal';
import { CrudFormComponent } from './form/CrudFormComponent';
import Lazy from 'yup/lib/Lazy';

export type CrudValidationSchema = AnyObjectSchema | Lazy<any, any>;
export type CrudValueMapper<Values, SubmittedValues> = (
  values: Values
) => SubmittedValues;

interface CustomCrudActionProps {
  verb?: string;
  apiPath?: string;
  buttonText?: string;
  action?: string;
  gerund?: string;
  past?: string;
  title?: string;
}

export type CustomCrudAction = CustomCrudActionProps | null;

interface CustomCrudHeaderProps {
  title: string;
}

export type CustomCrudHeader = CustomCrudHeaderProps | null;

export interface CrudContainerProps<T, Values, SubmittedValues = Values> {
  name: string;
  apiPath: ApiPath;
  columns: GridColDef[];
  defaultSortColumn: string;
  FormComponent: CrudFormComponent<T>;
  validationSchema: CrudValidationSchema;
  enableCreate?: boolean;
  enableUpdate?: boolean;
  enableDelete?: boolean;
  editText?: string;
  overrideRequest?: boolean;
  customHeader?: CustomCrudHeader;
  customCreate?: CustomCrudAction;
  customDelete?: CustomCrudAction;
  valueMapper?: CrudValueMapper<Values, SubmittedValues>;
  crudApiParams?: Record<string, string | number | boolean>;
  CrudHeaderControls?: JSX.Element;
  enableDeleteOnRow?: (row: T) => boolean;
  enableUpdateOnRow?: (row: T) => boolean;
}

export function CrudContainer<T, Values, SubmittedValues = Values>({
  name,
  apiPath,
  columns,
  defaultSortColumn,
  FormComponent,
  validationSchema,
  enableCreate = true,
  enableDelete = true,
  enableUpdate = true,
  editText = '',
  overrideRequest = false,
  customHeader = null,
  customCreate = null,
  customDelete = null,
  valueMapper = (values) => values as any,
  crudApiParams,
  CrudHeaderControls,
  enableDeleteOnRow,
  enableUpdateOnRow,
}: CrudContainerProps<T, Values, SubmittedValues>) {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

  const { data, isLoading, error } = useCrudList<T>({
    apiPath,
    options: crudApiParams,
  });

  const { url: baseUrl, path: routePath } = useRouteMatch();

  const { push } = useHistory();
  const closeModal = useCallback(
    (unselect?: boolean) => {
      if (unselect) {
        setSelectedRowId(null);
      }
      push(baseUrl);
    },
    [push, baseUrl]
  );

  return (
    <>
      <CrudHeader
        name={name}
        selectedRowId={selectedRowId}
        enableCreate={enableCreate}
        enableDelete={enableDelete}
        CrudHeaderControls={CrudHeaderControls}
        customHeader={customHeader}
        customCreate={customCreate}
        customDelete={customDelete}
      />
      <CrudDataGrid<T>
        columns={columns}
        data={data}
        loading={isLoading}
        error={error}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        defaultSortColumn={defaultSortColumn}
        enableUpdate={enableUpdate}
        enableDelete={enableDelete}
        editText={editText}
        enableDeleteOnRow={enableDeleteOnRow}
        enableUpdateOnRow={enableUpdateOnRow}
      />
      {enableUpdate && (
        <Route path={`${routePath}/:id/edit`} exact>
          <CrudFormProvider validationSchema={validationSchema}>
            {!overrideRequest ? (
              <CrudUpdateModal
                name={name}
                apiPath={apiPath}
                onClose={closeModal}
                FormComponent={FormComponent}
                valueMapper={valueMapper}
              />
            ) : (
              <CrudUpdateModalManual
                name={name}
                apiPath={apiPath}
                onClose={closeModal}
                FormComponent={FormComponent}
                valueMapper={valueMapper}
                data={data}
              />
            )}
          </CrudFormProvider>
        </Route>
      )}
      {enableDelete && (
        <Route path={`${routePath}/:id/delete`} exact>
          <CrudDeleteModal
            onClose={() => closeModal(true)}
            customDelete={customDelete}
            apiPath={apiPath}
          />
        </Route>
      )}
      {enableCreate && (
        <Route path={`${routePath}/create`} exact>
          <CrudFormProvider validationSchema={validationSchema}>
            <CrudCreateModal
              name={name}
              apiPath={apiPath}
              onClose={closeModal}
              valueMapper={valueMapper}
              customCreate={customCreate}
            >
              <FormComponent />
            </CrudCreateModal>
          </CrudFormProvider>
        </Route>
      )}
    </>
  );
}
