import { Tooltip, XAxis, YAxis, Bar, Area } from 'recharts';
import { ChartLoader } from './ChartLoader';
import {
  AggregatedConsumptionItemData,
  AggregatedConsumptionItemValue,
  ReportCostType,
  ConsumptionItemValue,
  ReportTimeInterval,
} from '../../domain/types';
import { useMonetaryFormat } from '../MonetaryDisplay';
import { Y_AXIS_WIDTH } from '../../domain/constants';
import { ChartContainer } from './ChartContainer';
import { ChartItemsTooltip } from './ChartItemsTooltip';
import { getResolvedArrayValue } from '../../utils/arrayUtils';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useChartSeriesColors } from '../../theme/useChartSeriesColors';
import { memo, useCallback, useState } from 'react';
import { useChartContainerContext } from './ChartProvider';
import { useBarGap } from './useBarGap';
import clsx from 'clsx';

interface ConsumptionByitemChartProps {
  data?: AggregatedConsumptionItemData;
  chartItems: ConsumptionItemValue[];
  itemMap: Record<string, number>;
  costType: ReportCostType;
  isLoading: boolean;
  isError: boolean;
  timeInterval: ReportTimeInterval;
  onDrillDown: (item: string) => void;
}

export const ConsumptionByItemChart = memo(
  ({
    data,
    chartItems,
    itemMap,
    costType,
    isLoading,
    isError,
    timeInterval,
    onDrillDown,
  }: ConsumptionByitemChartProps) => {
    const { setActiveHoverElement } = useChartContainerContext();
    const [isHoverActive, setIsHoverActive] = useState(false);

    const classes = useStyles();

    const formatter = useMonetaryFormat(data?.currency);
    const yFormatter = useMonetaryFormat(data?.currency, true);

    const dataToShow =
      costType === 'gross' ? data?.groupedData.gross : data?.groupedData.net;

    const colors = useChartSeriesColors();
    const theme = useTheme();

    const renderCustomTooltip = useCallback(
      (props: any) => {
        return (
          <ChartItemsTooltip
            payload={props.payload}
            customFormatter={formatter}
          />
        );
      },
      [formatter]
    );

    const barIterator = useBarGap();

    return (
      <ChartLoader<AggregatedConsumptionItemValue[]>
        dataReady={(data) => data.length > 0}
        onData={(data: AggregatedConsumptionItemValue[]) => (
          <>
            <ChartContainer data={data}>
              <XAxis dataKey="name" tick={{ fontSize: 12, dy: 5 }} />
              <YAxis tickFormatter={yFormatter} width={Y_AXIS_WIDTH} />
              <Tooltip content={renderCustomTooltip} />

              {chartItems.map(({ item }, index) => {
                const color =
                  getResolvedArrayValue(colors, itemMap[item]) ??
                  theme.palette.primary.main;

                if (timeInterval === 'doy') {
                  return (
                    <Area
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => {
                        setIsHoverActive(true);
                        setActiveHoverElement(item);
                      }}
                      onMouseLeave={() => {
                        setIsHoverActive(false);
                        setActiveHoverElement(undefined);
                      }}
                      onDoubleClick={() => onDrillDown(item)}
                      className={isHoverActive ? classes.chartItem : ''}
                      key={`${item}-${index}`}
                      dataKey={item}
                      stroke={color}
                      fill={color}
                      stackId={1}
                      strokeWidth={0}
                      activeDot={false}
                    />
                  );
                } else {
                  return (
                    <Bar
                      isAnimationActive={false}
                      className={clsx(
                        'item_bar',
                        `item__${item}`,
                        isHoverActive && classes['chartItem']
                      )}
                      onMouseEnter={() => {
                        setIsHoverActive(true);
                        setActiveHoverElement(item);
                      }}
                      onMouseLeave={() => {
                        setIsHoverActive(false);
                        setActiveHoverElement(undefined);
                      }}
                      onDoubleClick={() => {
                        onDrillDown(item);
                      }}
                      key={`${item}-${index}`}
                      dataKey={item}
                      stroke={color}
                      fill={color}
                      stackId={1}
                      shape={(props: any) => {
                        const { fill, x, y, width, height } = props;
                        const gap = barIterator({
                          width,
                          x,
                        });

                        return (
                          <>
                            <rect
                              x={x - gap.x}
                              y={y}
                              height={height}
                              width={width + gap.width}
                              fillOpacity={0}
                            />
                            <rect
                              className="inner-bar"
                              style={{ cursor: 'pointer' }}
                              x={x}
                              y={y}
                              width={width}
                              height={height}
                              fill={fill}
                            />
                          </>
                        );
                      }}
                    />
                  );
                }
              })}
            </ChartContainer>
          </>
        )}
        data={dataToShow}
        isLoading={isLoading}
        isError={isError}
      />
    );
  }
);

const useStyles = makeStyles({
  chartItem: {
    opacity: 0.7,
    userSelect: 'none',
    '&:hover': {
      opacity: 1,
    },
  },
});
