import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useStateContext } from '../../../contexts/StateProvider';
import { CrudContainer } from '../../Crud/CrudContainer';
import {
  Apportionment,
  ApportionmentAllocationType,
  ApportionmentSplitType,
  ServiceApportionment,
} from '../../../domain/types';
import { apiPaths } from '../../../utils/apiPaths';
import { enumStringFormat } from '../../../utils/stringUtils';
import { ServiceApportionmentForm } from './ServiceApportionmentForm';
import {
  ServiceApportionmentFormValues,
  ServiceApportionmentSchema,
  SubmittedServiceApportionmentFormValues,
} from './ServiceApportionmentSchema';
import { useAllServices } from '../Services/useAllServices';
import { useListProjectGroups } from './useListProjectGroups';
import { useListProjects } from './useListProjects';
import { QueryLoader } from '../../Messaging/QueryStatus';
import { PercentageDisplay } from '../../PercentageDisplay';

export const ServiceApportionmentsAdmin = () => {
  const { selectedCustomerId } = useStateContext();
  const {
    isLoading: isServicesLoading,
    services,
    serviceIdsToName,
  } = useAllServices(selectedCustomerId, Number());
  const {
    isLoading: isProjectGroupsLoading,
    projectGroupIdsToName,
  } = useListProjectGroups(selectedCustomerId);
  const { isLoading: isProjectsLoading, projectIdsToName } = useListProjects(
    selectedCustomerId
  );

  const columns: GridColDef[] = [
    {
      headerName: 'Service Name',
      field: 'service',
      flex: 0.5,
      valueGetter: ({ row }) => {
        return serviceIdsToName.get(row.serviceId);
      },
    },
    {
      headerName: 'Service ID',
      flex: 0.5,
      field: 'gcpServiceId',
      valueGetter: ({ row }) => {
        return services.find((serv) => serv.id === row.serviceId)
          ?.gcp_service_id;
      },
    },
    {
      headerName: 'Service Type',
      field: 'type',
      flex: 0.5,
      valueGetter: ({ row }) => {
        const type =
          services.filter((service) => service.id === row.serviceId)[0]?.type ??
          '';
        return type;
      },
    },
    {
      headerName: 'Allocation Type',
      field: 'allocationType',
      flex: 0.5,
      valueGetter: ({ value }) => {
        const allocationType = value as ApportionmentAllocationType;
        if (allocationType) {
          return enumStringFormat(allocationType);
        }
        return '';
      },
    },
    {
      headerName: 'Split Type',
      field: 'splitType',
      flex: 0.5,
      valueGetter: ({ value }) => {
        const splitType = value as ApportionmentSplitType;
        if (splitType) {
          return enumStringFormat(splitType);
        }
        return '';
      },
    },
    {
      headerName: 'Apportionments',
      field: 'apportionments',
      flex: 1,
      renderCell: ({ row }) => {
        const apportionments = row.apportionments as Apportionment[];
        const allocationType = row.allocationType as ApportionmentAllocationType;
        return (
          <>
            {(apportionments ?? [])
              .map((apportionment: Apportionment) => {
                const projectOrGroupName =
                  allocationType === ApportionmentAllocationType.PROJECT
                    ? projectIdsToName.get(Number(apportionment.projectId))
                    : projectGroupIdsToName.get(
                        Number(apportionment.projectGroupId)
                      );
                const apportionmentPercentage = !!apportionment.percentage
                  ? Math.round(apportionment.percentage * 100)
                  : Math.round((1 / apportionments.length) * 100);
                <PercentageDisplay
                  value={apportionmentPercentage}
                  symbol={true}
                  wholeNumber={true}
                />;
                return `${projectOrGroupName}: ${apportionmentPercentage}%`;
              })
              .join(', ')}
          </>
        );
      },
    },
  ];

  if (isServicesLoading || isProjectGroupsLoading || isProjectsLoading) {
    return <QueryLoader />;
  }
  return (
    <CrudContainer<
      ServiceApportionment,
      ServiceApportionmentFormValues,
      SubmittedServiceApportionmentFormValues
    >
      name="Service Apportionment"
      apiPath={apiPaths.serviceApportionments(selectedCustomerId)}
      columns={columns}
      enableCreate={true}
      enableDelete={true}
      defaultSortColumn="splitType"
      FormComponent={ServiceApportionmentForm}
      validationSchema={ServiceApportionmentSchema}
      valueMapper={(values) => {
        /**
         * Value mapper is used to ensure all whole percentage numbers are converted between 0 and 1
         * The api expects 0 - 1 and will return 0 - 1.
         * You will find on the UI these values are formatted as whole numbers  0 - 100
         */
        const { apportionments, ...submittedValues } = values;
        const formattedApportionments =
          apportionments?.map((apportionment) => {
            const percentage = apportionment.percentage
              ? apportionment.percentage / 100
              : undefined;
            return {
              ...apportionment,
              percentage,
            };
          }) ?? [];

        const selectedService = services.find(
          (service) => service.id === submittedValues.serviceId
        );
        submittedValues.serviceName = selectedService?.name;

        return {
          ...submittedValues,
          apportionments: formattedApportionments,
        };
      }}
    />
  );
};
