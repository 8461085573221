import { lazy, mixed, object, SchemaOf, string } from 'yup';
import { Project, ProjectContactType } from '../../../domain/types';

export type ProjectFormValues = {
  description: string;
  contacts?: Project['contacts'];
};

/*
 * If a contact is required it must include both the name and email
 * Otherwise it is not set and is undefined
 */
const contactSchema = (required: boolean) => {
  if (required) {
    return object({
      name: string().required(),
      email: string().email().required(),
    }).required();
  } else {
    return mixed().equals([undefined]);
  }
};

export const ProjectSchema = lazy<SchemaOf<ProjectFormValues>>(
  (values: ProjectFormValues) => {
    // these values are always present
    const baseSchema = object({
      description: string().defined(),
    });

    const { contacts } = values;

    if (contacts) {
      return baseSchema.shape({
        contacts: object({
          [ProjectContactType.BUSINESS_OWNER]: contactSchema(
            !!contacts.BUSINESS_OWNER
          ),
          [ProjectContactType.PRODUCT_MANAGER]: contactSchema(
            !!contacts.PRODUCT_MANAGER
          ),
        }),
      });
    } else {
      return baseSchema.shape({
        contacts: mixed().equals([undefined]),
      });
    }
  }
);
