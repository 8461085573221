import { useMemo } from 'react';
import { ReportBreakdown } from '../../../../domain/types';

type BreakdownOption = {
  value: ReportBreakdown;
  label: string;
};

const singleCustomerBreakdown: BreakdownOption[] = [
  {
    value: 'overall',
    label: 'Overall',
  },
  {
    value: 'project',
    label: 'Project',
  },
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'sku',
    label: 'SKU',
  },
  {
    value: 'project_group',
    label: 'Project Group',
  },
  {
    value: 'billing_account',
    label: 'Billing Account',
  },
  {
    value: 'project_label',
    label: 'Project Label',
  },
  {
    value: 'resource_label',
    label: 'Resource Label',
  },
];

const allCustomersBreakdown: BreakdownOption[] = [
  {
    value: 'overall',
    label: 'Overall',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'billing_account',
    label: 'Billing Account',
  },
  {
    value: 'service',
    label: 'Service',
  },
];

export const useBreakdownOptions = (isCustomerSelected: boolean) => {
  return useMemo(
    () =>
      isCustomerSelected ? singleCustomerBreakdown : allCustomersBreakdown,
    [isCustomerSelected]
  );
};
