import { useMemo } from 'react';
import { BurndownFilters } from '../../../domain/types';

export function useIsEmptySelection(appliedFilters: BurndownFilters) {
  return useMemo(() => {
    if (!appliedFilters.projectFilters) {
      return true;
    }
    const { groups, projects } = appliedFilters.projectFilters;
    return groups.length === 0 && projects.length === 0;
  }, [appliedFilters]);
}
