import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GET, PUT } from '../../../utils/api';
import { ApiPath } from '../../../utils/apiPaths';
import { useCallback, useRef } from 'react';
import { useStateContext } from '../../../contexts/StateProvider';
import { ApiErrorResponse } from '../../../domain/types';

interface Response<T, Values> {
  item?: T;
  onSubmit: (values: Values) => void;
  isDataLoading: boolean;
  hasDataError: boolean;
  isUpdating: boolean;
  hasUpdateError: boolean;
  updateErrorMessage?: string;
}

interface CrudUpdateProps {
  id: number;
  apiPath: ApiPath;
  onSuccess: () => void;
}
export const useCrudUpdate = <T, Values>({
  id,
  apiPath,
  onSuccess,
}: CrudUpdateProps): Response<T, Values> => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();

  // fetch item data
  const hasFetchedItem = useRef(false);
  const {
    data: item,
    isLoading: isDataLoading,
    isError: hasDataError,
  } = useQuery<T>(apiPath.key.item(id), () => GET(apiPath.item(id)), {
    enabled: !hasFetchedItem.current,
    onSuccess: () => {
      hasFetchedItem.current = true;
    },
  });

  const { mutate, isLoading, isError, error } = useMutation<
    T,
    ApiErrorResponse,
    Values
  >((data: Values) => PUT(apiPath.item(id), data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(apiPath.key.list());
      queryClient.removeQueries(apiPath.key.item(id));
      showNotification({
        message: 'Item updated successfully',
        severity: 'success',
      });
      onSuccess();
    },
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      mutate(values);
    },
    [mutate]
  );

  return {
    onSubmit,
    item,
    isDataLoading: isDataLoading,
    hasDataError: hasDataError,
    isUpdating: isLoading,
    hasUpdateError: isError,
    updateErrorMessage: error?.response?.data.detail,
  };
};

interface CrudUpdateManualProps {
  id: number;
  apiPath: ApiPath;
  onSuccess: () => void;
  dataItem?: any;
}
export const useCrudUpdateManual = <T, Values>({
  id,
  apiPath,
  onSuccess,
  dataItem,
}: CrudUpdateManualProps): Response<T, Values> => {
  const queryClient = useQueryClient();
  const { showNotification } = useStateContext();

  // fetch item data
  const isDataLoading = dataItem ? false : true;
  const hasDataError = false;
  const item = dataItem ? dataItem[0] : undefined;

  const { mutate, isLoading, isError, error } = useMutation<
    T,
    ApiErrorResponse,
    Values
  >((data: Values) => PUT(apiPath.item(id), data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(apiPath.key.list());
      queryClient.removeQueries(apiPath.key.item(id));
      showNotification({
        message: 'Item updated successfully',
        severity: 'success',
      });
      onSuccess();
    },
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      mutate(values);
    },
    [mutate]
  );

  return {
    onSubmit,
    item,
    isDataLoading: isDataLoading,
    hasDataError: hasDataError,
    isUpdating: isLoading,
    hasUpdateError: isError,
    updateErrorMessage: error?.response?.data.detail,
  };
};
