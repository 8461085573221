import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { FILTER_BAR_WIDTH } from './Dashboard/FilterBar.stylesheet';

interface ComponentProps {
  children?: ReactNode;
  title: string;
  reportFilterOn?: boolean;
}

export function PageHeader({
  children,
  title,
  reportFilterOn,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={2}
      mb={2}
      className={clsx(reportFilterOn && classes.filterBarHeader)}
    >
      <Typography variant="h6" className={classes.pageHeaderTitle}>
        {title}
      </Typography>
      <Box display="flex" alignContent="flex-start" justifyContent="flex-start">
        {children}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  filterBarHeader: {
    paddingRight: FILTER_BAR_WIDTH,
  },
  pageHeaderTitle: {
    color: theme.palette.grey['800'],
  },
}));
